import { Box } from '@mui/material';
import { useState } from 'react';

export const SIGNATURE_TYPES = {
  PHYSICIAN: 'Evaluating Physician',
  RADTECH: 'Radiologic Technologist',
  MEDTECH: 'Medical Technologist',
  RADIO: 'Radiologist',
  PATHO: 'Anatomic and Clinic Pathologist',
} as const;

type SignatureType = (typeof SIGNATURE_TYPES)[keyof typeof SIGNATURE_TYPES];

interface StaffMember {
  name: string;
  title: string;
  licenseNumber: string;
  signature: string;
}

type StaffSignatures = {
  [K in SignatureType]: StaffMember[];
};

const STAFF_SIGNATURES: StaffSignatures = {
  [SIGNATURE_TYPES.PHYSICIAN]: [
    {
      name: 'Francis Terukazu Sunagawa, MD',
      title: SIGNATURE_TYPES.PHYSICIAN,
      licenseNumber: 'LN 96317',
      signature: '/elsewhere_signatory/Francis Sunagawa.png',
    },
  ],
  [SIGNATURE_TYPES.RADTECH]: [
    {
      name: 'Joselito Espeleta Dilla',
      title: SIGNATURE_TYPES.RADTECH,
      licenseNumber: 'LN 4630',
      signature: '/elsewhere_signatory/Joselito Dilla.png',
    },
  ],
  [SIGNATURE_TYPES.RADIO]: [
    {
      name: 'Jenifer T. Carlos Mariano',
      title: SIGNATURE_TYPES.RADIO,
      licenseNumber: 'DPBR0104423',
      signature: '/elsewhere_signatory/Jenifer Mariano.png',
    },
  ],
  [SIGNATURE_TYPES.MEDTECH]: [
    {
      name: 'Alleleen B. Valcroza, RMT',
      title: SIGNATURE_TYPES.MEDTECH,
      licenseNumber: 'LN 0046795',
      signature: '/elsewhere_signatory/Alleleen Valcroza.png',
    },
    {
      name: 'Jade Ella B. Vergara, RMT',
      title: SIGNATURE_TYPES.MEDTECH,
      licenseNumber: 'LN 0114334',
      signature: '/elsewhere_signatory/Jade Ella Vergara.png',
    },
  ],
  [SIGNATURE_TYPES.PATHO]: [
    {
      name: 'Karen B. Damian, MD, DPSP',
      title: SIGNATURE_TYPES.PATHO,
      licenseNumber: 'LN 0096314',
      signature: '/elsewhere_signatory/Karen Damian.png',
    },
  ],
};

interface SignatureSelectorProps {
  type: SignatureType;
  defaultStaff?: StaffMember;
  isPrinting?: boolean;
  signatureWidth?: string;
}

const SignatureSelector: React.FC<SignatureSelectorProps> = ({
  type,
  defaultStaff,
  isPrinting = false,
  signatureWidth,
}) => {
  const [selectedStaff, setSelectedStaff] = useState<StaffMember>(
    defaultStaff || STAFF_SIGNATURES[type]?.[0] || { name: '', title: '', licenseNumber: '', signature: '' }
  );
  const staffList = STAFF_SIGNATURES[type] || [];

  const SignatureBlock: React.FC = () => {
    if (!selectedStaff) return null;

    return (
      <div className="text-center">
        <img
          src={selectedStaff.signature}
          alt={`${selectedStaff.name}'s signature`}
          className="h-16 mx-auto mb-2"
          style={{ width: 'auto', height: '50px', marginBottom: '-20px' }}
        />
        <div className="font-bold" style={{ fontSize: '11px' }}>
          {selectedStaff.name}
        </div>
        <div style={{ fontSize: '9px' }}>{selectedStaff.title}</div>
        <div style={{ fontSize: '9px' }}>{selectedStaff.licenseNumber}</div>
      </div>
    );
  };

  return (
    <Box className="text-center">
      {!isPrinting && staffList.length > 1 && (
        <div style={{ marginTop: '20px' }} className={isPrinting ? 'hidden' : ''}>
          <select
            value={selectedStaff.name}
            onChange={(e) => {
              const newStaff = staffList.find((staff) => staff.name === e.target.value);
              if (newStaff) {
                setSelectedStaff(newStaff);
              }
            }}
            className="w-full p-2 border rounded-md mb-2"
          >
            {staffList.map((staff) => (
              <option key={staff.name} value={staff.name}>
                {staff.name}
              </option>
            ))}
          </select>
        </div>
      )}
      <SignatureBlock />
    </Box>
  );
};

export default SignatureSelector;
