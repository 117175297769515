import * as yup from 'yup';

import { EntityFields } from '../../../utils';
import { Box, Typography } from '@mui/material';

export const createAppointmentLegendSchema = yup.object().shape({
  tag_name: yup.string().required('Tag is required.').max(50, 'Tag name must be less than or equal to 50 characters'),
});

export const APL_ENTITY_NAME = 'Appointment Legend';

export interface AppointmentLegendModel {
  id: number;
  tag_name: string;
  tag_color: string;
  is_active: boolean;
}

export interface AppointmentLegendInput {
  tag_name: string;
  tag_color: string;
}
export const AppointmentLegendInitialValues: AppointmentLegendInput = {
  tag_name: '',
  tag_color: '#000',
};

export const appointmentLegendFields: EntityFields[] = [
  { fieldName: 'tag_name', displayName: 'Tag Name' },
  { fieldName: 'tag_color', displayName: 'Tag Color', type: 'color' },
];

export const appointmentLegendFieldsTable: EntityFields[] = [
  { fieldName: 'tag_name', displayName: 'Tag Name' },
  {
    fieldName: 'tag_color',
    displayName: 'Tag Colors',
    type: 'component',
    align: 'left',
    span: 0.5,
    renderCell: (params) => (
      <Box
        sx={{
          backgroundColor: params.value,
          width: '50px',
          height: '20px',
          borderRadius: '4px',
        }}
      >
        <Typography sx={{ visibility: 'hidden' }}>.</Typography>
      </Box>
    ),
  },
];

// FORM
export const appointmentLegendFormFields: string[] = ['tag_name', 'tag_color'];

// TABLE

export const appointmentLegendTableColumns: string[] = ['tag_name', 'tag_color'];
