import { Typography } from '@mui/material';
import { TabProps } from 'core/components/CustomTabs';
import { CareGoPage } from 'core/PageBuilder';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import React, { useContext, useEffect, useState } from 'react';
import { BreadcrumbContext } from 'core/context/breadcrumb.context';
import BentoOutlinedIcon from '@mui/icons-material/BentoOutlined';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import ViewCategories from 'company/screens/InventorySystem/Inventory/ViewCategories';
import ProductSettings from './ProductSettings';
import StraightenOutlinedIcon from '@mui/icons-material/StraightenOutlined';
import { UserContext } from 'core/context/user.context';
import ProductUOMTable from 'company/screens/InventorySystem/Inventory/ProductUOM/ProductUOMTable';
import ProductUOM from 'company/screens/InventorySystem/Inventory/ProductUOM';
export default function () {
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const { user } = useContext(UserContext);
  const [selected, setSelected] = useState<number>(0);
  useEffect(() => {
    setBreadcrumb([{ label: 'Inventory Settings' }]);
  }, []);

  const tabs: TabProps[] = [
    {
      label: 'Product Settings',
      content: <ProductSettings />,
      icon: <LocalOfferOutlinedIcon />,
      hidden:
        !user?.authorities?.includes('MANAGE_INVENTORY') ||
        !user?.authorities?.includes('MANAGE_PRODUCTS_AND_VARIANTS'),
    },
    {
      label: 'Unit of Measurement',
      content: <ProductUOM />,
      icon: <StraightenOutlinedIcon />,
      //   hidden: !user?.authorities?.includes('MANAGE_CLINICS') || !user?.authorities?.includes('VIEW_CLINICS'),
    },
  ];
  return (
    <CareGoPage
      header="Inventory Settings"
      tabs={tabs}
      selected={selected}
      tabGroupId="inventory_settings"
      setSelected={setSelected}
      tabMinWidth="160px"
    ></CareGoPage>
  );
}
