import { Box, Button, Card, CardContent, LinearProgress, Typography, useTheme } from '@mui/material';
import { CustomContainer, HideOrShowComponent } from 'core/components';
import React, { useContext, useEffect, useState } from 'react';

import { Check as CheckIcon } from '@mui/icons-material';
import { FacilityContext } from 'core/context/facility.context';
import { UserContext } from 'core/context/user.context';
import { getPatients } from 'company/api/patient';
import { getTotalStorage } from 'company/api/file-upload';
import { getUsersAssigned } from 'company/api/users';
import styled from 'styled-components';

interface PlanHeaderProps {
  bgcolor: string;
}

const Plans = () => {
  const theme = useTheme();
  const { facility } = useContext(FacilityContext);
  const { user } = useContext(UserContext);

  const primaryColor = theme.palette.primary.main;
  const secondaryColor = theme.palette.secondary.main;
  const standardPlanColor = 'black';
  const plans = [
    {
      title: 'Free Trial',
      price: 'Always Free',
      features: ['Enroll up to 5 patients', '20 SMS Credits valid for 7 days'],
    },
    {
      title: 'Starter Plan',
      price: 'Php 399 / month',
      features: ['Enroll up to 100 patients', '150 SMS Credits valid for 1 month'],
    },
    {
      title: 'Standard Plan',
      price: 'Php 1299 / month',
      features: ['Enroll unlimited patients', '2500 SMS Credits valid for 1 year'],
    },
  ];

  const currentPlan = 'Free Trial';
  const [userCount, setUserCount] = useState(0);
  const [userLimit, setUserLimit] = useState(0);
  const [patientCount, setPatientCount] = useState(0);
  const [patientLimit, setPatientLimit] = useState(0);
  const [storageCount, setStorageCount] = useState({ value: 0, unit: 'KB' });
  const [storageLimit, setStorageLimit] = useState(0);

  const userProgress = (userCount / userLimit) * 100;
  const patientProgress = (patientCount / patientLimit) * 100;

  const storageProgress = () => {
    let storageInGB;
    if (storageCount.unit === 'GB') {
      storageInGB = storageCount.value;
    } else if (storageCount.unit === 'MB') {
      storageInGB = storageCount.value / 1024;
    } else {
      storageInGB = storageCount.value / (1024 * 1024);
    }

    const progressPercentage = (storageInGB / storageLimit) * 100;
    return progressPercentage;
  };

  useEffect(() => {
    getUserCount();
    getPatientCount();
    getStorageCount();
  }, []);

  useEffect(() => {
    if (user) {
      setUserLimit(user.company?.user_limit ?? 0);
      setPatientLimit(user.company?.patient_limit ?? 0);
      setStorageLimit(user.company?.storage_limit ?? 0);
    }
  }, [user]);

  const getUserCount = () => {
    getUsersAssigned(facility.id).then((users) => {
      setUserCount(users.data.data.length);
    });
  };

  const getPatientCount = () => {
    getPatients(facility.id).then((patients) => {
      setPatientCount(patients.data.meta.total);
    });
  };

  const getStorageCount = () => {
    getTotalStorage(facility.id).then((storage) => {
      const storageInKB = storage.data;
      let storageToDisplay;
      let unit;

      // Convert KB to MB and GB
      const storageInMB = storageInKB / 1024;
      const storageInGB = storageInMB / 1024;

      if (storageInGB >= 1) {
        storageToDisplay = parseFloat(storageInGB.toFixed(2));
        unit = 'GB';
      } else if (storageInMB >= 1) {
        storageToDisplay = parseFloat(storageInMB.toFixed(2));
        unit = 'MB';
      } else {
        storageToDisplay = storageInKB;
        unit = 'KB';
      }

      setStorageCount({ value: storageToDisplay, unit: unit });
    });
  };

  const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    marginTop: '20px',
    marginBottom: '20px',
    '& .MuiLinearProgress-bar': {
      borderRadius: 5,
    },
  }));

  return (
    <CustomContainer>
      <Box sx={{ width: '100%', maxWidth: 500, mb: 4, p: 2, border: '1px solid #ddd', borderRadius: 2, boxShadow: 1 }}>
        <Box sx={{ mb: 3 }}>
          <Typography variant="h6">Users</Typography>
          <Typography variant="body2">
            {userCount} / {userLimit}
          </Typography>
          <StyledLinearProgress variant="determinate" value={userProgress} />
        </Box>
        <Box>
          <Typography variant="h6">Patients</Typography>
          <Typography variant="body2">
            {patientCount} / {patientLimit}
          </Typography>
          <StyledLinearProgress variant="determinate" value={patientProgress} />
        </Box>
        <Box>
          <Typography variant="h6">Storage</Typography>
          <Typography variant="body2">
            {storageCount.value} {storageCount.unit} / {storageLimit} GB
          </Typography>
          <StyledLinearProgress variant="determinate" value={storageProgress()} />
        </Box>
      </Box>
      <CenteredBox>
        <PlansContainer>
          {plans.map((plan, idx) => (
            <StyledCard
              key={idx}
              variant="outlined"
              borderColor={
                plan.title === 'Standard Plan' ? standardPlanColor : idx === 0 ? primaryColor : secondaryColor
              }
            >
              <PlanHeader
                bgcolor={plan.title === 'Standard Plan' ? standardPlanColor : idx === 0 ? primaryColor : secondaryColor}
              >
                {plan.title}
                {plan.title === currentPlan && <CurrentPlanBadge>Current Plan</CurrentPlanBadge>}
              </PlanHeader>
              <PriceTypography gutterBottom>{plan.price}</PriceTypography>

              <Typography gutterBottom sx={{ fontWeight: 'bold' }}>
                Plan Features:
              </Typography>

              <CardContent>
                {plan.features.map((feature, fIdx) => (
                  <Typography key={fIdx} variant="body1">
                    <CheckIcon color="primary" fontSize="small" /> {feature}
                  </Typography>
                ))}
              </CardContent>
              <PlanButton
                variant="contained"
                color={plan.title === 'Standard Plan' ? 'inherit' : 'primary'}
                btnColor={plan.title === 'Standard Plan' ? 'white' : undefined}
                textColor={plan.title === 'Standard Plan' ? standardPlanColor : undefined}
              >
                Subscribe
              </PlanButton>
            </StyledCard>
          ))}
        </PlansContainer>
      </CenteredBox>
    </CustomContainer>
  );
};

export default Plans;

const CenteredBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const StyledCard = styled(Card)<{ borderColor: string }>`
  width: 290px;
  background-color: white;
  transition: transform 0.2s, box-shadow 0.2s;
  border: 1px solid ${(props) => props.borderColor};
  &:hover {
    border-color: ${(props) => props.borderColor};
    background-color: #f5f5f5;
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12);
  }
  margin: 20px;
  padding: 20px;
  border-radius: 15px;
  border: 1px solid #e0e0e0;
  @media (max-width: 768px) {
    width: 250px;
  }
`;

const PlanHeader = styled(Typography)<PlanHeaderProps>`
  background: ${(props) => props.bgcolor};
  padding: 8px 16px;
  border-radius: 8px;
  color: white;
  margin-bottom: 20px;
  font-weight: bold;
  text-align: center;
`;

const PlanButton = styled(Button)<{ btnColor?: string; textColor?: string }>`
  margin-top: 20px;
  background-color: ${(props) => props.btnColor};
  color: ${(props) => props.textColor || 'inherit'};
  &:hover {
    background-color: ${(props) => (props.btnColor === 'white' ? '#f5f5f5' : `${props.btnColor}D9`)};
  }
`;

const CurrentPlanBadge = styled.span`
  background-color: #529cf0;
  color: white;
  padding: 5px 10px;
  border-radius: 15px;
  margin-left: 10px;
  font-size: 0.75em;
  vertical-align: middle;
`;

const PlansContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;

const PriceTypography = styled(Typography)`
  font-size: 23px;
  font-weight: 700;
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 18px;
  text-align: center;
`;
