import { AppointmentsTabs, ClinicManagement } from 'company/screens';

import InventoryDashboard from 'company/screens/InventorySystem/Dashboard';
import PatientBilling from 'company/screens/RegistrationBoard/components/PatientBilling';
import ActivityLogs from 'company/screens/Settings/screens/ActivityLogs';
import { ProtectedRoute } from 'core/components';
import { Route } from 'react-router-dom';

const AdminRoutes = (
  <Route path="/company/admin" element={<ProtectedRoute parent requiredRole={['COMPANY_ADMIN']} />}>
    <Route index element={<InventoryDashboard />} />
    <Route path="appointments" element={<AppointmentsTabs />} />
    <Route path="transactions" element={<PatientBilling />} />
    <Route path="clinic-management" element={<ClinicManagement />} />
    <Route path="activity-log" element={<ActivityLogs />} />
  </Route>
);

export default AdminRoutes;
