import { DROPDOWN_FIELD, FieldInput } from 'core/model/interface';
import { useCallback, useMemo } from 'react';

import AccountTypeDropdown from 'company/components/dropdown/AccountTypeDropdown';
import CompanyExpenseTypeSearchDropdown from 'company/components/dropdown/CompanyExpenseTypeSearchDropdown';
import CorporateClientSearchDropdown from 'company/components/dropdown/CorporateClientSearchDropdown';
import DepartmentSearchDropdown from 'company/components/dropdown/DepartmentSearchDropdown';
import HMOSearchDropdown from 'company/components/dropdown/HMOSearchDropdown';
import HealthPackageSearchDropdown from 'company/components/dropdown/HealthPackageSearchDropdown';
import MedicineSearchDropdown from 'company/components/dropdown/MedicineSearchDropdown';
import PatientGroupSearchDropdown from 'company/components/dropdown/PatientGroupSearchDropdown';
import PatientSearchDropdown from 'company/components/dropdown/PatientSearchDropdown';
import ProductCategorySearchDropdown from 'company/components/dropdown/ProductCategorySearchDropdown';
import ProductSearchDropdown from 'company/components/dropdown/ProductSearchDropdown';
import ProductVariantSearchDropdown from 'company/components/dropdown/ProductVariantSearchDropdown';
import ServiceProviderSearchDropdown from 'company/components/dropdown/ServiceProviderSearchDropdown';
import ServiceSearchDropdown from 'company/components/dropdown/ServiceSearchDropdown';
import SupplierSearchDropdown from 'company/components/dropdown/SupplierSearchDropdown';
import UserGroupSearchDropdown from 'company/components/dropdown/UserGroupSearchDropdown';
import VariantAttributeValueSearchDropdown from 'company/components/dropdown/VariantAttributeValueSearchDropdown';
import HealthAssessmentBatchDropDown from 'company/components/dropdown/HealthAssessmentBatchDropdown';
import ServiceGroupSearchDropdown from 'company/components/dropdown/ServiceGroupSearchDropdown';
import AppointmentLegendSearchDropdown from 'company/components/dropdown/AppointmentLegendSearchDropdown';
import ServiceCategorySearchDropdown from 'company/components/dropdown/ServiceCategorySearchDropdown';
import ProductUOMSearchDropdown from 'company/components/dropdown/ProductUOMSearchDropdown';

type Props = {
  field: FieldInput;
  initialValue?: any;
  span?: number;
  touched?: boolean;
  error?: any;
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  onChange?: (value: any) => void;
  setCustomDisplayName?: React.Dispatch<any>;
  setFieldData?: React.Dispatch<any>;
  data?: any;
};

export const dropdownFields = [
  DROPDOWN_FIELD.PATIENT,
  DROPDOWN_FIELD.PATIENT_GROUP,
  DROPDOWN_FIELD.SUPPLIER,
  DROPDOWN_FIELD.CORPORATE_CLIENT,
  DROPDOWN_FIELD.USER_GROUP,
  DROPDOWN_FIELD.SERVICE,
  DROPDOWN_FIELD.SERVICE_PROVIDER,
  DROPDOWN_FIELD.EXPENSE_TYPE,
  DROPDOWN_FIELD.HEALTH_PACKAGE,
  DROPDOWN_FIELD.PRODUCT_CATEGORY,
  DROPDOWN_FIELD.PRODUCT,
  DROPDOWN_FIELD.ACCOUNT_TYPE,
  DROPDOWN_FIELD.MEDICINE,
  DROPDOWN_FIELD.PRODUCT_VARIANT,
  DROPDOWN_FIELD.CORPORATE_CLIENT_DEPARTMENT,
  DROPDOWN_FIELD.VARIANT_ATTRIBUTE,
  DROPDOWN_FIELD.HMO,
  DROPDOWN_FIELD.HEALTH_ASSESSMENT_BATCH,
  DROPDOWN_FIELD.SERVICE_GROUP,
  DROPDOWN_FIELD.APPOINTMENT_LEGEND,
  DROPDOWN_FIELD.SERVICE_CATEGORY,
  DROPDOWN_FIELD.PRODUCT_UOM,
  DROPDOWN_FIELD.HAB_TAG,
];

const CustomSearchDropdownField: React.FC<Props> = ({
  field,
  initialValue,
  span,
  touched,
  error,
  setFieldValue,
  onChange,
  setCustomDisplayName,
  setFieldData,
  data,
}) => {
  const handleChangeCallback = useCallback(
    (value: any) => {
      field.onChange && field.onChange(value, setFieldValue, setCustomDisplayName, setFieldData);
      onChange && onChange(value);
    },
    [field, setFieldValue, onChange, setCustomDisplayName, setFieldData]
  );

  const props = useMemo(
    () => ({
      ...field,
      fieldName: field.field_name,
      displayName: field.display_name,
      initialValue,
      span,
      touched,
      error,
      setFieldValue,
      handleChangeCallback,
    }),
    [error, field, handleChangeCallback, initialValue, setFieldValue, span, touched]
  );

  const fieldComponent = useMemo(() => {
    switch (field.type) {
      case DROPDOWN_FIELD.PATIENT:
        return <PatientSearchDropdown {...props} />;

      case DROPDOWN_FIELD.PATIENT_GROUP:
        return <PatientGroupSearchDropdown {...props} />;

      case DROPDOWN_FIELD.SUPPLIER:
        return <SupplierSearchDropdown {...props} />;

      case DROPDOWN_FIELD.CORPORATE_CLIENT:
        return <CorporateClientSearchDropdown {...props} />;

      case DROPDOWN_FIELD.HMO:
        return <HMOSearchDropdown {...props} />;

      case DROPDOWN_FIELD.USER_GROUP:
        return <UserGroupSearchDropdown {...props} />;

      case DROPDOWN_FIELD.SERVICE:
        return <ServiceSearchDropdown {...props} />;

      case DROPDOWN_FIELD.SERVICE_PROVIDER:
        return <ServiceProviderSearchDropdown {...props} />;

      case DROPDOWN_FIELD.EXPENSE_TYPE:
        return <CompanyExpenseTypeSearchDropdown {...props} />;

      case DROPDOWN_FIELD.HEALTH_PACKAGE:
        return <HealthPackageSearchDropdown {...props} />;

      case DROPDOWN_FIELD.PRODUCT_CATEGORY:
        return <ProductCategorySearchDropdown {...props} />;

      case DROPDOWN_FIELD.PRODUCT:
        return <ProductSearchDropdown {...props} />;

      case DROPDOWN_FIELD.ACCOUNT_TYPE:
        return <AccountTypeDropdown {...props} />;

      case DROPDOWN_FIELD.MEDICINE:
        return <MedicineSearchDropdown {...props} />;

      case DROPDOWN_FIELD.PRODUCT_VARIANT:
        return <ProductVariantSearchDropdown {...props} product_id={data?.product_id} />;

      case DROPDOWN_FIELD.CORPORATE_CLIENT_DEPARTMENT:
        return <DepartmentSearchDropdown {...props} corporate_client_id={data?.corporate_client_id} />;

      case DROPDOWN_FIELD.VARIANT_ATTRIBUTE:
        return <VariantAttributeValueSearchDropdown {...props} attribute_id={data?.attribute_id} />;

      case DROPDOWN_FIELD.HEALTH_ASSESSMENT_BATCH:
        return <HealthAssessmentBatchDropDown {...props} />;

      case DROPDOWN_FIELD.SERVICE_GROUP:
        return <ServiceGroupSearchDropdown {...props} />;

      case DROPDOWN_FIELD.APPOINTMENT_LEGEND:
        return <AppointmentLegendSearchDropdown {...props} />;

      case DROPDOWN_FIELD.SERVICE_CATEGORY:
        return <ServiceCategorySearchDropdown {...props} />;

      case DROPDOWN_FIELD.PRODUCT_UOM:
        return <ProductUOMSearchDropdown {...props} />;

      case DROPDOWN_FIELD.HAB_TAG:
        return <AppointmentLegendSearchDropdown {...props} fromBatch />;
      default:
        return <></>;
    }
  }, [field.type, props, data]);

  return fieldComponent;
};

export default CustomSearchDropdownField;
