import { Box, Typography, useTheme } from '@mui/material';
import {
  CustomAnimatedDrawer,
  CustomForm,
  CustomIconButton,
  CustomSelectField,
  CustomTextField,
  PrimaryButton,
  RegularButton,
} from 'core/components';
import CustomSearchDropdownField, { dropdownFields } from './CustomSearchDropdownField';
import { DROPDOWN_FIELD, FORM_MODE, FieldInput, SelectOption } from 'core/model/interface';
import React, { useEffect, useMemo, useState } from 'react';

import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import CustomDatePicker from '../CustomDatePicker';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { SIZES } from 'theme/constants';
import { formatDate } from 'core/utils';
import { tokens } from 'theme/theme';
import uuid from 'react-uuid';

export type CustomTableFormColumn = {
  fieldName: string;
  displayName: string;
  type?: string;
  optional?: boolean;
  flex?: number;
  options?: SelectOption[];
  hidden?: boolean;
  main_column?: boolean;
  dependents?: any;

  hiddenBasedOnOtherField?: (data: any) => boolean;
  onCellChange?: (
    value: any,
    rowId: any,
    handleRowEdit: (id: number, field: string, value: string | number) => void
  ) => void;
};

type CustomTableFormProps = {
  displayName?: string;
  columns: CustomTableFormColumn[];
  initialValues?: string | any[];
  handleChange?: (rows: any[]) => void;
  fixedRows?: boolean;
  formMode?: FORM_MODE;
  autoSave?: () => void;
  field?: FieldInput;
  compact?: boolean;
};

const CustomTableForm: React.FC<CustomTableFormProps> = ({
  displayName,
  columns,
  initialValues,
  handleChange,
  fixedRows,
  formMode,
  autoSave,
  field,
  compact,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [editOpen, setEditOpen] = useState(false);
  const [editRowOpen, setEditRowOpen] = useState(false);
  const [selectedColumn, setSelectedColumn] = useState<CustomTableFormColumn>();
  const [selectedRow, setSelectedRow] = useState<any>();
  const [rows, setRows] = useState<any[]>([]);
  const [focusedRow, setFocusedRow] = useState<number>(0);
  const [columnChanged, setColumnChanged] = useState<number>(0);
  const [finalColumns, setFinalColumns] = useState<CustomTableFormColumn[]>([]);
  const totalFlex = useMemo(
    () => finalColumns.reduce((result, column) => result + (column.flex ?? 1), 0),
    [finalColumns]
  );

  const userInputColumns = useMemo(() => finalColumns.filter((column) => column.type !== 'fixed_text'), [finalColumns]);

  const viewMode = useMemo(() => formMode === FORM_MODE.VIEW, [formMode]);
  const editMode = useMemo(() => formMode === FORM_MODE.EDIT, [formMode]);

  useEffect(() => {
    setFinalColumns(columns ?? []);
  }, [columns]);

  useEffect(() => {
    if (initialValues) {
      if (Array.isArray(initialValues)) setRows(initialValues);
      else setRows(initialValues !== '""' ? JSON.parse(initialValues) : []);
    } else {
      clearRows();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  const handleRowEdit = (updatedRow: any, field: string, value: string | number) => {
    updatedRow[field] = value;
    handleChange && handleChange(rows);
  };

  const handleRowDelete = (event: any, id: number) => {
    event.stopPropagation();
    const updatedRows = rows.filter((row) => row.id !== id);
    if (updatedRows.length) {
      setRows(updatedRows);
      handleChange && handleChange(updatedRows);
    } else {
      clearRows();
    }
  };

  const handleRowClick = (id: number) => {
    if (id === rows[rows.length - 1].id) {
      // Auto add new row if last row is clicked
      // addRow();
    }
  };

  const addRow = () => {
    const newData = finalColumns.reduce((result: any, column) => {
      result[column.fieldName] = '';
      return result;
    }, {});
    const updatedRows = [...rows, { id: uuid(), ...newData }];
    setRows(updatedRows);
    handleChange && handleChange(updatedRows);
  };

  const clearRows = () => {
    const updatedRows = [1].map((index) =>
      finalColumns.reduce(
        (result: any, column) => {
          result[column.fieldName] = '';
          return result;
        },
        { id: index }
      )
    );
    setRows(updatedRows);
    handleChange && handleChange(updatedRows);
  };

  const getField = (row: any, column: CustomTableFormColumn) => {
    if (dropdownFields.includes(column.type as DROPDOWN_FIELD)) {
      return (
        <CustomSearchDropdownField
          field={column}
          initialValue={row[column.fieldName]}
          onChange={(value) => {
            column.onCellChange && column.onCellChange(value, row, handleRowEdit);
            handleRowEdit(row, column.fieldName, value ? value.value : '');
          }}
        />
      );
    }

    switch (column.type) {
      case 'select':
        return viewMode ? (
          <Typography>{row[column.fieldName]}</Typography>
        ) : (
          <CustomSelectField
            value={row[column.fieldName] ?? ''}
            handleChange={(e) => handleRowEdit(row, column.fieldName, e.target.value)}
            label={column.displayName}
            fieldName={column.fieldName}
            options={column.options!}
            // readOnly={formMode !== FORM_MODE.RESPOND}
          />
        );
      case 'fixed_text':
        return editMode ? (
          <CustomTextField
            value={row[column.fieldName]}
            handleChange={(e) => handleRowEdit(row, column.fieldName, e.target.value)}
            label={column.displayName}
            fieldName={column.fieldName}
          />
        ) : (
          <Typography>{row[column.fieldName]}</Typography>
        );
      case 'date':
        return viewMode ? (
          <Typography>{formatDate(row[column.fieldName])}</Typography>
        ) : (
          <CustomDatePicker
            value={row[column.fieldName]}
            handleChange={(value) => handleRowEdit(row, column.fieldName, value)}
            label={column.displayName}
            fieldName={column.fieldName}
            readOnly={formMode !== FORM_MODE.RESPOND}
          />
        );
      default:
        return viewMode ? (
          <Typography>{row[column.fieldName]}</Typography>
        ) : (
          <CustomTextField
            value={row[column.fieldName]}
            handleChange={(e) => {
              column.onCellChange && column.onCellChange(e.target.value, row, handleRowEdit);
              handleRowEdit(row, column.fieldName, e.target.value);
            }}
            label={column.displayName}
            fieldName={column.fieldName}
            // readOnly={formMode !== FORM_MODE.RESPOND}
            multiline
            rows={1}
          />
        );
    }
  };

  const addColumn = (index: number) => {
    finalColumns.splice(index, 0, { fieldName: uuid(), displayName: 'New Column', type: 'text' });
    setFinalColumns([...finalColumns]);
  };

  const deleteColumn = (index: number) => {
    finalColumns.splice(index, 1);
    setFinalColumns([...finalColumns]);
  };

  const handleUpdateColumn = (data: any) => {
    if (selectedColumn) {
      const newColumn = {
        ...selectedColumn,
        displayName: data.column_name,
        type: data.column_type ?? 'text',
        flex: data.flex ?? 1,
        main_column: data.main_column,
      };
      if (newColumn.type === 'select') {
        newColumn.options = data.options
          .split(',')
          .map((option: string) => ({ key: option.trim(), value: option.trim() }));
      } else {
        newColumn.options = undefined;
      }
      setFinalColumns((prev) => prev.map((col) => (col.fieldName === selectedColumn.fieldName ? newColumn : col)));
      setEditOpen(false);
    }
  };

  const mainColumn = useMemo(() => finalColumns.find((column) => column.main_column), [finalColumns]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (editMode && field) {
        field.columns = finalColumns;
        autoSave && autoSave();
      }
    }, 1000);
    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finalColumns, columnChanged, rows, formMode]);

  return (
    <Box
      sx={{
        'table td': viewMode
          ? {
              border: '1px solid ' + colors.light_blue_background_2,
              padding: compact ? '5px 10px !important' : '10px 15px !important',
            }
          : {},
        'table th': viewMode
          ? { backgroundColor: colors.light_blue_background_2, padding: compact ? '5px' : '10px' }
          : {},
      }}
    >
      <div style={{ width: '100%', overflow: 'auto' }}>
        {displayName && (
          <Box display="flex" gap="10px" alignItems="center" mb="10px">
            <Typography fontWeight={600} fontSize="16px">
              {displayName}
            </Typography>
          </Box>
        )}
        <table style={{ borderCollapse: 'collapse', width: '100%' }}>
          <thead>
            <tr>
              {finalColumns.map((column, index) => {
                const hasAnyDisplayName = finalColumns.some((col) => col.displayName);
                return (
                  <th
                    key={index}
                    style={{
                      borderRadius:
                        index === 0 ? '10px 0 0 0' : index + 1 === finalColumns.length ? '0 10px 0 0' : undefined,
                      backgroundColor: viewMode && hasAnyDisplayName ? colors.light_blue_background_2 : 'white',
                    }}
                  >
                    <Box position="relative">
                      <Box display="flex" gap="3px" paddingInline="20px" alignItems="center" justifyContent="center">
                        <Typography fontWeight="500">{column.displayName}</Typography>
                        {editMode && (
                          <>
                            <CustomIconButton
                              icon={<EditOutlinedIcon color="success" />}
                              onClick={() => {
                                setSelectedColumn(column);
                                setEditOpen(true);
                              }}
                              tooltip="Edit Column"
                            />
                            <CustomIconButton
                              icon={<DeleteOutlinedIcon color="error" />}
                              onClick={() => deleteColumn(index)}
                              tooltip="Delete Column"
                              disabled={finalColumns.length === 1}
                            />
                          </>
                        )}
                      </Box>
                      {editMode && (
                        <>
                          {index === 0 && (
                            <CustomIconButton
                              icon={<AddCircleOutlineOutlinedIcon color="primary" />}
                              onClick={() => addColumn(0)}
                              tooltip="Add Column"
                              sx={{ position: 'absolute', left: -15, top: 0 }}
                            />
                          )}
                          <CustomIconButton
                            icon={<AddCircleOutlineOutlinedIcon color="primary" />}
                            onClick={() => addColumn(index + 1)}
                            tooltip="Add Column"
                            sx={{ position: 'absolute', right: -15, top: 0 }}
                          />
                        </>
                      )}
                    </Box>
                  </th>
                );
              })}
              {!fixedRows && !viewMode && <th></th>}
            </tr>
          </thead>
          <tbody>
            {rows.map((row, rowIndex) => (
              <tr
                key={row.id}
                style={{
                  borderRadius: '10px',
                  background: focusedRow === row.id ? colors.light_blue_background : 'transparent',
                }}
                onClick={() => handleRowClick(row.id)}
                onMouseEnter={() => setFocusedRow(row.id)}
                onMouseLeave={() => setFocusedRow(0)}
              >
                {finalColumns.map((column, columIndex) => (
                  <td
                    key={columIndex}
                    style={{
                      width: (100 / totalFlex) * (column.flex ?? 1) + '%',
                      padding:
                        columIndex === 0
                          ? '10px 5px 10px 10px'
                          : columIndex + 1 === finalColumns.length
                          ? '10px 10px 10px 5px'
                          : '10px 5px 10px 5px',
                      borderRadius:
                        columIndex === 0
                          ? '10px 0 0 10px'
                          : columIndex + 1 === finalColumns.length
                          ? '0 10px 10px 0'
                          : undefined,
                    }}
                  >
                    {column.hidden || (column.hiddenBasedOnOtherField && column.hiddenBasedOnOtherField(row)) ? (
                      <></>
                    ) : (
                      getField(row, column)
                    )}
                  </td>
                ))}

                {!fixedRows && formMode !== FORM_MODE.VIEW && (
                  <td width="50px" style={{ borderRadius: '0 10px 10px 0', padding: '0 10px 0 0' }}>
                    <CustomIconButton
                      icon={<EditOutlinedIcon color="success" />}
                      onClick={() => {
                        setSelectedRow(row);
                        setEditRowOpen(true);
                      }}
                      tooltip="Edit Row"
                      placement="right"
                    />
                    <CustomIconButton
                      onClick={(event) => handleRowDelete(event, row.id)}
                      icon={<DeleteOutlinedIcon color="error" />}
                      tooltip="Delete Row"
                      placement="right"
                    />
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
        {!fixedRows && formMode !== FORM_MODE.VIEW && (
          <Box display="flex" justifyContent="space-between" width="100%" my="20px">
            <Box display="flex" gap="10px">
              <PrimaryButton onClick={addRow} label="Add Row" size="small" />
              <RegularButton variant="outlined" onClick={clearRows} label="Clear All Rows" size="small" />
            </Box>
          </Box>
        )}
      </div>

      <CustomAnimatedDrawer
        open={editOpen}
        setOpen={setEditOpen}
        header="Edit Column"
        onClose={() => setSelectedColumn(undefined)}
      >
        <CustomForm
          onSubmit={handleUpdateColumn}
          fields={[
            {
              type: 'text',
              span: 4,
              field_name: 'column_name',
              display_name: 'Column Name',
            },
            {
              type: 'select',
              span: 4,
              field_name: 'column_type',
              display_name: 'Column Type',
              options: [
                { key: 'Select', value: 'select' },
                { key: 'Text Box', value: 'text' },
                { key: 'Date', value: 'date' },
                { key: 'Static Text', value: 'fixed_text' },
              ],
            },
            {
              type: 'text',
              span: 4,
              field_name: 'options',
              display_name: 'Options (Separate options by a comma',
              hiddenBasedOnOtherField: (data) => data.column_type !== 'select',
            },
            {
              type: 'number',
              span: 4,
              field_name: 'flex',
              display_name: 'Flex',
            },
            {
              type: 'checkbox',
              span: 4,
              field_name: 'main_column',
              display_name: 'Main Column',
              hiddenBasedOnOtherField: (data) => data.column_type !== 'fixed_text',
            },
          ]}
          initialValues={{
            column_name: selectedColumn?.displayName,
            column_type: selectedColumn?.type ?? 'text',
            options: selectedColumn?.options ? selectedColumn.options.map((option) => option.value).join(', ') : '',
            flex: selectedColumn?.flex ?? 1,
            main_column: !!selectedColumn?.main_column,
          }}
          loading={false}
        />
        <Typography mt="20px" fontSize="12px">
          Column ID: {selectedColumn?.fieldName}
        </Typography>
      </CustomAnimatedDrawer>

      <CustomAnimatedDrawer
        open={editRowOpen}
        setOpen={setEditRowOpen}
        header="Edit Row"
        onClose={() => setSelectedRow(undefined)}
        width={500}
      >
        {selectedRow && (
          <Box>
            {mainColumn && (
              <Typography fontSize="15px" fontWeight={600}>
                {selectedRow[mainColumn.fieldName] ?? mainColumn.displayName}
              </Typography>
            )}

            {userInputColumns.map((column) => (
              <Box padding="5px 10px">
                <hr />
                <Typography>{column.displayName}</Typography>
                <Box sx={{ paddingLeft: SIZES.padding }}>
                  {userInputColumns
                    .filter((others) => column.fieldName !== others.fieldName)
                    .map((others) => (
                      <Box>
                        <Typography>{others.displayName}</Typography>
                        <CustomTextField
                          value={
                            column.dependents
                              ? column.dependents[selectedRow.id]
                                ? column.dependents[selectedRow.id][others.fieldName] ?? ''
                                : ''
                              : ''
                          }
                          fieldName="value"
                          label="Value or Condition"
                          placeholder="value > 100 ? High : Normal"
                          handleChange={(event) => {
                            if (!column.dependents) column.dependents = {};
                            if (!column.dependents[selectedRow.id]) column.dependents[selectedRow.id] = {};
                            column.dependents[selectedRow.id][others.fieldName] = event.target.value;
                            setColumnChanged((prev) => prev + 1);
                          }}
                          multiline
                        />
                      </Box>
                    ))}
                </Box>
              </Box>
            ))}
          </Box>
        )}
      </CustomAnimatedDrawer>
    </Box>
  );
};

export default CustomTableForm;
