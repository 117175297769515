import { ApiQuery } from 'core/model/interface';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'appointments';

export const getAppointment = (facility_id: number, id: number) => {
  return axios.get(`${baseURL}/${path}/${facility_id}/one/${id}`);
};

export const getAppointments = (facility_id: number, query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/${facility_id}`, { params: query });
};

export const getAllPatientAppointments = (facility_id: number, patient_id: number) => {
  return axios.get(`${baseURL}/${path}/${facility_id}/all/${patient_id}`);
};
export const getTotalPatientsServedToday = (facility_id: Number) => {
  return axios.get(`${baseURL}/${path}/${facility_id}/getTotalPatientServedToday`);
};

export const getAppointmentInDateRange = (
  facility_id: number,
  start: string | Date,
  end: string | Date,
  query?: any
) => {
  return axios.get(`${baseURL}/${path}/${facility_id}/range`, {
    params: {
      start,
      end,
      ...query,
    },
  });
};

export const scheduleAppointment = (facility_id: number, patient_data: any) => {
  return axios.post(`${baseURL}/${path}/${facility_id}`, patient_data);
};

export const scheduleRepeatingAppointments = (facility_id: number, data: any) => {
  return axios.post(`${baseURL}/${path}/${facility_id}/custom-repeat`, data)
}

export const updateAppointment = (facility_id: number, id: number, appointment: any) => {
  return axios.put(`${baseURL}/${path}/${facility_id}/${id}`, appointment);
};

export const updateAttendance = (facility_id: number, id: number, attendance: boolean) => {
  return axios.patch(`${baseURL}/${path}/${facility_id}/attendance/${id}`, { attendance });
};

export const deleteAppointment = (facility_id: number, program_code: string, data: any) => {
  return axios.delete(`${baseURL}/${path}/${facility_id}/${program_code}`, {
    params: data,
  });
};

export const rescheduleAppointments = (facility_id: number, data: any) => {
  return axios.patch(`${baseURL}/${path}/${facility_id}/reschedule`, data);
};

export const closeAppointments = (facility_id: number, program_code: string, data: any) => {
  return axios.patch(`${baseURL}/${path}/${facility_id}/close/${program_code}`, data);
};

export const sendFollowUpReminder = (facility_id: number, program_code: string, data: any) => {
  return axios.patch(`${baseURL}/${path}/${facility_id}/follow-up/${program_code}`, data);
};

export const sendResultAvailable = (facility_id: number, program_code: string, data: any) => {
  return axios.patch(`${baseURL}/${path}/${facility_id}/result/${program_code}`, data);
};

export const updateNoShow = (facility_id: number, data: any) => {
  return axios.patch(`${baseURL}/${path}/${facility_id}/no-show`, data);
};

export const getAppointmentServices = (facility_id: number, id: number) => {
  return axios.get(`${baseURL}/${path}/${facility_id}/services/${id}`);
};

export const getAppointmentProducts = (facility_id: number, id: number) => {
  return axios.get(`${baseURL}/${path}/${facility_id}/products/${id}`);
};

export const exportAppointments = (facility_id: number, clinic_name: string, query?: ApiQuery) => {
  return axios
    .post(
      `${baseURL}/${path}/${facility_id}/export`,
      {},
      {
        params: query,
        headers: {
          'Content-Type': 'application/vnd.ms-excel;charset=UTF-8',
        },
      }
    )
    .then((response) => {
      const link = document.createElement('a');
      link.href = response.data;
      link.setAttribute('download', clinic_name + ' - Appointments.xlsx');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
};
