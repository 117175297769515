import CareGoTable, { TableActionProps, TableComponentProps, TableFormProps } from 'core/PageBuilder/CareGoTable';
import { CustomContainer, CustomModal } from 'core/components';
import { EntityFields, processTableColumns } from '../utils';
import { ReactNode, forwardRef, useEffect, useImperativeHandle, useState } from 'react';

import { ApiQuery } from 'core/model/interface';
import { GridColDef } from '@mui/x-data-grid';
import GuidedTourFormDisplay from 'core/screens/Helper/GuidedTour/GuidedTourFormDisplay';

type Props = {
  fields: EntityFields[];
  visibleColumns: string[];
  entityName: string;
  templateForm?: ReactNode;
  rowId?: string;
  checkboxSelection?: boolean;
  hideAddUpdateDeleteAction?: boolean;
  hideAddButton?: boolean;
  hideUpdateAction?: boolean;
  hideDeleteAction?: boolean;
  withContainer?: boolean;
  useColoredTable?: boolean;
  fileViewer?: boolean;

  tableComponent?: TableComponentProps;
  tableForm?: TableFormProps;
  tableAction?: TableActionProps;

  addActionDisplayOverwrite?: string;
  updateActionDisplayOverwrite?: string;

  getData: (query: ApiQuery) => Promise<any>;
  onGetData?: (data: any) => void;
  handleDelete?: (data: any) => void;
  selectedRow: any;
  setSelectedRow: React.Dispatch<any>;
  setDrawerSelectedRow?: (row: any) => void;
  drawerSelectedRow?: any;
  processFormInitialValues?: (data: any) => void;
  exportRecords?: (data: any) => void;
  patientExportRecords?: boolean;
  allItemsExportRecords?: boolean;

  tourTriggerIndex?: number;
  customColumns?: EntityFields[];
  hideViews?: boolean;
  hideSearch?: boolean;
  onFilesRefresh?: () => void;
  hidePagination?: boolean;
  showAppointmentActionsButton?: boolean;
  stackHeaderComponents?: boolean;
  afterSearchbarComponent?: ReactNode;
  showViewInvoiceButton?: boolean;
  modalWidth?: number | string;
};

const TemplateTable = forwardRef((props: Props, ref) => {
  const {
    fields,
    visibleColumns,
    entityName,
    templateForm,
    rowId,
    checkboxSelection,
    hideAddUpdateDeleteAction,
    hideAddButton,
    hideUpdateAction,
    hideDeleteAction,
    withContainer,
    useColoredTable = true,
    fileViewer,
    stackHeaderComponents,
    onFilesRefresh,
    afterSearchbarComponent,

    tableComponent,
    tableForm,
    tableAction,

    // selectedRow,
    drawerSelectedRow,
    setSelectedRow,
    setDrawerSelectedRow,

    addActionDisplayOverwrite,
    updateActionDisplayOverwrite,

    getData,
    onGetData,
    handleDelete,
    processFormInitialValues,
    exportRecords,
    patientExportRecords,
    allItemsExportRecords,
    tourTriggerIndex,
    customColumns,
    hideViews,
    hideSearch = false,
    hidePagination,
    modalWidth,
  } = props;

  useImperativeHandle(ref, () => ({ refreshTable, closeFormModal, handleAddButtonClick }));

  const [forceRefresh, setForceRefresh] = useState<number>(0);
  const [clearSelected, setClearSelected] = useState<number>(0);
  const { showAppointmentActionsButton, showViewInvoiceButton, ...otherProps } = props;

  const [tableColumns, setTableColumns] = useState<GridColDef[]>([]);
  const [action, setAction] = useState('');
  const [header, setHeader] = useState([action, entityName].join(' '));
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    let columns = visibleColumns;
    if (setDrawerSelectedRow && drawerSelectedRow) columns = visibleColumns.slice(0, 1);

    if (customColumns) {
      setTableColumns(processTableColumns([...fields, ...customColumns], columns));
    } else {
      setTableColumns(processTableColumns(fields, columns));
    }
  }, [fields, visibleColumns, setDrawerSelectedRow, drawerSelectedRow, customColumns]);

  useEffect(() => {
    if (setDrawerSelectedRow && !drawerSelectedRow) setClearSelected((prev) => prev + 1);
  }, [setDrawerSelectedRow, drawerSelectedRow]);

  const refreshTable = () => {
    setForceRefresh((prev: number) => prev + 1);
  };

  const handleRowActionsClick = (data: any, action: string) => {
    if (action === 'update') {
      setOpenModal(true);
      setSelectedRow(data);
      updateActionDisplayOverwrite ? setHeader(updateActionDisplayOverwrite) : setAction('Update');
    }
  };

  const handleAddButtonClick = () => {
    setOpenModal(true);
    setSelectedRow(undefined);
    addActionDisplayOverwrite ? setHeader(addActionDisplayOverwrite) : setAction('Create');
  };

  const handleSelectRow = (selected: any) => {
    if (!checkboxSelection) {
      setDrawerSelectedRow && setDrawerSelectedRow(selected);
      setSelectedRow(selected);
    }
  };

  const closeFormModal = () => {
    setOpenModal(false);
  };

  const customForm = (
    <CustomModal
      header={header}
      open={openModal}
      setOpen={setOpenModal}
      id={`${entityName.replace(/\s/g, '')}_modal`}
      width={modalWidth}
    >
      {templateForm}
    </CustomModal>
  );

  const _tableComponent = {
    columns: tableColumns,
    getData: getData,
    onGetData: onGetData,
    forceRefresh: forceRefresh,
    onSelectRow: handleSelectRow,
    clearSelected: clearSelected,
    rowId: rowId,
    checkboxSelection: checkboxSelection,
    fileViewer: fileViewer,
    stackHeaderComponents: stackHeaderComponents || drawerSelectedRow,
    afterSearchbarComponent: afterSearchbarComponent,

    // hideSearch: setDrawerSelectedRow && selectedRow,
    hideSearch: hideSearch,
    hidePagination: hidePagination,
    exportRecords: exportRecords,
    patientExportRecords: patientExportRecords,
    allItemsExportRecords: allItemsExportRecords,
    hideViews: hideViews,
    onFilesRefresh: onFilesRefresh && onFilesRefresh,
    showAppointmentActionsButton,
    showViewInvoiceButton,
    ...tableComponent,
  };

  const _tableAction: TableActionProps = {
    includeAddButton: !(hideAddButton || hideAddUpdateDeleteAction),
    includeUpdateAction: !(hideUpdateAction || hideAddUpdateDeleteAction),
    includeDeleteAction: !(hideDeleteAction || hideAddUpdateDeleteAction),
    deleteApiFunction: handleDelete,
    handleAddButtonClick: handleAddButtonClick,
    handleRowActionsClick: handleRowActionsClick,
    getRow: setSelectedRow,
    ...tableAction,
  };

  const _tableForm = {
    customForm: customForm,
    processFormInitialValues: processFormInitialValues,
    ...tableForm,
  };

  const content = {
    entityName: entityName,
    tableComponent: _tableComponent,
    tableAction: _tableAction,
    tableForm: _tableForm,
    doNotWaitForFacility: true,
    useColoredTable,
  };

  return (
    <>
      {tourTriggerIndex !== undefined && (
        <GuidedTourFormDisplay
          closeFormModal={closeFormModal}
          handleAddButtonClick={handleAddButtonClick}
          triggerIndex={tourTriggerIndex}
        />
      )}
      {withContainer ? (
        <CustomContainer>
          <CareGoTable {...content} tableComponent={_tableComponent} />
        </CustomContainer>
      ) : (
        <CareGoTable {...content} />
      )}
    </>
  );
});

export default TemplateTable;
