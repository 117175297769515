import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { CompanyProtectedComponent, ConfirmationDialog, CustomModal, RegularButton } from 'core/components';
import { deleteMedicine, getMedicine, getMedicines } from 'company/api/medicines'; // Ensure this import exists
import { useContext, useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import ClinicInventoryScroller from 'company/screens/InventorySystem/Inventory/components/ClinicInventoryScroller';
import { ContainerRow } from 'core/components/containers';
import CustomBottomPopover from 'core/layout/components/CustomBottomPopover';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { FacilityContext } from 'core/context/facility.context';
import InfiniteScrollContainer from 'core/components/dataView/InfiniteScrollContainer';
import { Link } from 'react-router-dom';
import LinkIcon from '@mui/icons-material/Link';
import { MedicineForm } from 'company/entities/forms';
import MedicineInstructionTable from 'company/entities/modules/ClinicManagement/Medicine/MedicineInstruction/MedicineInstructionTable';
import { MedicineModel } from 'company/entities/modules/ClinicManagement/Medicine/MedicineModel';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import PatientField from 'company/screens/PatientManagement/components/PatientField';
import { createMedicineInventoryLinkage } from 'company/api/medicine-inventory';
import { tokens } from 'theme/theme';
import { useSnackbar } from 'notistack';

const Medicines = () => {
  const [selectedMedicine, setSelectedMedicine] = useState<number>(0);
  const [selectedMedicineDetails, setSelectedMedicineDetails] = useState<MedicineModel>();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [toUpdate, setToUpdate] = useState<any>();
  const [openDelete, setOpenDelete] = useState(false);

  const [addedMedicine, setAddedMedicine] = useState<number>();
  const [updatedMedicine, setUpdatedMedicine] = useState<any>();
  const [deletedMedicine, setDeletedMedicine] = useState<number>();
  const [openActionMobile, setOpenActionMobile] = useState<boolean>(false);
  const [openAddMedicineSeparate, setOpenAddMedicineSeparate] = useState<boolean>(false);
  const [inventoryModalOpen, setInventoryModalOpen] = useState<boolean>(false);
  const [openLinkConfirm, setOpenLinkConfirm] = useState<boolean>(false);
  const [productVariantDetails, setProductVariantDetails] = useState<any>();
  const [refresh, setRefresh] = useState<number>(0);
  const isMobilePhone = useMediaQuery('(max-width:768px)');
  const { facility } = useContext(FacilityContext);
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  useEffect(() => {
    fetchMedicines();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMedicine, refresh]);

  const fetchMedicines = async () => {
    if (selectedMedicine) {
      getMedicine(selectedMedicine)
        .then((res) => {
          setSelectedMedicineDetails(res.data);
        })
        .catch((error) => console.error(error));
    }
  };

  const displayItem = (item: any) => {
    const isSelected = selectedMedicine === item.id;
    return (
      <ContainerRow>
        <Typography variant="h5" color={isSelected && !isMobilePhone ? 'white' : 'black'} fontWeight="600">
          {item.generic_name}
        </Typography>
      </ContainerRow>
    );
  };

  const handleDelete = () => {
    selectedMedicine &&
      deleteMedicine(selectedMedicine).then((response: any) => {
        setDeletedMedicine(parseInt(response.data.medicine));
        setOpenDelete(false);
      });
  };

  async function handleCreateInventoryLinkage() {
    const data = {
      medicine_id: selectedMedicineDetails?.id,
      variant_id: productVariantDetails?.id,
    };
    const results: any = await createMedicineInventoryLinkage(facility.id, data);
    setUpdatedMedicine(selectedMedicineDetails);
    setInventoryModalOpen(false);
    setRefresh((prev) => prev + 1);
    enqueueSnackbar(`${results.data.message}`, { variant: results.data.status });
  }

  return (
    <>
      <InfiniteScrollContainer
        title="List of Medicines"
        urlKeyId="mid"
        getSingleData={(id) => getMedicine(id)}
        getData={(query) => getMedicines(query)}
        renderItem={displayItem}
        selected={selectedMedicine}
        setSelected={setSelectedMedicine}
        addedNewItem={addedMedicine}
        updatedItem={updatedMedicine}
        deleteId={deletedMedicine}
        containerHeight="calc(100vh - 170px)"
        titleComponents={
          <CompanyProtectedComponent requiredAuth={['MANAGE_MEDICINES']}>
            {!isMobilePhone && (
              <RegularButton
                startIcon={<AddIcon />}
                label="Add"
                color="primary"
                onClick={() => {
                  setToUpdate(undefined);
                  if (isMobilePhone) {
                    setOpenAddMedicineSeparate(true);
                  } else {
                    setOpenModal(true);
                  }
                }}
                size="small"
              />
            )}
          </CompanyProtectedComponent>
        }
        displaySelectedTitle={() => (
          <Typography variant="h3" fontWeight={700} color={colors.primary}>
            {selectedMedicineDetails?.generic_name}
          </Typography>
        )}
        actions={
          !isMobilePhone
            ? [
                {
                  icon: <LinkIcon />,
                  label: 'Link to Inventory',
                  action: () => {
                    setInventoryModalOpen(true);
                  },
                },
                { label: 'divider' },
                {
                  icon: <DriveFileRenameOutlineIcon />,
                  label: 'Update',
                  action: (data) => {
                    setOpenModal(true);
                    setToUpdate(selectedMedicineDetails);
                  },
                },

                {
                  icon: <DeleteOutlineIcon />,
                  label: 'Delete',
                  color: 'error',
                  action: () => {
                    setOpenDelete(true);
                  },
                },
              ]
            : undefined
        }
      >
        {isMobilePhone ? (
          <Box mb="10px">
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '15px',
              }}
            >
              <Typography sx={{ fontSize: '1.25rem', fontWeight: '700', color: colors.accent }}>
                {selectedMedicineDetails?.generic_name}
              </Typography>
              {!isMobilePhone && (
                <MoreHorizIcon
                  sx={{ color: colors.primary, fontSize: '2rem' }}
                  onClick={() => {
                    setToUpdate(selectedMedicineDetails);
                    setOpenActionMobile(true);
                  }}
                />
              )}
            </Box>
            {selectedMedicineDetails ? (
              <Box display="grid" gridTemplateColumns="1fr 1fr" gap="20px">
                <PatientField label="Generic Name" value={selectedMedicineDetails.generic_name} />
                <PatientField label="Brand Name" value={selectedMedicineDetails.brand_name} />
                <PatientField label="Dosage" value={selectedMedicineDetails.dosage} />
              </Box>
            ) : (
              <Typography sx={{ color: 'text.secondary', textAlign: 'center', py: 2 }}>
                No medicine added yet
              </Typography>
            )}
          </Box>
        ) : (
          <Box mb="30px">
            <Typography variant="h5" fontWeight="bold" paddingBottom={'30px'}>
              Medicine Details
            </Typography>
            {selectedMedicineDetails ? (
              <Box display="grid" gridTemplateColumns="1fr 1fr" gap="20px">
                <PatientField label="Generic Name" value={selectedMedicineDetails.generic_name} />
                <PatientField label="Brand Name" value={selectedMedicineDetails.brand_name} />
                <PatientField label="Dosage" value={selectedMedicineDetails.dosage} />
                {selectedMedicineDetails.variant_id && (
                  <PatientField
                    label="Inventory"
                    value={
                      <Link
                        style={{ color: colors.text }}
                        to={`/company/inventory/products/variant?id=${selectedMedicineDetails.variant_id}`}
                      >
                        {`${selectedMedicineDetails.generic_name} (${selectedMedicineDetails.variant_name})`}
                      </Link>
                    }
                  />
                )}
              </Box>
            ) : (
              <Typography sx={{ color: 'text.secondary', textAlign: 'center', py: 2 }}>
                No medicine added yet
              </Typography>
            )}
          </Box>
        )}
        {selectedMedicine ? (
          <>
            <Typography variant="h5" fontWeight="bold" paddingBottom={'10px'} paddingTop={'20px'}>
              Medicine Instructions
            </Typography>
            <>{!!selectedMedicine && <MedicineInstructionTable medicineId={selectedMedicine} />}</>
          </>
        ) : (
          <Box> </Box>
        )}

        <CustomModal
          width={700}
          open={openModal}
          setOpen={setOpenModal}
          header={toUpdate ? 'Update Medicine' : 'Add Medicine'}
        >
          <MedicineForm
            medicine={toUpdate}
            callbackAfterSubmit={(data) => {
              setOpenModal(false);
              if (toUpdate) {
                setUpdatedMedicine(data.medicine);
                setSelectedMedicineDetails(data.medicine);
              } else {
                setAddedMedicine(data.medicine.id);
              }
            }}
          />
        </CustomModal>
        {selectedMedicineDetails && (
          <ConfirmationDialog
            content={'Are you sure to you want to delete ' + selectedMedicineDetails?.generic_name + '?'}
            open={openDelete}
            setOpen={setOpenDelete}
            onConfirm={handleDelete}
            bottomDrawerDialog={isMobilePhone}
          />
        )}

        <CustomBottomPopover open={openActionMobile} setOpen={setOpenActionMobile} title="Actions">
          <Box sx={{ display: 'flex', gap: '10px', marginTop: '20px' }}>
            <RegularButton
              onClick={() => setOpenModal(true)}
              label="Update"
              startIcon={<EditOutlinedIcon />}
              variant="outlined"
              fullWidth
              styles={{ border: 'none', backgroundColor: colors.light_blue_background, fontWeight: '400' }}
            />
            <RegularButton
              onClick={() => {
                setOpenDelete(true);
              }}
              label="Delete"
              startIcon={<DeleteOutlinedIcon />}
              variant="outlined"
              fullWidth
              styles={{
                color: colors.redAccent,
                backgroundColor: colors.light_red_background_2,
                border: 'none',
                fontWeight: '400',
              }}
            />
          </Box>
        </CustomBottomPopover>

        <CustomModal
          open={inventoryModalOpen}
          setOpen={setInventoryModalOpen}
          width="1200px"
          header={`Link ${selectedMedicineDetails?.generic_name} to Inventory`}
        >
          <ClinicInventoryScroller
            actions={[
              {
                icon: <LinkIcon />,
                label: 'Link',
                action: (data) => {
                  setProductVariantDetails(data);
                  setOpenLinkConfirm(true);
                },
              },
            ]}
          ></ClinicInventoryScroller>
        </CustomModal>

        <ConfirmationDialog
          open={openLinkConfirm}
          setOpen={setOpenLinkConfirm}
          onConfirm={handleCreateInventoryLinkage}
          title={`Link Medicine`}
          content={`Link ${selectedMedicineDetails?.generic_name} to product variant ${productVariantDetails?.product_name}`}
        ></ConfirmationDialog>
      </InfiniteScrollContainer>

      {isMobilePhone && (
        <CustomModal
          open={openAddMedicineSeparate}
          setOpen={setOpenAddMedicineSeparate}
          header={'Add Medicine'}
          onClose={() => setOpenAddMedicineSeparate(false)}
        >
          <MedicineForm
            callbackAfterSubmit={(data) => {
              setOpenAddMedicineSeparate(false);
              setAddedMedicine(data.medicine.id);
            }}
          />
        </CustomModal>
      )}
    </>
  );
};

export default Medicines;
