import { Box, Typography } from '@mui/material';
import React, { Ref, forwardRef, useEffect, useState } from 'react';
import { formatDate, formatGenLedgerDate, formatNumber } from 'core/utils';

import styled from 'styled-components';

const LIMIT_ROW_PER_PAGE = 42;

interface LedgerReportData {
  id: number;
  transaction_type_code: string;
  reference_id: string | number;
  description: string;
  amount: number | string;
  source_account_id: number;
  source_account_name: string;
  account_id: number;
  account_name: string;
  account_type_code: string;
  account_type: string;
  entry_type: string;
  created_at?: string;
}

interface CalculatedLedgerReportData extends LedgerReportData {
  balance: number;
}

interface LedgerCalculatedData {
  data: CalculatedLedgerReportData[];
  total: number;
}

//////////////////////////
//  Props defined here  //
//////////////////////////
type GeneralLedgerProps = {
  ledgerReportData: LedgerReportData[];
  startDate: Date;
  endDate: Date;
};

type LedgerTotalProps = {
  title: string;
  total: string | number;
};

/////////////////////////
//  Styled components  //
/////////////////////////
const Th = styled.th`
  padding-left: 0.5vh;
  text-align: left;
`;

const Thn = styled.th`
  padding-right: 0.5vh;
  text-align: right;
`;

const Td = styled.td`
  text-align: left;
  padding-left: 0.5vh;
`;

const Tdn = styled.td`
  text-align: right;
  padding-right: 0.5vh;
`;

const TitleHolder = styled.td`
  text-align: left;
`;

const TotalHolder = styled(TitleHolder)`
  border-top: 1px solid #b2b2b2;
`;

const PageBreaker = styled('div')`
  page-break-after: always;
`;

const LedgerTotal: React.FC<LedgerTotalProps> = (props) => {
  return (
    <tr>
      <TotalHolder colSpan={6}>{'Total for ' + props.title}</TotalHolder>
      <TotalHolder style={{ textAlign: 'right' }}>{'PHP ' + props.total}</TotalHolder>
      <TotalHolder></TotalHolder>
    </tr>
  );
};

// const LedgerBody: React.FC<LedgerBodyProps> = (props) => {
//   const { title, data } = props;
//   const [calculatedLedger, setCalculatedLedger] = useState<CalculatedLedgerReportData[]>([]);
//   const [totalBalance, setTotalBalance] = useState<number>(0);

//   useEffect(() => {
//     const calculated: CalculatedLedgerReportData[] = [];
//     let currentBalance = 0;

//     // calculation of balance
//     for (let i = 0; i < data.length; i++) {
//       const ledgerData = data[i];
//       if (ledgerData.entry_type === 'credit') {
//         currentBalance -= parseFloat(ledgerData.amount as string);
//         ledgerData.amount = -parseFloat(ledgerData.amount as string);
//       } else {
//         currentBalance += parseFloat(ledgerData.amount as string);
//       }

//       calculated.push({
//         ...ledgerData,
//         balance: currentBalance,
//       });
//     }

//     setCalculatedLedger(calculated);
//     setTotalBalance(currentBalance);
//   }, []);

//   return (
//     <>
//       <TitleHolder colSpan={8}>{title}</TitleHolder>
//       {calculatedLedger.length > 0 &&
//         calculatedLedger.map((ledgerData, index) => {
//           return (
//             <tr key={index}>
//               <Td>{formatDate(ledgerData.created_at)}</Td>
//               <Td>{ledgerData.transaction_type_code}</Td>
//               <Td>{ledgerData.reference_id}</Td>
//               <Td>{ledgerData.account_name}</Td>
//               <Td>{ledgerData.description}</Td>
//               <Td>{ledgerData.source_account_name}</Td>
//               <Tdn>{formatNumber(ledgerData.amount)}</Tdn>
//               <Tdn>{formatNumber(ledgerData.balance)}</Tdn>
//             </tr>
//           );
//         })}
//       <LedgerTotal title={title} total={formatNumber(totalBalance)} />
//     </>
//   );
// };

const LedgerHeader = () => {
  return (
    <thead style={{ borderTop: '1px solid black', borderBottom: '1px solid black' }}>
      <Th>DATE</Th>
      <Th>TRANSACTION TYPE</Th>
      <Th>NO.</Th>
      <Th>NAME</Th>
      <Th>MEMO/DESCRIPTION</Th>
      <Th>SPLIT</Th>
      <Thn>AMOUNT</Thn>
      <Thn>BALANCE</Thn>
    </thead>
  );
};

/////////////////////////////////
// Main general ledger report  //
/////////////////////////////////
const GeneralLedgerTable = forwardRef((props: GeneralLedgerProps, ref: Ref<HTMLDivElement>) => {
  const { ledgerReportData, startDate, endDate } = props;
  const [accountIds, setAccountIds] = useState<number[]>([]);

  function ledgerBodyDataCalculator(data: LedgerReportData[]): LedgerCalculatedData {
    const calculated: CalculatedLedgerReportData[] = [];
    let currentBalance = 0;

    // calculation of balance
    for (let i = 0; i < data.length; i++) {
      const ledgerData = data[i];
      if (ledgerData.entry_type === 'credit') {
        currentBalance -= parseFloat(ledgerData.amount as string);
        ledgerData.amount = -parseFloat(ledgerData.amount as string);
      } else {
        currentBalance += parseFloat(ledgerData.amount as string);
      }

      calculated.push({
        ...ledgerData,
        balance: currentBalance,
      });
    }

    return {
      data: calculated,
      total: currentBalance,
    };
  }

  function renderContent(localLedgerData: LedgerReportData[]) {
    const content = [];
    if (accountIds.length <= 0) return [];

    let currentRowCount = 0;
    let isFirstPage = true;

    for (let i = 0; i < accountIds.length; i++) {
      const id = accountIds[i];
      const filteredLedger = localLedgerData.filter((ledger) => ledger.account_id === id);
      const title = filteredLedger[0].account_name;

      // render the ledger title part
      content.push(<TitleHolder colSpan={8}>{title}</TitleHolder>);
      currentRowCount += 1;

      if (currentRowCount >= (isFirstPage ? LIMIT_ROW_PER_PAGE - 6 : LIMIT_ROW_PER_PAGE)) {
        content.push(<PageBreaker />);
        currentRowCount = 0;
        isFirstPage = false;
      }

      // render the needed details for the table rows
      const calculatedData = ledgerBodyDataCalculator(filteredLedger);
      const rowData = calculatedData.data;
      const total = calculatedData.total;

      for (let j = 0; j < rowData.length; j++) {
        const ledgerData = rowData[i];
        content.push(
          <tr key={j}>
            <Td>{formatDate(ledgerData.created_at)}</Td>
            <Td>{ledgerData.transaction_type_code}</Td>
            <Td>{ledgerData.reference_id}</Td>
            <Td>{ledgerData.account_name}</Td>
            <Td>{ledgerData.description}</Td>
            <Td>{ledgerData.source_account_name}</Td>
            <Tdn>{formatNumber(ledgerData.amount)}</Tdn>
            <Tdn>{formatNumber(ledgerData.balance)}</Tdn>
          </tr>
        );

        currentRowCount += 1;
        if (currentRowCount >= (isFirstPage ? LIMIT_ROW_PER_PAGE - 6 : LIMIT_ROW_PER_PAGE)) {
          content.push(<PageBreaker />);
          currentRowCount = 0;
          isFirstPage = false;
        }
      }

      content.push(<LedgerTotal title={title} total={total} />);
      currentRowCount += 1;
      if (currentRowCount >= (isFirstPage ? LIMIT_ROW_PER_PAGE - 6 : LIMIT_ROW_PER_PAGE)) {
        content.push(<PageBreaker />);
        currentRowCount = 0;
        isFirstPage = false;
      }
    }

    // accountIds.map((id, index) => {
    //   const filteredLedger = ledgerReportData.filter((ledger) => ledger.account_id === id);
    //   if (filteredLedger.length === 0) return <></>;

    //   const title = filteredLedger[0].account_name;
    //   return <LedgerBody key={index} data={filteredLedger} title={title} />;
    // });
    return content;
  }

  useEffect(() => {
    const uniqueIds = Array.from(new Set(ledgerReportData.map((ledger) => ledger.account_id)));
    setAccountIds(uniqueIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div ref={ref} style={{ padding: '0.5in' }}>
      <style>{styles}</style>
      {/* Outside title of the file */}
      <div style={{ textAlign: 'center', width: '100%' }}>
        <Typography variant="h4" fontWeight={'bold'} align="center">
          PICH
        </Typography>
        <Typography variant="h5" align="center">
          General Ledger
        </Typography>
        <Typography variant="h5" align="center">
          {formatGenLedgerDate(startDate, endDate)}
        </Typography>
      </div>

      {/* Table contents */}
      <table style={{ borderCollapse: 'collapse', width: '100%' }}>
        <LedgerHeader />
        <tbody>
          {/* {accountIds.length > 0 &&
            accountIds.map((id, index) => {
              const filteredLedger = ledgerReportData.filter((ledger) => ledger.account_id === id);
              if (filteredLedger.length === 0) return <></>;

              const title = filteredLedger[0].account_name;
              return <LedgerBody key={index} data={filteredLedger} title={title} />;
            })} */}
          {renderContent(ledgerReportData)}
        </tbody>
      </table>

      {/* footer */}
      <div className="footer" style={fixedFooterStyle}>
        <Box display={'flex'} alignItems={'center'}>
          <img
            alt="cphi-logo"
            width="120px"
            height="auto"
            id="logo"
            src={process.env.PUBLIC_URL + '/assets/carego_health_suite_logo.png'}
          />
        </Box>
      </div>
    </div>
  );
});

export default GeneralLedgerTable;

const fixedFooterStyle: React.CSSProperties = {
  position: 'fixed',
  bottom: '0',
  left: '0',
  width: '100%',
  padding: '10px',
  textAlign: 'center',
  pageBreakBefore: 'always',
};

const styles = `
  @page {
    size: A4;
    margin: 1cm;
  }
`;
