import * as yup from 'yup';

import { DROPDOWN_FIELD } from 'core/model/interface';
import { EntityFields } from 'company/entities/utils';

export const PRODUCT_ENTITY_NAME = 'Product';

export interface CategoryModel {
  id: number;
  category: number;
  company_id: number;
  created_by: number;
  description: string;
  is_active: number;
}

export const createProductModelSchema = yup.object().shape({
  product_name: yup.string().required('Product name is required'),
});

export interface ProductModel {
  id: number;
  product_id: number;
  category_id: number;
  category: string;
  category_description: string;
  description: string;
  is_active: string;
  price: string;
  product_name: string;
  initial_quantity?: number;
}

export const productFields: EntityFields[] = [
  { fieldName: 'product_name', displayName: 'Product Name', sortable: true },
  { fieldName: 'category', displayName: 'Category' },
  { fieldName: 'description', displayName: 'Description', type: 'text', multiline: true },
  {
    fieldName: 'category_id',
    type: DROPDOWN_FIELD.PRODUCT_CATEGORY,
    optional: true,
  },
  {
    fieldName: 'base_uom_id',
    displayName: 'Unit of Measurement',
    type: DROPDOWN_FIELD.PRODUCT_UOM,
    optional: true,
  },
  { fieldName: 'initial_quantity', displayName: 'Initial Quantity', type: 'number' },
];

export const visibleProductColumns = ['product_name', 'category'];
export const addProductFormFields = ['product_name', 'category_id', 'base_uom_id', 'initial_quantity'];
export const updateProductFormFields = ['product_name', 'category_id'];
export const addProductInitialValues = {
  product_name: '',
  description: '',
  initial_quantity: 0,
};

export const updateProductInitialValues = {
  product_name: '',
  description: '',
};
