import TemplateForm from 'company/entities/Template/TemplateForm';

import { FacilityContext } from 'core/context/facility.context';
import { useContext } from 'react';
import {
  patientNoteSummaryTemplateFields,
  patientNoteSummaryTemplateInitialValues,
  PatientNoteSummaryTemplateModel,
  patientNoteSummaryTemplateFormFields,
  PNST_ENTITY_NAME,
  createPatientNoteSummaryTemplate,
} from './PatientNoteSummaryTemplateModel';
import { createNewNoteSummaryTemplate, updateNoteSummaryTemplate } from 'company/api/patient-note-summary-template';
type Props = {
  readonly?: boolean;
  healthAssessment?: PatientNoteSummaryTemplateModel;
  showSubmitAtTop?: boolean;
  callbackAfterSubmit?: (data: any) => void;
};

const PatientNoteSummaryTemplateForm = (props: Props) => {
  const { readonly, healthAssessment, showSubmitAtTop, callbackAfterSubmit } = props;
  const { facility } = useContext(FacilityContext);
  async function handleSubmit(data: any) {
    if (!healthAssessment) return createNewNoteSummaryTemplate(data);
    return updateNoteSummaryTemplate(healthAssessment.id, data);
  }

  return (
    <TemplateForm
      schema={createPatientNoteSummaryTemplate}
      callbackAfterSubmit={callbackAfterSubmit}
      entityName={PNST_ENTITY_NAME}
      fields={patientNoteSummaryTemplateFields}
      initialValues={!!healthAssessment ? healthAssessment : patientNoteSummaryTemplateInitialValues}
      visibleFields={patientNoteSummaryTemplateFormFields}
      formSubmitApiFunction={handleSubmit}
    />
  );
};

export default PatientNoteSummaryTemplateForm;
