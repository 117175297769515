/* eslint-disable react-hooks/exhaustive-deps */

import { ApiQuery, DROPDOWN_FIELD, FieldInput } from 'core/model/interface';
import {
  ConfirmationDialog,
  CustomCheckbox,
  CustomForm,
  CustomInlineForm,
  CustomModal,
  ProtectedComponent,
  RegularButton,
  RowAction,
} from 'core/components';
import { Menu, MenuItem, Typography, useMediaQuery } from '@mui/material';
import PageBuilder, { BLOCK_TYPE } from 'core/PageBuilder/PageBuilder';
import { PageBuilderContainerType, PageBuilderPageType, PageBuilderTableType } from 'core/PageBuilder';
import { assignClinic, getClinicsAdded, getClinicsNotAdded, removeAssignedClinic } from 'company/api/corporate-clients';
import { assignDoctorToClinic, getDoctorClinics, updateDoctorSchedules } from 'company/api/doctors';
import { assignPackageToClinic, getHealthPackageClinics, removePackagesFromClinic } from 'company/api/health-packages';
import {
  assignServicePackageToClinic,
  getServicePackageClinics,
  removeServicePackagesFromClinic,
} from 'company/api/service-packages';
import {
  assignServiceToClinic,
  getServicesClinics,
  removeServicesFromClinic,
  updateAllClinicServicePrice,
  updateClinicServicePrice,
  updateServiceVisibility,
} from 'company/api/services';
import {
  assignSignatoryToClinic,
  getClinicsWithoutSignatory,
  getSignatoryClinics,
  removeSignatoryFromClinic,
} from 'company/api/signatories';
import { assignUserToClinic, getUserFacilities, removeUsersFromClinic } from 'company/api/users';
import {
  getClinicsWithoutPackageId,
  getClinicsWithoutProviderId,
  getClinicsWithoutServiceId,
  getClinicsWithoutServicePackageId,
} from 'company/api/facility';
import { useContext, useEffect, useState } from 'react';

import { Box } from '@mui/system';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import DateRangeIcon from '@mui/icons-material/DateRange';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { DropdownOptionProps } from 'core/components/Dropdown';
import { FacilityContext } from 'core/context/facility.context';
import { GridColDef } from '@mui/x-data-grid';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PriceChangeOutlinedIcon from '@mui/icons-material/PriceChangeOutlined';
import { UserContext } from 'core/context/user.context';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import WeeklySchedule from './WeeklySchedule';
import { formatCurrency } from 'core/utils';
import { updateFacilityServices } from 'core/components/ProtectedRoute';
import { useSnackbar } from 'notistack';

type ClinicsTableProps = {
  source:
    | 'Services'
    | 'User Access'
    | 'Service Providers'
    | 'Health Services'
    | 'Clients'
    | 'Service Packages'
    | 'Signatories';
  selectedClient?: any;
  selectedService?: any;
  selectedProvider?: any;
  selectedUser?: any;
  selectedServicePackage?: any;
  selectedSignatory?: any;
  selectedPackage?: any;
  externalRefresh?: number;
  setRefreshExternalDetails?: (refresh: any) => void;
};

const ClinicsTable: React.FC<ClinicsTableProps> = ({
  source,
  selectedClient,
  selectedService,
  selectedProvider,
  selectedUser,
  selectedServicePackage,
  selectedPackage,
  selectedSignatory,
  externalRefresh,
  setRefreshExternalDetails,
}) => {
  const { setFacility, facility } = useContext(FacilityContext);
  const { enqueueSnackbar } = useSnackbar();
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
  const [data, setData] = useState<any>();

  const [forceRefresh, setForceRefresh] = useState<number>(0);
  const [addId, setAddId] = useState<number>(0);
  const [openAddModal, setOpenAddModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [selectedRow, setSelectedRow] = useState<any>();
  const [openScheduleModal, setOpenScheduleModal] = useState<boolean>(false);
  const [service, setService] = useState<any>();
  const [doctor, setDoctor] = useState<any>();
  const [disabled, setDisabled] = useState<any>();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [clinicsNotAdded, setClinicsNotAdded] = useState<any[]>([]);
  const [deleteContent, setDeleteContent] = useState<string>(
    'Are you sure you want to remove the service from this clinic?'
  );
  const [addContent, setAddContent] = useState<string>('Do you want to add service to this clinic?');
  const [formFields, setFormFields] = useState<any>(null);
  const [formInitialValues, setFormInitialValues] = useState<any>(null);
  const [openUpdateModal, setOpenUpdateModal] = useState<boolean>(false);

  const [selectedClinicForSchedule, setSelectedClinicForSchedule] = useState<any>(null);
  const [openScheduleBeforeAdd, setOpenScheduleBeforeAdd] = useState<boolean>(false);
  const [scheduleForAssignment, setScheduleForAssignment] = useState<any>(null);

  const isMobilePhone = useMediaQuery('(max-width:768px)');

  const { user } = useContext(UserContext);
  useEffect(() => {
    setting();
  }, [source, selectedRow, addId]);

  const setting = async () => {
    if (source === 'Clients') {
      selectedClient &&
        getClinicsNotAdded(selectedClient.id).then((service) => {
          setClinicsNotAdded(service.data.data);
        });
    } else if (source === 'Services') {
      selectedRow &&
        setFormFields([
          { field_name: 'clinic_price', display_name: 'Price (Php)', type: 'text' },
          { field_name: 'provider_id', type: DROPDOWN_FIELD.SERVICE_PROVIDER, optional: true },
        ]);
      selectedRow &&
        setFormInitialValues({ clinic_price: selectedRow.clinic_price, provider_id: selectedRow.provider_id });
      selectedService &&
        getClinicsWithoutServiceId(selectedService.id).then((service) => {
          setClinicsNotAdded(service.data.data);
        });
    } else if (source === 'Service Providers') {
      setDeleteContent('Are you sure you want to unassign doctor from this facility?');
      setAddContent('Do you want to add provider to this clinic?');
      selectedProvider &&
        getClinicsWithoutProviderId(selectedProvider.id).then((service) => {
          setClinicsNotAdded(service.data.data);
        });
    } else if (source === 'User Access') {
      setDeleteContent('Are you sure you want to remove user from this facility?');
      setAddContent('Do you want to add user to this clinic?');
      selectedUser &&
        getClinicsWithoutProviderId(selectedUser.id).then((service) => {
          setClinicsNotAdded(service.data.data);
        });
    } else if (source === 'Service Packages') {
      setDeleteContent('Are you sure you want to remove this service package from the clinic?');
      setAddContent('Do you want to add this service package to the clinic?');
      selectedServicePackage &&
        getClinicsWithoutServicePackageId(selectedServicePackage.service_package_id).then((response) => {
          setClinicsNotAdded(response.data.data);
        });
    } else if (source === 'Signatories') {
      setDeleteContent('Are you sure you want to remove this signatory from the clinic?');
      setAddContent('Do you want to add this signatory to the clinic?');
      selectedSignatory &&
        getClinicsWithoutSignatory(selectedSignatory.id).then((response) => {
          setClinicsNotAdded(response.data.data);
        });
    } else {
      setDeleteContent('Are you sure you want remove health session from this facility?');
      setAddContent('Do you want to add health session to this clinic?');
      selectedPackage &&
        getClinicsWithoutPackageId(selectedPackage.id).then((service) => {
          setClinicsNotAdded(service.data.data);
        });
    }
  };

  useEffect(() => {
    refreshTable();
    setting();
  }, [
    source,
    selectedService,
    selectedProvider,
    selectedUser,
    selectedServicePackage,
    selectedPackage,
    selectedSignatory,
    externalRefresh,
  ]);

  const getData = async (query: ApiQuery) => {
    if (source === 'Clients') {
      return selectedClient && getClinicsAdded(selectedClient.id);
    } else if (source === 'Services') {
      return selectedService && getServicesClinics(selectedService.id);
    } else if (source === 'Service Providers') {
      return selectedProvider && getDoctorClinics(selectedProvider.id, query);
    } else if (source === 'User Access') {
      return selectedUser && getUserFacilities(selectedUser.id);
    } else if (source === 'Signatories') {
      return selectedSignatory && getSignatoryClinics(selectedSignatory.id);
    } else if (source === 'Service Packages') {
      return selectedServicePackage && getServicePackageClinics(selectedServicePackage.id, query);
    } else {
      return selectedPackage && getHealthPackageClinics(selectedPackage.id);
    }
  };

  const handleUpdateFormSubmit = async (data: any) => {
    if (source === 'Services') {
      setLoading(true);
      selectedRow &&
        selectedService &&
        updateClinicServicePrice(selectedRow.facility_id, selectedService.id, data)
          .then(() => {
            enqueueSnackbar('Service successfully updated!', { variant: 'success' });
          })
          .finally(() => {
            setLoading(false);
            setOpenUpdateModal(false);
            refreshTable();
            localStorage.removeItem('orders');
          });
    }
  };

  const handleSelectRows = (selected: any) => {
    setSelectedRows(selected);
  };

  const handleDeleteConfirm = (data: any) => {
    setData(data);

    if (source === 'Clients') {
      setDeleteContent('Are you sure you want to remove the client from this clinic?');
    }
    setOpenConfirmDialog(true);
  };

  const handleDelete = () => {
    if (source === 'Clients') {
      return removeAssignedClinic({ facilities: [data.id], corporate_client_id: selectedClient.id })
        .then(() => {
          setForceRefresh((prev: number) => prev + 1);
          enqueueSnackbar('Clinic successfully removed!', { variant: 'success' });
          setting();
          setRefreshExternalDetails && setRefreshExternalDetails((prev: number) => prev + 1);
        })
        .then(() => localStorage.removeItem('orders'));
    } else if (source === 'Services') {
      const serviceList = JSON.stringify(data ? [data.service_id] : selectedRows);
      const services = { services: serviceList };

      return removeServicesFromClinic(data.facility_id, services)
        .then(() => {
          setForceRefresh((prev: number) => prev + 1);
          updateFacilityServices(setFacility);
          enqueueSnackbar('Service successfully removed!', { variant: 'success' });
          setting();
          setRefreshExternalDetails && setRefreshExternalDetails((prev: number) => prev + 1);
        })
        .then(() => localStorage.removeItem('orders'));
    } else if (source === 'Service Packages') {
      const servicePackageList = data ? [data.service_package_id] : selectedRows;
      // Fix: Send array directly, backend will handle JSON conversion
      const servicePackages = servicePackageList;

      return removeServicePackagesFromClinic(data.facility_id, servicePackages)
        .then(() => {
          setForceRefresh((prev: number) => prev + 1);
          // updateFacilityServices(setFacility);
          enqueueSnackbar('Service Package successfully removed!', { variant: 'success' });
          setting();
          setRefreshExternalDetails && setRefreshExternalDetails((prev: number) => prev + 1);
        })
        .catch((error) => {
          enqueueSnackbar(error.response?.data?.message || 'Error removing service package', {
            variant: 'error',
          });
        })
        .finally(() => localStorage.removeItem('orders'));
    } else if (source === 'Signatories') {
      const signatoryList = data ? [data.signatory_id] : selectedRows;
      // Fix: Send array directly, backend will handle JSON conversion
      const signatories = signatoryList;

      return removeSignatoryFromClinic(data.facility_id, signatories)
        .then(() => {
          setForceRefresh((prev: number) => prev + 1);
          // updateFacilityServices(setFacility);
          enqueueSnackbar('Signatory successfully removed!', { variant: 'success' });
          setting();
          setRefreshExternalDetails && setRefreshExternalDetails((prev: number) => prev + 1);
        })
        .catch((error) => {
          enqueueSnackbar(error.response?.data?.message || 'Error removing signatory', {
            variant: 'error',
          });
        })
        .finally(() => localStorage.removeItem('orders'));
    } else if (source === 'Service Providers') {
      const doctorList = JSON.stringify(data ? [data.user_id] : selectedRows);
      const doctors = { users: doctorList };

      return removeUsersFromClinic(data.facility_id, doctors).then(() => {
        setForceRefresh((prev: number) => prev + 1);
        enqueueSnackbar('Service Provider successfully unassigned!', { variant: 'success' });
        setting();
        setRefreshExternalDetails && setRefreshExternalDetails((prev: number) => prev + 1);
      });
    } else if (source === 'User Access') {
      const userList = JSON.stringify(data ? [data.user_id] : selectedRows);
      const users = { users: userList };

      return removeUsersFromClinic(data.facility_id, users).then(() => {
        setForceRefresh((prev: number) => prev + 1);
        enqueueSnackbar('User successfully removed!', { variant: 'success' });
        setting();
        setRefreshExternalDetails && setRefreshExternalDetails((prev: number) => prev + 1);
      });
    } else {
      const packageList = JSON.stringify(data ? [data.package_id] : selectedRows);
      const packages = { packages: packageList };

      return removePackagesFromClinic(data.clinic_id, packages).then(() => {
        setForceRefresh((prev: number) => prev + 1);
        enqueueSnackbar('Health Session successfully removed!', { variant: 'success' });
        setting();
        setRefreshExternalDetails && setRefreshExternalDetails((prev: number) => prev + 1);
      });
    }
  };

  const handleAdd = async () => {
    if (source === 'Clients') {
      return assignClinic({ facilities: [addId], corporate_client_id: selectedClient.id }).then(() => {
        setForceRefresh((prev: number) => prev + 1);
        enqueueSnackbar('Clinic successfully removed!', { variant: 'success' });
        setting();
        setRefreshExternalDetails && setRefreshExternalDetails((prev: number) => prev + 1);
      });
    } else if (source === 'Services') {
      const addData = { data: JSON.stringify([selectedService && selectedService.id]) };

      return assignServiceToClinic(addId, addData).then(() => {
        setForceRefresh((prev: number) => prev + 1);
        enqueueSnackbar('Service successfully added to clinic!', { variant: 'success' });
        handleClose();
        setting();
        setRefreshExternalDetails && setRefreshExternalDetails((prev: number) => prev + 1);
      });
    } else if (source === 'Service Providers') {
      setLoading(true);
      try {
        // Use clinic schedule if disabled, else use scheduleForAssignment
        const scheduleToSend = disabled ? selectedClinicForSchedule?.schedule : scheduleForAssignment;

        const addData = {
          data: JSON.stringify([selectedProvider?.id]),
          schedule: scheduleToSend,
        };

        await assignDoctorToClinic(selectedClinicForSchedule.id, addData);

        setForceRefresh((prev) => prev + 1);
        enqueueSnackbar('Service Provider successfully assigned with schedule!', { variant: 'success' });
        handleClose();
        setting();
        setRefreshExternalDetails?.((prev: any) => prev + 1);
      } catch (error) {
        console.error('Error in provider assignment:', error);
        enqueueSnackbar('Failed to assign provider to clinic', { variant: 'error' });
      } finally {
        setLoading(false);
        setOpenScheduleBeforeAdd(false);
        setSelectedClinicForSchedule(null);
        setScheduleForAssignment(null);
      }
      return;
    } else if (source === 'User Access') {
      const addData = { data: JSON.stringify([selectedUser && selectedUser.id]) };

      return assignDoctorToClinic(addId, addData).then(() => {
        setForceRefresh((prev: number) => prev + 1);
        enqueueSnackbar('User successfully added to clinic!', { variant: 'success' });
        handleClose();
        setting();
        setRefreshExternalDetails && setRefreshExternalDetails((prev: number) => prev + 1);
      });
    } else if (source === 'Service Packages') {
      const addData = { data: JSON.stringify([selectedServicePackage && selectedServicePackage.service_package_id]) };

      return assignServicePackageToClinic(addId, addData).then(() => {
        setForceRefresh((prev: number) => prev + 1);
        enqueueSnackbar('Service Package successfully added to clinic!', { variant: 'success' });
        handleClose();
        setting();
        setRefreshExternalDetails && setRefreshExternalDetails((prev: number) => prev + 1);
      });
    } else if (source === 'Signatories') {
      const addData = { data: JSON.stringify([selectedSignatory && selectedSignatory.id]) };

      return assignSignatoryToClinic(addId, addData).then(() => {
        setForceRefresh((prev: number) => prev + 1);
        enqueueSnackbar('Signatory successfully added to clinic!', { variant: 'success' });
        handleClose();
        setting();
        setRefreshExternalDetails && setRefreshExternalDetails((prev: number) => prev + 1);
      });
    } else {
      const addData = { data: JSON.stringify([selectedPackage && selectedPackage.id]) };

      return assignPackageToClinic(addId, addData).then(() => {
        setForceRefresh((prev: number) => prev + 1);
        enqueueSnackbar('Health Session successfully added to clinic!', { variant: 'success' });
        handleClose();
        setting();
        setRefreshExternalDetails && setRefreshExternalDetails((prev: number) => prev + 1);
      });
    }
  };

  const setCustomSchedule = async (data: any) => {
    setOpenScheduleModal(true);
    selectedService && setService(data);
    selectedProvider && setDoctor(data);
    selectedService && setDisabled(!!!data.available_schedule);
    selectedProvider && setDisabled(!!!data.schedule);
  };

  const refreshTable = () => {
    setForceRefresh((prev: number) => prev + 1);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleAddClick = (data: any) => {
    if (source === 'Service Providers') {
      const selectedClinic = clinicsNotAdded.find((clinic) => clinic.id === data);
      setSelectedClinicForSchedule(selectedClinic);
      setOpenScheduleBeforeAdd(true);
      setScheduleForAssignment(selectedClinic?.schedule || null);
    } else {
      setAddId(data);
      setOpenAddModal(true);
    }
  };

  const handleScheduleBeforeAssign = (schedule: any) => {
    // If disabled use the og schedule
    // If not disabled, use the custom schedule
    const scheduleToUse = disabled ? selectedClinicForSchedule?.schedule : schedule;

    setScheduleForAssignment(scheduleToUse);
    setOpenScheduleBeforeAdd(false);
    setAddId(selectedClinicForSchedule.id);
    setOpenAddModal(true);
  };

  const renderScheduleBeforeAddModal = () => (
    <CustomModal
      header="Set Custom Schedule"
      subHeader={selectedProvider?.full_name_with_title || selectedProvider?.full_name}
      open={openScheduleBeforeAdd}
      setOpen={setOpenScheduleBeforeAdd}
      width={800}
    >
      <Box>
        <Box display="flex" gap="2px" alignItems="center">
          <CustomCheckbox
            label="Use Clinic Schedule"
            value={disabled}
            handleChange={() => {
              setDisabled((prev: boolean) => !prev);
            }}
            fieldName=""
          />
        </Box>
      </Box>
      <WeeklySchedule
        schedule={selectedClinicForSchedule?.schedule}
        id={selectedProvider?.id}
        type="doctor"
        onSave={handleScheduleBeforeAssign}
        disabled={disabled}
        clinic_id={selectedClinicForSchedule?.id}
      />
    </CustomModal>
  );

  const setVisibility = (data: any) => {
    const serviceList = JSON.stringify(data ? [data.service_id] : selectedRows);
    const services = {
      services: serviceList,
      visible: !data.visible,
    };

    updateServiceVisibility(data.facility_id, services)
      .then((res: any) => {
        localStorage.removeItem('orders');
        enqueueSnackbar('Service visibility successfully updated!', { variant: 'success' });
        refreshTable();
        setService(undefined);
        updateFacilityServices(setFacility);
      })
      .catch((error) => console.error(error));
  };

  const priceFields: FieldInput[] = [
    {
      field_name: 'clinic_price',
      display_name: 'Price',
      type: 'text',
      span: 1,
    },
  ];

  const handleUpdatePriceAll = async (data: any) => {
    if (source === 'Services') {
      setLoading(true);
      selectedService &&
        updateAllClinicServicePrice(selectedService?.id, data)
          .then(() => {
            enqueueSnackbar('Service successfully updated!', { variant: 'success' });
          })
          .finally(() => {
            setLoading(false);
            setOpenUpdateModal(false);
            refreshTable();
            localStorage.removeItem('orders');
          });
    }
  };

  const columns: GridColDef[] =
    source === 'Services'
      ? [
          { field: 'facility_name', headerName: 'Clinic Name', flex: 2 },
          {
            field: 'clinic_price',
            headerName: 'Price',
            flex: 1,
            renderCell: (params) => <Typography>{formatCurrency(params.value)}</Typography>,
            align: 'right',
            headerAlign: 'right',
          },
          {
            field: 'full_name',
            headerName: 'Service Provider',
            flex: 2,
            sortable: false,
          },
          {
            field: 'visible',
            headerName: 'Visibility',
            flex: 1,
            renderCell: (params) => <Typography>{params.row.visible ? 'Visible' : 'Hidden'}</Typography>,
          },

          {
            field: 'action',
            sortable: false,
            headerName: 'Action',
            headerAlign: 'center',
            align: 'center',
            flex: 1,
            renderCell: (params) => (
              <div>
                <RowAction actions={actions} data={params.row} getRow={setSelectedRow} />
              </div>
            ),
          },
        ]
      : [
          { field: 'facility_name', headerName: 'Clinic Name', flex: 2 },
          {
            field: 'action',
            sortable: false,
            headerName: 'Action',
            headerAlign: 'center',
            align: 'center',
            flex: 1,
            renderCell: (params) => (
              <div>
                <RowAction actions={actions} data={params.row} getRow={setSelectedRow} />
              </div>
            ),
          },
        ];

  const actions: DropdownOptionProps[] =
    source === 'Services'
      ? [
          {
            label: 'Update Clinic Service',
            action: () => {
              setOpenUpdateModal(true);
            },
            icon: <PriceChangeOutlinedIcon />,
          },
          { label: 'Set Custom Schedule', action: setCustomSchedule, icon: <CalendarMonthOutlinedIcon /> },
          {
            label: selectedRow && selectedRow.visible ? 'Hide Service' : 'Show Service',
            action: setVisibility,
            icon: selectedRow && selectedRow.visible ? <VisibilityOffIcon /> : <VisibilityIcon />,
          },
          { label: 'divider' },
          { label: 'Delete', color: 'error', action: handleDeleteConfirm, icon: <DeleteOutlinedIcon /> },
        ]
      : source === 'Service Providers'
      ? [
          { label: 'Delete', action: handleDeleteConfirm, icon: <DeleteOutlinedIcon /> },
          { label: 'Update Schedule', action: setCustomSchedule, icon: <DateRangeIcon /> },
        ]
      : [{ label: 'Delete', action: handleDeleteConfirm, icon: <DeleteOutlinedIcon />, color: 'error' }];
  const content = {
    type: BLOCK_TYPE.PAGE,
    block: {
      type: BLOCK_TYPE.CONTAINER,
      block: {
        type: BLOCK_TYPE.TABLE,
        entityName: 'Clinic',

        doNotWaitForFacility: true,
        useColoredTable: true,
        tableComponent: {
          hideSearch: source === 'Service Packages' || 'Signatories',
          //Hid search for now seems to be not working in general when tested (causes error with service package)
          columns: columns,
          getData: getData,
          forceRefresh: forceRefresh,
          // checkboxSelection: true,
          selectableRow: true,

          mobileViewConfig: (data) => ({
            title_fields: ['facility_name'],
            // subtitle_fields: ['sex', 'age'],
          }),

          afterSearchbarComponent: (
            <Box display="flex" gap="10px">
              {!isMobilePhone && !!user.facilities && user.facilities?.length > 1 && (
                <ProtectedComponent requiredRole={['COMPANY_ADMIN']}>
                  <RegularButton
                    styles={{ width: '150px' }}
                    label={`Add Clinics`}
                    onClick={handleClick}
                    endIcon={<KeyboardArrowDownIcon />}
                  />
                </ProtectedComponent>
              )}

              <Menu
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorEl={anchorEl}
                keepMounted
                sx={{
                  marginTop: '0.5rem',
                }}
              >
                <Box>
                  {clinicsNotAdded.length === 0 ? <MenuItem disabled={true}>All clinics are added</MenuItem> : <></>}
                  {clinicsNotAdded &&
                    clinicsNotAdded.map((clinic) => (
                      <MenuItem key={clinic.facility_name} value={clinic.id} onClick={() => handleAddClick(clinic.id)}>
                        {clinic.facility_name}
                      </MenuItem>
                    ))}
                </Box>
              </Menu>
            </Box>
          ),
          onSelectRow: handleSelectRows,
          rowHeight: 60,
          headerComponent: isMobilePhone ? (
            <Box sx={{ flex: 2 }}>
              <Typography sx={{ fontSize: '1rem', fontWeight: '600' }}>Clinics</Typography>
              {source === 'Services' && (
                <CustomInlineForm
                  submitButtonSize="small"
                  fields={priceFields}
                  onSubmit={handleUpdatePriceAll}
                  initialValues={{ clinic_price: '' }}
                  submitButtonText="Apply To All"
                  enableRowButtonMobile={true}
                />
              )}
            </Box>
          ) : (
            <Box>
              <Typography sx={{ fontSize: '1rem', fontWeight: '600' }}>Clinics</Typography>
              {source === 'Services' && (
                <CustomInlineForm
                  submitButtonSize="small"
                  fields={priceFields}
                  onSubmit={handleUpdatePriceAll}
                  initialValues={{ clinic_price: '' }}
                  submitButtonText="Apply To All"
                />
              )}
            </Box>
          ),
        },
        tableAction: {
          getRow: setService,
          // rowActions: isMobilePhone && [
          //   {
          //     label: 'Delete',
          //     icon: <DeleteOutlineOutlinedIcon />,
          //     color: 'error',
          //     action: handleDeleteConfirm,
          //   },
          // ],
        },
      } as PageBuilderTableType,
    } as PageBuilderContainerType,
  } as PageBuilderPageType;

  return (
    <>
      <PageBuilder content={content} />

      {renderScheduleBeforeAddModal()}

      <ConfirmationDialog
        open={openConfirmDialog}
        setOpen={setOpenConfirmDialog}
        content={deleteContent}
        onConfirm={handleDelete}
      />
      <ConfirmationDialog open={openAddModal} setOpen={setOpenAddModal} content={addContent} onConfirm={handleAdd} />

      <CustomModal open={openUpdateModal} setOpen={setOpenUpdateModal} header={'Update Clinic Service'}>
        <CustomForm
          fields={formFields && formFields}
          initialValues={formInitialValues && formInitialValues}
          onSubmit={handleUpdateFormSubmit}
          loading={loading}
        />
      </CustomModal>

      <CustomModal
        header={source === 'Services' ? 'Set Custom Schedule' : 'Update Schedule'}
        subHeader={
          source === 'Services'
            ? service?.service_name
            : selectedProvider?.full_name_with_title
            ? selectedProvider?.full_name_with_title
            : selectedProvider?.full_name
        }
        open={openScheduleModal}
        setOpen={setOpenScheduleModal}
        width={800}
      >
        <Box>
          {(service || doctor) && (
            <Box display={'flex'} gap="2px" alignItems={'center'}>
              <CustomCheckbox
                label={'Use Clinic Schedule'}
                value={disabled}
                handleChange={() => {
                  setDisabled((prev: boolean) => !prev);
                }}
                fieldName={''}
              />
            </Box>
          )}
        </Box>
        {(service || doctor) && (
          <WeeklySchedule
            schedule={source === 'Services' ? service.available_schedule : doctor.schedule}
            id={source === 'Services' ? service.service_id : doctor.assignment_id}
            type={source === 'Services' ? 'service' : 'doctor'}
            refreshTable={refreshTable}
            disabled={disabled}
            clinic_id={source === 'Services' ? service.facility_id : doctor.facility_id}
          />
        )}
      </CustomModal>
    </>
  );
};

export default ClinicsTable;
