/* eslint-disable react-hooks/exhaustive-deps */

import {
  CustomGridCell,
  CustomModal,
  CustomSwitch,
  CustomTable,
  HideOrShowComponent,
  RegularButton,
} from 'core/components';
import { GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';
import { chat, send } from 'core/icons';
import { getAllHealthPackageSms, getHealthPackageSms } from 'company/api/health-packages-sms';
import { useContext, useEffect, useState } from 'react';

import { ApiQuery } from 'core/model/interface';
import EditFrequencyModal from 'company/screens/Appointments/components/EditSmsFrequencyModal';
import { FacilityContext } from 'core/context/facility.context';
import { SmsScheduleModel } from 'company/model/Entities';
import ViewContent from '../../core/components/modals/ViewContent';
import { formatDateTime } from 'core/utils';
import { getPrescriptionLines } from 'company/api/prescription-line-items';
import { getProgramSms } from 'company/api/sms';
import { previewSms } from 'company/api/sms-template';

type SmsScheduleModalProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  programCode: string;
  patientID?: number; // program_patient_id or patient_id
  fromAppointment?: boolean;
  passActualPatientID?: boolean;
  sensitive?: boolean;
  packageID?: number;
  prescriptionID?: number;
  fromPatientManagement?: boolean;
};

const SmsScheduleModal: React.FC<SmsScheduleModalProps> = ({
  open,
  setOpen,
  programCode,
  patientID,
  fromAppointment,
  passActualPatientID,
  sensitive,
  packageID,
  prescriptionID,
  fromPatientManagement,
}) => {
  const { facility } = useContext(FacilityContext);

  const [loading, setLoading] = useState<boolean>(false);
  const [rows, setRows] = useState<any[]>([]);
  const [rowCount, setRowCount] = useState<number>(0);
  const [selectedRow, setSelectedRow] = useState<SmsScheduleModel>();

  const [viewSent, setViewSent] = useState<boolean>(false);
  const [firstLoad, setFirstLoad] = useState<boolean>(true);
  const [clearSelected, setClearSelected] = useState(0);
  const [refresh, setRefresh] = useState<number>(0);

  const [openSmsPreview, setOpenSmsPreview] = useState<boolean>(false);
  const [previewContent, setPreviewContent] = useState<string>('');

  const [openEditSmsFrequencyModal, setOpenEditSmsFrequencyModal] = useState<boolean>(false);

  const columns: GridColDef[] = fromPatientManagement
    ? [
        { field: 'medicine_full_name', headerName: 'Medicine', flex: 1.2, sortable: false },
        {
          field: 'send_date_time',
          headerName: 'Sending Schedule',
          flex: 1.2,
          renderCell: (params) => <CustomGridCell>{formatDateTime(params.row.send_date_time)}</CustomGridCell>,
        },
        { field: 'counter', headerName: 'Counter', flex: 0.5, sortable: false },
        { field: 'credits_deducted', headerName: 'Credits Used', flex: 0.7, sortable: false },
        {
          field: 'status',
          headerName: 'Status',
          flex: 0.7,
          renderCell: (params) => (
            <CustomGridCell>{params.row.status === 'Completed' ? 'Sent' : params.row.status}</CustomGridCell>
          ),
        },
      ]
    : [
        {
          field: fromAppointment
            ? 'appointment_id'
            : programCode === 'Health Sessions'
            ? 'package_patient_id'
            : 'program_patient_id',
          headerName: fromAppointment ? 'Appt. ID' : programCode === 'Health Sessions' ? 'Patient ID' : 'Cycle ID',
          flex: 0.6,
          headerAlign: 'right',
          align: 'right',
          sortable: false,
        },
        {
          field: sensitive ? 'uic' : 'full_name',
          headerName: sensitive ? 'UIC' : 'Client',
          flex: 1.2,
          sortable: false,
        },
        {
          field: sensitive ? 'encrypted_mobile_number' : 'mobile_number',
          headerName: 'Mobile Number',
          flex: 1,
          sortable: false,
        },
        {
          field: 'package_name',
          headerName: 'Health Session',
          flex: 1,
          sortable: false,
        },
        { field: 'counter', headerName: 'Counter', flex: 0.5, sortable: false },
        { field: 'sms_template', headerName: 'SMS Template', flex: 1.2, sortable: false },
        {
          field: 'send_date_time',
          headerName: 'Sending Schedule',
          flex: 1.2,
          renderCell: (params) => <CustomGridCell>{formatDateTime(params.row.send_date_time)}</CustomGridCell>,
        },
        { field: 'credits_deducted', headerName: 'Credits Used', flex: 0.7, sortable: false },
        {
          field: 'status',
          headerName: 'Status',
          flex: 0.7,
          renderCell: (params) => (
            <CustomGridCell>{params.row.status === 'Completed' ? 'Sent' : params.row.status}</CustomGridCell>
          ),
        },
      ];

  const getSmsSchedules = async (query: ApiQuery) => {
    query.patient_id = patientID;
    let res: any;
    if (passActualPatientID) query.passed_patient_id = 1;
    if (viewSent) query.get_completed = 1;

    if (facility) {
      try {
        setLoading(true);
        if (fromPatientManagement && prescriptionID) {
          res = await getPrescriptionLines(facility.id, prescriptionID, query);
        } else if (programCode === 'Health Sessions') {
          if (packageID) {
            res = await getHealthPackageSms(packageID, query);
          } else {
            res = await getAllHealthPackageSms(query);
          }
        } else {
          res = await getProgramSms(facility.id, programCode, query);
        }
        setRows(res.data.data);
        setRowCount(res.data.meta.total);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
  };

  const viewSmsDetail = async (selected: GridRowSelectionModel) => {
    if (selected.length && selectedRow?.id !== selected[0]) {
      const schedule = rows.find((row) => row.id === selected[0]);
      setSelectedRow(schedule);

      const preview =
        programCode === 'Health Sessions'
          ? await previewSms(facility.id, null, schedule.sms_template)
          : await previewSms(facility.id, schedule.id, null);
      setPreviewContent(preview.data.template_body);
      setOpenSmsPreview(true);
    } else {
      setSelectedRow(undefined);
    }
  };

  useEffect(() => {
    if (!firstLoad) {
      setRefresh((prev) => prev + 1);
    }
    setFirstLoad(false);
  }, [viewSent]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setViewSent(event.target.checked);
  };
  const handleOpenSmsPreview = async (rowData: any) => {
    setPreviewContent(rowData.sms_template);
    await viewSmsDetail([rowData.id]);
    setSelectedRow(rowData);
    setOpenSmsPreview(true);
  };

  return (
    <>
      <CustomModal header="SMS Schedules" open={open} setOpen={setOpen} width={1000}>
        <CustomTable
          columns={
            programCode === 'Health Sessions'
              ? columns.filter((col) => col.field !== 'counter')
              : columns.filter((col) => col.field !== 'package_name')
          }
          rows={rows}
          rowCount={rowCount}
          loading={loading}
          rowId={'id'}
          getData={getSmsSchedules}
          forceRefresh={refresh}
          rowHeight={35}
          selectableRow
          handleSelectRow={(data: any) => viewSmsDetail(data)}
          clearSelected={clearSelected}
          initialOrder={'desc'}
          initialOrderBy={'send_date_time'}
          headerComponent={
            <>
              <CustomSwitch
                label1="Pending"
                label2="Sent"
                image1={send}
                image2={chat}
                value={viewSent}
                handleChange={handleChange}
              />
            </>
          }
          mobileViewConfig={(data: any) => ({
            title_fields: ['full_name'],
            title_link: `/company/patient-management?patient_id=${data.patient_id}`,
            subtitle_fields: ['id', 'mobile_number'],
          })}
          mobileViewDetailsDisplay={(data: any) => (
            <RegularButton
              label="View SMS Template"
              onClick={() => handleOpenSmsPreview(data)} // Pass the 'data' parameter to handleOpenSmsPreview function
              styles={{ width: '200px', height: '200px' }}
            />
          )}
        />
      </CustomModal>

      <ViewContent
        content={previewContent}
        open={openSmsPreview}
        setOpen={setOpenSmsPreview}
        schedule={selectedRow}
        onClose={() => {
          setSelectedRow(undefined);
          setClearSelected((prev: number) => prev + 1);
        }}
      />
    </>
  );
};

export default SmsScheduleModal;
