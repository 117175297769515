import * as yup from 'yup';

import { EntityFields } from '../../../utils';

export const SIGNATORY_ENTITY_NAME = 'Signatory';

export const update_signatory_schema = yup.object().shape({
  first_name: yup.string().required('First Name is required.').max(50, 'Max of 50 characters.'),
  middle_name: yup.string().max(50, 'Max of 50 characters.'),
  last_name: yup.string().required('Last Name is required.').max(50, 'Max of 50 characters.'),
  title: yup.string().max(50, 'Max of 50 characters.'),
  signature: yup.string().required('Signature is required.'),
  role: yup.string().max(50, 'Max of 50 characters.'),
  license_number: yup.string().max(50, 'Max of 50 characters.'),
  ptr_number: yup.string().max(50, 'Max of 50 characters.'),
  facilities: yup.array().when('apply_to_all_clinics', {
    is: false,
    then: yup.array().min(1, 'At least one clinic must be selected'),
    otherwise: yup.array(),
  }),
});

export const create_signatory_schema = update_signatory_schema;

export interface SignatoryModel {
  id: number;
  company_id: number;
  first_name: string;
  middle_name: string | null;
  last_name: string;
  title: string;
  signature: string;
  role: string;
  license_number: string;
  ptr_number: string;
  order: number;
  is_active: boolean;
  created_by: number;
  created_at: string;
  updated_at: string;
  full_name: string;
  full_name_with_title: string;
  assigned_facilities: string;
  assigned_facilities_ids: string;
}

export interface SignatoryInput {
  first_name: string;
  middle_name: string;
  last_name: string;
  title: string;
  signature: string;

  role: string;
  license_number: string;
  ptr_number: string;

  facilities: any[];
  apply_to_all_clinics: boolean;
}

export const signatoryInitialValues: SignatoryInput = {
  first_name: '',
  middle_name: '',
  last_name: '',
  title: '',
  signature: process.env.PUBLIC_URL + '../../assets/blank_banner.png',

  role: '',
  license_number: '',
  ptr_number: '',

  facilities: [],
  apply_to_all_clinics: true,
};

export const signatoryFields: EntityFields[] = [
  { fieldName: 'first_name', displayName: 'First Name' },
  { fieldName: 'middle_name', displayName: 'Middle Name', optional: true },
  { fieldName: 'last_name', displayName: 'Last Name' },
  { fieldName: 'title', displayName: 'Title', optional: true },
  {
    fieldName: 'signature',
    displayName: 'Signature',
    type: 'image_upload',
    isBanner: true,
    onChange: (value: string, setFieldValue: any) => {
      setFieldValue('signature', value);
    },
  },

  { fieldName: 'role', displayName: 'Role', optional: true },
  { fieldName: 'license_number', displayName: 'License Number', optional: true },
  { fieldName: 'ptr_number', displayName: 'PTR Number', optional: true },
  {
    fieldName: 'apply_to_all_clinics',
    displayName: 'Apply to All Clinics',
    type: 'checkbox',
    span: 4,
  },
  {
    fieldName: 'facilities',
    displayName: 'Clinics',
    type: 'multiselect',
    options: [],
    span: 4,
    hiddenBasedOnOtherField: (data: any) => {
      return data.apply_to_all_clinics;
    },
  },
];

export const signatoryFormFields: string[] = [
  'first_name',
  'middle_name',
  'last_name',
  'title',

  'role',
  'license_number',
  'ptr_number',

  'signature',
  'apply_to_all_clinics',
  'facilities',
];

export const updateSignatoryFormFields: string[] = [
  'first_name',
  'middle_name',
  'last_name',
  'title',
  'role',
  'license_number',
  'ptr_number',
  'signature',
];
