import React from 'react';
import { Box, IconButton, TextField, Typography } from '@mui/material';
import { ArrowDropUp, ArrowDropDown } from '@mui/icons-material';

export const CustomNumberInput = ({ value, onChange }: any) => {
  const handleIncrement = () => onChange(value + 1);
  const handleDecrement = () => {
    if (value <= 1) return;
    onChange(value - 1);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        border: '1px solid #ccc',
        borderRadius: '8px',
        padding: '0 10px',
        backgroundColor: '#f7f9fc',
        width: '100px',
      }}
    >
      <Typography sx={{ fontSize: '0.825rem', marginRight: '8px' }}>{value}</Typography>
      <Typography sx={{ fontSize: '0.725rem', color: '#555', flexGrow: 1 }}>{value <= 1 ? 'time' : 'times'}</Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        <IconButton
          size="small"
          onClick={handleIncrement}
          sx={{
            padding: '2px',
            height: '20px',
            '&:hover': { backgroundColor: 'transparent' },
          }}
        >
          <ArrowDropUp fontSize="small" />
        </IconButton>
        <IconButton
          size="small"
          onClick={handleDecrement}
          sx={{
            padding: '2px',
            height: '20px',
            '&:hover': { backgroundColor: 'transparent' },
          }}
        >
          <ArrowDropDown fontSize="small" />
        </IconButton>
      </Box>
    </Box>
  );
};
