import TemplateDropdown, { TemplateDropdownProps } from './TemplateDropdown';

import { ApiQuery } from 'core/model/interface';
import { FacilityContext } from 'core/context/facility.context';
import { useContext } from 'react';
import { getAllProductUOMs, getProductUOM } from 'company/api/product-uom';

const ProductUOMSearchDropdown: React.FC<TemplateDropdownProps> = (props: TemplateDropdownProps) => {
  const { facility } = useContext(FacilityContext);

  function getData(query: ApiQuery) {
    return getAllProductUOMs(facility.id, query);
  }

  function getSingleData(id: number) {
    return getProductUOM(facility.id, id);
  }
  return (
    <TemplateDropdown
      {...props}
      entity={'Unit of Measurement'}
      getData={(query: ApiQuery) => getData({ ...query, order_by: undefined })}
      getById={(id: number) => getSingleData(id)}
      // processOption={(record) => ({ key: record.service_category_name, value: record.id, data: { ...record } })}
      processOption={(record) => ({ key: `${record.name} - ${record.symbol}`, value: record.id })}
      processNewRecord={(record) => record.data}
    />
  );
};

export default ProductUOMSearchDropdown;
