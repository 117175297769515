import * as yup from 'yup';

import { Box, Chip, Tooltip, Typography, useTheme } from '@mui/material';

import { DROPDOWN_FIELD } from 'core/model/interface';
import { EntityFields } from '../../../utils';
import { FieldInput } from 'core/model/interface';
import { Link } from 'react-router-dom';
import { ReactNode } from 'react';
import { SIZES } from 'theme/constants';
import { formatCurrency } from 'core/utils';
import moment from 'moment';
import { tokens } from 'theme/theme';

export const INVOICE_ENTITY_NAME = 'Invoice';

export const patientBillingSchema = yup.object().shape({
  patient_id: yup.number().required('Patient is required.'),
  issue_date: yup
    .date()
    .default(() => new Date())
    .required('Date is required.')
    .typeError('Invalid Date'),
  approved_by: yup
    .string()
    .test('len', 'Approved by must be 100 chars or less', (val) => !val || val.length <= 100)
    .nullable(),
  approved_by_2: yup
    .string()
    .test('len', 'Approved by must be 100 chars or less', (val) => !val || val.length <= 100)
    .nullable(),
  approved_by_3: yup
    .string()
    .test('len', 'Approved by must be 100 chars or less', (val) => !val || val.length <= 100)
    .nullable(),
  approved_by_4: yup
    .string()
    .test('len', 'Approved by must be 100 chars or less', (val) => !val || val.length <= 100)
    .nullable(),
  approved_by_5: yup
    .string()
    .test('len', 'Approved by must be 100 chars or less', (val) => !val || val.length <= 100)
    .nullable(),
  approval_code: yup
    .string()
    .test('len', 'Approval Code must be 50 chars or less', (val) => !val || val.length <= 50)
    .nullable(),
  approval_code_2: yup
    .string()
    .test('len', 'Approval Code must be 50 chars or less', (val) => !val || val.length <= 50)
    .nullable(),
  approval_code_3: yup
    .string()
    .test('len', 'Approval Code must be 50 chars or less', (val) => !val || val.length <= 50)
    .nullable(),
  approval_code_4: yup
    .string()
    .test('len', 'Approval Code must be 50 chars or less', (val) => !val || val.length <= 50)
    .nullable(),
  approval_code_5: yup
    .string()
    .test('len', 'Approval Code must be 50 chars or less', (val) => !val || val.length <= 50)
    .nullable(),
});

export interface PatientBillingModel {
  id: number;
  company_id: number;
  facility_id: number;
  patient_id: number;
  full_name: string;
  invoice_number: string;
  total_amount: number;
  original_amount: number;
  total_discount: number;
  service_provider_names: string;
  total_tax: number;
  total_vat_exempt: string;
  due_date: string;
  due_amount: number;
  status: string;
  issue_date: string;
  created_at: string;
  updated_at: string;
  is_sales_receipt: boolean;
  is_posted: boolean;
  reversal_id?: number;
  hmo_id?: number;
  corporate_client_id?: number;
  service_provider_id?: any;
  providerIDs?: any;
  hmo_name?: string;
  hmo_card_number?: string;
  approval_code?: string;
  approval_code_2?: string;
  approval_code_3?: string;
  approval_code_4?: string;
  approval_code_5?: string;
  approved_by?: string;
  approved_by_2?: string;
  approved_by_3?: string;
  approved_by_4?: string;
  approved_by_5?: string;
  company_name?: string;
  diagnosis?: string;
}

export interface PatientBillingInput {
  patient_id: number;
  issue_date: Date;
  due_date: Date;
}

export interface PatientBillingFieldInput extends FieldInput {
  field_name?: keyof PatientBillingInput;
}

export const patientBillingInitialValues: PatientBillingInput = {
  patient_id: 0,
  issue_date: new Date(),
  due_date: new Date(),
};

// fieldName should be unique
export const patientBillingFields = (colors: any, isAdmin: boolean) =>
  [
    {
      fieldName: 'issue_date',
      displayName: 'Date Issued',
      type: 'date',
      flex: 1,
      editable: true,
      valueFormatter: (params) => moment(params.value).format('DD/MM/YYYY'),
      headerAlign: 'left',
      align: 'left',
    },
    {
      fieldName: 'due_date',
      displayName: 'Due Date',
      type: 'date',
      flex: 1,
      editable: true,
      valueFormatter: (params) => moment(params.value).format('DD/MM/YYYY'),
      headerAlign: 'left',
      align: 'left',
    },
    {
      fieldName: 'service_provider_names',
      displayName: 'Service Providers',
      flex: 1,
    },
    {
      fieldName: 'total_amount',
      displayName: 'Total Amount',
      flex: 1,
      type: 'currency',
      headerAlign: 'right',
      align: 'right',
    },
    {
      fieldName: 'due_amount',
      displayName: 'Remaining Balance',
      flex: 1,
      type: 'currency',
      headerAlign: 'right',
      align: 'right',
    },
    {
      fieldName: 'status',
      displayName: 'Status',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      renderCell: ({ row, value }) => <InvoiceStatus value={value} row={row} />,
    },
    {
      fieldName: 'patient_id',
      displayName: 'Patient Name',
      type: DROPDOWN_FIELD.PATIENT,
      onChange: (value, setFieldValue, setCustomDisplayName) => {
        if (value?.data.corporate_client_id) {
          setFieldValue && setFieldValue('corporate_client', true);
          setFieldValue && setFieldValue('corporate_client_id', value.data.corporate_client_id);
          setCustomDisplayName &&
            setCustomDisplayName((prev: any) => {
              prev.corporate_client = 'Link Transaction To Corporate Client: ' + value.data.company_name;
              return prev;
            });
        } else {
          setFieldValue && setFieldValue('corporate_client', false);
          setFieldValue && setFieldValue('corporate_client_id', undefined);
        }
      },
    },

    {
      displayName: 'Services',
      type: 'table',
      fieldName: 'services',
      columns: [
        {
          fieldName: 'service',
          displayName: 'Services',
          type: DROPDOWN_FIELD.SERVICE,
          onCellChange: (value, row, handleRowEdit) => {
            handleRowEdit(row, 'unit_price', value ? value.data.clinic_price ?? value.data.price : '');
            handleRowEdit(row, 'quantity', value ? 1 : 0);
          },
        },
        { fieldName: 'unit_price', displayName: 'Unit Price', type: 'currency' },
        { fieldName: 'quantity', displayName: 'Quantity', type: 'number' },
      ],
    },
    {
      fieldName: 'corporate_client',
      displayName: 'Link Transaction To Corporate Client',
      flex: 1,
      type: 'checkbox',
      hiddenBasedOnOtherField: (data) => !data.corporate_client_id,
      headerAlign: 'left',
      align: 'left',
    },
    {
      displayName: 'Products',
      type: 'table',
      fieldName: 'products',
      columns: [
        {
          fieldName: 'product',
          displayName: 'Product',
          type: DROPDOWN_FIELD.PRODUCT_VARIANT,
          onCellChange: (value, row, handleRowEdit) => {
            const unitPrice = value ? value.data.clinic_price ?? value.data.price : '';
            const quantityInStock = value ? value.data.quantity_in_stock : 0;
            handleRowEdit(row, 'unit_price', unitPrice);
            handleRowEdit(row, 'quantity', value ? 1 : 0);
            handleRowEdit(row, 'quantity_in_stock', quantityInStock);
          },
        },
        { fieldName: 'unit_price', displayName: 'Unit Price', type: 'currency' },
        // {
        //   fieldName: 'quantity_in_stock',
        //   displayName: 'Stocks Available',
        //   type: 'number',
        // },
        {
          fieldName: 'quantity',
          displayName: 'Quantity',
          type: 'number',
        },
      ],
    },
    {
      fieldName: 'full_name',
      displayName: 'Patient ID & Name',
      flex: 1.5,
      headerAlign: 'left',
      align: 'left',
      renderCell: ({ row, value, stringOnly }: any) =>
        stringOnly ? (
          value
        ) : (
          <Box display="flex" flexDirection="column" alignItems="left">
            <Link to={`/company/patient-management?patient_id=${row.patient_id}`} className="emphasis">
              <Typography> {value} </Typography>
            </Link>
            <Typography sx={{ color: colors.grey_text, fontSize: '11px' }}> ID: {row.patient_id} </Typography>
            {isAdmin && (
              <Typography fontSize="11px" color={colors.text}>
                {row.facility_name}
              </Typography>
            )}
          </Box>
        ),
      // generateLink: (data) => `/company/patient-management?patient_id=${data.patient_id}`,
    },
    {
      fieldName: 'facility_name',
      displayName: 'Clinic',
      flex: 1,
      headerAlign: 'left',
      align: 'left',
    },
    {
      fieldName: 'hmo_name',
      displayName: 'HMO',
      flex: 1,
      headerAlign: 'left',
      align: 'left',
    },
    {
      fieldName: 'all_items',
      displayName: 'Orders',
      flex: 1.5,
      headerAlign: 'left',
      align: 'left',
      renderCell: (params) => {
        const allItems = params.row.all_items;
        if (typeof allItems === 'string') {
          const items = allItems.split(',').map((item) => item.trim());
          const displayedItems = items.slice(0, 5);
          const remainingCount = items.length - 5;

          return (
            <Typography sx={{ fontSize: '12px' }}>
              {displayedItems.join(', ')}
              {remainingCount > 0 ? ` ...` : ''}
            </Typography>
          );
        }
        return allItems || '';
      },
    },
    {
      fieldName: 'amount_received',
      displayName: 'Amount Received',
      flex: 1,
      headerAlign: 'right',
      align: 'right',
      renderCell: (params) =>
        params.row.reversal_id ? (
          <></>
        ) : (
          <Typography>
            {isNaN(params.row.total_amount) || isNaN(params.row.due_amount)
              ? 'N/A'
              : formatCurrency(params.row.total_amount - params.row.due_amount)}
          </Typography>
        ),
    },
    {
      fieldName: 'service_provider_names',
      displayName: 'Service Provider',
      flex: 1,
      headerAlign: 'left',
      align: 'left',
    },
  ] as EntityFields[];

// Table
export const patientBilingTableColumns: string[] = [
  'invoice_number',
  'issue_date',
  'service_provider_names',
  'all_items',
  'total_amount',
  'due_amount',
  'amount_received',
  'status',
];

export const patientBilingTableColumnsWithPatient: string[] = [
  'invoice_number',
  'issue_date',
  'service_provider_names',
  'full_name',
  // 'facility_name',
  'hmo_name',
  'all_items',
  'total_amount',
  'due_amount',
  'amount_received',
  'status',
];

// Forms
export const patientBillingFormFields: string[] = ['patient_id', 'issue_date', 'due_date'];

export const createPatientBillingFormFields: string[] = [
  'patient_id',
  'issue_date',
  'due_date',
  'corporate_client',
  'services',
  'products',
];

interface StatusProps {
  value: any;
  row: PatientBillingModel;
}

export const InvoiceStatus = ({ value, row }: StatusProps) => {
  let backgroundColor = '';
  let textColor = '';
  let statusText = '';

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  if (row.status === 'void') {
    backgroundColor = '#45464e42';
    textColor = theme.palette.mode === 'dark' ? '#ebebeb' : '#37383b';
    statusText = 'Void';
  } else if (!row.is_posted) {
    backgroundColor = '#0c507021';
    textColor = theme.palette.mode === 'dark' ? '#c1d1ec' : '#0d648dd1';
    statusText = 'Unposted';
  } else if (row.reversal_id) {
    backgroundColor = '#df3f0053';
    textColor = theme.palette.mode === 'dark' ? '#FF7518' : '#862b06';
    statusText = 'Reversed';
  } else {
    switch (row.status) {
      case 'paid':
        backgroundColor = '#00ff0036';
        textColor = theme.palette.mode === 'dark' ? '#00FF7F' : '#076d07df';
        statusText = 'Paid';
        break;
      case 'unpaid':
        backgroundColor = '#c12e3d32';
        textColor = '#e91227';
        statusText = 'Unpaid';
        break;
      case 'partial':
        backgroundColor = '#eed92163';
        textColor = theme.palette.mode === 'dark' ? '#FFFF00' : '#96930e';
        statusText = 'Partial';
        break;
      default:
        backgroundColor = 'transparent';
        textColor = '#000000';
        statusText = row.status;
    }
  }

  const tooltipText: { [key: string]: ReactNode } = {
    Reversed: (
      <span>
        <b>Reversed</b> indicates that the invoice has been cancelled for a specific reason.
      </span>
    ),
    Unposted: (
      <span>
        <b>Unposted</b> refers to an invoice that has not yet been confirmed.
      </span>
    ),
    Paid: (
      <span>
        <b>Paid</b> means the entire amount owed has been paid in full, leaving no outstanding balance.
      </span>
    ),
    Unpaid: (
      <span>
        <b>Unpaid</b> indicates that the full amount owed has not yet been settled or paid.
      </span>
    ),
    Partial: (
      <span>
        <b>Partial</b> refers to making a payment for only a portion of the total amount owed has been made.
      </span>
    ),
    Void: (
      <span>
        <b>Void</b> indicates that the invoice has been marked as void.
      </span>
    ),
  };

  const statusColors: any = {
    Reversed: '#862b06',
    Unposted: '#0d648dd1',
    Paid: '#076d07df',
    Unpaid: '#e91227',
    Partial: '#96930e',
    Void: '#37383b',
  };

  const tooltipColor = statusColors[statusText];

  return (
    <Tooltip
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: 'white',
            fontSize: '12px',
            color: tooltipColor,
            padding: '15px',
            borderRadius: SIZES.borderRadiusS,
            boxShadow: '0px 0px 8px 5px rgba(0, 0, 0, 0.1)',
            '& .MuiTooltip-arrow': {
              color: 'white',
            },
          },
        },
      }}
      title={tooltipText[statusText]}
      arrow
    >
      <Chip
        label={statusText}
        sx={{ backgroundColor: backgroundColor, color: textColor, fontWeight: '700', fontSize: '12px', width: '90px' }}
      />
    </Tooltip>
  );
};
