/* eslint-disable react-hooks/exhaustive-deps */

import { FORM_MODE, FieldInput } from 'core/model/interface';
import { useContext, useEffect, useState } from 'react';

import ApartmentOutlinedIcon from '@mui/icons-material/ApartmentOutlined';
import { Box } from '@mui/material';
import CareGoTemplates from './CareGoTemplates';
import CompanyTemplates from './CompanyTemplates';
import { ContainerRow } from 'core/components/containers';
import { CustomTabs } from 'core/components';
import DrawOutlinedIcon from '@mui/icons-material/DrawOutlined';
import FeedIcon from '@mui/icons-material/Feed';
import LabRequestForm from '../DocumentEditor/components/LabRequestForm';
import OtherHousesOutlinedIcon from '@mui/icons-material/OtherHousesOutlined';
import PatientNoteSummaryTemplate from './PatientNoteSummaryTemplate';
import Signatories from './Signatories';
import { TabProps } from 'core/components/CustomTabs';
import { UserContext } from 'core/context/user.context';
import { getPatientLabRequestForm } from 'company/api/lab-request';
import { useNavigate } from 'react-router-dom';

const Templates = () => {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (user && !user.company?.is_active) {
      navigate('/company');
    }
  }, [user]);

  useEffect(() => {
    getPatientLabRequestForm().then((res) => {
      setLabRequestFormFields(res.data.body ?? []);
    });
    // localStorage.setItem('previousPath', window.location.pathname + window.location.search);
  }, []);

  const [selected, setSelected] = useState<number>(0);
  const [forceRefresh, setForceRefresh] = useState<number>(0);
  const [labRequestFormFields, setLabRequestFormFields] = useState<FieldInput[]>([]);

  useEffect(() => localStorage.setItem('previousPath', window.location.pathname + window.location.search), []);

  const tabs: TabProps[] = [
    {
      label: 'Patient Note Templates',
      content: <CompanyTemplates refreshFromCopy={forceRefresh} />,
      icon: <ApartmentOutlinedIcon />,
    },
    {
      label: 'Lab Request Template',
      content: (
        <ContainerRow>
          <LabRequestForm fields={labRequestFormFields} initialMode={FORM_MODE.VIEW} />
        </ContainerRow>
      ),
      icon: <ApartmentOutlinedIcon />,
    },
    {
      label: 'CareGo Templates',
      content: <CareGoTemplates setForceRefresh={setForceRefresh} />,
      icon: <OtherHousesOutlinedIcon />,
    },
    {
      label: 'Patient Note Summary Template',
      content: <PatientNoteSummaryTemplate />,
      icon: <FeedIcon />,
    },
    {
      label: 'Signatories',
      content: <Signatories selectedTab={selected} />,
      icon: <DrawOutlinedIcon />,
    },
  ];

  return (
    <Box>
      <CustomTabs tabs={tabs} selected={selected} setSelected={setSelected} tabsButtonStyles={{ width: '60%' }} />
    </Box>
  );
};

export default Templates;
