import { RequestLogQuery } from 'core/model/interface';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'firewall/loggers';

export const getAll = (query: RequestLogQuery) => {
  return axios.get(`${baseURL}/${path}`, { params: query });
};

export const getOne = (id: number) => {
  return axios.get(`${baseURL}/details/${path}/${id}`);
};

export const block = (id: number, blockByCfId?: boolean) => {
  return axios.patch(`${baseURL}/${path}/block/${id}?block_by_cf_id=${blockByCfId ? 'true' : ''}`);
};

export const exportRequestLogs = (query: RequestLogQuery) => {
  return axios
    .post(
      `${baseURL}/${path}/export`,
      {},
      {
        params: query,
        headers: {
          'Content-Type': 'application/vnd.ms-excel;charset=UTF-8',
        },
      }
    )
    .then((response) => {
      const link = document.createElement('a');
      link.href = response.data;
      link.setAttribute('download', 'Clinics.xlsx');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
};
