import React, { useContext, useEffect, useState } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { CustomIconButton, CustomModal, PrimaryButton } from 'core/components';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { FacilityContext } from 'core/context/facility.context';
import { getSignatoriesAssigned } from 'company/api/signatories';
import { tokens } from 'theme/theme';
import { useSnackbar } from 'notistack';
import CustomLoadingIndicator from 'core/components/CustomLoadingIndicator';

interface ExportedReportSignatoryProps {
  reportId: number;
  signatories: any[];
  onSignatoriesChange: (signatories: any[]) => void;
  loading?: boolean;
}

const ExportedReportSignatories: React.FC<ExportedReportSignatoryProps> = ({
  reportId,
  signatories,
  onSignatoriesChange,
  loading = false,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { facility } = useContext(FacilityContext);
  const { enqueueSnackbar } = useSnackbar();

  const [availableSignatories, setAvailableSignatories] = useState<any[]>([]);
  const [isAddSignatoryModalOpen, setIsAddSignatoryModalOpen] = useState(false);
  const [loadingSignatories, setLoadingSignatories] = useState(false);

  useEffect(() => {
    if (facility?.id) {
      setLoadingSignatories(true);
      getSignatoriesAssigned(facility.id, { length: 100 })
        .then((response) => {
          setAvailableSignatories(response.data.data);
        })
        .catch((error) => {
          console.error('Error fetching signatories:', error);
          enqueueSnackbar('Error fetching signatories', { variant: 'error' });
        })
        .finally(() => {
          setLoadingSignatories(false);
        });
    }
  }, [facility?.id, enqueueSnackbar]);

  const handleRemoveSignatory = (signatoryId: number) => {
    const updatedSignatories = signatories.filter((sig) => sig.signatory_id !== signatoryId);
    onSignatoriesChange(updatedSignatories);
  };

  const handleAddSignatory = (signatory: any) => {
    const newSignatory = {
      exported_report_id: reportId,
      signatory_id: signatory.id,
      facility_id: facility.id,
    };
    onSignatoriesChange([...signatories, newSignatory]);
    setIsAddSignatoryModalOpen(false);
  };

  if (loading || loadingSignatories) {
    return <CustomLoadingIndicator />;
  }

  const availableToAdd = availableSignatories.filter((sig) => !signatories.find((s) => s.signatory_id === sig.id));

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h6">Signatories</Typography>
        <PrimaryButton label="Add Signatory" onClick={() => setIsAddSignatoryModalOpen(true)} />
      </Box>

      {signatories.map((sig) => {
        const signatory = availableSignatories.find((s) => s.id === sig.signatory_id);
        if (!signatory) return null;

        return (
          <Box
            key={sig.signatory_id}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p={2}
            sx={{ backgroundColor: colors.background, mb: 1 }}
          >
            <Box display="flex" flexDirection="column">
              <Typography variant="h5">
                {`${signatory.first_name} ${signatory.last_name}${signatory.title ? `, ${signatory.title}` : ''}`}
              </Typography>
              {signatory.role && (
                <Typography variant="h6" color="textSecondary">
                  {signatory.role}
                </Typography>
              )}
              {signatory.license_number && (
                <Typography variant="h6" color="textSecondary">
                  {`License No: ${signatory.license_number}`}
                </Typography>
              )}
              {signatory.ptr_number && (
                <Typography variant="h6" color="textSecondary">
                  {`PTR No: ${signatory.ptr_number}`}
                </Typography>
              )}
            </Box>
            <Box>
              <Box component="img" src={signatory.signature} alt="signature" sx={{ height: '60px', mr: '20px' }} />

              <CustomIconButton
                icon={<DeleteOutlineIcon />}
                onClick={() => handleRemoveSignatory(sig.signatory_id)}
                tooltip="Remove Signatory"
                color="error"
              />
            </Box>
          </Box>
        );
      })}

      <CustomModal open={isAddSignatoryModalOpen} setOpen={setIsAddSignatoryModalOpen} header="Add Signatory">
        <Box sx={{ maxHeight: '60vh', overflowY: 'auto' }}>
          {availableToAdd.map((signatory) => (
            <Box
              key={signatory.id}
              onClick={() => handleAddSignatory(signatory)}
              sx={{
                p: 2,
                cursor: 'pointer',
                borderRadius: '8px',
                backgroundColor: colors.light_blue_background,
                '&:hover': { backgroundColor: colors.light_blue_background_2 },
                mb: 1,
              }}
              display={'flex'}
              justifyContent={'space-between'}
              alignItems={'center'}
            >
              <Box display="flex" flexDirection="column" alignItems={'start'}>
                <Typography variant="h5">
                  {`${signatory.first_name} ${signatory.last_name}${signatory.title ? `, ${signatory.title}` : ''}`}
                </Typography>
                {signatory.role && (
                  <Typography variant="h6" color="textSecondary">
                    {signatory.role}
                  </Typography>
                )}
                {signatory.license_number && (
                  <Typography variant="h6" color="textSecondary">
                    {`License No: ${signatory.license_number}`}
                  </Typography>
                )}
                {signatory.ptr_number && (
                  <Typography variant="h6" color="textSecondary">
                    {`PTR No: ${signatory.ptr_number}`}
                  </Typography>
                )}
              </Box>

              <Box component="img" src={signatory.signature} alt="signature" sx={{ height: '60px', mr: '20px' }} />
            </Box>
          ))}
          {availableToAdd.length === 0 && (
            <Typography textAlign="center" color="textSecondary">
              No available signatories to add
            </Typography>
          )}
        </Box>
      </CustomModal>
    </Box>
  );
};

export default ExportedReportSignatories;
