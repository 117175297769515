import {
  BackButton,
  ConfirmationDialog,
  CustomAnimatedDrawer,
  CustomForm,
  CustomModal,
  CustomSelectField,
  Dropdown,
  RegularButton,
} from 'core/components';
import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  ChangeEvent,
  Fragment,
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { ContainerColumn, ContainerRow } from 'core/components/containers';
import { FORM_MODE, FieldInput } from 'core/model/interface';
import { PatientModel, patientModelSample } from 'company/entities/modules/ClinicManagement/Patient/PatientModel';
import PatientNoteSignatories, { Signatory } from './components/PatientNoteSignatories';
import { createNote, getNote, updateNote } from 'company/api/patient-notes';
import {
  createNoteSignatory,
  deleteAllNoteSignatories,
  getNoteSignatories,
} from 'company/api/patient-note-signatories';
import {
  createNoteTemplateSignatory,
  deleteAllNoteTemplateSignatories,
  getNoteTemplateSignatories,
} from 'company/api/note-template-signatories';
import { debounce, formatDateTime } from 'core/utils';
import { getNote as getCareGoNote, updateNotes } from 'carego-admin/api/carego-notes-templates';
import { getCareGoTemplate, getTemplate, updateTemplate } from 'company/api/note-templates';
import { getPatient, getPatients } from 'company/api/patient';
import { useNavigate, useParams } from 'react-router-dom';

import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import BackToTopButton from 'core/components/BackToTopButton';
import { BreadcrumbContext } from 'core/context/breadcrumb.context';
import { CompanyAppointmentModel } from 'company/entities/modules/ClinicManagement/Appointment/AppointmentModel';
import CustomDatePicker from 'core/components/CustomDatePicker';
import CustomLoadingIndicator from 'core/components/CustomLoadingIndicator';
import DocumentChart from './components/DocumentChart';
import DocumentForm from './components/DocumentForm';
import DocumentHeader from './components/DocumentHeader';
import DocumentHistory from './DocumentHistory';
import { DropResult } from 'react-beautiful-dnd';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import { FacilityContext } from 'core/context/facility.context';
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import PatientField from '../PatientManagement/components/PatientField';
import { PatientNoteModel } from 'company/model/Entities';
import { PrescriptionModel } from 'company/entities/modules/ClinicManagement/Prescription/PrescriptionModel';
import PrintablePatientNote from './components/PrintablePatientNote';
import React from 'react';
import { SIZES } from 'theme/constants';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import { UserContext } from 'core/context/user.context';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { createNoteHistory } from 'company/api/patient-notes-update-history';
import { getAllPatientAppointments } from 'company/api/appointments';
import { getAppointment } from 'company/api/appointments';
import { getCodeOfPatientNote } from 'company/api/icdcode';
import { getPrescription } from 'company/api/prescriptions';
import { getPrintSettings } from 'company/api/print-settings';
import { isDevMode } from 'core/components/HideOrShowComponent';
import moment from 'moment';
import { tokens } from 'theme/theme';
import { useReactToPrint } from 'react-to-print';
import { useSnackbar } from 'notistack';

export type Components = {
  id: string;
  type: 'rich_text' | 'form' | 'chart';
  title?: string;
  description?: string;
  fields?: any[];
  content?: string; // richtext
  responses?: any; // forms
  chart_type?: string; // charts
};

const fieldSpan = 12 / 2;

type DocumentEditorProps = {
  source: string;
  isPreview?: boolean;
  template_id?: number;
  patient_id?: number;
  patient?: PatientModel;
  prescription_id?: number;
  triggerPrint?: boolean;
  fromPatientRecords?: boolean;
  stickyTop?: string;
  refreshTimeline?: () => void;
  formMode?: FORM_MODE;
  hideClinicHeader?: boolean;
  responses?: any;
};

type PrintSettingsProps = { font: string; page_size: string; page_margin: string; hide_empty_fields?: boolean };

const DocumentEditor: React.FC<DocumentEditorProps> = ({
  source,
  isPreview = false,
  template_id,
  patient_id,
  prescription_id,
  triggerPrint,
  fromPatientRecords,
  stickyTop,
  refreshTimeline,
  formMode,
  hideClinicHeader = false,
  responses,
}) => {
  const { initialMode } = useParams() as any;
  const { id } = useParams() as any;
  const { facility } = useContext(FacilityContext);
  const { user } = useContext(UserContext);
  const isMobilePhone = useMediaQuery('(max-width:768px)');
  const componentRef = useRef<any>(null);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { enqueueSnackbar } = useSnackbar();
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  let navigate = useNavigate();

  const [patient, setPatient] = useState<PatientModel>();
  const [appointment, setAppointment] = useState<any>();

  const [name, setName] = useState('');

  const [components, setComponents] = useState<Components[]>([]);
  const [mode, setMode] = useState<FORM_MODE>(
    isPreview
      ? FORM_MODE.VIEW
      : [FORM_MODE.VIEW, FORM_MODE.EDIT, FORM_MODE.RESPOND].includes(initialMode)
      ? initialMode
      : fromPatientRecords || source === 'patient'
      ? formMode ?? FORM_MODE.VIEW
      : FORM_MODE.VIEW
  );

  useEffect(() => {
    if (formMode) setMode(formMode);
  }, [formMode]);

  const [patientAppointments, setPatientAppointments] = useState<any[]>([]);
  const [selectedAppointment, setSelectedAppointment] = useState<number>();
  const [appointmentDetails, setAppointmentDetails] = useState<CompanyAppointmentModel>();
  const [selectedDateRecorded, setSelectedDateRecorded] = useState<any>();
  const [forceSubmit, setForceSubmit] = useState(false);
  const [note, setNote] = useState<PatientNoteModel>();

  const [openAddConfirmModal, setOpenAddConfirmModal] = useState<boolean>(false);
  const [addSectionMenuOpen, setAddSectionMenuOpen] = useState(false);
  const [addButtonAnchorEl, setAddButtonAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedComponent, setSelectedComponent] = useState(0);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [deletingSectionIndex, setDeletingSectionIndex] = useState<number | null>(null);
  const [isSaving, setIsSaving] = useState(false);
  const [initialValueCode, setInitialValueCodes] = useState<any[]>([]);

  const [displayPrescriptionContainer, setDisplayPrescriptionContainer] = useState<boolean>(false);
  const [openPrescriptionModal, setOpenPrescriptionModal] = useState<boolean>(false);
  const [prescription, setPrescription] = useState<PrescriptionModel>();
  const [updatedBy, setUpdatedBy] = useState<string>('');

  const [lastUpdate, setLastUpdate] = useState<{ editor: any; updated_at: string } | null>(null);
  const [lastUpdated, setLastUpdated] = useState<string>('');

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [backToTopButtonVisible, setBackToTopButtonVisible] = useState(false);
  const [openSettingsForm, setOpenSettingsForm] = useState<boolean>(false);
  const [openUpdateHistory, setOpenUpdateHistory] = useState<boolean>(false);
  const [printSettings, setPrintSettings] = useState<PrintSettingsProps>({
    font: '',
    page_size: 'A4',
    page_margin: '1cm',
  });
  const [hideEmptyFields, setHideEmptyFields] = useState<boolean | undefined>(false);
  const [loadingSavePrintSettings, setLoadingSavePrintSettings] = useState<boolean>(false);
  const [displayFields, setDisplayFields] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [noteSignatories, setNoteSignatories] = useState<any[]>([]);
  const [loadingSignatories, setLoadingSignatories] = useState(false);
  const [lastHistoryUpdate, setLastHistoryUpdate] = useState<Date | null>(null);
  const fetchNoteSignatories = async () => {
    if (!note?.id) return;

    setLoadingSignatories(true);
    try {
      const response =
        source === 'company'
          ? await getNoteTemplateSignatories(facility.id, note.id)
          : await getNoteSignatories(facility.id, note.id);
      setNoteSignatories(response.data.data);
    } catch (error) {
      console.error('Error fetching signatories:', error);
    } finally {
      setLoadingSignatories(false);
    }
  };

  useEffect(() => {
    async function getAllSettings() {
      if (user?.user_group_id === 3) return;
      const res = await getPrintSettings();
      const settings = res.data.data;
      if (!settings) {
        setPrintSettings({ font: '', page_size: 'A4', page_margin: '1cm' });
        return;
      }
      const toSet = {
        font: settings.patient_note_font_size,
        page_size: settings.patient_note_page_size,
        page_margin: settings.patient_note_margin,
      };
      setHideEmptyFields(!!settings.hide_empty_fields);
      setPrintSettings(toSet);
    }

    getAllSettings();
  }, [user]);

  const scrollRef = useRef();

  const idToUse = template_id ?? id;

  const notesNotFromCurrentFacility = useMemo(() => {
    if (note && facility) return facility.id !== note.facility_id;
    return false;
  }, [facility, note]);

  useEffect(() => {
    if (note?.id) {
      fetchNoteSignatories();
    }
  }, [note?.id]);

  useEffect(() => {
    if (notesNotFromCurrentFacility && source === 'patient') {
      setMode(FORM_MODE.VIEW);
    }
  }, [notesNotFromCurrentFacility, source]);

  useEffect(() => {
    if (lastUpdate) {
      setUpdatedBy(lastUpdate.editor);
      setLastUpdated(lastUpdate.updated_at);
    } else if (note) {
      setUpdatedBy(note.last_editor_name ?? '');
      setLastUpdated(note.updated_at);
    }
  }, [lastUpdate, note]);

  useEffect(() => {
    setIsLoading(true);
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facility?.id, template_id, source]);

  useEffect(() => {
    if (!note) return;
    if (!selectedDateRecorded) return;
    setNote({ ...note, date_recorded: selectedDateRecorded });
  }, [selectedDateRecorded]);

  useEffect(() => {
    if (patient && !hideClinicHeader && facility) {
      getAllPatientAppointments(facility.id, patient.patient_id).then((docs) => {
        const filteredAppointments = docs.data.filter(
          (doc: any) => doc.status !== 'cancelled' && doc.status !== 'no-show'
        );

        setPatientAppointments(
          filteredAppointments.map((u: any) => {
            return { key: `${formatDateTime(u.schedule)} - ${u.service_names}`, value: u.id, data: u };
          })
        );
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient?.patient_id, facility]);

  useEffect(() => {
    if (selectedAppointment && patientAppointments.length) {
      const found = patientAppointments.find((appointment) => appointment.value === selectedAppointment);
      if (found) setAppointmentDetails(found.data);

      //auto-save when updating appointments
      if (note && selectedAppointment !== note?.appointment_id) {
        note.appointment_id = selectedAppointment;
        handleSavebuttonClicked(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAppointment, patientAppointments]);

  useEffect(() => {
    if (note && facility) {
      setSelectedDateRecorded(note?.date_recorded ?? '');
      if (note?.appointment_id && (!appointment || appointment.id !== note.appointment_id)) {
        getAppointment(facility.id, note.appointment_id).then((appointmentdoc) => {
          setAppointment(appointmentdoc.data);
        });
      }

      if (note.patient_id && (!patient || patient.id !== note.patient_id)) {
        getPatient(facility.id, note.patient_id).then((doc) => {
          setPatient(doc.data);
        });
      }

      setSelectedAppointment(note.appointment_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [note, facility?.id]);

  useEffect(() => {
    if (note?.patient_id && prescription_id && facility) {
      getPrescription(facility.id, prescription_id).then((doc) => {
        setPrescription(doc.data);
        setDisplayPrescriptionContainer(true);
      });
    } else {
      setPrescription(undefined);
      setDisplayPrescriptionContainer(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [note?.patient_id, prescription_id, facility]);

  useEffect(() => {
    if (triggerPrint) {
      componentRef.current.printNote();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerPrint]);

  const getData = () => {
    if (!idToUse) return;
    if (source === 'patient') {
      if (facility) {
        getPatientNote();
      }
    } else if (source === 'company') {
      setDisplayFields(true);
      if (facility) {
        getTemplate(idToUse)
          .then((template) => {
            setNote(template.data);
            setName(template.data.header);
            setComponents(JSON.parse(template.data.body ? template.data.body : '[]'));
          })
          .finally(() => {
            setIsLoading(false);
            fetchNoteSignatories();
          });
      }
    } else if (source === 'admin') {
      setDisplayFields(true);
      getCareGoNote(idToUse)
        .then((template) => {
          setPatient(patientModelSample);
          setNote(template.data);
          setName(template.data.header);
          setComponents(JSON.parse(template.data.body ? template.data.body : '[]'));
        })
        .finally(() => setIsLoading(false));
    } else {
      setDisplayFields(true);
      getCareGoTemplate(idToUse)
        .then((template) => {
          getPatients(facility.id).then((res) => setPatient(res.data.data[0]));
          setNote(template.data);
          setName(template.data.header);
          setComponents(JSON.parse(template.data.body ? template.data.body : '[]'));
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const getPatientNote = async () => {
    try {
      setDisplayFields(false);

      const noteResponse = await getNote(facility.id, idToUse);
      const noteData = noteResponse.data;

      setNote(noteData);
      setName(noteData.header);

      if (noteData.body) {
        if (noteData.template_id) {
          const templateResponse = await getTemplate(noteData.template_id);
          const templateData = templateResponse.data;

          const noteSignatoriesResponse = await getNoteSignatories(facility.id, noteData.id);
          setNoteSignatories(noteSignatoriesResponse.data.data);

          const from_note_template = JSON.parse(templateData.body.replace(/^s:\d+:"(.+)";$/, '$1'));
          const from_patient_note = JSON.parse(noteData.body.replace(/^s:\d+:"(.+)";$/, '$1'));
          if (responses) from_patient_note[0].responses = responses;
          if (from_patient_note.length > 0 && from_note_template.length > 0 && from_note_template[0]?.fields) {
            from_patient_note[0].fields = from_note_template[0].fields;
            const fields: FieldInput[] = from_patient_note[0].fields;
            const patient_responses: any = from_patient_note[0].responses;
            const template_responses: any = from_note_template[0].responses;

            fields.forEach((field) => {
              if (field.type === 'table') {
                const table_patient_responses = patient_responses[field.field_name!];
                const table_template_responses = template_responses[field.field_name!];

                patient_responses[field.field_name!] = table_template_responses.map((response: any) => {
                  const existingResponse = table_patient_responses
                    ? table_patient_responses.find((item: any) => item.id === response.id)
                    : false;

                  if (existingResponse) {
                    field.columns?.forEach((col) => {
                      if (col.type === 'fixed_text') existingResponse[col.fieldName] = response[col.fieldName];
                    });
                    return existingResponse;
                  }
                  return response;
                });
              }
            });
          }
          setComponents(from_patient_note);
        } else {
          const originalString = noteData.body.replace(/^s:\d+:"(.+)";$/, '$1');
          setComponents(JSON.parse(originalString));
        }

        setIsLoading(false);
      } else {
        setComponents([]);
      }

      await getCodesOfNotes(noteData.id);
    } catch (error) {
      console.error('Error fetching patient note:', error);
      enqueueSnackbar('Error fetching patient note', { variant: 'error' });
    } finally {
      setTimeout(() => {
        setDisplayFields(true);
      }, 500);
    }
  };

  // useEffect(() => {
  //   getPatientNote();
  // }, [facility?.id, template_id]);

  const getCodesOfNotes = (note_id: number) => {
    getCodeOfPatientNote(note_id).then((res) => {
      const formattedData = res.data.data.map((item: { id: number; code: string; description: string }) => ({
        key: item.code + ' - ' + item.description,
        value: item.code,
      }));

      setInitialValueCodes(formattedData ? formattedData : []);
    });
  };

  const handleSelectAppointment = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedAppointment(event.target.value as unknown as number);
  };

  const handleDateRecordedChange = (dateRecorded: string | null) => {
    if (note)
      updateNote(facility.id, note?.id, { ...note, date_recorded: dateRecorded }).then(() => {
        enqueueSnackbar(`Date recorded successfully updated!`, { variant: 'success' });
      });
  };

  const handleCreate = async () => {
    const data = {
      template_id: idToUse,
      source: 'clinic',
      appointment_id: appointment?.id,
    };

    return (
      facility &&
      patient_id &&
      createNote(facility.id, patient_id, data)
        .then((res) => {
          enqueueSnackbar(`Note successfully added!`, { variant: 'success' });
          // setRefreshForNotes && setRefreshForNotes((prev: number) => prev + 1);
          navigate(`/company/patient-notes/respond/${res.data.note.id}`);
        })
        .catch((error) => {
          console.error(error);
          enqueueSnackbar(`Error occured! Unable to add note.`, { variant: 'error' });
        })
    );
  };

  const handleDragEnd = async (result: DropResult) => {
    if (!result.destination) return;

    const newList = Array.from(components);
    const [removed] = newList.splice(result.source.index, 1);
    newList.splice(result.destination.index, 0, removed);
    setComponents(newList);
  };

  const handleAddClick = (event: React.MouseEvent<HTMLButtonElement>, index: number) => {
    setSelectedComponent(index);
    setAddButtonAnchorEl(event.currentTarget);
    setAddSectionMenuOpen(true);
    event.preventDefault();
    event.stopPropagation();
  };

  const handleAddSection = (type: 'rich_text' | 'form' | 'chart') => {
    const newSection: Components = {
      id: String(Date.now()),
      type,
      title: '',
      description: '',
      fields: [
        {
          field_name: 'first_field',
          display_name: 'Field Name',
          type: 'text',
          span: fieldSpan,
        },
      ],
      responses: { first_field: '' },
    };

    setComponents((prevComponents) => {
      const newList = [...prevComponents];
      newList.splice(selectedComponent + 1, 0, newSection);
      return newList;
    });

    setAddButtonAnchorEl(null);
  };

  const handleDeleteSection = (index: number) => {
    setDeletingSectionIndex(index);
    setConfirmationDialogOpen(true);
  };

  const confirmDeleteSection = () => {
    if (deletingSectionIndex !== null) {
      setComponents((prevComponents) => {
        const newList = [...prevComponents];
        newList.splice(deletingSectionIndex, 1);
        return newList;
      });
      setDeletingSectionIndex(null);
      setConfirmationDialogOpen(false);
    }
  };

  const cancelDeleteSection = () => {
    setDeletingSectionIndex(null);
    setConfirmationDialogOpen(false);
  };

  const addContainer = (content: any, index: number) => {
    return content;
    // if (mode !== FORM_MODE.EDIT) return content;
    // return (
    //   <Box>
    //     <Box display="flex" justifyContent="flex-end" marginBottom="-24px">
    //       <RegularButton
    //         label="Delete Section"
    //         size="small"
    //         color="error"
    //         variant="outlined"
    //         startIcon={<CloseOutlinedIcon />}
    //         onClick={() => handleDeleteSection(index)}
    //       />
    //     </Box>
    //     {content}
    //   </Box>
    // );
  };

  const renderAddButton = (index: number) => {
    return (
      <Box
        width="100%"
        height="10px"
        borderRadius="5px"
        sx={{
          position: 'relative',
          backgroundColor: colors.hoverBackground,
          cursor: 'pointer',
          '& .add-section': { visibility: 'visible' },
        }}
      >
        <Box
          className="add-section"
          sx={{
            display: 'inline',
            position: 'absolute',
            left: 'calc(50% - 20px)',
            top: '-17px',
            width: 'auto',
            zIndex: 1,
          }}
        >
          <Tooltip title={'Add Section'} arrow>
            <IconButton
              size="large"
              onClick={(event: React.MouseEvent<HTMLButtonElement>) => handleAddClick(event, index)}
            >
              <AddCircleOutlineOutlinedIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    );
  };

  const renderComponents = (component: Components, index: number) => {
    let content = <></>;
    switch (component.type) {
      case 'form':
        content = addContainer(
          <DocumentForm
            components={components}
            noteId={idToUse}
            component={component}
            mode={mode}
            forceSubmit={forceSubmit}
            autoSave={autoSave}
            appointment={appointmentDetails}
            patient={patient}
            setLastUpdate={setLastUpdate}
          />,
          index
        );
        break;
      case 'chart':
        content = addContainer(<DocumentChart component={component} mode={mode} forceSubmit={forceSubmit} />, index);
        break;
      default:
        content = addContainer(
          <DocumentForm
            components={components}
            noteId={idToUse}
            component={component}
            mode={mode}
            forceSubmit={forceSubmit}
            autoSave={autoSave}
            appointment={appointmentDetails}
            patient={patient}
            setLastUpdate={setLastUpdate}
          />,
          index
        );
    }

    return <Fragment key={component.id}>{content}</Fragment>;
  };

  const isEmpty = components.length === 0;

  const autoSaveSignatures = async (signatoriesToSave: Signatory[]) => {
    if (note?.id) {
      if (source === 'company') {
        await deleteAllNoteTemplateSignatories(note.id);
        if (signatoriesToSave.length > 0) {
          await Promise.all(
            signatoriesToSave.map(
              async (sig, index) =>
                await createNoteTemplateSignatory({
                  note_template_id: note.id,
                  signatory_id: sig.signatory_id || sig.id,
                  facility_id: facility.id,
                  order: index + 1,
                })
            )
          );
          const response = await getNoteTemplateSignatories(facility.id, note.id);
          setNoteSignatories(response.data.data);
        }
      } else {
        await deleteAllNoteSignatories(note.id);

        if (signatoriesToSave.length > 0) {
          await Promise.all(
            signatoriesToSave.map(
              async (sig, index) =>
                await createNoteSignatory({
                  patient_note_id: note.id,
                  signatory_id: sig.signatory_id || sig.id,
                  facility_id: facility.id,
                  order: index + 1,
                })
            )
          );
          const response = await getNoteSignatories(facility.id, note.id);
          setNoteSignatories(response.data.data);
        }
      }
    }
  };

  const handleSavebuttonClicked = (autoSave?: boolean) => {
    setForceSubmit(true);
    setTimeout(async () => {
      const newNote = {
        ...note,
        body: JSON.stringify(components),
        header: name,
      };

      try {
        setIsSaving(true);

        if (source === 'patient') {
          await updateNote(facility.id, idToUse, newNote);

          const currentResponses = components[0]?.responses;
          if (currentResponses) {
            await createNoteHistory(idToUse, {
              responses: JSON.stringify(currentResponses),
            });
          }
        } else if (source === 'company') {
          await updateTemplate(newNote, idToUse);
        } else {
          await updateNotes(idToUse, newNote);
        }

        if (!autoSave) {
          enqueueSnackbar('Note successfully updated!', { variant: 'success' });
          getData();
        }
      } catch (error) {
        if (!autoSave) {
          enqueueSnackbar('Error occurred! Unable to update note.', { variant: 'error' });
        }
      } finally {
        setTimeout(() => setIsSaving(false), 500);
        setForceSubmit(false);
      }
    }, 300);
  };

  const autoSave = debounce(() => {
    handleSavebuttonClicked(true);
  }, 1000);

  useEffect(() => {
    if (!fromPatientRecords) setBreadcrumb([{ label: 'Document Editor' }]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!loadingSavePrintSettings && componentRef.current) {
      componentRef.current.printNote();
    }
  }, [printSettings, loadingSavePrintSettings]);

  // useEffect(() => {
  //   const scrollRefCurrent = scrollRef.current as any;

  //   const handleScroll = (e: any) => {
  //     if (scrollRefCurrent.scrollTop > 100) {
  //       setBackToTopButtonVisible(true);
  //     } else {
  //       setBackToTopButtonVisible(false);
  //     }
  //   };

  //   if (scrollRefCurrent) {
  //     scrollRefCurrent.addEventListener('scroll', handleScroll);
  //   }

  //   return () => {
  //     if (scrollRefCurrent) scrollRefCurrent.removeEventListener('scroll', handleScroll);
  //   };
  // }, [scrollRef]);

  function handleUpdatePrintSettings(data: PrintSettingsProps) {
    setLoadingSavePrintSettings(true);
    setTimeout(() => {
      setPrintSettings(data); // This will trigger useEffect
      setLoadingSavePrintSettings(false);
      setOpenSettingsForm(false);
      setHideEmptyFields(data.hide_empty_fields);
    }, 800);
  }

  if (formMode === FORM_MODE.PRINT && note && patient) {
    return (
      <PrintedNote
        note={note}
        patient={patient}
        components={components}
        ref={componentRef}
        appointment={appointment}
        printSettings={printSettings}
        fromPrintOutside
      />
    );
  }

  return (
    <Box sx={{ position: 'relative' }}>
      {!fromPatientRecords && !isPreview && !responses && <BackButton />}
      {/* <RegularButton onClick={() => setOpenAddConfirmModal(true)} label="Use" /> */}
      {!fromPatientRecords && isPreview && (
        <Box sx={{ marginBottom: '20px' }}>
          {/* <Typography variant="h6">Preview of Note Template</Typography> */}
          <DocumentHeader
            name={name}
            setName={setName}
            mode={FORM_MODE.VIEW}
            note={note}
            autoSave={autoSave}
            patient={patient}
            hideClinicHeader={hideClinicHeader}
          />
          {components.map((component, index) => (
            <Fragment key={component.id}>{renderComponents(component, index)}</Fragment>
          ))}

          {noteSignatories && (
            <PatientNoteSignatories mode={'view'} noteSignatories={noteSignatories} loading={loadingSignatories} />
          )}
        </Box>
      )}
      {!isPreview && !responses && (
        <ContainerRow
          sx={{
            alignItems: 'flex-start',
            // position: 'sticky',
            // top: stickyTop ? stickyTop : fromPatientRecords ? 20 : 0,
            // left: 0,
            background: colors.secondary_background,
            // zIndex: 2,
            paddingBlock: SIZES.paddingS,
          }}
        >
          <ContainerColumn gap={SIZES.paddingS} sx={{ paddingTop: SIZES.paddingS }}>
            {notesNotFromCurrentFacility && source !== 'company' && (
              <Typography variant="body2" fontWeight={600}>
                From: {note?.facility_name}
              </Typography>
            )}
            <Box display="flex" gap="10px">
              {!hideClinicHeader && note?.facility_id === facility?.id && (
                <Dropdown
                  // disabled={isMobilePhone}
                  buttonLabel={(mode === FORM_MODE.VIEW ? 'View' : 'Edit') + ' Mode'}
                  startIcon={mode === FORM_MODE.VIEW ? <VisibilityOutlinedIcon /> : <EditOutlinedIcon />}
                  optionList={[
                    {
                      label: 'Edit',
                      action: () => setMode(FORM_MODE.RESPOND),
                      hidden: source !== 'patient',
                      icon: <EditOutlinedIcon />,
                    },
                    { label: 'View', action: () => setMode(FORM_MODE.VIEW), icon: <VisibilityOutlinedIcon /> },
                    {
                      label: 'Modify Template',
                      action: () => setMode(FORM_MODE.EDIT),
                      hidden: !(source !== 'carego' && source !== 'patient'),
                      icon: <EditOutlinedIcon />,
                    },
                  ]}
                  notFullWidth
                  icon={<ExpandMoreOutlinedIcon />}
                  buttonColor={FORM_MODE.VIEW === mode ? 'warning' : 'success'}
                />
              )}

              {mode !== FORM_MODE.EDIT && !isMobilePhone && (
                <Box sx={{ display: 'flex', width: 'auto', justifyContent: 'center', gap: '15px' }}>
                  <ButtonGroup disableElevation variant="outlined" aria-label="Basic button group">
                    <Button
                      startIcon={<LocalPrintshopOutlinedIcon />}
                      variant="outlined"
                      onClick={() => componentRef.current.printNote()}
                      sx={{
                        textTransform: 'none',
                        padding: '6px 18px',
                        borderRadius: '8px',
                        fontSize: '12px',
                        border: `1px solid ${colors.primary}`,
                        '&:hover': {
                          borderRight: `1px solid ${colors.primary}`,
                        },
                      }}
                    >
                      Print
                    </Button>
                    <Button
                      startIcon={<SettingsIcon sx={{ marginLeft: '8px' }} />}
                      variant="outlined"
                      onClick={() => setOpenSettingsForm(true)}
                      sx={{
                        width: '20%',
                        height: '100%',
                        padding: '4.5px 18px',
                        borderRadius: '8px',
                        textTransform: 'none',
                        border: `1px solid ${colors.primary}`,
                        // borderLeft: 'none',

                        fontSize: '12px',
                      }}
                    ></Button>
                  </ButtonGroup>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <PatientField label={'Page Margin'} value={printSettings.page_margin}></PatientField>
                  </Box>
                  <PatientField label={'Page Size'} value={printSettings.page_size}></PatientField>
                </Box>
              )}

              {mode !== FORM_MODE.VIEW && isMobilePhone && (
                <Box width={200} display="flex" marginBottom={'-10px'}>
                  <RegularButton
                    startIcon={<SaveOutlinedIcon />}
                    label={'Save'}
                    onClick={() => handleSavebuttonClicked()}
                    disabled={isLoading}
                  />
                  <Typography
                    fontStyle="italic"
                    sx={{ visibility: isSaving ? 'visible' : 'hidden', textAlign: 'center', marginLeft: '10px' }}
                  >
                    Saving updates ...
                  </Typography>
                </Box>
              )}
            </Box>
            {source === 'patient' && note && !hideClinicHeader && (
              <Box display="flex" gap="10px">
                {/* {isDevMode() && (
                  <Box width="100%" maxWidth="250px">
                    <CustomSelectField
                      options={patientAppointments}
                      label={'Appointments'}
                      fieldName={'appointments'}
                      value={note.appointment_id}
                      handleChange={handleSelectAppointment}
                    />
                  </Box>
                )} */}

                {/* <Box width="100%" maxWidth="250px">
                    <CustomDatePicker
                      fieldName=""
                      label="Select Date Recorded"
                      value={selectedDateRecorded}
                      setFieldValue={(field: string, value: any) => {
                        const stringValue =
                          value || value !== null ? moment(new Date(value)).format('YYYY-MM-DD') : null;
                        setSelectedDateRecorded(stringValue);
                        handleDateRecordedChange(stringValue);
                      }}
                    />
                  </Box> */}

                {/* <Box width="100%" maxWidth="250px">
                    <ICDCodeSearchDropdown
                      initialValue={initialValueCode}
                      limitTags={1}
                      handleChangeCallback={(data) => {
                        if (data && Array.isArray(data)) {
                          const values = data.map((item) => item.value);
                          return updateIcdCodesOfNote(facility.id, note.id, {
                            codes: values ? JSON.stringify(values) : [],
                          });
                        }
                      }}
                    /> 
                  </Box> */}
              </Box>
            )}

            <Box width="100%" maxWidth="250px">
              <CustomDatePicker
                fieldName=""
                label="Select Date Recorded"
                value={selectedDateRecorded}
                setFieldValue={(field: string, value: any) => {
                  const stringValue = value || value !== null ? moment(new Date(value)).format('YYYY-MM-DD') : null;
                  setSelectedDateRecorded(stringValue);
                  handleDateRecordedChange(stringValue);
                }}
              />
            </Box>
          </ContainerColumn>
          <Box>
            {isDevMode() && user?.user_group_id !== 3 && (
              <RegularButton
                styles={{ width: '200px' }}
                variant="outlined"
                label="View Version History"
                onClick={() => setOpenUpdateHistory(true)}
                startIcon={<HistoryOutlinedIcon />}
              />
            )}
          </Box>

          {mode !== FORM_MODE.VIEW && !isMobilePhone && (
            <Box
              width={150}
              display="flex"
              flexDirection="column"
              justifyContent="flex-end"
              alignItems="flex-end"
              gap="10px"
              marginTop={'10px'}
            >
              <RegularButton
                startIcon={<SaveOutlinedIcon />}
                label={'Save'}
                onClick={() => handleSavebuttonClicked()}
                disabled={isLoading}
              />
              <Typography fontStyle="italic" sx={{ visibility: isSaving ? 'visible' : 'hidden' }}>
                Saving updates ...
              </Typography>
            </Box>
          )}
        </ContainerRow>
      )}

      {isLoading && (
        <Box height="400px" display="flex" justifyContent="center" alignItems="center">
          <CustomLoadingIndicator />
        </Box>
      )}

      <Box
        ref={scrollRef}
        width="100%"
        className="hide-scrollbar"
        visibility={displayFields ? 'visible' : 'hidden'}
        sx={{
          backgroundColor: colors.secondary_background,
          height: 'auto',
          overflowY: 'auto',
          padding: isMobilePhone ? '5px' : '25px',
          paddingTop: 0,
          borderRadius: 2,
          ...(isMobilePhone && {
            '&::-webkit-scrollbar': {
              display: 'none',
            },
            scrollbarWidth: 'none',
            '-ms-overflow-style': 'none',
          }),
        }}
      >
        {/* <RegularButton label={readOnly ? 'Edit' : 'View'} onClick={() => {setReadOnly(!readOnly)}} /> */}

        <BackToTopButton parentRef={scrollRef} visible={backToTopButtonVisible} position={{ bottom: 100, right: 45 }} />
        {!displayFields && (
          <Box
            height="400px"
            sx={{ backgroundColor: 'red' }}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <CustomLoadingIndicator />
          </Box>
        )}
        <Box sx={{ visibility: displayFields ? 'visible' : 'hidden' }}>
          {mode === FORM_MODE.VIEW ? (
            <>
              {note && patient && (
                <Box
                  sx={{
                    width: '100%',
                    maxWidth: '920px',
                    margin: '0 auto 50px',
                  }}
                >
                  <PrintablePatientNote
                    note={note}
                    patient={patient}
                    components={components}
                    appointment={appointment}
                    hideEmptyFields={printSettings.hide_empty_fields}
                    formView
                  />
                  {noteSignatories && (
                    <Box mt={'40px'}>
                      <PatientNoteSignatories
                        mode={'view'}
                        noteSignatories={noteSignatories}
                        loading={loadingSignatories}
                      />
                    </Box>
                  )}
                </Box>
              )}
            </>
          ) : (
            <Box
              sx={{
                width: '100%',
                maxWidth: '920px',
                margin: '0 auto',
                borderRadius: '10px',
                display: 'flex',
                flexDirection: 'column',
                gap: mode !== FORM_MODE.EDIT ? 0 : '10px',
                paddingY: mode !== FORM_MODE.EDIT ? '24px' : undefined,
              }}
            >
              <DocumentHeader
                name={name}
                setName={setName}
                mode={mode}
                note={note}
                autoSave={autoSave}
                patient={patient}
                hideClinicHeader={hideClinicHeader}
              />

              {components.map((component, index) => (
                <Fragment key={component.id}>{renderComponents(component, index)}</Fragment>
              ))}

              {note && (
                <PatientNoteSignatories
                  mode={'edit'}
                  noteSignatories={noteSignatories}
                  onSignatoriesChange={autoSaveSignatures}
                  loading={loadingSignatories}
                  source={source}
                  template_id={note.id}
                  note_id={note.id}
                />
              )}
            </Box>
          )}
        </Box>

        {/* {isEmpty && mode === FORM_MODE.EDIT && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '2rem',
              height: '100%',
            }}
          >
            <RegularButton
              startIcon={<AddIcon />}
              label="Create New Section"
              onClick={(event: React.MouseEvent<HTMLButtonElement>) => handleAddClick(event, 0)}
              variant="outlined"
              color="primary"
            />
          </Box>
        )} */}

        {note && patient && components && (
          <PrintedNote
            note={note}
            patient={patient}
            components={components}
            ref={componentRef}
            appointment={appointment}
            printSettings={printSettings}
            noteSignatories={noteSignatories}
          />
        )}
        <Menu
          id="add-section-menu"
          anchorEl={addButtonAnchorEl}
          keepMounted
          open={Boolean(addButtonAnchorEl) && addSectionMenuOpen}
          onClose={() => {
            setAddSectionMenuOpen(false);
            setAddButtonAnchorEl(null);
          }}
          autoFocus={false}
        >
          <MenuItem onClick={() => handleAddSection('form')}>Add Form</MenuItem>
          <MenuItem onClick={() => handleAddSection('chart')}>Add Chart</MenuItem>
        </Menu>

        {formMode !== FORM_MODE.EDIT && !responses && (
          <Box
            display="grid"
            gridTemplateColumns="1fr 1fr "
            gap="10px"
            sx={{
              width: '100%',
              maxWidth: '920px',
              margin: '0 auto',
            }}
          >
            <Divider sx={{ gridColumn: 'span 2' }} />
            <PatientField fieldFontSize="11px" label="Created By:" value={note?.creator_name} />
            <PatientField fieldFontSize="11px" label="Date Created:" value={formatDateTime(note?.created_at)} />
            <PatientField fieldFontSize="11px" label="Last Updated By:" value={updatedBy} />
            <PatientField fieldFontSize="11px" label="Last Updated:" value={formatDateTime(lastUpdated)} />
          </Box>
        )}
      </Box>

      <ConfirmationDialog
        open={confirmationDialogOpen}
        onClose={cancelDeleteSection}
        onConfirm={confirmDeleteSection}
        // title="Confirm Deletion"
        content="Are you sure you want to delete this section?"
        setOpen={setConfirmationDialogOpen}
      />
      <ConfirmationDialog
        content="Are you sure you want to use this template?"
        open={openAddConfirmModal}
        setOpen={setOpenAddConfirmModal}
        onConfirm={handleCreate}
        confirmButtonColor
      />

      <CustomModal setOpen={setOpenSettingsForm} open={openSettingsForm} header={'Customize Print'}>
        <CustomForm
          submitButtonText={'Print'}
          fields={[
            {
              field_name: 'page_margin',
              type: 'select',
              display_name: 'Page Margin',
              options: [
                { key: '1cm', value: '1cm' },
                { key: '2cm', value: '2cm' },
                { key: '3cm', value: '3cm' },
                { key: '4cm', value: '4cm' },
              ],
            },

            {
              field_name: 'page_size',
              type: 'select',
              display_name: 'Page Size',
              options: [
                { key: 'A0', value: 'A0' }, // 841 x 1189 mm
                { key: 'A1', value: 'A1' }, // 594 x 841 mm
                { key: 'A2', value: 'A2' }, // 420 x 594 mm
                { key: 'A3', value: 'A3' }, // 297 x 420 mm
                { key: 'A4', value: 'A4' }, // 210 x 297 mm (common for documents)
                { key: 'A5', value: 'A5' }, // 148 x 210 mm
                { key: 'A6', value: 'A6' }, // 105 x 148 mm
                { key: 'A7', value: 'A7' }, // 74 x 105 mm
                { key: 'A8', value: 'A8' }, // 52 x 74 mm
                { key: 'A9', value: 'A9' }, // 37 x 52 mm
                { key: 'Letter', value: 'Letter' }, // 8.5 x 11 inches (commonly used)
                { key: 'Legal', value: 'Legal' }, // 8.5 x 14 inches (for legal documents)
                { key: 'Executive', value: 'Executive' }, // 7.25 x 10.5 inches (often for memos)
                { key: 'Folio', value: 'Folio' }, // 8.5 x 13 inches (less common but still used)
              ],
            },

            {
              field_name: 'hide_empty_fields',
              type: 'checkbox',
              display_name: 'Hide Empty Fields',
            },
          ]}
          onSubmit={(data) => {
            handleUpdatePrintSettings(data);
          }}
          initialValues={{ ...printSettings, hide_empty_fields: hideEmptyFields }}
          loading={loadingSavePrintSettings}
        ></CustomForm>
        {/* <PageMarginDropDown /> */}
      </CustomModal>

      <CustomAnimatedDrawer
        open={openUpdateHistory}
        setOpen={setOpenUpdateHistory}
        header="Version History"
        width={1200}
      >
        <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
          {note && user?.user_group_id !== 3 && (
            <>
              <Box sx={{ flex: '1' }}>
                {/* <DocumentHistory
                  note_id={note.id}
                  // onRestore={(responses: any) => {
                  //   const restoredComponents = [...components];
                  //   if (restoredComponents[0]) {
                  //     restoredComponents[0].responses = responses;
                  //   }
                  //   setComponents(restoredComponents);
                  //   handleSavebuttonClicked();
                  //   setOpenUpdateHistory(false);
                  // }}
                /> */}
              </Box>
            </>
          )}
        </Box>
      </CustomAnimatedDrawer>
    </Box>
  );
};

type Props = {
  note: PatientNoteModel;
  components: Components[];
  patient: PatientModel;
  appointment: CompanyAppointmentModel;
  printSettings?: { font: string; page_size: string; page_margin: string; hide_empty_fields?: boolean };
  fromPrintOutside?: boolean;
  noteSignatories?: any[];
};

export const PrintedNote = forwardRef(
  (
    {
      note,
      patient,
      appointment,
      components,
      fromPrintOutside,
      noteSignatories,
      printSettings = { font: '', page_size: 'A4', page_margin: '1cm' },
    }: Props,
    ref
  ) => {
    const componentRef = useRef<HTMLDivElement | null>(null);
    const [showPrint, setShowPrint] = useState(false);
    const printNote = () => {
      setShowPrint(true);
      document.title = `${note?.header} - ${patient?.full_name}`;
      setTimeout(() => handlePrint(), 1000);
      setTimeout(() => {
        setShowPrint(false);
        document.title = `CareGo Health Suite`;
      }, 3000);
    };

    const getFontSizes = (size: string) => {
      switch (size) {
        case 'xl':
          return { header: '15px', body: '14px' };
        case 'l':
          return { header: '14px', body: '13px' };
        case 's':
          return { header: '12px', body: '11px' };
        case 'm':
        default:
          return { header: '13px', body: '12px' };
      }
    };

    const fontSizes = getFontSizes(printSettings.font);

    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
      pageStyle: `
      @page {
        size: ${printSettings.page_size};
        margin: ${printSettings.page_margin};
      }
      body {
        margin: 0;
      }
    `,
    });

    useImperativeHandle(ref, () => ({ printNote }));

    return (
      <Box display={fromPrintOutside ? undefined : 'none'}>
        {(showPrint || fromPrintOutside) && (
          <PrintablePatientNote
            note={note}
            patient={patient}
            components={components}
            ref={componentRef}
            appointment={appointment}
            hideEmptyFields={printSettings.hide_empty_fields}
            // compact={fromPrintOutside}
            compact
            noteSignatories={noteSignatories}
            fontSizes={fontSizes}
          />
        )}
      </Box>
    );
  }
);

export default DocumentEditor;
