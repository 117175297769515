import { ApiQuery } from 'core/model/interface';
import { Services } from 'company/model/Entities';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'service-category';


export const getAllServiceCategories = (facility_id: number, query: ApiQuery) => {
    return axios.get(`${baseURL}/${path}/${facility_id}`, { params: query });
};

export const getServiceCategory = (facility_id: number, id: number) => {
    return axios.get(`${baseURL}/${path}/${facility_id}/${id}`);
}

export const getServicesNotAddedWithCategory = (facility_id: number, category_id: number, query: ApiQuery) => {
    return axios.get(`${baseURL}/${path}/${facility_id}/not-added-service/${category_id}`, {params: query});
}

export const addServicesToCategory = (facility_id: number, category_id: number, data: any) => {
    return axios.post(`${baseURL}/${path}/${facility_id}/add-services/${category_id}`, data)
}

export const createServiceCategory = (facility_id: number, data: any) => {
    return axios.post(`${baseURL}/${path}/${facility_id}`, data);
}

export const updateServiceCategory = (facility_id: number, id: number, data: any) => {
    return axios.patch(`${baseURL}/${path}/${facility_id}/${id}`, data)
}

export const deleteServiceCategory = (facility_id: number, id: number) => {
    return axios.delete(`${baseURL}/${path}/${facility_id}/${id}`)
}


export const removeServiceFromCategory = (facility_id: number, category_id: number, data: any) => {
    return axios.patch(`${baseURL}/${path}/${facility_id}/remove-service/${category_id}`, data)
}

