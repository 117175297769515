import { CompanySignatory, PatientNoteSignatory } from 'company/model/Entities';
import { ApiQuery } from 'core/model/interface';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'patient-note-signatories';

export const getNoteSignatory = (id: number) => {
  return axios.get(`${baseURL}/${path}/${id}`);
};

export const getAllNoteSignatories = (query?: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/`, {
    params: query,
  });
};

export const getNoteSignatories = (facility_id: number, patient_note_id: number) => {
  return axios.get(`${baseURL}/${path}/note/${facility_id}/${patient_note_id}`);
};

export const createNoteSignatory = (data: PatientNoteSignatory) => {
  return axios.post(`${baseURL}/${path}`, data);
};

export const updateNoteSignatory = (id: number, data: PatientNoteSignatory) => {
  return axios.put(`${baseURL}/${path}/${id}`, data);
};

export const deleteNoteSignatory = (id: number) => {
  return axios.delete(`${baseURL}/${path}/${id}`);
};

export const deleteAllNoteSignatories = (patient_note_id: number) => {
  return axios.delete(`${baseURL}/${path}/delete-all-signatories-from-note/${patient_note_id}`);
};

export const applyTemplateSignatoriesToAllNotes = (
  facility_id: number,
  template_id: number,
  signatories: PatientNoteSignatory[]
) => {
  return axios.post(`${baseURL}/${path}/apply-template-signatories`, {
    facility_id,
    template_id,
    signatories,
  });
};
