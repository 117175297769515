import { PatientNoteModel } from 'company/model/Entities';

export const medical_history = [
  'Tuberculosis',
  'Diabetes Mellitus',
  'Cancer or Tumor',
  'Asthma',
  'Thyroid Disorders',
  'Gastritis /Ulcer',
  'Pnuemonia',
  'Eye and Ear Disorders',
  'Kidney & Liver Disease',
  'Hypertension',
  'Nose and Throat Disorders',
  'Seizure Disorders',
  'Heart Disease',
  'Skin Disease',
];
export const medical_history2 = [
  'Present Symptoms',
  'Other Past Medical History',
  'Family Medical History',
  'Operations and Accidents',
  'Allergies',
  'OB & Menstrual History',
  'OB Score',
  'Smoking History',
  'If "Smoker" is selected, indicate duration',
  'Alcohol Consumption',
  'Other Present Symptoms',
];
export const vital_signs = [
  "Height (ft' in)",
  'Height (m)',
  'Weight (lbs)',
  'BMI',
  'BMI Class',
  'Blood Pressure (mmHg)',
  'Pulse Rate (per min)',
  'Respiratory Rate (per min)',
  'Temperature (°C)',
  'Blood Pressure Class',
  'Weight (kg)',
];
export const eye_test = ['Eye Test', 'OD', 'OS', 'OU', 'Color Vision (Ishihara)'];
export const dental_exam = [
  'For Extraction',
  'For Restoration',
  'For Oral Prophylaxis',
  'Missing',
  'Dentures',
  'Others',
  'Dental Caries',
  'Severe Tooth Decay',
  'Non Restorable Tooth',
];
export const physical_exam = [
  'Head/Scalp',
  'Eyes',
  'Ears',
  'Nose/Sinuses',
  'Mouth/Throat',
  'Neck/Thyroid',
  'Chest/Breast',
  'Lungs',
  'Heart',
  'Abdomen',
  'Muscoloskeletal',
  'Anus',
  'Genitals',
  'Extremities',
  'Skin',
];

export const hematology = [
  'Hemoglobin',
  'Hematocrit',
  'RBC Count',
  'WBC Count',
  'Platelet Count',
  // 'Segmenters',
  // 'Lymphocytes',
  // 'Monocytes',
  // 'Eosinophils',
  // 'Basophils',
];

export const clinical_chemistry = [
  'Blood Uric Acid (BUA)',
  'Creatinine',
  'SGOT / AST',
  'SGPT / ALT',
  'Cholesterol',
  'Triglycerides',
  'Low Density Lipoprotein (LDL)',
  'Fasting Blood Sugar (FBS)',
];

export const differential_count = ['Segmenters', 'Lymphocytes', 'Monocytes', 'Eosinophils', 'Basophils'];

export const hematology_normal_values = [
  '(M) 133 - 180 g|L\n(F) 123 - 157 g|L',
  '(M) 0.40 - 0.54%\n(F) 0.37 - 0.47%',
  '3.8 - 5.8 x 10^12/L',
  '5 - 10 x 10^12/L',
  '150 - 450 / cumm',
  // '0.35 - 0.65',
  // '0.25 - 0.45',
  // '0.0 - 0.10',
  // '0.0 - 0.03',
  // '0.0 - 0.01',
];

export const differential_count_normal_values = [
  '0.35 - 0.65',
  '0.25 - 0.45',
  '0.0 - 0.08',
  '0.0 - 0.03',
  '0.0 - 0.01',
];

export const urinalysis = [
  'Color',
  'Transparency',
  'pH',
  'Specific Gravity',
  'Protein',
  'Sugar',
  'WBC',
  'RBC',
  'Epithelial Cells',
  'Mucus Threads',
  'Bacteria',
  'Crystals',
];

export const fecalysis = ['Color', 'Consistency', 'WBC', 'RBC', 'Parasites', 'Parasite Seen'];
export const xray = ['RESULT', 'IMPRESSION', 'X-RAY VIEW', 'SIGNIFICANT FINDINGS', 'RECOMMENDATION'];
export const other_tests = [
  'Drug Test',
  'Blood Chemistry',
  'ECG Findings',
  'Audiometry Findings',
  'Other ECG Findings',
];

export const medical_history_index = [1, 6, 11, 2, 7, 12, 3, 8, 13, 4, 9, 14, 5, 10, 15];
export const medical_history_mapping = medical_history.reduce((acc: any, condition) => {
  acc[condition] = {
    template_name: 'Medical History',
    source_type: 'table',
    table_name: '',
    table_column_name: 'Past Medical History',
    table_column_name_answer: 'Yes or No',
    more_info: '',
  };
  return acc;
}, {});

export const medical_history_mapping2 = medical_history2.reduce((acc: any, condition) => {
  acc[condition] = {
    template_name: 'Medical History',
  };
  return acc;
}, {});

export const vital_signs_mapping = vital_signs.reduce((acc: any, condition) => {
  acc[condition] = {
    template_name: 'Vitals Signs',
  };
  return acc;
}, {});

export const eye_test_mapping = eye_test.reduce((acc: any, condition) => {
  acc[condition] = {
    template_name: 'Eye Test',
  };
  return acc;
}, {});

export const dental_exam_mapping = dental_exam.reduce((acc: any, condition) => {
  acc[condition] = {
    template_name: 'Dental Exam',
  };
  return acc;
}, {});

export const fecalysis_mapping = fecalysis.reduce((acc: any, condition) => {
  acc['F_' + condition] = {
    template_name: 'Fecalysis',
  };
  acc['Remarks'] = {
    template_name: 'Fecalysis',
  };
  return acc;
}, {});

export const physical_exam_mapping = physical_exam.reduce((acc: any, condition) => {
  acc[condition] = {
    template_name: 'Physical Exam',
    source_type: 'table',
    table_name: '',
    table_column_name: 'Physical Exam',
    table_column_name_answer: 'Findings',
  };
  return acc;
}, {});

export const hematology_mapping = hematology.reduce(
  (acc: any, condition) => {
    acc[condition] = {
      template_name: 'Hematology',
      source_type: 'table',
      table_name: '',
      table_column_name: 'Test',
      table_column_name_answer: 'Result',
    };
    return acc;
  },
  {
    'Blood Typing': {
      template_name: 'Hematology',
    },
    'RH Typing': {
      template_name: 'Hematology',
    },
  }
);

export const clinical_chemistry_mapping = clinical_chemistry.reduce(
  (acc: any, condition) => {
    acc[condition] = {
      template_name: 'Clinical Chemistry',
      source_type: 'table',
      table_name: '',
      table_column_name: 'Test',
      table_column_name_answer: 'Level',
    };
    return acc;
  },
  {
    CC_Remarks: {
      template_name: 'Clinical Chemistry',
    },
    'CC_Remarks (Significant Findings based on Result)': {
      template_name: 'Clinical Chemistry',
    },
  }
);

export const differential_count_mapping = differential_count.reduce((acc: any, condition) => {
  acc['DC_' + condition] = {
    template_name: 'Hematology',
    source_type: 'table',
    table_name: 'Differential Count',
    table_column_name: 'Test',
    table_column_name_answer: 'Result',
  };
  return acc;
}, {});

export const urinalysis_mapping = urinalysis.reduce(
  (acc: any, condition) => {
    acc['U_' + condition] = {
      template_name: 'Urinalysis',
    };
    return acc;
  },
  {
    'Remarks (Significant Findings based on Result)': {
      template_name: 'Urinalysis',
    },
    'With Menstruation': {
      template_name: 'Urinalysis',
    },
  }
);

export const other_tests_mapping = other_tests.reduce((acc: any, condition) => {
  acc[condition] = {
    template_name: 'Other Tests Findings',
  };
  return acc;
}, {});

export const xray_mapping = xray.reduce((acc: any, condition) => {
  acc[condition] = {
    template_name: 'Radiologic Findings',
  };
  return acc;
}, {});

export const summary_mapping = {
  ...medical_history_mapping,
  ...medical_history_mapping2,
  ...vital_signs_mapping,
  ...eye_test_mapping,
  ...dental_exam_mapping,
  ...physical_exam_mapping,
  ...hematology_mapping,
  ...differential_count_mapping,
  ...urinalysis_mapping,
  ...fecalysis_mapping,
  ...other_tests_mapping,
  ...xray_mapping,
  ...clinical_chemistry_mapping,

  'Hepatitis B Result': {
    template_name: 'Hepatitis B Screening (HBsAg)',
  },
};

export const getAnswer = (
  field: string,
  notes: PatientNoteModel[],
  defaultValue?: string,
  occurence?: number,
  prefix?: string,
  columns?: string[]
): string => {
  const defValue = defaultValue ?? '-';
  const defOccurence = occurence ?? 1;
  const source_mapping = summary_mapping as any;
  const fieldKey = prefix ? prefix + field : field;
  const mapping = source_mapping[fieldKey];
  if (mapping) {
    const note = notes.find(
      (note) =>
        note.header.toLowerCase().includes(mapping.template_name.toLowerCase()) ||
        note.template_id === mapping.template_id
    );

    if (note) {
      const body = JSON.parse(note.body)[0];
      if (mapping.source_type === 'table') {
        const tableField = body.fields.find(
          (field: any) =>
            (mapping.table_name && field.display_name === mapping.table_name) ||
            (!mapping.table_name && field.type === 'table')
        );

        if (tableField) {
          const questionColumn = tableField.columns.find(
            (column: any) => column.displayName === mapping.table_column_name
          );

          const rowResponses = body.responses[tableField.field_name];
          if (rowResponses) {
            const response = rowResponses.find((response: any) => {
              return response[questionColumn.fieldName] === field;
            });

            if (response) {
              if (columns && columns.length > 0) {
                // Format columns into a string for easy display
                return columns
                  .map((columnName) => {
                    const column = tableField.columns.find((col: any) => col.displayName === columnName);
                    return response[column?.fieldName] ? `${columnName}: ${response[column?.fieldName]}` : defValue;
                  })
                  .join(', ');
              } else {
                // Default behavior: return only the main answer column
                const answerColumn = tableField.columns.find(
                  (column: any) => column.displayName === mapping.table_column_name_answer
                );
                return response[answerColumn?.fieldName] || defValue;
              }
            }
          }
        }
      } else {
        let count = 0;
        const formField = body.fields.find((item: any) => {
          if (item.display_name?.trim() === field?.trim() && !item.section_header) {
            count++;
            return defOccurence === count;
          }
          return false;
        });

        return body.responses[formField?.field_name] || defValue;
      }
    }
  }

  return defValue;
};

export enum APE_FIELD {
  BMI,
  BP_CLASS,
  SMOKING_HISTORY,
  VISUAL_ACUITY,
  ISHIHARA,
  URINALYSIS_SUGAR,
  URINALYSIS_PROTEIN_RBC,
  URINALYSIS_PROTEIN,
  URINALYSIS_WBC,
  URINALYSIS_RBC,
  CBC,
  PE_EARS,
  PE_BREAST,
  PE_NECK,
  PE_ANUS,
  PE_HEART,
  PE_LUNGS,
  AUDIOMETRY,
  CHEST_XRAY,
  PRESENT_SYMPTOMS,
  ECG,
  CC_BUA,
  CC_CREATININE,
  CC_SGOT,
  CC_SGPT,
  CC_CHOLESTEROL,
  CC_TRIGLYCERIDE,
  CC_LDL,
  CC_FBS,
  OTHERS,
}

type AutomatedFindingsField = {
  field: APE_FIELD;
  result: string;
  recommendation?: string;
  extra?: any;
};

const ecg_results_cardiology_evaluation = [
  'Ventricular Hypertrophy',
  'Ischemia',
  'Infarct',
  'Atrial enlargement',
  'Atrial fibrillation',
  'Atrial rhythm',
];

const ecg_results_yearly_monitoring = [
  'Sinus Arrythmia',
  'Complete right bundle branch block',
  'Incomplete left bundle branch block',
  'Poor R wave',
  'Normal ST/T wave changes',
  'Left axis deviation',
  'Sinus rhythm',
  'Tachycardia',
  'Bradycardia',
  'Incomplete right bundle branch block',
];

export const getFindingsAndRecommendations = (fields: AutomatedFindingsField[], currentFindings: string): string[] => {
  const concatString = (currentValue: string, newValue: string, currentValue2: string, newValue2: string) => {
    if (!currentValue) return [newValue, newValue2];
    if (currentValue.toLowerCase().includes(newValue.toLocaleLowerCase())) return [currentValue, currentValue2];
    if (currentFindings.toLowerCase().includes(newValue.toLowerCase().trim())) return [currentValue, currentValue2];

    const value1 = currentValue + '\n' + newValue;
    const value2 = currentValue2 + '\n' + newValue2;
    return [value1, value2];
  };

  const defaultValue = ['', ''];
  if (!fields.length) return defaultValue;

  return fields.reduce((result: any, field) => {
    if (!field.result) return result;
    if (currentFindings.includes(field.result)) return result;

    let findings = '';
    let recommendations = '';
    switch (field.field) {
      case APE_FIELD.BMI:
        if (field.result !== 'Normal') {
          findings = field.result;
          recommendations = getRecommendations(field);
        }
        break;

      case APE_FIELD.BP_CLASS:
        if (field.result.includes('HPN') || field.result === 'Hypotension') {
          findings = field.result;
          recommendations = getRecommendations(field);
        }
        break;

      case APE_FIELD.VISUAL_ACUITY:
        if (field.result) {
          findings = field.result;
          recommendations = field.extra.with_corrective_lenses
            ? 'With corrective lenses'
            : 'Suggest ophthalmology consult';
        }
        break;

      case APE_FIELD.ISHIHARA:
        if (field.result === 'Inadequate') {
          findings = 'Defective color perception';
          recommendations = 'Suggest ophthalmology consult';
        }
        break;

      case APE_FIELD.URINALYSIS_SUGAR:
        if (field.result !== 'Negative') {
          findings = 'Tc Diabetes Mellitus';
          recommendations = 'Suggest to undergo FBS and HBa1C';
        }
        break;

      case APE_FIELD.URINALYSIS_PROTEIN_RBC:
        if (field.result) {
          findings = field.result;
          recommendations = 'Suggest to undergo BUN, creatinine, and nephorology consult';
        }
        break;

      case APE_FIELD.URINALYSIS_PROTEIN:
        if (field.result !== 'Negative' && !result[0].includes('Tc CKD')) {
          findings = 'Proteinuria';
          recommendations = getRecommendations(field);
        }
        break;

      case APE_FIELD.URINALYSIS_WBC:
        if (field.result) {
          const match = field.result.trim().match(/(\d+)\s*-\s*(\d+)/);
          if (match) {
            const num1 = parseInt(match[1]);
            const num2 = parseInt(match[2]);
            if (num1 >= 12 || num2 >= 12) {
              findings = 'Pyuria';
              recommendations = 'Suggest increase oral fluid intake and repeat urinalysis';
            }
          }
        }
        break;

      case APE_FIELD.URINALYSIS_RBC:
        if (field.result && !result[0].includes('Tc CKD')) {
          const match = field.result.trim().match(/(\d+)\s*-\s*(\d+)/);
          if (match) {
            const num1 = parseInt(match[1]);
            if (num1 > 0) {
              if (field.extra.sex === 'Male') {
                findings = 'Hematuria';
                recommendations = 'Suggest KUB Ultrasound';
              } else {
                if (num1 >= 5) {
                  findings = 'Hematuria';
                  recommendations = 'Suggest increase oral fluid intake';
                  if (field.extra.with_menstruation)
                    recommendations = recommendations + ' and repeat urinalysis after menstruation';
                }
              }
            }
          }
        }
        break;

      case APE_FIELD.CBC:
        if (field.result) {
          findings = field.result;
          recommendations = 'Suggest iron supplementation and repeat CBC after a month';
        }
        break;

      case APE_FIELD.PE_EARS:
        if (field.result) {
          if (field.result.toLowerCase().includes('perforated tm')) {
            findings = 'Perforated TM';
          } else if (field.result.toLowerCase().includes('impacted cerumen')) {
            findings = 'Impacted Cerumen';
          } else if (field.result.toLowerCase().includes('retained cerumen')) {
            findings = 'Retained Cerumen';
          } else if (field.result.toLowerCase().includes('mass')) {
            findings = 'Mass';
          }
          recommendations = getRecommendations(field, findings);
        }
        break;

      case APE_FIELD.PE_BREAST:
        if (
          field.result &&
          (field.result.toLowerCase().includes('breast mass') || field.result.toLowerCase().includes('breast cyst'))
        ) {
          findings = 'Breast mass';
          recommendations = getRecommendations(field);
        }
        break;

      case APE_FIELD.PE_NECK:
        if (field.result && field.result.toLowerCase().includes('enlarged of anterior neck')) {
          findings = 'Tc Goiter';
          recommendations = 'Suggest ultrasound of anterior the neck, FT3, FT4, TSH and EENT consult';
        }
        break;

      case APE_FIELD.PE_ANUS:
        if (field.result) {
          if (field.result.toLowerCase().includes('anal skin tag')) {
            findings = 'Anal skin tag';
          } else if (field.result.toLowerCase().includes('hemorrhoid')) {
            findings = field.result;
          } else if (field.result.toLowerCase().includes('mass')) {
            findings = 'Mass';
          }
          recommendations = getRecommendations(field, findings);
        }
        break;

      case APE_FIELD.PE_LUNGS:
        if (field.result) {
          if (
            field.result.toLowerCase().includes('shortness of breath') ||
            field.result.toLowerCase().includes('decreased breath sound') ||
            field.result.toLowerCase().includes('wheezing')
          ) {
            findings = 'Upper Respiratory Tract infection';
          } else if (field.result.toLowerCase().includes('mass')) {
            findings = 'Mass';
          }
          recommendations = getRecommendations(field, findings);
        }
        break;

      case APE_FIELD.AUDIOMETRY:
        if (field.result && field.result.toLowerCase().includes('hearing loss')) {
          findings = 'Hearing loss';
          recommendations = 'Suggest EENT consult';
        }
        break;

      case APE_FIELD.CHEST_XRAY:
        if (field.result && field.result !== 'Normal' && field.result !== 'No X-ray') {
          findings = field.result;
          recommendations =
            field.recommendation && field.recommendation !== 'No recommendations' ? field.recommendation : '';
        }
        break;

      case APE_FIELD.PRESENT_SYMPTOMS:
        if (
          field.result &&
          (field.result.toLowerCase().includes('cough') || field.result.toLowerCase().includes('colds'))
        ) {
          findings = 'Upper Respiratory Tract infection';
          recommendations = 'Suggest medical consult';
        }
        break;

      case APE_FIELD.ECG:
        if (ecg_results_cardiology_evaluation.includes(field.result.trim())) {
          findings = 'With ECG findings';
          recommendations = 'Suggest cardiology evaluation';
        } else if (ecg_results_yearly_monitoring.includes(field.result.trim())) {
          findings = 'With ECG findings';
          recommendations = 'Suggest yearly monitoring';
        }
        break;

      case APE_FIELD.CC_BUA:
        if (field.result === 'High') {
          findings = 'Hyperuricemia';
          recommendations = 'Suggest low purine diet and repeat BUN after 3 months';
        }
        break;

      case APE_FIELD.CC_CREATININE:
        if (field.result === 'High') {
          findings = 'Tc kidney pathology';
          recommendations = 'Suggest low protein diet and repeat BUN and Creatinine after 2 weeks';
        }
        break;

      case APE_FIELD.CC_SGOT:
      case APE_FIELD.CC_SGPT:
        if (field.result === 'High') {
          findings = 'Tc fatty liver';
          recommendations = 'Suggest whole abdomen ultrasound';
        }
        break;

      case APE_FIELD.CC_CHOLESTEROL:
      case APE_FIELD.CC_TRIGLYCERIDE:
      case APE_FIELD.CC_LDL:
        if (field.result === 'High') {
          findings = 'Dyslipidemia';
          recommendations = 'Suggest diet modification and repeat blood chemistry after 3 months';
        }
        break;

      case APE_FIELD.CC_FBS:
        if (field.result === 'High') {
          findings = 'Tc Diabetes Mellitus';
          recommendations = 'Suggest to undergo HBa1cf';
        }
        break;

      case APE_FIELD.SMOKING_HISTORY:
        if (field.result === 'Smoker') {
          findings = field.result;
          recommendations = 'Suggest lifestyle modification';
        }
        break;
    }

    return findings ? concatString(result[0], findings, result[1], recommendations) : result;
  }, defaultValue);
};

export const getRecommendations = (field: AutomatedFindingsField, findings?: string) => {
  switch (field.field) {
    case APE_FIELD.BMI:
      if (field.result.includes('Obese')) return 'Suggest diet and weight management';
      if (field.result === 'Overweight') return 'Suggest diet modification';
      if (field.result.includes('Underweight')) return 'Suggest increase caloric intake';
      return '';

    case APE_FIELD.BP_CLASS:
      if (field.result.includes('HPN')) {
        if (field.result === 'Pre-HPN') return 'Suggest BP Monitoring';
        else return 'Suggest BP Monitoring and cardiology evaluation';
      }
      if (field.result === 'Hypotension') return 'Suggest regular exercise and BP Monitoring';
      return '';

    case APE_FIELD.URINALYSIS_PROTEIN:
      if (field.result === 'Trace') return 'Suggest increase oral fluid intake and repeat Urinalysis';
      if (field.result) return 'Suggest to undergo BUN and creatinine';
      return '';

    case APE_FIELD.PE_EARS:
      if (findings === 'Perforated TM' || findings === 'Impacted Cerumen') return 'Suggest EENT consult';
      if (findings === 'Retained Cerumen') return 'Suggest proper hygiene';
      if (findings === 'Mass') return 'Suggest surgery consult';
      return '';

    case APE_FIELD.PE_BREAST:
      if (field.result.toLowerCase().includes('breast mass')) return 'Suggest breast ultrasound';
      if (field.result.toLowerCase().includes('breast cyst')) return 'Suggest Mammogram';
      return '';

    case APE_FIELD.PE_ANUS:
      if (findings === 'Anal skin tag') return 'Suggest high fiber diet';
      if (field.result.toLowerCase().includes('hemorrhoid') || findings === 'Mass') return 'Suggest surgery consult';
      return '';

    case APE_FIELD.PE_LUNGS:
      if (findings === 'Mass') return 'Suggest surgery consult';
      if (findings) return 'Suggest medical consult';
      return '';

    default:
      return '';
  }
};
