import * as yup from 'yup';

import { DROPDOWN_FIELD } from 'core/model/interface';
import { EntityFields } from '../../../utils';
import moment from 'moment';

export const CE_ENTITY_NAME = 'Calendar Event';

export const OOO_ENTITY_NAME = 'Out of Office Event';

export const HOLIDAY_ENITY_NAME = 'Holiday Event';

export const createCalendarEventSchema = yup.object().shape({
  event_name: yup.string().required('Event Name is required.').max(30, 'Should not exceed 30 characters.'),
  event_description: yup.string().max(200, 'Event description should not exceed 200 characters.'),
  start_date_2: yup.date(),
  end_date_2: yup.date().test('is-greater', 'End date cannot be before start date', function (value) {
    const { start_date_2 } = this.parent;
    return moment(value).isSameOrAfter(moment(start_date_2));
  }),

  start_date: yup.date(),
  end_date: yup.date().test('is-greater', 'End date cannot be before start date', function (value) {
    const { start_date } = this.parent;
    return moment(value).isSameOrAfter(moment(start_date));
  }),
  service_provider_id: yup.array().min(1, 'At least one service provider is required.'),
});

export const createHolidaySchema = yup.object().shape({
  event_name: yup.string().required('Event Name is required.').max(30, 'Should not exceed 30 characters.'),
});

export interface CalendarEventModel {
  id: number;
  provider_id: number;
  event_name: string;
  event_description: string;
  start_date: Date;
  end_date: Date;
  all_day?: boolean;
  holiday?: boolean;
  out_of_office?: boolean;
  event_color: string;
}

export interface CalendarEventInput {
  event_name: string;
  provider_id: any;
  event_description: string;
  start_date?: Date;
  end_date?: Date;
  all_day?: boolean;
  holiday?: boolean;
  out_of_office?: boolean;
  event_color: string;
  start_date_2?: Date;
  end_date_2?: Date;
}

export const calendarEventInitialValues: CalendarEventInput = {
  event_name: '',
  provider_id: '',
  event_description: '',
  start_date: new Date(new Date().setHours(0, 0, 0, 0)),
  end_date: new Date(new Date().setHours(23, 59, 59, 999)),
  all_day: false,
  holiday: false,
  out_of_office: true,
  event_color: '#FCE8D8',
};

export const calendarEventFields: EntityFields[] = [
  { fieldName: 'event_name', displayName: 'Event Name' },
  {
    fieldName: 'service_provider_id',
    displayName: 'Service Provider',
    type: DROPDOWN_FIELD.SERVICE_PROVIDER,
    multiple: true,
    optional: true,
  },
  {
    fieldName: 'event_description',
    displayName: 'Event Description',
    multiline: true,
    rows: 3,
    span: 4,
    optional: true,
  },
  { fieldName: 'start_date', displayName: 'Start Date', type: 'datetime' },
  { fieldName: 'end_date', displayName: 'End Date', type: 'datetime' },
  { fieldName: 'out_of_office', displayName: 'Out of Office', type: 'checkbox' },
  { fieldName: 'all_day', displayName: 'All Day', type: 'checkbox' },
  { fieldName: 'holiday', displayName: 'Holiday', type: 'checkbox' },
  { fieldName: 'event_color', displayName: 'Event Color', type: 'color', optional: true },
];

// // Table
// export const patientNoteSummaryTemplateTableColumns: string[] = ['template_name', 'template_description'];

// // Forms
export const calendarEventFormFields: string[] = [
  'event_name',
  'service_provider_id',
  'event_description',
  'start_date',
  'end_date',
  'out_of_office',
  'all_day',
  'holiday',
  'event_color',
];

// **  **  ** ** ** ** ** ** ** ** ** ** **  OUT OF OFFICE ** ** ** ** ** ** ** ** ** ** ** ** \\
export const outOfOfficeInitialValues: CalendarEventInput = {
  event_name: 'Out of Office',
  provider_id: '',
  event_description: '',
  start_date: new Date(),
  end_date: new Date(),
  start_date_2: new Date(),
  end_date_2: new Date(),
  all_day: true,
  event_color: '#FCE8D8',
};
export interface OutOfOfficeModel {
  id: number;
  provider_id: number;
  event_name: string;
  event_description?: string;
  start_date: Date;
  end_date: Date;
  all_day?: boolean;
  out_of_office: boolean;
  event_color: string;
  start_date_2?: Date;
  end_date_2?: Date;
}
export const outOfOfficeFields: EntityFields[] = [
  { fieldName: 'event_name', displayName: 'Event Name' },
  {
    fieldName: 'service_provider_id',
    displayName: 'Service Provider',
    type: DROPDOWN_FIELD.SERVICE_PROVIDER,
    multiple: true,
  },
  {
    fieldName: 'full_name',
    displayName: 'Service Provider',
  },
  {
    fieldName: 'event_description',
    displayName: 'Event Description',
    multiline: true,
    rows: 3,
    span: 4,
    optional: true,
  },
  {
    fieldName: 'start_date',
    displayName: 'Start Date',
    type: 'datetime',
    hiddenBasedOnOtherField: (data: any) => data.all_day,
  },

  {
    fieldName: 'end_date',
    displayName: 'End Date',
    type: 'datetime',
    hiddenBasedOnOtherField: (data: any) => data.all_day,
  },
  {
    fieldName: 'start_date_2',
    displayName: 'Date',
    type: 'date',
    hiddenBasedOnOtherField: (data: any) => !data.all_day,
    onChange: (value, setFieldValue) => {
      setFieldValue && setFieldValue('end_date_2', value);
    },
  },
  {
    fieldName: 'end_date_2',
    displayName: 'End Date',
    type: 'date',
    // hiddenBasedOnOtherField: (data: any) => !data.all_day,
    hidden: true,
  },
  {
    fieldName: 'all_day',
    displayName: 'All Day',
    span: 4,
    type: 'checkbox',
    onChange: (value, setFieldValue) => {
      if (value) {
        setFieldValue && setFieldValue('start_date', new Date(new Date().setHours(0, 0, 0, 0)));
        setFieldValue && setFieldValue('end_date', new Date(new Date().setHours(23, 59, 59, 999)));
      } else {
        setFieldValue && setFieldValue('start_date_2', new Date());
        setFieldValue && setFieldValue('end_date_2', new Date());
      }
    },
  },
  { fieldName: 'event_color', displayName: 'Event Color', type: 'color', optional: true },
];

export const updateOutOfOfficeFields: EntityFields[] = [
  { fieldName: 'event_name', displayName: 'Event Name' },
  {
    fieldName: 'event_description',
    displayName: 'Event Description',
    multiline: true,
    rows: 3,
    span: 4,
    optional: true,
  },
  {
    fieldName: 'start_date',
    displayName: 'Start Date',
    type: 'datetime',
  },
  {
    fieldName: 'end_date',
    displayName: 'End Date',
    type: 'datetime',
  },
  { fieldName: 'event_color', displayName: 'Event Color', type: 'color' },
];

export const updateOutOfOfficeFieldsAllDay: EntityFields[] = [
  { fieldName: 'event_name', displayName: 'Event Name' },
  {
    fieldName: 'event_description',
    displayName: 'Event Description',
    multiline: true,
    rows: 3,
    span: 4,
    optional: true,
  },
  {
    fieldName: 'start_date_2',
    displayName: 'Date',
    type: 'date',
    span: 4,
    hiddenBasedOnOtherField: (data: any) => !data.all_day,
    onChange: (value, setFieldValue) => {
      setFieldValue && setFieldValue('end_date_2', value);
    },
  },
  {
    fieldName: 'end_date_2',
    displayName: 'End Date',
    type: 'date',
    // hiddenBasedOnOtherField: (data: any) => !data.all_day,
    hidden: true,
  },
  { fieldName: 'event_color', displayName: 'Event Color', type: 'color' },
];

export const updateOutOfOfficeFormFields: string[] = [
  'event_name',
  'event_color',
  'event_description',
  'start_date',
  'end_date',
];

export const updateOutOfOfficeAllDayFormFields: string[] = [
  'event_name',
  'event_color',
  'event_description',
  'start_date_2',
  'end_date_2',
];

export const outOfOfficeFormFields: string[] = [
  'event_name',
  'service_provider_id',
  'event_description',
  'all_day',
  'start_date',
  'end_date',
  'start_date_2',
  'end_date_2',
  'event_color',
];

export const outOfOfficeTableFields: string[] = ['event_name', 'full_name', 'event_description', 'start_date'];

// **  **  ** ** ** ** ** ** ** ** ** ** **  HOLIDAY ** ** ** ** ** ** ** ** ** ** ** ** \\
export interface HolidayEventInput {
  event_name: string;
  start_date: Date;
}
export const holidayEventInitialValues: HolidayEventInput = {
  event_name: '',
  start_date: new Date(),
};

export interface HolidayEventModel {
  id: number;
  event_name: string;
  start_date: Date;
  event_color?: string;
}

export const holidayEventFields: EntityFields[] = [
  { fieldName: 'event_name', displayName: 'Event Name' },

  {
    fieldName: 'start_date',
    displayName: 'Date',
    type: 'date',
  },
];

export const holidayEventFormFields: string[] = ['event_name', 'start_date'];
