import { Box, Button } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { addServicesToCategory, getServicesNotAddedWithCategory } from 'company/api/service-categories';
import { addServicePackagesToCategory, getGroupsNotAddedWithCategory } from 'company/api/service-groups';
import { getServicesAssigned, getServicesNotAssigned } from 'company/api/services';
import { SkeletonDetails } from 'company/screens/HealthAssessments';
import { ConfirmationDialog } from 'core/components';
import { FacilityContext } from 'core/context/facility.context';
import { ApiQuery } from 'core/model/interface';
import { PageBuilderTableType } from 'core/PageBuilder';
import PageBuilder, { BLOCK_TYPE } from 'core/PageBuilder/PageBuilder';
import { formatNumber } from 'core/utils';
import { useSnackbar } from 'notistack';
import React, { ReactNode, useContext, useEffect, useState } from 'react';

interface AddServiceAndPackageTableProps {
  entity: string;
  service_category_id: number;
  serviceCategoryTableRef?: any;
}
export const setStateAsync = (stateSetter: Function, value: any) => {
  return new Promise<void>((resolve) => {
    stateSetter(value);
    resolve();
  });
};
export default function AddServiceAndPackageTable({
  entity,
  service_category_id,
  serviceCategoryTableRef,
}: AddServiceAndPackageTableProps) {
  const { facility } = useContext(FacilityContext);

  const [forceRefreshTable, setForceRefreshTable] = useState<number>(0);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const forService = entity === 'Services';
  function refreshOuterTable() {
    if (!serviceCategoryTableRef.current.refreshTable) return;

    serviceCategoryTableRef.current?.refreshTable();
  }
  const service_columns: GridColDef[] = [
    { field: 'service_name', headerName: 'Name', flex: 2 },
    {
      field: 'price',
      headerName: 'Price',
      flex: 1,
      renderCell: (params) =>
        formatNumber(parseFloat(params.row.price)) ? formatNumber(parseFloat(params.row.price)) : '0',
    },
    {
      field: 'action',
      sortable: false,
      headerName: 'Action',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      renderCell: (params) => (
        <Box display="flex" gap="5px">
          <Button
            variant="outlined"
            size="small"
            color="success"
            onClick={(event) => {
              //   setOpenAddDialog(true);
              //   setAlertContent('Are you sure you want to add ' + params.row.service_name + '?');
              //   setSelectedRow(params.row);
              event.stopPropagation();
              setStateAsync(setSelectedRows, [params.row.id]).then(() => setOpenConfirmDialog(true));
            }}
          >
            Add
          </Button>
        </Box>
      ),
    },
  ];

  const service_package_columns: GridColDef[] = [
    { field: 'group_name', headerName: 'Service Group Name', flex: 2 },
    {
      field: 'action',
      sortable: false,
      headerName: 'Action',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      renderCell: (params) => (
        <Box display="flex" gap="5px">
          <Button
            variant="outlined"
            size="small"
            color="success"
            onClick={(event) => {
              //   setOpenAddDialog(true);
              //   setAlertContent('Are you sure you want to add ' + params.row.service_name + '?');
              //   setSelectedRow(params.row);
              event.stopPropagation();
              setStateAsync(setSelectedRows, [params.row.id]).then(() => setOpenConfirmDialog(true));
            }}
          >
            Add
          </Button>
        </Box>
      ),
    },
  ];

  const getList = (query: ApiQuery) => {
    if (forService) return getServicesNotAddedWithCategory(facility.id, service_category_id, query);
    return getGroupsNotAddedWithCategory(facility.id, service_category_id, query);
  };

  const handleSelectRows = (selected: any) => {
    setSelectedRows(selected);
  };

  useEffect(() => {
    setForceRefreshTable((prev) => prev + 1);
  }, [service_category_id]);

  async function handleAddSelectedRows(selectedRows: number[]) {
    setLoading(true);
    const res = await addServicesToCategory(facility.id, service_category_id, { service_ids: selectedRows });
    setForceRefreshTable((prev) => prev + 1);
    enqueueSnackbar('Services added to category!', { variant: 'success' });
    refreshOuterTable();
    setLoading(false);
  }

  async function handleAddSelectedGroups(selectedRows: number[]) {
    setLoading(true);
    await addServicePackagesToCategory(facility.id, service_category_id, { group_ids: selectedRows });
    setForceRefreshTable((prev) => prev + 1);
    enqueueSnackbar('Service groups added to category!', { variant: 'success' });
    refreshOuterTable();
    setLoading(false);
  }

  const content = {
    type: BLOCK_TYPE.TABLE,
    entityName: entity,
    tableComponent: {
      columns: forService ? service_columns : service_package_columns,
      getData: getList,
      checkboxSelection: true,
      selectableRow: true,
      forceRefresh: forceRefreshTable,
      onSelectRow: handleSelectRows,
      onRowsSelected: forService ? handleAddSelectedRows : handleAddSelectedGroups,
    },
  } as PageBuilderTableType;

  return (
    <Box>
      {!loading ? (
        <PageBuilder content={content} />
      ) : (
        <SkeletonDetails length={8} styles={{ width: '100%', height: '60px', borderRadius: '10px', padding: 0 }} />
      )}
      <ConfirmationDialog
        open={openConfirmDialog}
        setOpen={setOpenConfirmDialog}
        onConfirm={() => {
          if (forService) return handleAddSelectedRows(selectedRows);
          return handleAddSelectedGroups(selectedRows);
        }}
        content={`Are you sure you want to add the selected services to this category?`}
      />
    </Box>
  );
}
