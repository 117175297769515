import * as yup from 'yup';

import { DROPDOWN_FIELD } from 'core/model/interface';
import { EntityFields } from '../../../utils';
import { FacilityModel } from 'core/model/Entities';

export const SERVICE_GROUP_ENTITY_NAME = 'Service Group';

export const serviceGroupSchema = yup.object().shape({
  group_name: yup.string().required('Group name is required').max(30, 'Must be at most 30 characters'),
  price: yup
    .number()
    .typeError('Invalid input. Numbers only.')
    .max(999999.99, 'Amount cannot exceed 999,999.99')
    .min(0, 'Amount should be positive')
    .test('decimal-places', 'Maximum of 2 decimal places allowed', (value) => {
      if (!value) return true;
      return /^\d+(\.\d{0,2})?$/.test(value.toString());
    }),
});

export const serviceGroupFields: EntityFields[] = [
  {
    fieldName: 'group_name',
    displayName: 'Group Name',
    type: 'string',
    span: 4,
  },
  {
    fieldName: 'service_id',
    displayName: 'Services',
    type: DROPDOWN_FIELD.SERVICE,
    optional: true,
    multiple: true,
  },
  {
    fieldName: 'service_category_id',
    displayName: 'Services Category',
    type: DROPDOWN_FIELD.SERVICE_CATEGORY,
    optional: true,
    multiple: true,
  },
  { type: 'divider', fieldName: 'divider' },
  {
    fieldName: 'is_package',
    displayName: 'For POS',
    type: 'checkbox',
  },
  {
    fieldName: 'price',
    displayName: 'Price',
    optional: true,
    hiddenBasedOnOtherField: (data) => !data.is_package,
  },
  { fieldName: 'apply_to_all_clinics', displayName: 'Apply to All Clinics', type: 'checkbox', span: 4 },
  {
    fieldName: 'facilities',
    displayName: 'Clinic',
    type: 'multiselect',
    options: [],
    span: 4,
    hiddenBasedOnOtherField: (data: any) => {
      return data.apply_to_all_clinics;
    },
  },
];

export interface ServiceGroupInput {
  group_name: string;
  service_id: any[];
  is_package: boolean;
  price: string;
  service_category_id?: any[];
  facilities: any[];
  apply_to_all_clinics: boolean;
}

export const serviceGroupInitialValues: ServiceGroupInput = {
  group_name: '',
  service_id: [],
  is_package: false,
  price: '0.00',
  service_category_id: [],
  facilities: [],
  apply_to_all_clinics: true,
};

export interface ServiceGroupModel {
  id: number;
  is_package: boolean;
  group_name: string;
  price: number;
  service_category_id?: any[];
  assigned_facilities: string;
  assigned_facilities_ids: string;
  facilities: any[];
}

export const serviceGroupColumnTable: string[] = [];

export const serviceGroupFormFields: string[] = [
  'group_name',
  'service_id',
  'service_category_id',
  'divider',
  'is_package',
  'price',
  'divider',
  'apply_to_all_clinics',
  'facilities',
];
export const serviceGroupUpdateFormFields: string[] = [
  'group_name',
  'service_category_id',
  'divider',
  'is_package',
  'price',
  // 'divider',
  // 'apply_to_all_clinics',
  // 'facilities',
];
