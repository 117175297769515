import { Box, Popover, Typography, useTheme } from '@mui/material';
import { CalendarEvent, ViewProps } from '.';
import {
  EVENT_HEIGHT,
  NoServiceProviderDisplay,
  OoFPopOverContent,
  calculateEventPosition,
  calculateOOFPosition,
} from './WeekView';
import React, { useContext, useEffect, useState } from 'react';
import {
  calculatePositionedEvents,
  calculatePositionedOutOfOfficeEvents,
  formatCalenderTime,
  isSameDate,
} from './utils';

import AppointmentForm from 'company/entities/modules/ClinicManagement/Appointment/AppointmentForm';
import { ConfirmationDialog, CustomModal } from 'core/components';
import EventBusyOutlinedIcon from '@mui/icons-material/EventBusyOutlined';
import { FacilityContext } from 'core/context/facility.context';
import { OutOfOfficeModel } from 'company/entities/modules/ClinicManagement/CalendarEvent/CalendarEventModel';
import { formatTime } from 'core/utils';
import { tokens } from 'theme/theme';
import { deleteOOFEvent } from 'company/api/calendar-events';
import CalendarEventForm from 'company/entities/modules/ClinicManagement/CalendarEvent/CalendarEventForm';
import { useSnackbar } from 'notistack';

const DayView: React.FC<ViewProps> = ({
  date,
  staff,
  events,
  hours,
  startHour,
  endHour,
  setOpenServiceProviders,
  refreshEvents,
  holidays,
  outOfOfficeEvents,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { facility } = useContext(FacilityContext);
  const [currentTimePosition, setCurrentTimePosition] = useState<number | null>(null);
  const [openCreate, setOpenCreate] = useState(false);
  const [selectedData, setSelectedData] = useState({
    schedule: new Date(),
    service_provider: 0,
  });

  const [openUpdateOOFForm, setOpenUpdateOOFForm] = useState<boolean>(false);
  const [outOfOfficeAnchorEl, setOutOfOfficeAnchorEl] = React.useState<HTMLElement | null>(null);
  const [selectedEvent, setSelectedEvent] = React.useState<OutOfOfficeModel | null>(null);
  const [openDeleteOOFDialog, setOpenDeleteOOFDialog] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const handleClick = (event: React.MouseEvent<HTMLElement>, eventData: OutOfOfficeModel) => {
    event.stopPropagation();
    setOutOfOfficeAnchorEl(event.currentTarget);
    setSelectedEvent(eventData); // Set the clicked event's data
  };
  const handleClose = () => {
    setOutOfOfficeAnchorEl(null);
    setSelectedEvent(null);
    setOpenUpdateOOFForm(false);
  };
  const open = Boolean(outOfOfficeAnchorEl);

  async function handleDeleteOOFEvent() {
    if (!selectedEvent) return;
    await deleteOOFEvent(facility.id, selectedEvent.id);
    setOpenDeleteOOFDialog(false);
    handleClose();
    refreshEvents();
    enqueueSnackbar('Event successfully deleted!', { variant: 'success' });
  }

  const getEventsForStaffAndHour = (staffId: number, facilityId: number, hour: number) => {
    return calculatePositionedEvents(
      events.filter((event) => {
        const scheduleStart = new Date(event.schedule);
        return (
          ((event.provider_id === staffId && event.facility_id === facilityId && event.status !== 'cancelled') ||
            (!staffId && !event.provider_id)) &&
          scheduleStart.getFullYear() === date.getFullYear() &&
          scheduleStart.getMonth() === date.getMonth() &&
          scheduleStart.getDate() === date.getDate() &&
          scheduleStart.getHours() === hour
        );
      })
    );
  };
  const getOutOfOfficeEventsForStaffAndDay = (staffId: number, facilityId: number, day: Date) => {
    if (!outOfOfficeEvents) return;
    return calculatePositionedOutOfOfficeEvents(
      outOfOfficeEvents
        .filter((event) => !event.all_day)
        .filter((event) => {
          const scheduleStart = new Date(event.start_date);
          return (
            (event.provider_id === staffId || (!staffId && !event.provider_id)) &&
            scheduleStart.getFullYear() === day.getFullYear() &&
            scheduleStart.getMonth() === day.getMonth() &&
            scheduleStart.getDate() === day.getDate() &&
            scheduleStart.getHours() <= endHour &&
            scheduleStart.getHours() >= startHour
          );
        })
    );
  };
  useEffect(() => {
    const updateCurrentTimePosition = () => {
      const now = new Date();
      if (
        now.getFullYear() === date.getFullYear() &&
        now.getMonth() === date.getMonth() &&
        now.getDate() === date.getDate() &&
        date.getHours() <= endHour
      ) {
        const minutesSinceStartOfDay = now.getHours() * 60 + now.getMinutes() - startHour * 60;
        setCurrentTimePosition(minutesSinceStartOfDay);
      } else {
        setCurrentTimePosition(null);
      }
    };

    updateCurrentTimePosition();
    const intervalId = setInterval(updateCurrentTimePosition, 60000); // Update every minute

    return () => clearInterval(intervalId);
  }, [date, endHour, startHour]);
  const isHoliday = holidays?.find((holiday) => isSameDate(String(date), String(holiday.start_date)));

  return (
    <>
      {!!isHoliday && (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '10px',
          }}
        >
          <Typography
            color={colors.background}
            sx={{
              backgroundColor: isHoliday.event_color,
              borderRadius: '5px',
              width: 'auto',
              padding: '2px 60px',
            }}
          >
            {isHoliday.event_name}
          </Typography>
        </Box>
      )}
      <Box display="flex" width="100%">
        {staff.length > 0 && (
          <Box>
            <Box
              height={'70px'}
              position="sticky"
              top={0}
              sx={{ backgroundColor: colors.secondary_background, borderBottom: '3px solid #ccc' }}
            />
            {hours.map((hour) => (
              <Box key={hour} className="time-slot" sx={{ height: `${EVENT_HEIGHT}px` }}>
                <Box className="time-label" textAlign="right" width="40px" paddingRight="5px">
                  <Typography fontSize="11px">{formatCalenderTime(hour)}</Typography>
                </Box>
              </Box>
            ))}
          </Box>
        )}

        <Box width="100%">
          <Box
            display="flex"
            position="sticky"
            top={0}
            zIndex={11}
            sx={{ backgroundColor: colors.secondary_background }}
          >
            {staff.map((staffMember, staffIndex) => {
              const currDay = date.toDateString().split(' ')[0].toUpperCase();
              const isBlocked =
                !!staffMember.schedule &&
                currDay === staffMember.schedule.filter((sched: any) => sched.day === currDay)[0].day &&
                staffMember.schedule.filter((sched: any) => sched.day === currDay)[0].hours.length < 1;

              const isOutOfOfficeAllDay = outOfOfficeEvents?.find(
                (event: OutOfOfficeModel) =>
                  event.provider_id === staffMember.doctor_id &&
                  isSameDate(String(date), String(event.start_date)) &&
                  event.all_day
              );
              const isHoliday = holidays?.find((holiday) => isSameDate(String(date), String(holiday.start_date)));
              const oooColor =
                isOutOfOfficeAllDay?.event_color === '#000' ? '#FCE8D8' : isOutOfOfficeAllDay?.event_color;
              return (
                <Box
                  flex={1}
                  width="100%"
                  key={staffMember.assignment_id}
                  className="staff-column"
                  sx={{
                    borderLeft: staffIndex === 0 ? '1px solid #ccc' : undefined,
                    backgroundColor: isBlocked
                      ? colors.light_red_background_2
                      : isHoliday
                      ? '#D6EFD8'
                      : isOutOfOfficeAllDay
                      ? oooColor
                      : undefined,
                  }}
                >
                  <Box
                    className="staff-name"
                    sx={{
                      borderTop: '1px solid #ccc',
                      borderBottom: '3px solid ' + colors.primary,
                      borderColor: staffMember.color,
                    }}
                  >
                    <Box sx={{ wordBreak: 'break-word' }}>
                      <Typography fontWeight={500}>
                        {staffMember.first_name} {staffMember.last_name ? staffMember.last_name?.charAt(0) + '.' : ''}
                      </Typography>
                      {facility?.is_admin && <Typography fontSize="11px">{staffMember.facility_name}</Typography>}
                      {!!isOutOfOfficeAllDay && (
                        <Box
                          sx={{
                            display: 'flex',
                            // flexDirection: 'column',

                            // height: `${EVENT_HEIGHT}px`,
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '5px',
                            backgroundColor: oooColor,
                            padding: '2px 6px',
                            borderRadius: '5px',
                            border: '1px solid #F6B580',
                          }}
                        >
                          <EventBusyOutlinedIcon sx={{ fontSize: '14px' }} />
                          <Typography sx={{ fontSize: '10px' }}>{isOutOfOfficeAllDay.event_name}</Typography>
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Box>
              );
            })}
          </Box>

          {!staff.length && <NoServiceProviderDisplay setOpenServiceProviders={setOpenServiceProviders} />}

          <Box display="flex" width="100%" position="relative">
            {staff.map((staffMember, index) => {
              const currDay = date.toDateString().split(' ')[0].toUpperCase();
              const isBlocked =
                !!staffMember.schedule &&
                currDay === staffMember.schedule.filter((sched: any) => sched.day === currDay)[0].day &&
                staffMember.schedule.filter((sched: any) => sched.day === currDay)[0].hours.length < 1;

              const isOutOfOfficeAllDay = outOfOfficeEvents?.find(
                (event: OutOfOfficeModel) =>
                  event.provider_id === staffMember.doctor_id &&
                  isSameDate(String(date), String(event.start_date)) &&
                  event.all_day
              );
              const oooColor =
                isOutOfOfficeAllDay?.event_color === '#000' ? '#FCE8D8' : isOutOfOfficeAllDay?.event_color;

              return (
                <Box width="100%" key={index}>
                  <Box
                    key={staffMember.id}
                    className="staff-column"
                    sx={{
                      position: 'relative',
                      borderLeft: index === 0 ? '1px solid #ccc' : undefined,
                      backgroundColor: isBlocked
                        ? colors.light_red_background_2
                        : isHoliday
                        ? '#D6EFD8'
                        : isOutOfOfficeAllDay
                        ? oooColor
                        : undefined,
                    }}
                  >
                    <div className="events">
                      {hours.map((hour) => (
                        <div key={hour} className="time-slot">
                          <Box
                            key={hour}
                            className="time-slot"
                            sx={{
                              height: `60px`,
                              display: 'flex',
                              flexDirection: 'column',
                            }}
                          >
                            {[0, 15, 30, 45].map((minute) => {
                              const currentDate = new Date(date);
                              currentDate.setHours(hour, minute, 0, 0);
                              return (
                                <Box
                                  sx={{
                                    width: '100%',
                                    height: '100%',
                                    border: '1ps solid red',
                                    paddingInline: '10px',
                                    '& p': { display: 'none' },
                                    '&:hover':
                                      !isBlocked && !isOutOfOfficeAllDay
                                        ? {
                                            backgroundColor: colors.light_blue_background_2,
                                            cursor: 'pointer',
                                            '& p': { display: 'block' },
                                          }
                                        : {},
                                  }}
                                  onClick={
                                    !isBlocked && !isOutOfOfficeAllDay
                                      ? () => {
                                          setSelectedData({
                                            schedule: currentDate,
                                            service_provider: staffMember.doctor_id,
                                          });
                                          setOpenCreate(true);
                                        }
                                      : undefined
                                  }
                                >
                                  <Typography fontSize="12px">{formatTime(currentDate)}</Typography>
                                </Box>
                              );
                            })}
                          </Box>
                          {getEventsForStaffAndHour(staffMember.doctor_id, staffMember.facility_id, hour).map(
                            (event) => {
                              const { top, height } = calculateEventPosition(event, startHour);
                              return (
                                <Box key={event.id} sx={{ position: 'absolute', top: `${top}px`, width: '100%' }}>
                                  <CalendarEvent
                                    event={{ ...event, height }}
                                    staffColor={staffMember.color || null}
                                    staffCount={staff.length}
                                    dayView
                                    refreshEvents={refreshEvents}
                                  />
                                </Box>
                              );
                            }
                          )}

                          {getOutOfOfficeEventsForStaffAndDay(
                            staffMember.doctor_id,
                            staffMember.facility_id,
                            date
                          )?.map((event) => {
                            const { top, height } = calculateOOFPosition(event, startHour);
                            return (
                              <Box
                                onClick={(e) => handleClick(e, event)}
                                key={event.id}
                                sx={{ position: 'absolute', top: `${top}px`, width: '100%' }}
                              >
                                <Box
                                  key={event.id}
                                  className="event"
                                  sx={{
                                    top: `${event.top}px`,
                                    height: `${event.height}px`,
                                    left: `${event.left * (100 / event.width)}%`,
                                    width: `${100 / event.width}%`,
                                    overflow: 'hidden',
                                    borderTop: `20px solid ${oooColor}`,
                                    borderRight: `2px solid ${oooColor}`,
                                    borderBottom: `2px solid ${oooColor}`,
                                    borderLeft: `2px solid ${oooColor}`,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    flexDirection: 'column',
                                    cursor: 'pointer',
                                    gap: '10px',
                                  }}
                                  display="flex"
                                >
                                  <EventBusyOutlinedIcon sx={{ fontSize: '18px', color: colors.redAccent }} />
                                  <Typography color={'#243642'} fontWeight={'600'}>
                                    {event.event_name}
                                  </Typography>
                                </Box>
                              </Box>
                            );
                          })}
                        </div>
                      ))}
                    </div>
                    {currentTimePosition !== null && (
                      <Box
                        sx={{
                          top: `${currentTimePosition}px`,
                          position: 'absolute',
                          height: '2px',
                          width: '100%',
                          backgroundColor: colors.redAccent,
                          zIndex: 2,
                        }}
                      />
                    )}
                  </Box>
                </Box>
              );
            })}
          </Box>
        </Box>

        <CustomModal header="Create Appointment" open={openCreate} setOpen={setOpenCreate}>
          <AppointmentForm
            facility={facility}
            schedule={selectedData.schedule}
            providerId={selectedData.service_provider}
            callbackAfterSubmit={() => {
              setOpenCreate(false);
              refreshEvents();
            }}
          />
        </CustomModal>
        <ConfirmationDialog
          setOpen={setOpenDeleteOOFDialog}
          open={openDeleteOOFDialog}
          onConfirm={handleDeleteOOFEvent}
          content={'Are you sure you want to delete this event?'}
        />
        <Popover
          id={open ? 'simple-popover' : undefined}
          open={open}
          anchorEl={outOfOfficeAnchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
        >
          {selectedEvent && !openUpdateOOFForm ? (
            <OoFPopOverContent
              selectedEvent={selectedEvent}
              setOpenUpdateOOFForm={setOpenUpdateOOFForm}
              setOpenDeleteOOFDialog={setOpenDeleteOOFDialog}
            />
          ) : (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px', padding: '20px' }}>
              <Typography sx={{ fontWeight: '600', fontSize: '18px', color: colors.accent }}>
                Update Calendar Event
              </Typography>
              {selectedEvent && (
                <CalendarEventForm
                  outOfOffice
                  updateOutOfOffice
                  outOfOfficeEvent={selectedEvent}
                  callbackAfterSubmit={() => {
                    refreshEvents();
                    handleClose();
                  }}
                />
              )}
            </Box>
          )}
        </Popover>
      </Box>
    </>
  );
};

export default DayView;
