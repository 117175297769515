import { Box, List, ListItem, Typography, useTheme } from '@mui/material';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { CustomModal, RegularButton } from 'core/components';

import CustomDateTimePicker from 'core/components/CustomDatetimePicker';
import { FacilityContext } from 'core/context/facility.context';
import { importPatients } from 'company/api/patient';
import moment from 'moment';
import { tokens } from 'theme/theme';
import { useSnackbar } from 'notistack';

type ImportModalProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  callbackAfterImport: () => void;
  assessmentBatchId?: number;
};

const ImportModal: React.FC<ImportModalProps> = ({ open, setOpen, callbackAfterImport, assessmentBatchId }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [file, setFile] = useState<File>();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const [date, setDate] = useState<string>();
  const { facility } = useContext(FacilityContext);

  const handleImportPatients = async () => {
    setLoading(true);
    if (facility) {
      try {
        const assessment_date = moment(new Date(date ?? '')).format('YYYY-MM-DD HH:mm:ss');
        await importPatients(facility.id, file, { batch_id: assessmentBatchId, assessment_date });

        enqueueSnackbar(`Patients successfully imported!`, { variant: 'success' });
        setOpen(false);
        callbackAfterImport();
      } catch (error) {
      } finally {
        setLoading(false);
      }
    }
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }
    setFile(e.target.files[0]);
  };

  useEffect(() => setFile(undefined), [open]);

  return (
    <>
      <CustomModal header="Import Patients" open={open} setOpen={setOpen}>
        <Box>
          <Typography variant="h5"> Instructions: </Typography>

          <List dense>
            <ListItem>
              <Typography>
                1. Download the Excel template
                <a
                  href={process.env.PUBLIC_URL + '/template/client_import_template.xlsx'}
                  download="client_import_template.xlsx"
                  style={{ color: colors.accent, paddingLeft: '5px' }}
                >
                  here
                </a>
                .
              </Typography>
            </ListItem>
            <ListItem>
              <Typography>2. Employee ID, First Name, Last Name, Sex, and Address are required fields. </Typography>
            </ListItem>
            <ListItem>
              <Typography>3. Mobile number format should be 09*********. </Typography>
            </ListItem>
            <ListItem>
              <Typography>4. The age is automatically computed if the birthday is provided. </Typography>
            </ListItem>
            {!!assessmentBatchId && (
              <>
                <ListItem>
                  <Typography fontWeight={600}>Note: Imported patients will be added to the selected batch.</Typography>
                </ListItem>
                {!!assessmentBatchId && (
                  <ListItem>
                    <Typography fontWeight={600}>You can import up to 500 patients per Excel file.</Typography>
                  </ListItem>
                )}
              </>
            )}
          </List>

          {!assessmentBatchId && (
            <Typography variant="h5" mb="15px">
              Note: You can import up to 500 patients per Excel file.
            </Typography>
          )}
        </Box>
        <Box marginTop={'10px'} display="grid" gridTemplateColumns="1fr 1fr">
          <Box>
            <Typography variant="h5"> Select file here: </Typography>
            <Box margin={'10px'}>
              <input type="file" onChange={handleFileChange} accept=".xlsx" />
            </Box>
          </Box>
          {!!assessmentBatchId && (
            <CustomDateTimePicker label={'Assessment Date'} fieldName={''} handleChange={(value) => setDate(value)} />
          )}
        </Box>
        <Box display="flex" justifyContent="end">
          <RegularButton
            label={'Import'}
            onClick={handleImportPatients}
            loading={loading}
            disabled={!file || (!!assessmentBatchId && !date)}
          />
        </Box>
      </CustomModal>
    </>
  );
};

export default ImportModal;
