import * as yup from 'yup';

import { EntityFields } from 'company/entities/utils';

export const PUOM_ENTITY = 'Product Unit of Measurement';
export const createUOMSchema = yup.object().shape({
  name: yup.string().required('Name is required.').max(50, 'Must be less than or equal to 50 characters'),
  symbol: yup.string().required('Symbol is required.').max(50, 'Must be less than or equal to 50 characters'),
});
export interface ProductUnitOfMeasureModel {
  id: number;
  name: string;
  symbol: string;
  is_active: boolean;
}
export const productUnitOfMeasureFields: EntityFields[] = [
  { fieldName: 'name', displayName: 'Name' },
  { fieldName: 'symbol', displayName: 'Symbol' },
];
export interface productUnitOfMeasureFieldInputs {
  name: string;
  symbol: string;
}
export const productUnitOfMeasureInitialValues: productUnitOfMeasureFieldInputs = {
  name: '',
  symbol: '',
};
export const productUnitOfMeasureTableColumns: string[] = ['name', 'symbol'];
