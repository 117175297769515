/* eslint-disable react-hooks/exhaustive-deps */

import { Box, Typography } from '@mui/material';
import { CustomContainer, CustomModal, RegularButton } from 'core/components';
import CustomTabs, { TabProps } from 'core/components/CustomTabs';
import { SERVICE_ADD_STEPS, TOUR_ID } from 'core/screens/Helper/GuidedTour/steps';
import { useContext, useEffect, useRef, useState } from 'react';

import AppointmentForm from 'company/entities/modules/ClinicManagement/Appointment/AppointmentForm';
import AppointmentLegendTable from 'company/entities/modules/ClinicManagement/AppointmentLegend/AppointmentLegendTable';
import AppointmentTable from 'company/entities/modules/ClinicManagement/Appointment/AppointmentTable';
import BookingsForConfirmation from './BookingsForConfirmation';
import { BreadcrumbContext } from 'core/context/breadcrumb.context';
import Calendar from '../../CompanyDashboard/components/Calendar';
import CalendarEventForm from 'company/entities/modules/ClinicManagement/CalendarEvent/CalendarEventForm';
import CalendarEventHolidayTable from 'company/entities/modules/ClinicManagement/CalendarEvent/CalendarEventHolidayTable';
import CalendarEventTableOOO from 'company/entities/modules/ClinicManagement/CalendarEvent/CalendarEventTableOOO';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import { CareGoPage } from 'core/PageBuilder';
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import DomainDisabledOutlinedIcon from '@mui/icons-material/DomainDisabledOutlined';
import EventNoteOutlinedIcon from '@mui/icons-material/EventNoteOutlined';
import { FacilityContext } from 'core/context/facility.context';
import LegendToggleOutlinedIcon from '@mui/icons-material/LegendToggleOutlined';
import LiveHelpOutlinedIcon from '@mui/icons-material/LiveHelpOutlined';
import PermContactCalendarOutlinedIcon from '@mui/icons-material/PermContactCalendarOutlined';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import ServiceProviderCalendar from 'company/components/ServiceProviderCalendar';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined';
import { SmsScheduleModal } from 'company/components';
import { TourContext } from 'core/context/tour.context';
import { UserContext } from 'core/context/user.context';

const AppointmentsTabs = () => {
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const { facility } = useContext(FacilityContext);
  const { user } = useContext(UserContext);
  const { setTourState } = useContext(TourContext);
  const tableRef: any = useRef();
  const serviceViewRef: any = useRef();

  const [selected, setSelected] = useState<number>(0);
  const [refresh, setRefresh] = useState<number>(0);
  const [open, setOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date>();
  const [showTable, setShowTable] = useState<boolean>(true);
  //Schedule Patients
  const [openEnrollModal, setOpenEnrollModal] = useState(false);

  const [openSmsModal, setOpenSmsModal] = useState(false);

  const [openLegendModal, setOpenLegendModal] = useState<boolean>(false);
  const [openOutOfOfficeModal, setOpenOutOfOfficeModal] = useState<boolean>(false);
  const [openHolidayEventModal, setOpenHolidayEventModal] = useState<boolean>(false);

  const handleDateClick = (date: Date) => {
    setSelectedDate(date);
  };

  const refreshTable = () => {
    setRefresh((prev: number) => prev + 1);
    tableRef.current?.refreshTable();
    serviceViewRef.current?.getAppointments();
  };

  const tabs: TabProps[] = [
    {
      label: 'Calendar View',
      icon: <CalendarTodayOutlinedIcon />,
      content: (
        <CustomContainer>
          <Calendar refresh={refresh} handleDateSelected={handleDateClick} />
        </CustomContainer>
      ),
      hidden: true,
    },
    {
      label: 'Provider View',
      content: (
        <CustomContainer>
          <ServiceProviderCalendar ref={serviceViewRef} scheduleViewRef={tableRef} />
        </CustomContainer>
      ),
      icon: <PermContactCalendarOutlinedIcon />,
      // hidden: user?.user_group.group_name !== 'COMPANY_ADMIN',
    },
    {
      label: 'Schedule View',
      content: (
        <CustomContainer>
          {showTable && <BookingsForConfirmation onUpdate={() => refreshTable()} setShowTable={setShowTable} />}
          <AppointmentTable tableRef={tableRef} stackHeaderComponents={false} providerViewRef={serviceViewRef} />
        </CustomContainer>
      ),
      icon: <EventNoteOutlinedIcon />,
    },
  ];

  const handleOpenLegendModal = () => {
    setOpenLegendModal(true);
  };

  useEffect(() => {
    if (selectedDate) {
      // initialValues.schedule = selectedDate;
      setOpenEnrollModal(true);
    }
  }, [selectedDate]);

  const actions = [
    // {
    //   label: 'Schedule Appointment',
    //   action: schedulePatients,
    //   icon: <GroupAddOutlinedIcon />,
    //   hidden: !user?.authorities?.includes('MANAGE_APPOINTMENTS'),
    // },
    {
      label: 'Appointment Legend',
      action: handleOpenLegendModal,
      icon: <LegendToggleOutlinedIcon />,
      hidden: !user?.authorities?.includes('MANAGE_APPOINTMENTS'),
    },
    {
      label: 'SMS Schedules',
      action: () => {
        setOpenSmsModal(true);
      },
      icon: <SmsOutlinedIcon />,
      hidden: !user?.authorities?.includes('MANAGE_APPOINTMENTS'),
    },
    {
      label: 'Out of Office',
      action: () => {
        setOpenOutOfOfficeModal(true);
      },
      icon: <DomainDisabledOutlinedIcon />,
      hidden: !user?.authorities?.includes('MANAGE_APPOINTMENTS'),
    },
    {
      label: 'Holiday',
      action: () => {
        setOpenHolidayEventModal(true);
      },
      icon: <DateRangeOutlinedIcon />,
      hidden: !user?.authorities?.includes('MANAGE_APPOINTMENTS'),
    },
  ];

  useEffect(() => {
    setRefresh((refresh) => refresh + 1);
  }, [selected]);

  useEffect(() => {
    setBreadcrumb([{ label: 'Appointments' }]);
  }, [facility]);

  // useEffect(() => {
  //   if (facility) {
  //     getFormFields(PROGRAM_CODES.APPOINTMENTS, setFormFields, setSchema, facility, undefined, appointments);
  //     getFormFieldsValues(PROGRAM_CODES.APPOINTMENTS, setInitialValues);
  //   }
  // }, [facility, appointments]);

  return (
    <Box>
      <CareGoPage
        header="Appointments"
        showActionButton={!facility?.is_admin && user?.authorities?.includes('MANAGE_APPOINTMENTS')}
        actionButtonLabel="Appointment Settings"
        actionButtonIcon={<SettingsOutlinedIcon />}
        dropdownActions={actions}
        regularButtonStyle={{ width: '200px' }}
      >
        <CustomTabs tabs={tabs} selected={selected} setSelected={setSelected} tabMinWidth="250px" />
        {/* </ContainerColumn> */}

        <CustomModal
          open={open}
          setOpen={setOpen}
          width={400}
          header="Service Required"
          headerIcon={<ReportProblemOutlinedIcon />}
        >
          <Box sx={{ textAlign: 'center' }}>
            <Typography>
              Sorry, you don't have services added in the clinic.
              <br />
              Please add services in the Clinic Management tab.
            </Typography>
            <RegularButton
              onClick={() =>
                setTourState((prev: any) => {
                  return {
                    ...prev,
                    steps: SERVICE_ADD_STEPS,
                    tourId: TOUR_ID.SERVICE_ADD_STEPS,
                    run: true,
                    tourActive: true,
                    stepIndex: 0,
                  };
                })
              }
              label="How to add Service?"
              styles={{ marginTop: 2 }}
              startIcon={<LiveHelpOutlinedIcon />}
            />
          </Box>
        </CustomModal>
      </CareGoPage>

      <>
        <CustomModal
          header="Schedule Patients"
          // subHeader={selectedDate ? formatDateTimeWithDay(selectedDate) : ''}
          open={openEnrollModal}
          setOpen={setOpenEnrollModal}
        >
          <AppointmentForm
            facility={facility}
            schedule={selectedDate}
            callbackAfterSubmit={() => {
              setOpenEnrollModal(false);
              refreshTable();
            }}
          />
        </CustomModal>

        <CustomModal setOpen={setOpenLegendModal} open={openLegendModal} header={'Appointment Legends'} width={700}>
          <AppointmentLegendTable callBackAfterSubmitFromAppointment={() => refreshTable()} />
        </CustomModal>

        {/** Out of Office MODAL*/}
        <CustomModal
          width={'65%'}
          setOpen={setOpenOutOfOfficeModal}
          open={openOutOfOfficeModal}
          header="Create Calendar Event"
        >
          {/* <CalendarEventForm
            outOfOffice
            callbackAfterSubmit={() => {
              setOpenOutOfOfficeModal(false);
              refreshTable();
            }}
          /> */}
          <CalendarEventTableOOO callBackAfterSubmit={() => refreshTable()} />
        </CustomModal>

        {/** Holiday Event Modal */}
        <CustomModal
          width={'60%'}
          setOpen={setOpenHolidayEventModal}
          open={openHolidayEventModal}
          header="Create Holiday Event"
        >
          {/* <CalendarEventForm
            callbackAfterSubmit={() => {
              setOpenHolidayEventModal(false);
              refreshTable();
            }}
          /> */}
          <CalendarEventHolidayTable callBackAfterSubmit={() => refreshTable()} />
        </CustomModal>

        <SmsScheduleModal
          open={openSmsModal}
          setOpen={setOpenSmsModal}
          programCode="appointments"
          fromAppointment
          passActualPatientID
        />
      </>
    </Box>
  );
};

export default AppointmentsTabs;
