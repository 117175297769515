import { CareGoPage } from 'core/PageBuilder';
import React from 'react';
import ProductUOMTable from './ProductUOMTable';
import { Box } from '@mui/material';

export default function ProductUOM() {
  return (
    <Box sx={{ ml: '30px' }}>
      <CareGoPage header="Product Unit of Measurements">
        <ProductUOMTable />
      </CareGoPage>
    </Box>
  );
}
