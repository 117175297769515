import {
  CE_ENTITY_NAME,
  CalendarEventModel,
  OutOfOfficeModel,
  createCalendarEventSchema,
  holidayEventFields,
  holidayEventFormFields,
  holidayEventInitialValues,
  outOfOfficeFields,
  outOfOfficeFormFields,
  outOfOfficeInitialValues,
  updateOutOfOfficeAllDayFormFields,
  updateOutOfOfficeFields,
  updateOutOfOfficeFieldsAllDay,
  updateOutOfOfficeFormFields,
  createHolidaySchema,
  HolidayEventModel,
} from './CalendarEventModel';
import {
  createCalendarEvent,
  createHolidayEvent,
  createOutOfOfficeEvent,
  updateHolidayEvent,
  updateOutOfOfficeEvent,
} from 'company/api/calendar-events';

import { FacilityContext } from 'core/context/facility.context';
import TemplateForm from 'company/entities/Template/TemplateForm';
import { useContext } from 'react';
import { ServiceProviderModel } from '../ServiceProvider/ServiceProviderModel';

type Props = {
  readonly?: boolean;
  calendarEvent?: CalendarEventModel;
  showSubmitAtTop?: boolean;
  callbackAfterSubmit?: (data: any) => void;
  outOfOffice?: boolean;
  updateOutOfOffice?: boolean;
  outOfOfficeEvent?: OutOfOfficeModel;
  holidayEvent?: HolidayEventModel;
  serviceProvider?: ServiceProviderModel;
};

const CalendarEventForm = (props: Props) => {
  const {
    readonly,
    calendarEvent,
    showSubmitAtTop,
    callbackAfterSubmit,
    outOfOffice,
    updateOutOfOffice,
    outOfOfficeEvent,
    holidayEvent,
    serviceProvider,
  } = props;
  const { facility } = useContext(FacilityContext);

  async function handleSubmit(data: any) {
    if (outOfOffice && !updateOutOfOffice) return createOutOfOfficeEvent(facility.id, data);
    if (outOfOffice && updateOutOfOffice && !!outOfOfficeEvent)
      return updateOutOfOfficeEvent(facility.id, outOfOfficeEvent.id, data);
    if (!!holidayEvent) return updateHolidayEvent(facility.id, holidayEvent.id, data);
    return createHolidayEvent(facility.id, data);
  }

  //   const processInitialValues = (setFieldData: React.Dispatch<any>, values: any) => {
  //     setFieldData((prev: any) => {
  //       if(values.all_day) return {...prev, start_date: new Date()};
  //     });
  //   };

  function determineField() {
    if (outOfOffice && !updateOutOfOffice) {
      return outOfOfficeFields;

      // return outOfOfficeFields.map((fields) => {
      //   if (fields.fieldName === 'service_provider_id') return { ...fields, multiple: false };
      //   return fields;
      // });

      return { ...outOfOfficeFields };
    } else if (outOfOffice && updateOutOfOffice) {
      return outOfOfficeEvent?.all_day ? updateOutOfOfficeFieldsAllDay : updateOutOfOfficeFields;
    } else {
      return holidayEventFields;
    }
  }

  function determineVisibleFields() {
    if (outOfOffice && !updateOutOfOffice) {
      return outOfOfficeFormFields;
    } else if (outOfOffice && updateOutOfOffice) {
      return outOfOfficeEvent?.all_day ? updateOutOfOfficeAllDayFormFields : updateOutOfOfficeFormFields;
    } else return holidayEventFormFields;
  }

  function determineInitialValues() {
    if (outOfOffice && !updateOutOfOffice) {
      if (!serviceProvider) return outOfOfficeInitialValues;
      return {
        ...outOfOfficeInitialValues,
        service_provider_id: [{ key: serviceProvider.full_name, value: serviceProvider.doctor_id }],
      };
    } else if (outOfOffice && updateOutOfOffice && !!outOfOfficeEvent) {
      if (outOfOfficeEvent?.all_day) {
        outOfOfficeEvent.start_date_2 = outOfOfficeEvent.start_date;
        outOfOfficeEvent.end_date_2 = outOfOfficeEvent.end_date;
      }
      outOfOfficeEvent.event_description = outOfOfficeEvent.event_description ?? '';
      return outOfOfficeEvent;
    } else if (!!holidayEvent) return holidayEvent;

    return holidayEventInitialValues;
  }
  return (
    <TemplateForm
      entity={outOfOfficeEvent}
      schema={outOfOffice ? createCalendarEventSchema : createHolidaySchema}
      callbackAfterSubmit={callbackAfterSubmit}
      entityName={CE_ENTITY_NAME}
      fields={determineField()}
      initialValues={determineInitialValues()}
      visibleFields={determineVisibleFields()}
      formSubmitApiFunction={handleSubmit}
      //   processInitialValues={processInitialValues}
    />
  );
};

export default CalendarEventForm;
