/* eslint-disable react-hooks/exhaustive-deps */

import CustomTabs, { TabProps } from 'core/components/CustomTabs';
import { useContext, useState } from 'react';

import { CareGoPage } from 'core/PageBuilder';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import FormatPaintOutlinedIcon from '@mui/icons-material/FormatPaintOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import ProductPage from './ProductPage';
import SupplierPage from './SupplierPage';
import { UserContext } from 'core/context/user.context';
import VariantAttributePage from 'company/screens/ClinicManagement/components/InventorySettings/VariantAttributePage';

const InventoryPage = () => {
  const { user } = useContext(UserContext);

  const [selected, setSelected] = useState<number>(0);

  const tabs: TabProps[] = [
    {
      label: 'Products',
      icon: <CategoryOutlinedIcon />,
      content: <ProductPage />,
    },
    // {
    //   label: 'Attributes',
    //   icon: <FormatPaintOutlinedIcon />,
    //   content: <VariantAttributePage />,
    // },
    {
      label: 'Suppliers',
      icon: <LocalShippingOutlinedIcon />,
      content: <SupplierPage />,
    },
  ];

  return (
    <>
      <CareGoPage
        loading={!user}
        header="Product Directory"
        breadcrumb={[{ label: 'Clinic Inventory', link: '/company/inventory' }, { label: 'Product Directory' }]}
      >
        <CustomTabs tabs={tabs} selected={selected} setSelected={setSelected} />
      </CareGoPage>
    </>
  );
};

export default InventoryPage;
