/* eslint-disable react-hooks/exhaustive-deps */

import { Box, IconButton, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ClearIcon from '@mui/icons-material/Clear';
import CustomSelectField from './CustomSelectField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { tokens } from 'theme/theme';

export type DateRangeValue = [string | undefined, string | undefined];

type DateRangeOption =
  | 'today'
  | 'week'
  | 'last-week'
  | 'month'
  | 'last-month'
  | 'quarter'
  | 'last-quarter'
  | 'year'
  | 'last-year'
  | 'semiannual';

type CustomDateRangeFilterProps = {
  setFilterDateRange: Dispatch<SetStateAction<DateRangeValue>>;
  hideQuickFilter?: boolean;
  quickFilter?: DateRangeOption;
};

const CustomDateRangeFilter: React.FC<CustomDateRangeFilterProps> = ({ setFilterDateRange, hideQuickFilter, quickFilter: externalQuickFilter }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  console.log('External Quick Filter Received:', externalQuickFilter);
  console.log('Type of Quick Filter:', typeof externalQuickFilter);
  const [quickFilter, setQuickFilter] = useState<string>(externalQuickFilter || 'month');
  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [endDate, setEndDate] = useState<Dayjs | null>(null);

  const isMobile = useMediaQuery('(max-width:768px)');

  useEffect(() => {
    setQuickFilter(externalQuickFilter || 'month');
  }, []);

  useEffect(() => {
    const start_date = startDate?.format('YYYY-MM-DD');
    const end_date = endDate?.format('YYYY-MM-DD');
    setFilterDateRange([start_date, end_date]);
  }, [startDate, endDate]);

  useEffect(() => {
    if (quickFilter && !hideQuickFilter) {
      const today = new Date();
      let localStartDate, localEndDate;
      switch (quickFilter) {
        case 'today':
          localStartDate = new Date(today);
          localEndDate = new Date(today);
          break;

        case 'week':
          localStartDate = new Date(today);
          localStartDate.setDate(today.getDate() - today.getDay());
          localEndDate = new Date(today);
          localEndDate.setDate(localStartDate.getDate() + 6);
          break;
        case 'last-week':
          localStartDate = new Date(today);
          localStartDate.setDate(today.getDate() - today.getDay() - 7);
          localEndDate = new Date(today);
          localEndDate.setDate(localStartDate.getDate() + 6);
          break;

        case 'month':
          localStartDate = new Date(today.getFullYear(), today.getMonth(), 1);
          localEndDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);
          break;
        case 'last-month':
          localStartDate = new Date(today.getFullYear(), today.getMonth() - 1, 1);
          localEndDate = new Date(today.getFullYear(), today.getMonth(), 0);
          break;

        case 'quarter':
          const quarter = Math.floor(today.getMonth() / 3);
          localStartDate = new Date(today.getFullYear(), quarter * 3, 1);
          localEndDate = new Date(today.getFullYear(), quarter * 3 + 3, 0);
          break;
        case 'last-quarter':
          const lastQuarter = Math.floor((today.getMonth() - 3) / 3);
          localStartDate = new Date(today.getFullYear(), lastQuarter * 3, 1);
          localEndDate = new Date(today.getFullYear(), lastQuarter * 3 + 3, 0);
          break;

        case 'year':
          localStartDate = new Date(today.getFullYear(), 0, 1);
          localEndDate = new Date(today.getFullYear(), 11, 31);
          break;
        case 'last-year':
          localStartDate = new Date(today.getFullYear() - 1, 0, 1);
          localEndDate = new Date(today.getFullYear() - 1, 11, 31);
          break;
        
          case 'semiannual':
            // First half of the year (January 1 to June 30)
            if (today.getMonth() < 6) {
              localStartDate = new Date(today.getFullYear(), 0, 1);     // January 1
              localEndDate = new Date(today.getFullYear(), 5, 30);      // June 30
            } 
            // Second half of the year (July 1 to December 31)
            else {
              localStartDate = new Date(today.getFullYear(), 6, 1);     // July 1
              localEndDate = new Date(today.getFullYear(), 11, 31);     // December 31
            }
            break;
          case 'last-semiannual':
            // If currently in first half, go to previous year's second half
            if (today.getMonth() < 6) {
              localStartDate = new Date(today.getFullYear() - 1, 6, 1);   // July 1 of previous year
              localEndDate = new Date(today.getFullYear() - 1, 11, 31);   // December 31 of previous year
            } 
            // If currently in second half, go to first half of current year
            else {
              localStartDate = new Date(today.getFullYear(), 0, 1);       // January 1
              localEndDate = new Date(today.getFullYear(), 5, 30);        // June 30
            }
            break;
      }
      setStartDate(dayjs(localStartDate));
      setEndDate(dayjs(localEndDate));
    } else {
      clearFilter();
    }
  }, [quickFilter]);

  const clearFilter = () => {
    setQuickFilter('');
    setStartDate(null);
    setEndDate(null);
  };

  const datePickerSx = {
    '.MuiFormLabel-root.Mui-focused': { color: colors.light },
    '.MuiFilledInput-root': { borderRadius: 2, borderBottom: 'none' },
    '.MuiFilledInput-root:hover:not(.Mui-disabled, .Mui-error):before': { border: 'none' },
    '.MuiFilledInput-root:before': { border: 'none' },
    '.Mui-disabled:before': { border: 'none !important' },
    '.MuiFilledInput-root:after': {
      borderBottom: `1px solid ${colors.primary}`,
      borderRadius: 2,
      height: '100%',
    },
    '.MuiFilledInput-input': { height: '17px' },
    '& .MuiFormHelperText-root': { position: 'absolute', bottom: '-18px' },
    width: isMobile ? '100%' : 'auto',
  };

  return (
    <Box 
      display="flex" 
      flexDirection={isMobile ? 'column' : 'row'} 
      gap={"10px"} 
      alignItems={isMobile ? 'flex' : 'center'}
    >
      {!hideQuickFilter && (
        <Box sx={{ width: isMobile ? '100%' : '200px' }}>
          <CustomSelectField
            value={quickFilter}
            options={[
              { key: 'Today', value: 'today' },
              { key: 'This Week', value: 'week' },
              { key: 'This Month', value: 'month' },
              { key: 'This Quarter', value: 'quarter' },
              { key: 'This Half-Year', value: 'semiannual' },
              { key: 'This Year', value: 'year' },
              { key: 'Last Week', value: 'last-week' },
              { key: 'Last Month', value: 'last-month' },
              { key: 'Last Quarter', value: 'last-quarter' },
              { key: 'Last Half-Year', value: 'last-semiannual' },
              { key: 'Last Year', value: 'last-year' },
            ]}
            label={'Quick Filter'}
            fieldName={'filter'}
            handleChangeCallback={(value) => setQuickFilter(value)}
          />
        </Box>
      )}
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Box display="flex" flexDirection={'row'} gap={"10px"} alignItems={'center'}>
          <DatePicker
            label="Start Date"
            value={startDate}
            onChange={(newValue) => setStartDate(newValue)}
            maxDate={endDate}
            slotProps={{
              textField: {
                variant: 'filled',
                size: 'small',
              },
            }}
            sx={datePickerSx}
          />
          {<Typography variant="h5"> - </Typography>}
          <DatePicker
            label="End Date"
            value={endDate}
            onChange={(newValue) => setEndDate(newValue)}
            minDate={startDate}
            slotProps={{
              textField: {
                variant: 'filled',
                size: 'small',
              },
            }}
            sx={datePickerSx}
          />
          <Tooltip title={'Clear Filter'}>
            <IconButton onClick={() => clearFilter()} sx={isMobile ? { alignSelf: 'flex-end' } : {}}>
              <ClearIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </LocalizationProvider>
    </Box>
  );
};

export default CustomDateRangeFilter;
