import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { ConfirmationDialog, CustomModal, HideOrShowComponent, RegularButton, RowAction } from 'core/components';
import { deletePrescription, duplicatePrescription } from 'company/api/prescriptions';
import { forwardRef, useCallback, useContext, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import AppointmentTimeline from '../../../../RegistrationBoard/components/AppointmentTimeline';
import { ContainerColumn } from 'core/components/containers';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import DocumentEditor from 'company/screens/DocumentEditor';
import { DropdownOptionProps } from 'core/components/Dropdown';
import EditIcon from '@mui/icons-material/Edit';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { FORM_MODE } from 'core/model/interface';
import { FacilityContext } from 'core/context/facility.context';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import LabRequestForm from 'company/screens/DocumentEditor/components/LabRequestForm';
import { OrderProps } from 'company/screens/InventorySystem/POS';
import { POS } from 'company/screens/InventorySystem';
import { PatientModel } from 'company/entities/modules/ClinicManagement/Patient/PatientModel';
import PrescriptionContainer from 'company/entities/modules/ClinicManagement/Prescription/PrescriptionContainer';
import { SIZES } from 'theme/constants';
import SearchBar from 'company/screens/InventorySystem/POS/components/SearchBar';
import { deleteNote } from 'company/api/patient-notes';
import { deletePatientLabRequest } from 'company/api/lab-request';
import { isDevMode } from 'core/components/HideOrShowComponent';
import { tokens } from 'theme/theme';

interface Props {
  patient: PatientModel;
  appointmentId?: number;
  packagePatientId?: number;
  packageStageId?: number;
  patientNote?: any;
  patientPrescription?: any;
  runningInvoice?: any;
  labRequest?: any;
  mode?: FORM_MODE;
}

const PatientNotesContainer = forwardRef((props: Props, ref) => {
  useImperativeHandle(ref, () => ({ refreshTimeline }));
  const {
    patient,
    appointmentId,
    packagePatientId,
    packageStageId,
    patientNote,
    patientPrescription,
    runningInvoice,
    labRequest,
    mode = FORM_MODE.VIEW,
  } = props;

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();

  const [selectedPatientNote, setSelectedPatientNote] = useState<any>();
  const [selectedPrescription, setSelectedPrescription] = useState<any>();
  const [selectedLabRequest, setSelectedLabRequest] = useState<any>();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openDuplicateDialog, setOpenDuplicateDialog] = useState(false);
  const [openNoteDeleteDialog, setOpenNoteDeleteDialog] = useState(false);
  const [openLabReqDeleteDialog, setOpenLabReqDeleteDialog] = useState<boolean>(false);
  const [openPOSModal, setOpenPOSModal] = useState<boolean>(false);
  const [openMobileViewContent, setOpenMobileViewContent] = useState<boolean>(false);

  const { facility } = useContext(FacilityContext);
  const timelineRef: any = useRef();
  const isMobilePhone = useMediaQuery('(max-width:768px)');

  const [refreshTrigger, setRefreshTrigger] = useState(0);
  const location = useLocation();

  const [isFullScreen, setIsFullScreen] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  const [formMode, setFormMode] = useState(mode);

  const hasSelectedContent = selectedPatientNote || selectedLabRequest || selectedPrescription;
  const [noDataFound, setNoDataFound] = useState(false);

  const handleAfterPayment = (orders: OrderProps[], invoiceId: number) => {
    refreshTimeline();
  };

  const scrollToTop = useCallback(() => {
    if (contentRef.current) {
      contentRef.current.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }, []);

  useEffect(() => {
    scrollToTop();
  }, [selectedPatientNote, selectedPrescription, selectedLabRequest, scrollToTop]);

  const toggleFullScreen = useCallback(() => {
    setIsFullScreen(!isFullScreen);
  }, [isFullScreen]);

  const fetchLatestData = useCallback(() => {
    if (patientNote) {
      setSelectedPatientNote(patientNote);
    } else {
      setSelectedPatientNote(undefined);
    }

    if (patientPrescription) {
      setSelectedPrescription(patientPrescription);
    } else {
      setSelectedPrescription(undefined);
    }

    if (labRequest) {
      setSelectedLabRequest(labRequest);
    } else {
      setSelectedLabRequest(undefined);
    }
  }, [patientNote, patientPrescription, labRequest]);

  useEffect(() => {
    fetchLatestData();
  }, [fetchLatestData, refreshTrigger, patient]);

  // Function to trigger a refresh
  const refreshData = () => {
    setRefreshTrigger((prev) => prev + 1);
  };

  useEffect(() => {
    refreshData();
  }, [location]);

  const refreshTimeline = () => {
    timelineRef.current.getAllAppointments();
  };

  const handleDuplicate = () => {
    if (selectedPrescription && facility && facility.id) {
      duplicatePrescription(facility.id, selectedPrescription.id)
        .then((res) => {
          setSelectedPrescription(res.data.new_prescription);
          refreshTimeline();
        })
        .catch((error) => {
          console.error('Failed to duplicate prescription:', error);
        });
    }
    setOpenDuplicateDialog(false);
  };

  const handleDeleteLabRequest = () => {
    deletePatientLabRequest(facility.id, selectedLabRequest.id)
      .then((res) => {
        setSelectedLabRequest(undefined);
        refreshTimeline();
      })
      .catch((error) => {
        console.error('Failed to delete lab request:', error);
      })
      .finally(() => {
        setOpenLabReqDeleteDialog(false);
      });
  };

  const handleDelete = () => {
    if (selectedPrescription && facility && facility.id) {
      deletePrescription(facility.id, selectedPrescription.id)
        .then(() => {
          setSelectedPrescription(undefined);
          refreshTimeline();
        })
        .catch((error) => {
          console.error('Failed to delete prescription:', error);
        });
    }
    setOpenDeleteDialog(false);
  };

  const handleNoteDelete = () => {
    if (selectedPatientNote && facility && facility.id) {
      deleteNote(facility.id, selectedPatientNote.id)
        .then(() => {
          setSelectedPatientNote(undefined);
          refreshTimeline();
        })
        .catch((error) => {
          console.error('Failed to delete note:', error);
        });
    }
    setOpenNoteDeleteDialog(false);
  };

  const presActions: DropdownOptionProps[] =
    parseInt(selectedPrescription?.facility_id + '') === facility.id
      ? [
          {
            label: 'Duplicate',
            action: () => setOpenDuplicateDialog(true),
            icon: <FileCopyOutlinedIcon />,
          },
          {
            label: 'Delete',
            action: () => setOpenDeleteDialog(true),
            icon: <DeleteOutlinedIcon style={{ color: 'red' }} />,
            color: 'error',
          },
        ]
      : [];
  const noteActions: DropdownOptionProps[] =
    parseInt(selectedPatientNote?.facility_id + '') === facility.id
      ? [
          {
            label: 'Delete',
            action: () => setOpenNoteDeleteDialog(true),
            icon: <DeleteOutlinedIcon style={{ color: 'red' }} />,
            color: 'error',
          },
          {
            label: 'Modify Template',
            action: () => navigate(`/company/notes/Modify/${selectedPatientNote?.template_id}`),
            icon: <EditOutlinedIcon />,
            hidden: !selectedPatientNote?.template_id,
          },
        ]
      : [];

  const labActions: DropdownOptionProps[] =
    parseInt(selectedLabRequest?.facility_id + '') === facility.id
      ? [
          {
            label: 'Delete',
            action: () => setOpenLabReqDeleteDialog(true),
            icon: <DeleteOutlinedIcon style={{ color: 'red' }} />,
            color: 'error',
          },
          {
            label: 'Modify Template',
            action: () => navigate(`/company/clinic-management?settings=6&tab=1`),
            icon: <EditIcon />,
          },
        ]
      : [];

  const disabledRunningInvoiceBtn = !(runningInvoice && runningInvoice.length >= 1);

  const getModalHeader = useCallback(() => {
    return selectedPatientNote ? selectedPatientNote.header : selectedPrescription ? 'Prescription' : 'Lab Request';
  }, [selectedPatientNote, selectedPrescription]);

  const getRowActions = useCallback(() => {
    return selectedPatientNote ? noteActions : selectedPrescription ? presActions : labActions;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPatientNote, selectedPrescription]);

  const PatientNotesContent = useMemo(() => {
    return (
      <ContainerColumn
        gap="0"
        sx={{
          pt: 0,
          pb: SIZES.paddingL,
          height: isMobilePhone ? '100vh' : 'auto',
          overflow: 'auto',
          position: 'relative',
        }}
      >
        <Box
          sx={{
            position: 'sticky',
            zIndex: 2,
            width: '100%',
            top: 0,
            pb: '5px',
            backgroundColor: colors.background,
            '&::-webkit-scrollbar': { width: '5px' },
            paddingRight: '10px',
          }}
        >
          <Box width="100%">
            <Box display="flex" justifyContent="space-between" width="100%" alignItems="center">
              <Typography fontWeight="600" variant="h4">
                {getModalHeader()}
              </Typography>
              <Box display="flex" justifyContent="flex-end">
                {(selectedPatientNote || selectedLabRequest) && !isMobilePhone && (
                  <Box display="flex" onClick={toggleFullScreen} justifyContent="right">
                    <RegularButton
                      variant="text"
                      size="small"
                      onClick={toggleFullScreen}
                      label={`${isFullScreen ? 'Exit' : 'Enter'} Full Screen Mode`}
                      startIcon={isFullScreen ? <FullscreenIcon /> : <FullscreenExitIcon />}
                    />
                  </Box>
                )}
                {getRowActions().length > 0 && <RowAction actions={getRowActions()} />}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box position="relative">
          {selectedPatientNote && (
            <DocumentEditor
              // key={`${patient?.patient_id}-${selectedPatientNote.id}`} to avoid remounting when toggling
              source={'patient'}
              template_id={selectedPatientNote.id}
              patient_id={patient?.patient_id}
              prescription_id={selectedPatientNote?.prescription_id}
              fromPatientRecords
              refreshTimeline={refreshTimeline}
              formMode={formMode}
            />
          )}
        </Box>

        {selectedPrescription && <PrescriptionContainer patient={patient} prescription={selectedPrescription} />}

        {selectedLabRequest && (
          <Box sx={{ width: '100%' }}>
            <LabRequestForm
              patient={patient}
              patientLabRequest={selectedLabRequest}
              fields={JSON.parse(selectedLabRequest.body)}
              responses={{}}
              initialMode={formMode}
            />
          </Box>
        )}
      </ContainerColumn>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formMode, isFullScreen, patient, selectedLabRequest, selectedPatientNote, selectedPrescription]);

  return (
    <>
      <Box
        display="grid"
        // gridTemplateColumns={isMobilePhone ? '1fr' : isFullScreen ? '0 1fr' : '300px 1fr'}
        gridTemplateColumns={isMobilePhone ? '1fr' : isFullScreen ? 'auto' : '300px 1fr'}
        gap="20px"
        alignItems="flex-start"
        position="relative"
      >
        <ContainerColumn
          className="hide-scrollbar"
          gap="0"
          sx={{
            backgroundColor: colors.light_blue_background,
            borderRadius: SIZES.paddingS,
            // height: isMobilePhone ? '100vh' : 'calc(100vh - 210px)',
            // height: isMobilePhone ? '100vh' : 'auto',
            overflow: 'auto',
            position: isMobilePhone ? undefined : 'sticky',
            top: isMobilePhone ? undefined : 130,
            display: isFullScreen ? 'none' : 'flex',
          }}
        >
          <Box
            padding={SIZES.padding}
            pb={SIZES.paddingS}
            sx={{
              position: 'sticky',
              top: 0,
              backgroundColor: colors.light_blue_background,
              zIndex: 1,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              gap: '10px',
            }}
          >
            <HideOrShowComponent hidden={!isDevMode()}>
              <SearchBar handleSearch={(key) => undefined} backgroundColor={colors.secondary_background} fullWidth />
              <RegularButton
                onClick={() => setOpenPOSModal(true)}
                fullWidth
                label={disabledRunningInvoiceBtn ? 'No active running invoice' : 'Running Invoice'}
                disabled={disabledRunningInvoiceBtn}
              />
            </HideOrShowComponent>
          </Box>
          <Box sx={{ paddingX: SIZES.padding, pb: SIZES.paddingL }}>
            {patient && (
              <AppointmentTimeline
                key={patient.patient_id}
                ref={timelineRef}
                appointmentId={appointmentId}
                packagePatientId={packagePatientId}
                packageStageId={packageStageId}
                showNoDataFoundMsg
                patientId={patient.patient_id}
                selectedItemId={
                  selectedPatientNote
                    ? selectedPatientNote.id
                    : selectedPrescription
                    ? selectedPrescription.id
                    : selectedLabRequest?.id
                }
                selectedItemType={selectedPatientNote ? 'note' : selectedPrescription ? 'prescription' : 'lab_request'}
                onUpdateSelected={(data, type, onClick, newlyCreated) => {
                  setSelectedPatientNote(undefined);
                  setSelectedPrescription(undefined);
                  setSelectedLabRequest(undefined);
                  setFormMode(FORM_MODE.VIEW);
                  type === 'note'
                    ? setSelectedPatientNote(data)
                    : type === 'prescription'
                    ? setSelectedPrescription(data)
                    : setSelectedLabRequest(data);

                  if (onClick && isMobilePhone) setOpenMobileViewContent(true);

                  if (newlyCreated) setFormMode(FORM_MODE.RESPOND);
                }}
                setNoDataFound={setNoDataFound}
              />
            )}
          </Box>
        </ContainerColumn>

        {isMobilePhone ? (
          <CustomModal open={openMobileViewContent} setOpen={setOpenMobileViewContent} header={getModalHeader()}>
            {PatientNotesContent}
          </CustomModal>
        ) : hasSelectedContent ? (
          // THIS IS THE PATIENT CONTENT MOVED HERE TO AVOID RELOADING WHEN RE-RENDERING
          PatientNotesContent
        ) : (
          <Typography variant="subtitle1"> Nothing selected yet. </Typography>
        )}

        <CustomModal open={openPOSModal} setOpen={setOpenPOSModal} width={'90%'} header={'Running Invoice'}>
          <POS fromRunningInvoice afterPayment={handleAfterPayment} />
        </CustomModal>
      </Box>
      <ConfirmationDialog
        title={'Confirmation'}
        open={openDeleteDialog}
        setOpen={setOpenDeleteDialog}
        content={'Are you sure you want to delete this prescription?'}
        onConfirm={handleDelete}
      />
      <ConfirmationDialog
        title={'Confirmation'}
        open={openDuplicateDialog}
        setOpen={setOpenDuplicateDialog}
        content={'Are you sure you want to duplicate this prescription?'}
        onConfirm={handleDuplicate}
      />
      <ConfirmationDialog
        title={'Confirmation'}
        open={openNoteDeleteDialog}
        setOpen={setOpenNoteDeleteDialog}
        content={'Are you sure you want to delete this note?'}
        onConfirm={handleNoteDelete}
      />
      <ConfirmationDialog
        title={'Confirmation'}
        open={openLabReqDeleteDialog}
        setOpen={setOpenLabReqDeleteDialog}
        content={'Are you sure you want to delete this lab request?'}
        onConfirm={handleDeleteLabRequest}
      />
    </>
  );
});

export default PatientNotesContainer;
