import { ApiQuery } from 'core/model/interface';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'product-uom';

export const getAllProductUOMs = (facility_id: number, query: ApiQuery) => {
    return axios.get(`${baseURL}/${path}/${facility_id}`, {params: query});
};

export const getProductUOM =  (facility_id: number, id: number) => {
    return axios.get(`${baseURL}/${path}/${facility_id}/${id}`)
}

export const createProductUOM = (facility_id: number, data: any) => {
    return axios.post(`${baseURL}/${path}/${facility_id}`, data);
}

export const updateProductUOM = (facility_id: number, id: number, data: any) => {
    return axios.patch(`${baseURL}/${path}/${facility_id}/${id}`, data)
}

export const deleteProductUOM = (facility_id: number, id: number) => {
    return axios.delete(`${baseURL}/${path}/${facility_id}/${id}`);
}