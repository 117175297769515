import { Box } from '@mui/material';
import ViewCategories from 'company/screens/InventorySystem/Inventory/ViewCategories';
import React, { useState } from 'react';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import CustomTabs, { TabProps } from 'core/components/CustomTabs';
import ClassOutlinedIcon from '@mui/icons-material/ClassOutlined';
import FormatPaintOutlinedIcon from '@mui/icons-material/FormatPaintOutlined';
import VariantAttributePage from 'company/screens/ClinicManagement/components/InventorySettings/VariantAttributePage';
export default function ProductSettings() {
  const [selected, setSelected] = useState<number>(0);

  const tabs: TabProps[] = [
    {
      label: 'Product Categories',
      content: <ViewCategories />,
      icon: <ClassOutlinedIcon />,
    },

    {
      label: 'Product Attributes',
      icon: <FormatPaintOutlinedIcon />,
      content: <VariantAttributePage />,
    },
  ];
  return (
    <Box ml="20px">
      <CustomTabs tabs={tabs} selected={selected} setSelected={setSelected} tabsButtonStyles={{ width: '60%' }} />
    </Box>
  );
}
