import React, { useEffect, useState } from 'react';

import { CustomForm } from 'core/components';
import { FieldInput } from 'core/model/interface';
import { getPrograms } from 'core/api/public';
import { health_programs_schema } from 'core/model/schema';

type HealthProgramsFormProps = {
  formData: {
    health_programs: any[];
  };
  setFormData: React.Dispatch<
    React.SetStateAction<{
      health_programs: any[];
    }>
  >;
  setIsFormValid: React.Dispatch<React.SetStateAction<boolean>>;
};

type UserInput = {
  health_programs: any[];
};

interface UserFieldInput extends FieldInput {
  field_name?: keyof UserInput;
}

const HealthProgramsForm: React.FC<HealthProgramsFormProps> = ({ formData, setFormData, setIsFormValid }) => {
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [companyHealthPrograms, setCompanyHealthPrograms] = useState<any>();

  const fields: UserFieldInput[] = [
    {
      field_name: 'health_programs',
      display_name: 'Health Programs',
      type: 'multiselect',
      span: 4,
      options: companyHealthPrograms
        ? companyHealthPrograms.map((u: any) => {
            return { key: u.program_name, value: u.id };
          })
        : [],
      onChange: () => {
        setIsSuccess(false);
        setIsFormValid(false);
      },
    },
  ];

  const handleSubmit = async (data: any) => {
    const health_programs = data.health_programs.map((item: any) => item.value);

    setFormData({
      health_programs: JSON.parse(JSON.stringify(health_programs)),
    });

    setIsFormValid(true);
    setIsSuccess(true);
  };

  const getHealthPrograms = () => {
    getPrograms()
      .then((res) => {
        setCompanyHealthPrograms(res.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getHealthPrograms();
  }, []);

  return (
    <CustomForm
      initialValues={formData}
      fields={fields}
      schema={health_programs_schema}
      loading={false}
      onSubmit={handleSubmit}
      submitButtonText="Done"
      buttonWidth="150px"
      background="linear-gradient(to right, #239bd7, #0463ae);"
      isSuccess={isSuccess}
      submitButtonMarginTop={2}
    />
  );
};

export default HealthProgramsForm;
