import { useTheme } from '@mui/material';
import { Box, Typography } from '@mui/material';
import { LineChart } from '@mui/x-charts/LineChart';
import { useEffect, useState } from 'react';

interface YDataFormat {
  company_id: number;
  action: string;
  count: number;
  feature: string;
  created_at: Date;
}

interface Props {
  title: string;
  legendHidden?: boolean;
  dataSummary?: {
    [key: string]: YDataFormat[];
  };
}

const AnalyticsLineChart: React.FC<Props> = ({ title, dataSummary, legendHidden }) => {
  const theme = useTheme();
  const [yData, setYData] = useState<number[]>([]);
  const [xLabels, setXLabels] = useState<string[]>([]);

  useEffect(() => {
    if (dataSummary) {
      const localXLabels: string[] = Object.keys(dataSummary);
      const localYData: number[] = [];

      // retrieves every date string key and store in xLabels
      // this in summary, retrieves the number of requests per day
      for (let i = 0; i < localXLabels.length; i++) {
        const dateLabel = localXLabels[i];
        const dataSum = dataSummary[dateLabel];
        const reduced = dataSum.map((value) => value.count).reduce((total, curr) => total + curr);
        localYData.push(reduced);
      }

      setXLabels(localXLabels);
      setYData(localYData);
    }
  }, [dataSummary]);

  return (
    <Box p={2} borderRadius={'10px'} border={`1px solid ${theme.palette.mode === 'dark' ? 'white' : 'black'}`}>
      <Box p={1}>
        <Typography align="center" variant="h4" gutterBottom>
          {title}
        </Typography>
        <LineChart
          height={300}
          width={legendHidden ? 940 : 840}
          xAxis={[{ data: xLabels, scaleType: 'point' }]}
          series={[
            {
              data: yData,
              label: 'Total Requests',
            },
          ]}
        />
      </Box>
    </Box>
  );
};

export default AnalyticsLineChart;
