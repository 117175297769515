import { Box, Typography } from '@mui/material';
import {
  PRODUCT_VARIANT_ENTITY_NAME,
  ProductVariantModel,
  createProductVariantModelSchema,
  productVariantFields,
  productVariantFormFields,
  productVariantFormFieldsExclusive,
  productVariantFormFieldsExclusiveWithProduct,
  productVariantInitialValues,
  productVariantUpdateFormFields,
} from './ProductVariantModel';
import { createVariant, updateVariant } from 'company/api/inventories';
import { useContext, useEffect, useState } from 'react';

import { ContainerColumn } from 'core/components/containers';
import { FacilityContext } from 'core/context/facility.context';
import { FacilityModel } from 'core/model/Entities';
import { ProductModel } from '../ProductModel';
import { SIZES } from 'theme/constants';
import TemplateForm from 'company/entities/Template/TemplateForm';
import { VariantAttributeModel } from '../../VariantAttribute/VariantAttributeModel';
import VariantAttributeValueSearchDropdown from 'company/components/dropdown/VariantAttributeValueSearchDropdown';
import { getVariantAttributes } from 'company/api/variant-attributes';

type Props = {
  product?: ProductModel;
  productVariant?: ProductVariantModel;
  supplierId?: number;
  callbackAfterSubmit?: (data?: any) => void;
  variantAttributes?: any[];
};

const ProductVariantForm: React.FC<Props> = (props) => {
  const { product, productVariant, supplierId, callbackAfterSubmit, variantAttributes } = props;
  const { facility } = useContext(FacilityContext);
  const [attributes, setAttributes] = useState<VariantAttributeModel[]>([]);
  const [attributeValues, setAttributeValues] = useState<any>({});

  const handleFormSubmit = (data: ProductVariantModel) => {
    const output: any = {};

    for (const key in attributeValues) {
      if (attributeValues.hasOwnProperty(key)) {
        output[key] = attributeValues[key] ? attributeValues[key].map((item: any) => item.value) : [];
      }
    }

    data.attribute_ids = JSON.stringify(output);
    data.variant_name = product?.product_name ? product?.product_name : 'Standard';
    return productVariant
      ? updateVariant(productVariant.id, data)
      : createVariant(facility.id, (product?.id ?? data.product_id ?? null) as number, {
          ...data,
          exclusive_supplier: supplierId ?? data.exclusive_supplier,
        });
  };

  useEffect(() => {
    getVariantAttributes({ length: 100 }).then((res) => {
      setAttributes(res.data.data);
    });
  }, []);

  return (
    <TemplateForm
      hideDefaultSnackbar
      submitButtonId={'create_product_variant_submit_button'}
      entity={productVariant}
      entityName={PRODUCT_VARIANT_ENTITY_NAME}
      fields={productVariantFields}
      visibleFields={
        productVariant
          ? productVariantUpdateFormFields
          : !!supplierId
          ? productVariantFormFieldsExclusive
          : product
          ? productVariantFormFields
          : productVariantFormFieldsExclusiveWithProduct
      }
      schema={createProductVariantModelSchema}
      initialValues={productVariantInitialValues}
      formSubmitApiFunction={handleFormSubmit}
      callbackAfterSubmit={callbackAfterSubmit}
      customFields={[
        {
          type: 'component',
          fieldName: 'attributes',
          component: (
            <Box>
              {!!attributes.length && (
                <ContainerColumn gap="25px" sx={{ mt: SIZES.paddingL }}>
                  <Typography variant="h5" fontWeight="bold">
                    Attributes
                  </Typography>
                  <Box display="grid" gridTemplateColumns="1fr 1fr" gap="25px">
                    {attributes.map((attribute) => (
                      <VariantAttributeValueSearchDropdown
                        key={attribute.id}
                        attribute_id={attribute.id}
                        multiple
                        limit={1}
                        initialValue={
                          variantAttributes
                            ? variantAttributes[attribute.id]?.map((value: any) => ({
                                key: value.value,
                                value: value.value_id,
                              }))
                            : []
                        }
                        handleChangeCallback={(value) =>
                          setAttributeValues((prev: any) => {
                            prev[attribute.id] = value;
                            return { ...prev };
                          })
                        }
                      />
                    ))}
                  </Box>
                </ContainerColumn>
              )}
            </Box>
          ),
        },
      ]}
    />
  );
};

export default ProductVariantForm;
