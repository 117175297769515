import { Box, Skeleton, Typography, useMediaQuery, useTheme } from '@mui/material';
import { formatCurrency, rotateArray, shuffle } from 'core/utils';
import { useEffect, useMemo, useState } from 'react';

import { LineChart } from '@mui/x-charts/LineChart';
import { LineSeriesType } from '@mui/x-charts/models';
import { chart_colors } from 'company/screens/PatientRecords/components/ChartEditor';
import { tokens } from 'theme/theme';

interface Props {
  processData: (
    setChartDataKeys: (key: string) => void,
    setDataSet: (dataSet: any[]) => void,
    setChartDataSeries: (values: LineSeriesType[]) => void
  ) => void;
  title: string;
  xAxisLabel: string;
  yAxisLabel: string;
}

const DashboardLineChart: React.FC<Props> = ({ title, xAxisLabel, yAxisLabel, processData }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [chartDataKey, setChartDataKey] = useState<string>('');
  const [chartDataSet, setDataSet] = useState<any[]>([]);
  const [chartDataSeries, setChartDataSeries] = useState<LineSeriesType[]>();
  const isMobilePhone = useMediaQuery('(max-width:768px)');

  const chartColors = useMemo(() => shuffle(chart_colors), []);

  useEffect(() => {
    setTimeout(() => {
      processData(setChartDataKey, setDataSet, setChartDataSeries);
    }, 2000);
  }, [processData]);

  const total = useMemo(() => {
    if (!chartDataSet || !chartDataSeries) return 0;

    let grandTotal = 0;

    for (const dataPoint of chartDataSet) {
      let pointTotal = 0;

      for (const series of chartDataSeries) {
        const value = dataPoint[series.dataKey as string] || 0;
        pointTotal += value;
      }

      grandTotal += pointTotal;
    }

    return grandTotal;
  }, [chartDataSet, chartDataSeries]);

  if (!chartDataSeries) {
    return (
      <Skeleton
        variant="rounded"
        width={'100%'}
        height={'400px'}
        sx={{ gridColumn: isMobilePhone ? 'span 12' : 'span 6', borderRadius: '8px' }}
        animation="wave"
      />
    );
  }

  return (
    <Box
      sx={{
        gridColumn: isMobilePhone ? 'span 12' : 'span 6',
        border: '1px solid ' + colors.border,
        borderRadius: '8px',
        minHeight: '400px',
        padding: '12px 24px',
        '& .MuiChartsAxis-directionY .MuiChartsAxis-label': {
          transform: 'translateX(-25px) !important',
        },
        '& .MuiChartsAxis-directionX .MuiChartsAxis-label': {
          transform: 'translateY(15px) !important',
        },
      }}
    >
      <Box>
        <Box
          sx={{
            border: 'none',
            borderBottom: '1px solid #CCCCCC',
            padding: '12px',
            display: 'flex',
            justifyContent: 'space-between',
            // marginBottom: '30px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              columnGap: '12px',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <Typography variant="h3" fontWeight="700">
              {title}
            </Typography>
            <Typography variant="h4" fontWeight="600">
              Total: {formatCurrency(total.toString(), 2)}
            </Typography>
          </Box>
        </Box>

        <LineChart
          colors={chartColors}
          grid={{ horizontal: true }}
          dataset={chartDataSet}
          series={chartDataSeries ?? []}
          height={310}
          xAxis={[
            {
              id: '1',
              dataKey: chartDataKey,
              label: xAxisLabel,
              labelStyle: { fontWeight: 'bold', fontSize: '16px' },
              scaleType: 'band',
              tickPlacement: 'middle',
              tickLabelPlacement: 'tick',
            },
          ]}
          yAxis={[
            {
              label: yAxisLabel,
              labelStyle: { fontWeight: 'bold', fontSize: '15px' },
              tickMinStep: 1,
            },
          ]}
          margin={{ bottom: 60, left: 70, right: 20 }}
        />
      </Box>
    </Box>
  );
};

export default DashboardLineChart;
