/* eslint-disable react-hooks/exhaustive-deps */

import { ApiQuery, FieldInput } from 'core/model/interface';
import { Box, Typography, useTheme } from '@mui/material';
import { CustomForm, CustomModal } from 'core/components';
import { Fragment, useContext, useEffect, useState } from 'react';
import PageBuilder, { BLOCK_TYPE } from 'core/PageBuilder/PageBuilder';
import { PageBuilderDrawerType, PageBuilderPageType, PageBuilderTableType } from 'core/PageBuilder';
import { getLocalizedTemplates, getTemplates, updateLocalizedTemplates } from 'company/api/sms-template';

import { BreadcrumbContext } from 'core/context/breadcrumb.context';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { FacilityContext } from 'core/context/facility.context';
import { GridColDef } from '@mui/x-data-grid';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import ReportIcon from '@mui/icons-material/Report';
import ViewContent from 'core/components/modals/ViewContent';
import { replaceCompanySmsTemplate } from 'core/utils';
import { sms_template_schema } from 'company/model/schema';
import { specialTokens } from 'core/data/list';
import { tokens } from 'theme/theme';
import { useSnackbar } from 'notistack';

const SmsTemplates = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { enqueueSnackbar } = useSnackbar();
  const { facility } = useContext(FacilityContext);
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const [selectedRow, setSelectedRow] = useState<any>();
  const [clearSelected, setClearSelected] = useState(0);
  const [smsCount, setSmsCount] = useState(1);

  //Localized SMS States
  const [preview, setPreview] = useState('');
  const [templateBody, setTemplateBody] = useState<string>('');
  const [openSmsPreview, setOpenSmsPreview] = useState<boolean>(false);
  const [previewContent, setPreviewContent] = useState<string>('');
  const [refresh, setRefresh] = useState(0);
  const [clearSelectedLocal, setClearSelectedLocal] = useState(0);

  const [openTemplateModal, setOpenTemplateModal] = useState<boolean>(false);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [selectedTemplate, setSelectedTemplate] = useState<any>();

  // Table Columns
  const columns: GridColDef[] = [
    { field: 'template_name', headerName: 'Template Name', flex: 1 },
    { field: 'template_code', headerName: 'Template Code', flex: 1 },
    { field: 'category_name', headerName: 'Category', flex: 1 },
    // { field: 'languages', headerName: 'Languages', flex: 1 },
  ];

  const localized_columns: GridColDef[] = [
    { field: 'language', headerName: 'Language', flex: 1 },
    { field: 'template_body', headerName: 'Template Body', flex: 3 },
  ];

  // Functions
  const getCompanyTemplates = (query: ApiQuery) => {
    return getTemplates(query);
  };

  const getCompanyLocalizedTemplates = (query: ApiQuery) => {
    return getLocalizedTemplates(selectedRow?.id, facility.id, query);
  };

  // Form Fields
  const scheduleFormFields: FieldInput[] = [
    {
      type: 'component',
      component: (
        <Box sx={{ p: '20px', borderRadius: 2, border: '1px solid ' + colors.primary }}>
          <Typography variant="h5" color={colors.text} fontWeight="bold">
            Special tokens
          </Typography>{' '}
          <br></br>
          <Box display="grid" gridTemplateColumns="150px 1fr 150px 1fr" gap="10px">
            {specialTokens.map((token, index) => (
              <Fragment key={index}>
                <Typography
                  variant="body1"
                  color={colors.text}
                  style={{
                    fontWeight: 'bold',
                    padding: '5px',
                  }}
                >
                  <span style={{ backgroundColor: colors.textBackground, borderRadius: '5px', padding: '6px' }}>
                    {' '}
                    {token.token}{' '}
                  </span>
                </Typography>
                <Typography variant="body1" color={colors.text}>
                  {token.description}
                </Typography>
              </Fragment>
            ))}
          </Box>
        </Box>
      ),
      span: 4,
    },
    {
      type: 'component',
      span: 4,
      component: (
        <Box sx={{ p: '10px', borderRadius: 2, border: '1px solid' + colors.secondary }}>
          <Box display="flex" gap="0.3rem">
            <Typography color={colors.secondary} p="0.5rem 0">
              <ReportIcon sx={{ fontSize: '50px' }} />
            </Typography>
            <Box display="block">
              <Typography variant="subtitle1" color={colors.secondary}>
                Clinic understands that the use of the PLATFORM is limited only to its intended use, health promotions
                and reminders for its clients or staff within its organization. The PLATFORM shall not be used in any
                sectarian, political purposes, and for organizations who are not in the signed agreement with CP Health
                Innovations Inc. Violation may lead up to suspension and termination of the account without refunds.
              </Typography>

              {templateBody?.length >= 140 && (
                <Box marginTop="20px">
                  {templateBody.length >= 140 && templateBody.length <= 160 ? (
                    <Box display="block">
                      <Typography variant="subtitle1" color={colors.secondary}>
                        <b> Attention: </b> The SMS Template is approaching the 160-character limit. Please be aware
                        that SMS credits are deducted for every 160 characters, including special tokens.
                      </Typography>
                      <Typography variant="subtitle1" color={colors.secondary}>
                        <b> Disclaimer: </b> Character count may vary depending on the special tokens used.
                      </Typography>
                    </Box>
                  ) : (
                    <Box display="block">
                      <Typography variant="subtitle1" color={colors.secondary}>
                        <b> Attention: </b> Please be aware that the SMS will still be sent but every exceeding 160
                        characters count as 1 SMS credit. <b> (SMS Credits Deduction: {smsCount}) </b>
                      </Typography>
                      <Typography variant="subtitle1" color={colors.secondary}>
                        <b> Disclaimer: </b> Character count may vary depending on the special tokens used.
                      </Typography>
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      ),
    },
    {
      type: 'component',
      component: (
        <Box sx={{ p: '20px', borderRadius: 2, border: '1px solid ' + colors.primary }}>
          <Typography variant="h5" color={colors.text} fontWeight="bold">
            Preview ({templateBody?.length} characters)
          </Typography>
          <Box
            color={colors.text}
            sx={{
              marginBottom: '20px',
              whiteSpace: 'pre-line',
              '& code': { color: 'white', backgroundColor: colors.main, padding: '2px 5px', borderRadius: '5px' },
            }}
          >
            <ReactMarkdown children={preview} />
          </Box>
        </Box>
      ),
      span: 4,
    },
    {
      type: 'component',
      component: (
        <Box sx={{ p: '20px', borderRadius: 2, border: '1px solid ' + colors.primary, display: 'flex', gap: '20px' }}>
          <Typography variant="h6" color={colors.text}>
            Special tokens
          </Typography>
          <Box display="flex" gap="5px">
            {specialTokens.map((token, index) => (
              <Fragment key={index}>
                <Typography
                  variant="body1"
                  color={colors.text}
                  style={{
                    cursor: 'pointer',
                    fontWeight: 'bold',
                    transition: 'color 0.3s',
                    color: colors.text,
                  }}
                  onClick={() => handleTokenClick(token.token)}
                >
                  <span
                    style={{
                      backgroundColor: colors.textBackground,
                      borderRadius: '5px',
                      padding: '6px',
                    }}
                    onMouseOver={(e) => {
                      e.currentTarget.style.backgroundColor = colors.secondary;
                      e.currentTarget.style.color = 'white';
                    }}
                    onMouseOut={(e) => {
                      e.currentTarget.style.backgroundColor = colors.textBackground;
                      e.currentTarget.style.color = colors.text;
                    }}
                  >
                    {token.token}
                  </span>
                </Typography>
              </Fragment>
            ))}
          </Box>
        </Box>
      ),
      span: 4,
    },
    {
      field_name: 'sms_template',
      display_name: 'SMS Template',
      type: 'text',
      multiline: true,
      rows: 3,
      span: 4,
      onChange: (value: any) => {
        setTemplateBody(value);
      },
    },
  ];

  const handleTokenClick = async (clickedToken: string) => {
    const updatedTemplateBody = templateBody + ' ' + clickedToken;
    setTemplateBody(updatedTemplateBody);
    setPreview(replaceCompanySmsTemplate(templateBody, facility));
  };

  const handleSelectRow = (selected: any) => {
    setRefresh((prev) => prev + 1);
    setSelectedRow(selected);
  };

  const closeFacilityDetails = () => {
    setSelectedRow(undefined);
    setClearSelected((prev: number) => prev + 1);
  };

  const viewTemplateBody = async (selected: any) => {
    if (selected) {
      setPreviewContent(selected.template_body);
      setOpenSmsPreview(true);
    }
  };

  const handleSubmit = async (data: any) => {
    setButtonLoading(true);

    if (selectedTemplate) {
      return updateLocalizedTemplates(selectedTemplate.id, facility.id, data.sms_template)
        .then(() => {
          setTemplateBody('');
          enqueueSnackbar('Template successfully updated!', { variant: 'success' });
          setRefresh((prev) => prev + 1);
          setButtonLoading(false);
          setOpenTemplateModal(false);
        })
        .catch(function (error) {
          if (error) {
            enqueueSnackbar('Error occured! Unable to update template.', { variant: 'error' });
            setButtonLoading(false);
          }
        });
    }
  };

  useEffect(() => {
    setPreview(replaceCompanySmsTemplate(templateBody, facility));

    if (templateBody?.length > 0) {
      setSmsCount(Math.ceil(templateBody.length / 160));
    }
  }, [templateBody]);

  useEffect(() => {
    if (!openTemplateModal) {
      setTemplateBody('');
      setSelectedTemplate(undefined);
    }
  }, [openTemplateModal]);

  useEffect(() => {
    setBreadcrumb([{ label: 'SMS' }]);
  }, []);

  const content = {
    type: BLOCK_TYPE.PAGE,
    block: {
      type: BLOCK_TYPE.DRAWER,
      parentHeader: 'SMS Templates',
      parentSubHeader: 'Click the row to see localized SMS templates.',
      childHeader: selectedRow ? selectedRow.template_name : '',
      childSubHeader: selectedRow ? 'Template Code: ' + selectedRow.template_code : '',
      parentWidth: 400,
      handleClose: closeFacilityDetails,
      showChild: !!selectedRow,
      parentComponent: {
        type: BLOCK_TYPE.TABLE,
        entityName: 'SMS Template',
        tableComponent: {
          columns: selectedRow ? [columns[0]] : columns,
          getData: getCompanyTemplates,
          selectableRow: true,
          onSelectRow: handleSelectRow,
          clearSelected: clearSelected,
          rowHeight: 35,
        },
      } as PageBuilderTableType,
      childComponent: {
        type: BLOCK_TYPE.TABLE,
        entityName: 'SMS Template',
        tableComponent: {
          hideTable: !selectedRow,
          columns: localized_columns,
          getData: getCompanyLocalizedTemplates,
          selectableRow: true,
          onSelectRow: (data: any) => viewTemplateBody(data),
          forceRefresh: refresh,
          clearSelected: clearSelectedLocal,
        },
        tableAction: {
          includeAddButton: false,
          includeUpdateAction: false,
          includeDeleteAction: false,
          rowActionRequiredAuth: [], //TODO
          getRow: setSelectedTemplate,
          rowActions: [
            {
              label: 'Update',
              action: (data) => {
                setTemplateBody(data.template_body);
                setOpenTemplateModal(true);
              },
              icon: <EditOutlinedIcon />,
            },
          ],
        },
      } as PageBuilderTableType,
    } as PageBuilderDrawerType,
  } as PageBuilderPageType;

  return (
    <>
      <PageBuilder content={content} />
      <ViewContent
        content={previewContent}
        open={openSmsPreview}
        setOpen={setOpenSmsPreview}
        onClose={() => {
          setClearSelectedLocal((prev: number) => prev + 1);
        }}
      />

      <CustomModal width={950} header={'Update Template'} open={openTemplateModal} setOpen={setOpenTemplateModal}>
        <CustomForm
          initialValues={{ sms_template: templateBody }}
          fields={scheduleFormFields}
          onSubmit={handleSubmit}
          schema={sms_template_schema}
          loading={buttonLoading}
        />
      </CustomModal>
    </>
  );
};

export default SmsTemplates;
