import { Box, useMediaQuery } from '@mui/material';
import CustomDateRangeFilter, { DateRangeValue } from 'core/components/CustomDateRangeFilter';
import CustomFilter, { CheckboxOptionProps } from 'core/components/CustomFilter';
import { CustomForm, CustomModal, HideForInactiveCompany, HideOrShowComponent, PrimaryButton } from 'core/components';
import { formatCurrency, formatDate } from 'core/utils';
import { getTotalPaidCustom, getTotalRemainingBalance } from 'company/api/billing-payment';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';

import { AnyNaptrRecord } from 'dns';
import { ApiQuery } from 'core/model/interface';
import { BreadcrumbContext } from 'core/context/breadcrumb.context';
import { CareGoPage } from 'core/PageBuilder';
import { ContainerRow } from 'core/components/containers';
import CustomDashboardWidget from 'core/components/CustomDashboardWidget';
import { FacilityContext } from 'core/context/facility.context';
import { POS } from 'company/screens/InventorySystem';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import { PatientBillingTable } from 'company/entities/tables';
import { SIZES } from 'theme/constants';
import ServiceProviderSearchDropdown from 'company/components/dropdown/ServiceProviderSearchDropdown';
import { UserContext } from 'core/context/user.context';
import dayjs from 'dayjs';
import { getClients } from 'company/api/corporate-clients';
import { getCompanyTags } from 'company/api/company-tags';
import { getHMOs } from 'company/api/company-hmos';
import { isDevMode } from 'core/components/HideOrShowComponent';
import { reverseInvoice } from 'company/api/patient-billing';
import { reverse_invoice_schema } from 'company/model/schema';
import { useSnackbar } from 'notistack';

type PatientBillingProps = {
  viewInPatientProfile?: boolean;
  viewInInventory?: boolean;
  fromAccounting?: boolean;
  patient?: any;
};

const PatientBilling: React.FC<PatientBillingProps> = ({
  viewInPatientProfile,
  patient,
  viewInInventory,
  fromAccounting,
}) => {
  const { user } = useContext(UserContext);
  const { facility } = useContext(FacilityContext);
  const { enqueueSnackbar } = useSnackbar();
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const tableRef: any = useRef();
  const [selectedRow, setSelectedRow] = useState<any>();

  const [selectedInvoice, setSelectedInvoice] = useState<any>();
  const [openCreateInvoiceModal, setOpenCreateInvoiceModal] = useState<boolean>(false);
  const [openReverseInvoiceDialog, setOpenReverseInvoiceDialog] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [totalPaidCustom, setTotalPaidCustom] = useState<string>('0.00');
  const [totalRemainingBalance, setTotalRemainingBalance] = useState<string>('0.00');
  const [otherFilter, setOtherFilter] = useState<any>({});
  const [paymentFilters, setPaymentFilters] = useState<string[]>([]);
  const [serviceProviderFilter, setServiceProviderFilter] = useState<string[]>([]);

  const [tags, setTags] = useState<any[]>([]);
  const [clients, setClients] = useState<any[]>([]);
  const [hmos, setHmos] = useState<any[]>([]);
  const [clinics, setClinics] = useState<any[]>([]);

  const isMobilePhone = useMediaQuery('(max-width:768px)');

  const hasViewDailyTransactionsOnly = user?.authorities?.includes('VIEW_DAILY_TRANSACTIONS_ONLY');
  const hasViewWeeklyTransactionsOnly = user?.authorities?.includes('VIEW_WEEKLY_TRANSACTIONS_ONLY');
  const hasViewMonthlyTransactionsOnly = user?.authorities?.includes('VIEW_MONTHLY_TRANSACTIONS_ONLY');
  const hasViewQuarterlyTransactionsOnly = user?.authorities?.includes('VIEW_QUARTERLY_TRANSACTIONS_ONLY');
  const hasViewSemiannualTransactionsOnly = user?.authorities?.includes('VIEW_SEMIANUALLY_TRANSACTIONS_ONLY');
  const hasViewYearlyTransactionsOnly = user?.authorities?.includes('VIEW_YEARLY_TRANSACTIONS_ONLY');

  const getQuickFilterFromPermissions = (): any => {
    if (hasViewDailyTransactionsOnly) return 'today';
    if (hasViewWeeklyTransactionsOnly) return 'week';
    if (hasViewMonthlyTransactionsOnly) return 'month';
    if (hasViewQuarterlyTransactionsOnly) return 'quarter';
    if (hasViewSemiannualTransactionsOnly) return 'semiannual';
    if (hasViewYearlyTransactionsOnly) return 'year';
    return 'month';
  };

  const quickFilterValue = getQuickFilterFromPermissions();
  console.log('Quick Filter Before Passing:', quickFilterValue);

  const hasAnyRestrictedView = (): boolean => 
    [
      hasViewDailyTransactionsOnly,
      hasViewWeeklyTransactionsOnly,
      hasViewMonthlyTransactionsOnly,
      hasViewQuarterlyTransactionsOnly,
      hasViewSemiannualTransactionsOnly,
      hasViewYearlyTransactionsOnly
    ].some(permission => permission);

  const refreshTable = async () => {
    setOpenCreateInvoiceModal(false);
    tableRef.current.refreshTable();
  };

  const getTags = () => {
    if (facility) {
      getCompanyTags(facility.id, { length: 1000 }).then((res) => {
        setTags(res.data.data);
      });
    }
  };

  const getClientsData = () => {
    if (facility) {
      getClients({ length: 1000 }).then((res) => {
        setClients(res.data.data);
      });
    }
  };

  const getHMOsData = () => {
    if (facility) {
      getHMOs({ length: 1000 }).then((res) => {
        setHmos(res.data.data);
      });
    }
  };

  const getClinicsData = () => {
    if (user) {
      setClinics(user.facilities ?? []);
    }
  };

  const getDefaultStartDate = (): string => {
    const today = new Date();
    
    // Based on the quick filter permissions, adjust the start date
    if (hasViewDailyTransactionsOnly) {
      return dayjs(today).format('YYYY-MM-DD');
    }
    
    if (hasViewWeeklyTransactionsOnly) {
      const startOfWeek = new Date(today);
      startOfWeek.setDate(today.getDate() - today.getDay());
      return dayjs(startOfWeek).format('YYYY-MM-DD');
    }
    
    if (hasViewMonthlyTransactionsOnly) {
      return dayjs(new Date(today.getFullYear(), today.getMonth(), 1)).format('YYYY-MM-DD');
    }
    
    if (hasViewQuarterlyTransactionsOnly) {
      const quarter = Math.floor(today.getMonth() / 3);
      return dayjs(new Date(today.getFullYear(), quarter * 3, 1)).format('YYYY-MM-DD');
    }

    if (hasViewSemiannualTransactionsOnly) {
      // If current month is in first half (Jan-Jun), set to January 1
      // If current month is in second half (Jul-Dec), set to July 1
      const semiAnnualStart = today.getMonth() < 6 ? 0 : 6;
      return dayjs(new Date(today.getFullYear(), semiAnnualStart, 1)).format('YYYY-MM-DD');
    }

    if (hasViewYearlyTransactionsOnly) {
      return dayjs(new Date(today.getFullYear(), 0, 1)).format('YYYY-MM-DD');
    }
    
    // Default to current month's start
    return dayjs(new Date(today.getFullYear(), today.getMonth(), 1)).format('YYYY-MM-DD');
  };
  
  const getDefaultEndDate = (): string => {
    const today = new Date();
    
    if (hasViewDailyTransactionsOnly) {
      return dayjs(today).format('YYYY-MM-DD');
    }
    
    if (hasViewWeeklyTransactionsOnly) {
      const endOfWeek = new Date(today);
      endOfWeek.setDate(today.getDate() + (6 - today.getDay()));
      return dayjs(endOfWeek).format('YYYY-MM-DD');
    }
    
    if (hasViewMonthlyTransactionsOnly) {
      const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
      return dayjs(lastDayOfMonth).format('YYYY-MM-DD');
    }
    
    if (hasViewQuarterlyTransactionsOnly) {
      const quarter = Math.floor(today.getMonth() / 3);
      const lastDayOfQuarter = new Date(today.getFullYear(), quarter * 3 + 3, 0);
      return dayjs(lastDayOfQuarter).format('YYYY-MM-DD');
    }
    
    if (hasViewSemiannualTransactionsOnly) {
      // If current month is in first half (Jan-Jun), set to June 30
      // If current month is in second half (Jul-Dec), set to December 31
      const semiAnnualEnd = today.getMonth() < 6 ? 5 : 11;
      const lastDayOfSemiAnnual = new Date(today.getFullYear(), semiAnnualEnd + 1, 0);
      return dayjs(lastDayOfSemiAnnual).format('YYYY-MM-DD');
    }
    
    if (hasViewYearlyTransactionsOnly) {
      return dayjs(new Date(today.getFullYear(), 11, 31)).format('YYYY-MM-DD');
    }
    
    // Default to current month's end
    const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    return dayjs(lastDayOfMonth).format('YYYY-MM-DD');
  };

  const [filterDateRange, setFilterDateRange] = useState<DateRangeValue>([getDefaultStartDate(), getDefaultEndDate()]);

  useEffect(() => {
    if (facility) {
      getTags();
      getClientsData();
      getHMOsData();
      getClinicsData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facility.id]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      getWidgetData();
    }, 500);
    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otherFilter, filterDateRange]);

  const handleReverseInvoice = async (data: any) => {
    if (selectedRow) {
      return reverseInvoice(facility.id, selectedRow.id, data).then((res) => {
        setOpenReverseInvoiceDialog(false);
        enqueueSnackbar(`Invoice successfully changed to void!`, { variant: 'success' });
        refreshTable();
      });
    }
  };

  const getWidgetData = () => {
    // getTotalPaidInYear();
    getTotalPaidCustomDate();
    getRemainingBalance();
  };

  // const getTotalPaidInYear = async () => {
  //   getTotalPaid(facility.id).then((res) => {
  //     setTotalPaidInYear(formatCurrency(res.data.data));
  //   });
  // };

  const getTotalPaidCustomDate = async (query?: ApiQuery) => {
    const startDate = filterDateRange[0];
    const endDate = filterDateRange[1];

    const dateFilter: { start_date?: string; end_date?: string } = {};
    if (startDate) dateFilter['start_date'] = startDate;
    if (endDate) dateFilter['end_date'] = endDate;
    const finalQuery = { ...dateFilter, ...query };

    getTotalPaidCustom(facility ? facility.id : -1, finalQuery).then((res) => {
      setTotalPaidCustom(formatCurrency(res.data.data));
    });
  };

  const getRemainingBalance = async (query?: ApiQuery) => {
    const startDate = filterDateRange[0];
    const endDate = filterDateRange[1];

    const dateFilter: { start_date?: string; end_date?: string } = {};
    if (startDate) dateFilter['start_date'] = startDate;
    if (endDate) dateFilter['end_date'] = endDate;
    const finalQuery = { ...dateFilter, ...query };

    getTotalRemainingBalance(facility ? facility.id : -1, finalQuery).then((res) => {
      setTotalRemainingBalance(formatCurrency(res.data.data));
    });
  };

  useEffect(() => {
    const corporate_client_filter = paymentFilters
      .filter((value) => value.startsWith('corporate:'))
      .map((value) => parseInt(value.split(':')[1]));

    const hmo_filter = paymentFilters
      .filter((value) => value.startsWith('hmo:'))
      .map((value) => parseInt(value.split(':')[1]));
    const status_filter = paymentFilters.filter((value) => ['unpaid', 'partial', 'paid'].includes(value));

    const clinic_filter = paymentFilters
      .filter((value) => value.startsWith('clinic:'))
      .map((value) => parseInt(value.split(':')[1]));

    setOtherFilter({
      ...otherFilter,
      get_unposted: paymentFilters.includes('unposted'),
      get_reversed: paymentFilters.includes('reversed'),
      get_voided: paymentFilters.includes('void'),
      corporate_client_filter,
      hmo_filter,
      status_filter,
      clinic_filter,
      provider_id_filter: serviceProviderFilter.length ? serviceProviderFilter : undefined,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentFilters, serviceProviderFilter]);

  const labelContentTotalPaid = useMemo(() => {
    return filterDateRange[0] && filterDateRange[1]
      ? `Total Paid ${formatDate(filterDateRange[0])} - ${formatDate(filterDateRange[1])}`
      : filterDateRange[0]
      ? `Total Paid since ${formatDate(filterDateRange[0])}`
      : filterDateRange[1]
      ? `Total Paid until ${formatDate(filterDateRange[1])}`
      : `Total Paid`;
  }, [filterDateRange]);

  const labelContentRemainingBalance = useMemo(() => {
    return filterDateRange[0] && filterDateRange[1]
      ? `Total Remaining Balance ${formatDate(filterDateRange[0])} - ${formatDate(filterDateRange[1])}`
      : filterDateRange[0]
      ? `Total Remaining Balance since ${formatDate(filterDateRange[0])}`
      : filterDateRange[1]
      ? `Total Remaining Balance until ${formatDate(filterDateRange[1])}`
      : `Total Remaining Balance`;
  }, [filterDateRange]);

  useEffect(() => {
    if (!patient) {
      setBreadcrumb([{ label: fromAccounting ? 'Invoices' : 'Patient Transactions' }]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient]);

  useEffect(() => {
    const tags: any[] = [];
    const clients: any[] = [];
    const start_date = filterDateRange[0];
    const end_date = filterDateRange[1];

    setOtherFilter((prev: any) => ({
      ...prev,
      start_date,
      end_date,
      tags: JSON.stringify(tags),
      clients: JSON.stringify(clients),
    }));
  }, [filterDateRange]);

  useEffect(() => {
    if (filterDateRange[0] !== undefined || filterDateRange[1] !== undefined) {
      setFilterDateRange(filterDateRange);
    }
  }, [filterDateRange]);

  const filters: CheckboxOptionProps[] = useMemo(() => {
    let list = [
      { label: 'Status', id: 'status', isHeader: true },
      { label: 'Unposted', id: 'unposted' },
      { label: 'Void', id: 'void' },
      { label: 'Unpaid', id: 'unpaid' },
      { label: 'Partial', id: 'partial' },
      { label: 'Paid', id: 'paid' },
      { label: 'Reversed', id: 'reversed' },
    ];

    if (hmos.length) {
      list = [
        ...list,
        { label: 'HMO', id: 'hmo', isHeader: true },
        ...hmos.map((hmo) => ({ label: hmo.hmo_name, id: 'hmo:' + hmo.id })),
        { label: 'Non-HMO/Cash', id: 'hmo:' + -1 },
      ];
    }
    if (clients.length) {
      list = [
        ...list,
        { label: 'Corporate Client', id: 'corporate_client', isHeader: true },
        ...clients.map((client) => ({ label: client.company_name, id: 'corporate:' + client.id })),
      ];
    }
    if (tags.length) {
      list = [
        ...list,
        { label: 'Patient Group', id: 'patient_group', isHeader: true },
        ...tags.map((tag) => ({ label: tag.tag_name, id: 'group:' + tag.id })),
      ];
    }
    if (clinics.length && facility?.is_admin) {
      list = [
        ...list,
        { label: 'Clinic', id: 'clinic', isHeader: true },
        ...clinics.map((clinic) => ({ label: clinic.facility_name, id: 'clinic:' + clinic.id })),
      ];
    }

    return list;
  }, [hmos, clients, tags, clinics, facility?.is_admin]);

  return (
    <CareGoPage header="Patient Transactions">
      <Box
        width={isMobilePhone ? '100%' : '60%'}
        gap="20px"
        display={'flex'}
        flexDirection={isMobilePhone ? 'column' : 'row'}
        justifyContent={'space-between'}
        mb={SIZES.padding}
      >
        <HideOrShowComponent hidden={!facility?.is_admin}>
          <CustomDashboardWidget
            count={totalRemainingBalance}
            label={labelContentRemainingBalance}
            icon={PaidOutlinedIcon}
          />
          {/* <CustomDashboardWidget count={totalPaidInYear} label={totalPaid} icon={PaidOutlinedIcon} /> */}
          <CustomDashboardWidget count={totalPaidCustom} label={labelContentTotalPaid} icon={PaidOutlinedIcon} />
        </HideOrShowComponent>
      </Box>
      <PatientBillingTable
        tableRef={tableRef}
        otherFilter={otherFilter}
        refreshWidgets={getWidgetData}
        headerComponent={
          <Box display="flex" gap="15px" flexDirection="column" width={selectedInvoice && 300}>
            <HideForInactiveCompany>
              <HideOrShowComponent
                hidden={!!viewInPatientProfile || !isDevMode() || facility.is_admin || isMobilePhone}
              >
                <PrimaryButton
                  onClick={() => {
                    setOpenCreateInvoiceModal(true);
                  }}
                  label="Create Invoice"
                  width="180px"
                  id="create-invoice-button"
                />
              </HideOrShowComponent>
            </HideForInactiveCompany>
            <ContainerRow
              gap={SIZES.paddingS}
              sx={{
                alignItems: 'flex-end',
                flexDirection: isMobilePhone ? 'column' : 'row',
                zIndex: 2,
                paddingRight: '5px',
                paddingTop: isMobilePhone ? '8px' : undefined,
              }}
            >
              <Box display="flex" gap="10px" width={isMobilePhone ? '100%' : undefined}>
                <Box width={isMobilePhone ? '100%' : '200px'}>
                  <ServiceProviderSearchDropdown
                    handleChangeCallback={(value) =>
                      setServiceProviderFilter(value ? value.map((val: any) => val.value) : [])
                    }
                    hideAddOption
                    multiple
                  />
                </Box>
                {isMobilePhone && (
                  <CustomFilter
                    buttonLabel="Filter"
                    span={2}
                    optionList={filters}
                    selected={paymentFilters}
                    setSelected={setPaymentFilters}
                  />
                )}
              </Box>
              <HideOrShowComponent hidden={hasAnyRestrictedView()}>
                <CustomDateRangeFilter setFilterDateRange={setFilterDateRange} quickFilter={'year'}/>
              </HideOrShowComponent>
              {!isMobilePhone && (
                <CustomFilter
                  buttonLabel="Filter"
                  span={2}
                  optionList={filters}
                  selected={paymentFilters}
                  setSelected={setPaymentFilters}
                />
              )}
            </ContainerRow>
          </Box>
        }
      />
      <CustomModal
        header={'Reverse Invoice ' + selectedRow?.invoice_number}
        open={openReverseInvoiceDialog}
        setOpen={setOpenReverseInvoiceDialog}
      >
        <CustomForm
          initialValues={{ reason: '' }}
          onSubmit={handleReverseInvoice}
          fields={[
            {
              field_name: 'reason',
              display_name: 'Purpose',
              type: 'string',
              span: 4,
            },
          ]}
          schema={reverse_invoice_schema}
          loading={loading}
        />
      </CustomModal>
      <CustomModal
        width="90%"
        open={openCreateInvoiceModal}
        setOpen={setOpenCreateInvoiceModal}
        header={'Create Invoice'}
      >
        <POS fromCreateInvoice />
        {/* <PatientBillingForm facility={facility} callbackAfterSubmit={refreshTable} /> */}
      </CustomModal>
    </CareGoPage>
  );
};

export default PatientBilling;
