import { Box, Chip, Divider, Typography, useTheme } from '@mui/material';
import {
  ConfirmationDialog,
  CustomContainer,
  CustomIconButton,
  CustomInlineForm,
  Header,
  RegularButton,
} from 'core/components';
import {
  ProductVariantModel,
  variantPrice,
} from 'company/entities/modules/InventorySystem/Product/ProductVariant/ProductVariantModel';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { changeNullToBlank, formatAttributeValues, formatNumber } from 'core/utils';
import {
  deleteVariant,
  getClinicsOfVariant,
  getInventories,
  getInventory,
  getVariantsOfProduct,
  updateInventoryPrice,
} from 'company/api/inventories';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import AddIcon from '@mui/icons-material/Add';
import AddStock from 'company/entities/modules/InventorySystem/components/AddStock';
import AdjustStock from 'company/entities/modules/InventorySystem/components/AdjustStock';
import { BreadcrumbContext } from 'core/context/breadcrumb.context';
import CloseIcon from '@mui/icons-material/Close';
import { ContainerColumn } from 'core/components/containers';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { FacilityContext } from 'core/context/facility.context';
import { FieldInput } from 'core/model/interface';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import { LoadingScreen } from 'core/screens';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import MoveUpOutlinedIcon from '@mui/icons-material/MoveUpOutlined';
import ProductForm from 'company/entities/modules/InventorySystem/Product/ProductForm';
import ProductVariantForm from 'company/entities/modules/InventorySystem/Product/ProductVariant/ProductVariantForm';
import { SIZES } from 'theme/constants';
import TransferStock from 'company/entities/modules/InventorySystem/components/TransferStock';
import { UserContext } from 'core/context/user.context';
import { getProduct } from 'company/api/products';
import { saveAs } from 'file-saver';
import { tokens } from 'theme/theme';
import { useSnackbar } from 'notistack';

type ProductVariantsPageProps = {
  variant: ProductVariantModel;
  refreshVariant: () => void;
};
const ProductVariantsPage: React.FC<ProductVariantsPageProps> = ({ variant, refreshVariant }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useContext(UserContext);
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const { facility } = useContext(FacilityContext);
  const [madeToOrder, setMadeToOrder] = useState<boolean>(false);

  // const { facility  } = useContext(FacilityContext);
  const [openAddVariant, setOpenAddVariant] = useState(false);
  const [openAddStock, setOpenAddStock] = useState(false);
  const [openAdjustStock, setOpenAdjustStock] = useState(false);
  const [openTransferStock, setOpenTransferStock] = useState(false);
  const [selectedClinic, setSelectedClinic] = useState<any>();
  const [variantAttributes, setVariantAttributes] = useState<any[]>([]);
  const [product, setProduct] = useState<any>();
  const [variants, setVariants] = useState<ProductVariantModel[]>([]);
  const [clinics, setClinics] = useState<any[]>([]);
  const [clinicInventory, setClinicInventory] = useState<any[]>([]);

  const [productValues, setProductValues] = useState<any>({
    product_name: '',
    description: '',
    category_id: '',
  });
  const [isUpdatingProduct, setIsUpdatingProduct] = useState(false);
  const [isUpdatingProductVariant, setIsUpdatingProductVariant] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [variantsWithAttrValues, setVariantsWithAttrValues] = useState<any[]>();
  const [refreshVariants, setRefreshVariants] = useState(0);

  //used so the header and the variants list has the same attribute values displayed (sorted the same way)

  const combined = formatAttributeValues(variant?.attributes, true);
  const header = formatAttributeValues(variant?.attributes, false, (value) => (value ? `(${value}) ` : ''));

  const reorderClinic = useCallback(
    (clinics: any[]) => {
      const index = clinics.findIndex((clinic) => clinic.id === facility.id);
      if (index !== -1) {
        const item = clinics.splice(index, 1)[0];
        clinics.unshift(item);
      }
      setClinics(
        clinics.map((facility) => {
          const inventory = clinicInventory.find((inv) => inv.facility_id === facility.id);
          return {
            id: facility.id,
            facility_name: facility.facility_name,
            inventory,
          };
        })
      );
    },
    [clinicInventory, facility]
  );

  useEffect(() => {
    getProductDetails();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variant.product_id]);

  useEffect(() => {
    if (user && user.facilities && clinicInventory) {
      reorderClinic(user.facilities);
    }
  }, [user, clinicInventory, reorderClinic]);

  useEffect(() => {
    setVariantAttributes([]);
    if (variant && variant.attributes && variant.attributes.length) {
      const combined = variant.attributes.reduce((result: any, attribute) => {
        if (result[attribute.attribute_id]) {
          result[attribute.attribute_id].push(attribute);
        } else {
          result[attribute.attribute_id] = [attribute];
        }
        return result;
      }, {});
      setVariantAttributes(combined);
    }
    getClinicInventory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variant, refresh]);

  const getClinicInventory = () => {
    getClinicsOfVariant(variant.id).then((res) => {
      setClinicInventory(res.data.data);
    });
  };

  useEffect(() => {
    if (clinicInventory.length > 0) {
      const isMadeToOrder = clinicInventory.some((clinic) => clinic.made_to_order === 1);
      setMadeToOrder(isMadeToOrder);
    }
  }, [clinicInventory]);

  const getProductDetails = () => {
    getProduct(variant.product_id!).then((res) => {
      const product = res.data;
      setProduct(product);
      setProductValues({
        id: product.id ?? undefined,
        product_name: product.product_name ?? '',
        description: product.description ?? '',
        category_id: product.category_id ?? '',
        category: product.category ?? '',
      });
    });
  };

  const handleSaveVariantCallback = async (data: any) => {
    setOpenAddVariant(false);
    setIsUpdatingProductVariant(false);
    refreshVariant();
  };

  const handleUpdatePrice = async (data: any, clinic: any) => {
    const updateData = {
      facility_id: clinic.id,
      facility_inventory_price: data.facility_inventory_price,
      product_id: variant.product_id,
    };

    updateInventoryPrice(variant!.id, updateData).then((res) => {
      enqueueSnackbar('Price successfully updated!', { variant: 'success' });
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const downloadBarcode = (sku: string) => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    if (context) {
      canvas.width = 300;
      canvas.height = 100;

      const JsBarcode = require('jsbarcode');

      JsBarcode(canvas, sku, {
        format: 'CODE128',
        displayValue: false,
      });

      context.fillStyle = '#000';
      context.font = '14px Arial';
      context.fillText(`SKU: ${sku}`, 10, 90);

      const dataURL = canvas.toDataURL('image/png');

      const blob = dataURItoBlob(dataURL);

      saveAs(blob, `barcode_${sku}.png`);
    } else {
      console.error('Canvas context is null.');
    }
  };

  const dataURItoBlob = (dataURI: string) => {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };

  const handleUpdatePriceToAll = async (data: any) => {
    const updateData = {
      facility_inventory_price: data.facility_inventory_price,
      product_id: variant.product_id,
    };

    let successfulUpdates = 0;

    clinics.forEach((clinic) => {
      updateInventoryPrice(variant!.id, { ...updateData, facility_id: clinic.id })
        .then(() => {
          successfulUpdates++;
          if (successfulUpdates === clinics.length) {
            enqueueSnackbar(`Prices successfully updated for all clinics!`, { variant: 'success' });
            getClinicInventory();
          }
        })
        .catch((error) => {
          console.error(`Failed to update price for ${clinic.facility_name}: ${error.message}`);
        });
    });
  };

  const clinicFields: FieldInput[] = [
    // {
    //   field_name: 'quantity_in_stock',
    //   display_name: 'Quantity',
    //   type: 'text',
    //   disabled: true,
    //   span: 1,
    // },
    {
      field_name: 'facility_inventory_price',
      display_name: 'Price',
      type: 'currency',
      span: 1,
    },
  ];

  const inventoryFields: FieldInput[] = [
    {
      field_name: 'facility_inventory_price',
      display_name: 'Update Price',
      type: 'text',
      span: 1,
    },
  ];

  const handleSaveProductCallbackAfterSubmit = async (data: any) => {
    refreshVariant();
    setIsUpdatingProduct(false);
    // if (variant.product_id) {
    //   getProductDetails();
    //   setIsUpdatingProduct(false);
    // } else {
    //   navigate(`/company/products/add/${variant!.product_id}`);
    // }
  };

  const location = useLocation();
  const previousLocation = (location as any).state && (location as any).state.from;

  useEffect(() => {
    if (previousLocation) {
      if (previousLocation === '/company/inventory/pos') {
        setBreadcrumb([
          { label: 'Clinic Inventory', link: '/company/inventory/pos' },
          { label: `${product?.product_name}` },
        ]);
      } else if (previousLocation === '/company/inventory/products') {
        setBreadcrumb([
          { label: 'Clinic Inventory', link: '/company/inventory/products' },
          { label: `${product?.product_name}${combined}` },
        ]);
      }
    } else {
      setBreadcrumb([
        { label: 'Clinic Inventory', link: '/company/inventory/products' },
        { label: `${product?.product_name}${combined}` },
      ]);
    }
  }, [previousLocation, setBreadcrumb, product, variant]);

  if (!variant) return <LoadingScreen loading />;

  return (
    <Box>
      <Box display="flex" flexDirection="column">
        <CustomContainer>
          <Box
            width="100%"
            sx={
              isUpdatingProduct
                ? {
                    padding: SIZES.padding,
                    border: '1px solid ' + colors.primary,
                    borderRadius: SIZES.borderRadiusS,
                  }
                : undefined
            }
          >
            <Box display="flex" justifyContent="space-between" alignItems="flex-start" width="100%">
              <Header title={productValues.product_name} variant="h4" />
              {!isUpdatingProduct ? (
                <RegularButton
                  size="small"
                  variant="outlined"
                  startIcon={<ModeEditOutlineOutlinedIcon />}
                  label="Update"
                  onClick={() => setIsUpdatingProduct(true)}
                />
              ) : (
                <CustomIconButton onClick={() => setIsUpdatingProduct(false)} icon={<CloseIcon />} />
              )}
            </Box>
            {isUpdatingProduct ? (
              <Box width="100%">
                <ProductForm product={productValues} callbackAfterSubmit={handleSaveProductCallbackAfterSubmit} />
              </Box>
            ) : (
              <Box display="flex" justifyContent="space-between">
                <Box display="grid" gap="40px" gridTemplateColumns="1fr 1fr">
                  <Box display="flex" gap={SIZES.padding}>
                    <Typography color={colors.grey_text}>Product Name</Typography>
                    <Typography>{productValues.product_name}</Typography>
                  </Box>
                  <Box display="flex" gap={SIZES.padding}>
                    <Typography color={colors.grey_text}>Category</Typography>
                    <Typography>{productValues.category}</Typography>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </CustomContainer>
        <Divider style={{ margin: '20px 0' }} />
        <CustomContainer>
          <Box display="flex-column" justifyContent="flex-end" width="100%" mt={SIZES.padding}>
            <Box
              display="flex-column"
              width="100%"
              sx={
                isUpdatingProductVariant
                  ? {
                      padding: SIZES.padding,
                      border: '1px solid ' + colors.primary,
                      borderRadius: SIZES.borderRadiusS,
                    }
                  : undefined
              }
            >
              <Box display="flex" justifyContent="space-between" marginBottom={SIZES.padding} alignItems="flex-start">
                <Box display="flex" gap="10px" alignItems="flex-start">
                  <Header title={`${header}Details`} variant="h4" mb="0" />
                  {/* <Tooltip title={'Click to download'} arrow>
                            <Box onClick={() => downloadBarcode(variant.sku)} sx={{ '&:hover': { cursor: 'pointer' } }}>
                              <Barcode value={variant.sku} height={30} width={1} fontSize={10} />
                            </Box>
                          </Tooltip> */}
                </Box>
                {variant && !isUpdatingProductVariant && (
                  <RegularButton
                    size="small"
                    variant="outlined"
                    startIcon={<ModeEditOutlineOutlinedIcon />}
                    label="Update"
                    onClick={() => setIsUpdatingProductVariant(true)}
                  />
                )}
                {isUpdatingProductVariant && (
                  <CustomIconButton onClick={() => setIsUpdatingProductVariant(false)} icon={<CloseIcon />} />
                )}
              </Box>

              {variant &&
                (isUpdatingProductVariant ? (
                  <Box width="100%">
                    <ProductVariantForm
                      product={productValues}
                      productVariant={changeNullToBlank(variant)}
                      callbackAfterSubmit={handleSaveVariantCallback}
                      variantAttributes={variantAttributes}
                    />
                  </Box>
                ) : (
                  <>
                    <Box display="grid" columnGap="40px" rowGap="10px" gridTemplateColumns="1fr 1fr">
                      <Box display="flex" gap={SIZES.padding}>
                        <Typography color={colors.grey_text}>SKU</Typography>
                        <Typography>{variant.sku}</Typography>
                      </Box>

                      {variant.exclusive_supplier && (
                        <Box display="flex" gap={SIZES.padding}>
                          <Typography color={colors.grey_text}>Exclusive Supplier</Typography>
                          <Typography>{variant.exclusive_supplier_name}</Typography>
                        </Box>
                      )}
                      {variant.variant_description && (
                        <Box width="100%" sx={{ gridColumn: 'span 2' }}>
                          <Typography color={colors.grey_text}>Description</Typography>
                          <Typography mt="5px">{variant.variant_description}</Typography>
                        </Box>
                      )}
                      {variant.made_to_order === 1 && (
                        <Box display="flex" gap={SIZES.padding}>
                          <Chip
                            size="small"
                            variant="outlined"
                            label={'Made-to-Order'}
                            sx={{
                              borderColor: '#ff1a1a',
                              color: '#ff1a1a',
                              fontSize: 12,
                              paddingBlock: 0,
                            }}
                          />
                        </Box>
                      )}
                      {variant.product_cost && (
                        <Box display="flex" gap={SIZES.padding}>
                          <Typography color={colors.grey_text}>Product Cost</Typography>
                          <Typography>{variant.product_cost}</Typography>
                        </Box>
                      )}

                      <Box display="flex" gap={SIZES.padding}>
                        <Chip
                          size="small"
                          variant="outlined"
                          label={variant.tax === 'non-vat' ? 'Non-VAT' : 'VAT'}
                          sx={{
                            borderColor: '#ff1a1a',
                            color: '#ff1a1a',
                            fontSize: 12,
                            paddingBlock: 0,
                          }}
                        />
                      </Box>

                      {!!Object.values(variantAttributes).length && (
                        <ContainerColumn gap="5px" sx={{ mt: SIZES.paddingL, gridColumn: 'span 2' }}>
                          <Typography variant="h5" fontWeight="bold">
                            Attributes
                          </Typography>
                          {Object.values(variantAttributes).map((attribute, index) => (
                            <Box
                              key={index}
                              display="grid"
                              gridTemplateColumns="120px 3fr"
                              columnGap={SIZES.padding}
                              alignItems="center"
                            >
                              <Typography>{attribute[0].attribute_name}</Typography>
                              <Box display="flex" gap={SIZES.paddingS}>
                                {attribute.map(({ value }: any, index: number) => (
                                  <Chip
                                    key={index}
                                    label={value}
                                    size="small"
                                    sx={{ fontSize: '14px', padding: '10px 5px' }}
                                  />
                                ))}
                              </Box>
                            </Box>
                          ))}
                        </ContainerColumn>
                      )}
                    </Box>
                  </>
                ))}
            </Box>

            {clinics && clinics.length > 0 && (
              <Box marginBottom="50px">
                <Divider style={{ marginBlock: '20px' }} />
                <Header title="Inventory" variant="h4" mb="0" />

                {clinics.length > 1 && (
                  <Box marginTop={'2rem'} width="80%">
                    <CustomInlineForm
                      onSubmit={(data) => handleUpdatePriceToAll(data)}
                      fields={inventoryFields}
                      initialValues={{ facility_inventory_price: '0' }}
                      loading={false}
                      hideSubmitButton={false}
                      submitButtonText="Apply To All"
                      buttonWidth="210px"
                    />
                  </Box>
                )}

                <ContainerColumn sx={{ marginTop: SIZES.padding }}>
                  {clinics.map((clinic, index) => (
                    <Box key={clinic.id}>
                      <Box
                        // marginTop={'2rem'}
                        key={clinic.id}
                        sx={{
                          padding: SIZES.padding,
                          backgroundColor: colors.light_blue_background,
                          borderRadius: SIZES.borderRadiusS,
                        }}
                      >
                        <Typography variant="h5" fontWeight={'600'} mb="10px">
                          {clinic.facility_name}
                        </Typography>
                        <Typography variant="h5" fontWeight={'600'} mb="10px" color={colors.primary}>
                          Available: {formatNumber(clinic.inventory?.quantity_in_stock ?? 0, 0)}
                        </Typography>

                        <Box display="flex" gap="10px" justifyContent="space-between" alignItems="center">
                          <CustomInlineForm
                            onSubmit={(data) => handleUpdatePrice(data, clinic)}
                            fields={clinicFields}
                            initialValues={{
                              facility_inventory_price: clinic.inventory?.facility_inventory_price ?? '0',
                            }}
                            schema={variantPrice}
                            loading={false}
                            disabled={!clinic.inventory}
                            submitButtonText="Save"
                            // submitStartIcon={<CheckOutlinedIcon />}
                            submitButtonSize="small"
                          />
                          <Box
                            display="flex"
                            gap="8px"
                            alignItems="right"
                            sx={{
                              borderLeft: !madeToOrder ? '2px solid ' + colors.border : '',
                              paddingLeft: '10px',
                              paddingBlock: '5px',
                            }}
                          >
                            {!madeToOrder && (
                              <>
                                <RegularButton
                                  onClick={() => {
                                    setOpenAddStock(true);
                                    setSelectedClinic(clinic);
                                  }}
                                  label="Restock"
                                  startIcon={<AddIcon />}
                                  size="small"
                                  variant="outlined"
                                />
                                <RegularButton
                                  onClick={() => {
                                    setOpenAdjustStock(true);
                                    setSelectedClinic(clinic);
                                  }}
                                  label="Adjust"
                                  startIcon={<Inventory2OutlinedIcon />}
                                  size="small"
                                  variant="outlined"
                                />
                                <RegularButton
                                  onClick={() => {
                                    setOpenTransferStock(true);
                                    setSelectedClinic(clinic);
                                  }}
                                  label="Transfer"
                                  startIcon={<MoveUpOutlinedIcon />}
                                  size="small"
                                  variant="outlined"
                                  styles={{
                                    visibility:
                                      !clinic.inventory || !clinic.inventory.quantity_in_stock ? 'hidden' : 'visible',
                                  }}
                                />
                              </>
                            )}
                          </Box>
                        </Box>
                      </Box>

                      {index === 0 && clinics.length > 1 && (
                        <Box display="flex" gap="10px" alignItems="center" marginTop={SIZES.padding}>
                          <Typography variant="h5" fontWeight="600">
                            Other Clinics
                          </Typography>
                          <Divider sx={{ flex: 1 }} />
                        </Box>
                      )}
                    </Box>
                  ))}
                </ContainerColumn>
                <AddStock
                  variant={variant}
                  open={openAddStock}
                  setOpen={setOpenAddStock}
                  callbackAfterSubmit={() => setRefresh(refresh + 1)}
                  exclusiveSupplier={variant.exclusive_supplier}
                  facilityDetails={selectedClinic}
                />
                <AdjustStock
                  variant={variant}
                  open={openAdjustStock}
                  setOpen={setOpenAdjustStock}
                  callbackAfterSubmit={() => setRefresh(refresh + 1)}
                  facilityDetails={selectedClinic}
                  initialStock={selectedClinic?.inventory?.quantity_in_stock}
                />
                <TransferStock
                  variant={variant}
                  open={openTransferStock}
                  setOpen={setOpenTransferStock}
                  callbackAfterSubmit={() => setRefresh(refresh + 1)}
                  facilityDetails={selectedClinic}
                  currentStock={selectedClinic?.inventory?.quantity_in_stock}
                />
              </Box>
            )}
          </Box>
        </CustomContainer>
      </Box>
    </Box>
  );
};

export default ProductVariantsPage;
