import { Box, List, ListItem, Typography, useTheme } from '@mui/material';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { CustomModal, RegularButton } from 'core/components';

import { FacilityContext } from 'core/context/facility.context';
import { importPatients } from 'company/api/patient';
import { importServices } from 'company/api/services';
import { tokens } from 'theme/theme';
import { useSnackbar } from 'notistack';

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  callbackAfterImport?: () => void;
};

const ImportServiceModal: React.FC<Props> = ({ open, setOpen, callbackAfterImport }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [file, setFile] = useState<File>();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const { facility } = useContext(FacilityContext);

  const handleImportServices = async () => {
    setLoading(true);
    if (facility) {
      try {
        await importServices(file);

        enqueueSnackbar(`Services successfully imported!`, { variant: 'success' });
        setOpen(false);
        callbackAfterImport && callbackAfterImport();
      } catch (error) {
      } finally {
        setLoading(false);
      }
    }
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }
    setFile(e.target.files[0]);
  };

  useEffect(() => setFile(undefined), [open]);

  return (
    <>
      <CustomModal header="Import Services" open={open} setOpen={setOpen}>
        <Box>
          <Typography variant="h5"> Instructions: </Typography>

          <List dense>
            <ListItem>
              <Typography>
                1. Download the Excel template
                <a
                  href={process.env.PUBLIC_URL + '/template/service_import_template.xlsx'}
                  download="client_import_template.xlsx"
                  style={{ color: colors.accent, paddingLeft: '5px' }}
                >
                  here
                </a>
                .
              </Typography>
            </ListItem>
            <ListItem>
              <Typography>2. Tax only accepts "vat" or "non-vat", and duration is in minutes. </Typography>
            </ListItem>
          </List>

          <Typography variant="h5" mb="15px">
            Note: You can import up to 500 service per Excel file.
          </Typography>
        </Box>
        <Box marginTop={'10px'} display="grid" gridTemplateColumns="1fr 1fr">
          <Box>
            <Typography variant="h5"> Select file here: </Typography>
            <Box margin={'10px'}>
              <input type="file" onChange={handleFileChange} accept=".xlsx" />
            </Box>
          </Box>
        </Box>
        <Box display="flex" justifyContent="end">
          <RegularButton label={'Import'} onClick={handleImportServices} loading={loading} />
        </Box>
      </CustomModal>
    </>
  );
};

export default ImportServiceModal;
