import { ApiQuery } from 'core/model/interface';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'patient-notes';

export const getNotes = (facility_id: number, patient_id: number, query?: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/${facility_id}/patient/${patient_id}`, { params: query });
};

export const getNotesByAppointment = (facility_id: number, appointment_id: number, query?: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/${facility_id}/appointment/${appointment_id}`, { params: query });
};

export const getNotesByTemplate = (facility_id: number, template_id: number, query?: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/${facility_id}/template/${template_id}`, { params: query });
};

export const getPatientNotesByTemplate = (facility_id: number, patient_id: number, template_id: number, query?: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/${facility_id}/patient-note/${patient_id}/template/${template_id}`, { params: query });
};

export const getNote = (facility_id: number, id: number) => {
  return axios.get(`${baseURL}/${path}/${facility_id}/${id}`);
};

export const createNote = (facility_id: number, patient_id: number, note: any) => {
  return axios.post(`${baseURL}/${path}/${facility_id}/${patient_id}`, note);
};

export const updateNote = (facility_id: number, id: number, note: any) => {
  return axios.put(`${baseURL}/${path}/${facility_id}/${id}`, note);
};

export const autoSavePatientNote = (facility_id: number, id: number, body: any) => {
  return axios.put(`${baseURL}/${path}/${facility_id}/auto-save/${id}`, { body });
};

export const deleteNote = (facility_id: number, id: number) => {
  return axios.delete(`${baseURL}/${path}/${facility_id}/${id}`);
};

export const updateIcdCodesOfNote = (facility_id: number, patient_note_id: number, codes: any) => {
  return axios.post(`${baseURL}/${path}/${facility_id}/code/${patient_note_id}`, codes);
};

export const toggleNoteStatus = (facility_id: number, patient_note_id: number, data: any) => {
  return axios.patch(`${baseURL}/${path}/${facility_id}/toggle-status/${patient_note_id}`, data);
}
