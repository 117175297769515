import {
  PUOM_ENTITY,
  ProductUnitOfMeasureModel,
  productUnitOfMeasureFields,
  productUnitOfMeasureTableColumns,
} from './ProductUOMModel';
import { deleteProductUOM, getAllProductUOMs } from 'company/api/product-uom';
import { forwardRef, useContext, useRef, useState } from 'react';

import { ApiQuery } from 'core/model/interface';
import { Box } from '@mui/material';
import { FacilityContext } from 'core/context/facility.context';
import ProductUOMForm from './ProductUOMForm';
import { TableActionProps } from 'core/PageBuilder/CareGoTable';
import TemplateTable from 'company/entities/Template/TemplateTable';

type Props = {
  visibleColumns?: string[];
  tableRef?: any;
  tableAction?: TableActionProps;
  callBackAfterSubmitFromAppointment?: () => void;
};

const ProductUOMTable = forwardRef((props: Props, ref) => {
  const { visibleColumns, tableRef, tableAction, callBackAfterSubmitFromAppointment } = props;
  const [selectedRow, setSelectedRow] = useState<ProductUnitOfMeasureModel>();

  const backupRef: any = useRef();
  const mainRef: any = tableRef ?? backupRef;
  const { facility } = useContext(FacilityContext);

  const getData = (query: ApiQuery) => {
    return getAllProductUOMs(facility.id, query);
  };

  const refreshTable = () => {
    if (mainRef.current) {
      mainRef.current.refreshTable();
    } else {
      console.error('tableRef is not assigned correctly');
    }
  };

  function callBackAfterSubmit() {
    refreshTable();
    callBackAfterSubmitFromAppointment && callBackAfterSubmitFromAppointment();
    mainRef.current?.closeFormModal();
  }

  const handleDeleteProductUOM = (data: any) => {
    return deleteProductUOM(facility.id, data);
  };

  return (
    <Box>
      <TemplateTable
        // tableAction={{
        //   rowActions,
        // }}
        // hideAddUpdateDeleteAction
        handleDelete={(data) => handleDeleteProductUOM(data)}
        // hideUpdateAction
        // hideDeleteAction
        useColoredTable
        ref={mainRef}
        entityName={PUOM_ENTITY}
        fields={productUnitOfMeasureFields}
        getData={getData}
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
        visibleColumns={visibleColumns ?? productUnitOfMeasureTableColumns}
        templateForm={<ProductUOMForm callbackAfterSubmit={callBackAfterSubmit} uom={selectedRow} />}
      />
    </Box>
  );
});

export default ProductUOMTable;
