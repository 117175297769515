/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid, Typography, useTheme } from '@mui/material';
import { ColoredTable, ConfirmationDialog, CustomModal, Dropdown, ExportButton, RegularButton } from 'core/components';
import { ContainerColumn, ContainerRow } from 'core/components/containers';
import { Link, useNavigate } from 'react-router-dom';
import { deleteClinicProduct, exportProductDirectory, getProducts } from 'company/api/products';
import { deleteVariant, getInventories } from 'company/api/inventories';
import { displayAttribute, formatCurrency, formatDate } from 'core/utils';
import { useContext, useEffect, useRef, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import { CareGoPage } from 'core/PageBuilder';
import CustomFilter from 'core/components/CustomFilter';
import DeleteButton from 'core/components/buttons/DeleteButton';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { FacilityContext } from 'core/context/facility.context';
import GeneralProductLogs from './components/GeneralProductLogs';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ProductForm from 'company/entities/modules/InventorySystem/Product/ProductForm';
import { SIZES } from 'theme/constants';
import UpdateButton from 'core/components/buttons/UpdateButton';
import UpdateDetails from 'company/entities/modules/InventorySystem/components/UpdateDetails';
import { UserContext } from 'core/context/user.context';
import { tokens } from 'theme/theme';
import { useSnackbar } from 'notistack';

interface Product {
  attributes: string;
  id: number;
  product_id: number;
  variant_id: number;
  variant_name: string;
  variant_description: string;
  price: string;
  variant_price: string;
  sku: string;
  product_name: string;
  quantity_in_stock: string;
  exclusive_supplier?: number;
  exclusive_supplier_name: string;
  supplier_name: string;
  category: string;
  category_id: number;
  description: string;
  category_description: string;
  is_active: string;

  created_at: string;
}

const ProductPage = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { user } = useContext(UserContext);
  const { facility } = useContext(FacilityContext);
  const { enqueueSnackbar } = useSnackbar();
  const [products, setProducts] = useState<Product[]>([]);
  const [productSearchQuery, setProductSearchQuery] = useState<string>('');

  const [selectedProductVariant, setSelectedProductVariant] = useState<Product>();
  const [selectedProduct, setSelectedProduct] = useState<Product>();
  const [openUpdateDetails, setOpenUpdateDetails] = useState(false);
  const [productFilters, setProductFilters] = useState<string[]>([]);
  const [openExclusiveProductModal, setOpenExclusiveProductModal] = useState<boolean>(false);
  const tableRef: any = useRef();
  const [openDeleteProductVariant, setOpenDeleteProductVariant] = useState<boolean>(false);
  const [openDeleteProduct, setOpenDeleteProduct] = useState<boolean>(false);
  const [refreshTransactions, setRefreshTransactions] = useState<boolean>(false);
  const [loadingExport, setLoadingExport] = useState<boolean>(false);

  const navigate = useNavigate();

  const refreshTable = () => {
    if (tableRef.current) {
      tableRef.current.refreshTable();
    } else {
      console.error('tableRef is not assigned correctly');
    }
  };

  const getAllProducts = () => {
    if (facility) {
      return getInventories(facility.id, {
        length: 1000,
        order_by: 'updated_at',
        order: 'desc',
        search: productSearchQuery,
      }).then((res) => {
        const newProducts = res.data.data;
        setProducts(newProducts);
      });
    }
  };

  const actions = [
    {
      label: 'Update',
      action: (data: any) => {
        setSelectedProductVariant(data);
        setOpenUpdateDetails(true);
      },
      icon: <EditOutlinedIcon />,
    },
    {
      label: 'Delete',
      action: (data: any) => {
        setSelectedProductVariant(data);
        setOpenDeleteProductVariant(true);
      },
      icon: <DeleteOutlinedIcon color="error" />,
      labelStyle: { color: colors.redAccent },
    },
  ];

  const viewCategories = () => {
    navigate('/company/inventory/products/categories');
  };

  const handleDeleteProductVariant = () => {
    if (selectedProductVariant) {
      deleteVariant(selectedProductVariant.id)
        .then((res) => {
          enqueueSnackbar('Product Variant successfully deleted!', { variant: 'success' });
          getAllProducts();
          setRefreshTransactions(true);
        })
        .catch((error) => {
          enqueueSnackbar('Error occured! Unable to delete product variant.', { variant: 'error' });
        });
    }
  };

  const handleDeleteProduct = () => {
    if (selectedProduct) {
      deleteClinicProduct(selectedProduct.id)
        .then((res) => {
          enqueueSnackbar('Product successfully deleted!', { variant: 'success' });
          refreshTable();
          getAllProducts();
          setRefreshTransactions(true);

          const currentOrders = JSON.parse(localStorage.getItem('orders') || '[]');
          const updatedOrders = currentOrders.filter((order: any) => order.product_id !== selectedProduct.id);
          localStorage.setItem('orders', JSON.stringify(updatedOrders));
        })
        .catch((error) => {
          enqueueSnackbar('Error occurred! Unable to delete product.', { variant: 'error' });
        });
    }
  };

  const handleExport = () => {
    setLoadingExport(true);
    try {
      exportProductDirectory(facility.id, facility.facility_name, {
        search: productSearchQuery,
      });
    } finally {
      setLoadingExport(false);
    }
  };

  useEffect(() => {
    getAllProducts();
  }, [facility]);

  return (
    <>
      <CareGoPage loading={!user}>
        <Box display="flex" gap="30px" width="100%">
          <Box width="100%" flex="1">
            <Box display="flex" flexDirection="column" gap="20px" sx={{ backgroundColor: 'none' }}>
              <ColoredTable
                ref={tableRef}
                getData={getProducts}
                searchOnLeft
                headerComponent={
                  <ContainerColumn gap="10px">
                    <ContainerRow gap="10px" sx={{ justifyContent: 'flex-end' }}>
                      {/* <RegularButton
                        variant="outlined"
                        onClick={() => viewCategories()}
                        label="View Categories"
                        startIcon={<RemoveRedEyeOutlinedIcon />}
                      /> */}
                      <RegularButton
                        startIcon={<AddIcon />}
                        label="Add Product"
                        color="primary"
                        variant="contained"
                        onClick={() => {
                          setSelectedProduct(undefined);
                          setOpenExclusiveProductModal(true);
                        }}
                      />
                    </ContainerRow>
                    <ContainerRow gap="5px" sx={{ justifyContent: 'flex-end' }}>
                      <CustomFilter
                        optionList={[
                          { label: 'Available', id: 'available' },
                          { label: 'Low Stock', id: 'lowStock' },
                          { label: 'Unavailable', id: 'unavailable' },
                        ]}
                        buttonLabel="Status"
                        selected={productFilters}
                        setSelected={setProductFilters}
                      />
                      <ExportButton onClick={handleExport} loading={loadingExport} />
                    </ContainerRow>
                  </ContainerColumn>
                }
                columns={[
                  {
                    field: 'product_name',
                    headerName: 'Product Name',
                    flex: 1,
                  },
                  {
                    field: 'category',
                    headerName: 'Category',
                    flex: 1,
                  },
                  {
                    field: 'action',
                    sortable: false,
                    headerName: 'Actions',
                    headerAlign: 'center',
                    align: 'center',
                    flex: 1,
                    renderCell: (params) => (
                      <Box display="flex" gap="10px" alignItems="center">
                        <UpdateButton
                          onClick={(e) => {
                            e.stopPropagation();
                            setSelectedProduct(params.row);
                            setOpenExclusiveProductModal(true);
                          }}
                        />
                        <DeleteButton
                          onClick={(e) => {
                            e.stopPropagation();
                            setSelectedProduct(params.row);
                            setOpenDeleteProduct(true);
                          }}
                        />
                      </Box>
                    ),
                  },
                ]}
                displayChildComponent={(row) => {
                  const filteredProducts = products.filter((product) => {
                    const quantityFilter =
                      +product.quantity_in_stock <= 0
                        ? 'unavailable'
                        : +product.quantity_in_stock >= 10
                        ? 'available'
                        : 'lowStock';
                    const idFilter = product.product_id === row.id;
                    return (!productFilters.length || productFilters.includes(quantityFilter)) && idFilter;
                  });

                  return (
                    <Box
                      sx={{ overflowY: 'auto' }}
                      display="flex"
                      flexDirection="column"
                      gap={'20px'}
                      padding={SIZES.paddingS}
                      paddingRight={0}
                    >
                      {filteredProducts.length > 0 ? (
                        filteredProducts.map((product) => (
                          <Grid
                            key={product.variant_id}
                            container
                            spacing={1}
                            sx={{
                              borderRadius: '10px',
                              padding: '20px 15px',
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              width: '100%',
                              '&:hover': {
                                backgroundColor: colors.hoverTableRow,
                              },
                            }}
                          >
                            <Grid item xs={12} sm={6} md={6} lg={2.75}>
                              <Link
                                to={`/company/inventory/products/variant?id=${product.id}`}
                                style={{ textDecoration: 'none' }}
                                state={{ from: '/company/inventory/pos' }}
                              >
                                <Typography
                                  variant="h5"
                                  color={colors.accent}
                                  mb="5px"
                                  fontWeight="bold"
                                  sx={{ '&:hover': { textDecoration: 'underline' } }}
                                >
                                  {product.product_name}
                                </Typography>
                              </Link>
                              {displayAttribute(product.attributes)}
                              <Typography fontSize={'10px'} mb="3px" color={colors.grey_text}>
                                Category: {product.category}
                              </Typography>
                              <Typography>{product.sku}</Typography>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={6}
                              md={3}
                              lg={1.5}
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'left',
                                alignItems: 'left',
                              }}
                            >
                              <Typography fontSize={'10px'} color={colors.grey_text}>
                                Price
                              </Typography>
                              <Typography color={colors.accent} fontWeight="bold">
                                {formatCurrency(product.price)}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={6}
                              md={4}
                              lg={1}
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'left',
                                alignItems: 'left',
                              }}
                            >
                              {parseInt(product.quantity_in_stock) <= 0 ? (
                                <Typography color={'#e74c3c'} fontSize={'10px'}>
                                  Unavailable
                                </Typography>
                              ) : parseInt(product.quantity_in_stock) > 0 &&
                                parseInt(product.quantity_in_stock) < 10 ? (
                                <Typography color={'#fe9801'} fontSize={'10px'}>
                                  Low Stock
                                </Typography>
                              ) : (
                                <Typography color={'#4CAF50'} fontSize={'10px'}>
                                  Available
                                </Typography>
                              )}
                              <Typography>{product.quantity_in_stock}</Typography>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={6}
                              md={4}
                              lg={1.5}
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'left',
                                alignItems: 'left',
                              }}
                            >
                              <Typography fontSize={'10px'} color={colors.grey_text}>
                                Date Added
                              </Typography>
                              <Typography>{formatDate(product.created_at)}</Typography>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={6}
                              md={2}
                              lg={2}
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'left',
                                alignItems: 'left',
                              }}
                            >
                              <Typography fontSize={'10px'} color={colors.grey_text}>
                                Supplier
                              </Typography>
                              <Typography>
                                {product.exclusive_supplier_name ? product.exclusive_supplier_name : 'N/A'}{' '}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={6}
                              md={2}
                              lg={1}
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'left',
                                alignItems: 'left',
                              }}
                            >
                              <Dropdown
                                optionList={actions}
                                isIcon
                                icon={<MoreHorizIcon sx={{ fontSize: '24px', color: colors.accent }} />}
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                                data={product}
                              />
                            </Grid>
                          </Grid>
                        ))
                      ) : (
                        <Typography variant="h6" color="textSecondary" align="center">
                          No products found.
                        </Typography>
                      )}
                    </Box>
                  );
                }}
              />
            </Box>
          </Box>

          <GeneralProductLogs refresh={refreshTransactions} />
        </Box>

        {selectedProductVariant && (
          <>
            <UpdateDetails
              variant={selectedProductVariant}
              open={openUpdateDetails}
              setOpen={setOpenUpdateDetails}
              callbackAfterSubmit={() => {
                setOpenUpdateDetails(false);
                getAllProducts();
                setRefreshTransactions(true);
              }}
            />

            <ConfirmationDialog
              content={
                'Are you sure you want to delete ' +
                selectedProductVariant.product_name +
                ' (' +
                selectedProductVariant.variant_name +
                ')?'
              }
              open={openDeleteProductVariant}
              setOpen={setOpenDeleteProductVariant}
              onConfirm={handleDeleteProductVariant}
            />
          </>
        )}
      </CareGoPage>

      <CustomModal
        header={selectedProduct ? 'Update Product' : 'Add Product'}
        open={openExclusiveProductModal}
        setOpen={setOpenExclusiveProductModal}
        width={800}
      >
        <ProductForm
          product={selectedProduct}
          callbackAfterSubmit={(data: any) => {
            refreshTable();
            getAllProducts();
            setRefreshTransactions(true);
            setOpenExclusiveProductModal(false);
            navigate(`/company/inventory/products/variant?id=${data.variant?.id}`);
          }}
        />
      </CustomModal>

      {selectedProduct && (
        <ConfirmationDialog
          content={'Are you sure you want to delete ' + selectedProduct.product_name + '?'}
          open={openDeleteProduct}
          setOpen={setOpenDeleteProduct}
          onConfirm={handleDeleteProduct}
        />
      )}
    </>
  );
};

export default ProductPage;
