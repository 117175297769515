import { CustomForm, CustomModal } from 'core/components';
import { addServiceToStage, getServicesByStage } from 'company/api/health-packages';
import { useContext, useEffect, useState } from 'react';

import { ApiQuery, DROPDOWN_FIELD } from 'core/model/interface';
import { FacilityContext } from 'core/context/facility.context';
import { FieldInput } from 'core/model/interface';
import { FormikHelpers } from 'formik';
import { HealthPackageStage } from 'company/model/Entities';
import { add_services_schema } from 'company/model/schema';
import { tranformFormErrors } from 'core/utils';
import { useSnackbar } from 'notistack';
import { exportInvoicesByAllitems } from 'company/api/patient-billing';

type UserInput = {
  service_id: number[];
};

interface UserFieldInput extends FieldInput {
  field_name?: keyof UserInput;
}

const initialValues: UserInput = {
  service_id: [],
};

type AddServicesProps = {
  openAddServicesModal: boolean;
  setOpenAddServicesModal: (openAddMultipleStagesModal: boolean) => void;
  stage?: HealthPackageStage;
  forExport?: boolean;
  callbackAfterChanges?: () => void;
  query?: ApiQuery;
};

const AddServices: React.FC<AddServicesProps> = ({
  openAddServicesModal,
  setOpenAddServicesModal,
  stage,
  forExport = false,
  callbackAfterChanges,
  query,
}) => {
  const { facility } = useContext(FacilityContext);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const [services, setServices] = useState<any>(initialValues);

  const formFields: UserFieldInput[] = [
    {
      field_name: 'service_id',
      type: DROPDOWN_FIELD.SERVICE,
      span: 4,
      multiple: true,
      ...(forExport && {
        placeholder: 'Select services for export',
        helperText: 'These services will be included in the excel export',
      }),
    },
  ];

  const handleSubmit = (data: any, formikHelpers: FormikHelpers<any>) => {
    setLoading(true);

    if (forExport) {
      handleExport(data, formikHelpers);
    } else if (stage && data) {
      data.services = data.service_id ? JSON.stringify(data.service_id.map((service: any) => service.value)) : '';
      return addServiceToStage(stage.package_id, stage.id, data)
        .then((res) => {
          successFormSubmit(res, formikHelpers);
        })
        .catch((err) => console.error(err));
    }
  };

  const handleExport = async (data: any, formikHelpers: FormikHelpers<any>) => {
    try {
      const selectedServiceIds = data.service_id?.map((service: any) => Number(service.value)) || [];
      console.log('data.service_id', data.service_id);
      console.log('selectedServiceIds', selectedServiceIds);

      await exportInvoicesByAllitems(facility.id, facility.facility_name + ' - ', {
        ...query,
        service_ids: selectedServiceIds,
      });

      successFormSubmit({ status: 200 }, formikHelpers);
    } catch (error) {
      console.error('Export error:', error);
      enqueueSnackbar('Export failed!', { variant: 'error' });
      setLoading(false);
    }
  };

  const successFormSubmit = (res: any, { setErrors }: FormikHelpers<any>) => {
    if (res.status === 200) {
      const message = forExport ? 'Export generated successfully!' : 'Services successfully added!';
      enqueueSnackbar(message, { variant: 'success' });
      setOpenAddServicesModal(false);
      callbackAfterChanges && callbackAfterChanges();
    } else if (res.response?.status === 400) {
      setErrors(tranformFormErrors(res.response.data));
    }
    setLoading(false);
  };

  const getStageServices = async () => {
    if (stage?.id) {
      return getServicesByStage(facility.id, stage.id).then((res) => {
        setServices(res.data.data);
      });
    }
  };

  useEffect(() => {
    if (openAddServicesModal && !forExport) {
      getStageServices();
    }
  }, [openAddServicesModal, stage?.id, forExport]);

  const modalTitle = forExport ? 'Select Services for Export' : 'Add Services';

  return (
    <CustomModal header={modalTitle} open={openAddServicesModal} setOpen={setOpenAddServicesModal} width={500}>
      <CustomForm
        onSubmit={handleSubmit}
        fields={formFields}
        initialValues={{
          service_id:
            services.length > 0
              ? services.map((u: any) => {
                  return { key: u.service_name, value: u.service_id };
                })
              : [],
        }}
        schema={add_services_schema}
        loading={loading}
        submitButtonId={forExport ? 'export-services-submit-button' : 'add-services-form-submit-button'}
        submitButtonText={forExport ? 'Export' : 'Add Services'}
      />
    </CustomModal>
  );
};

export default AddServices;
