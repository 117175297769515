import {
  INVOICE_ITEM_ENTITY_NAME,
  InvoiceItemModel,
  invoiceItemFields,
  invoiceItemTableColumns,
} from './InvoiceItemModel';
import { Typography, useMediaQuery, useTheme } from '@mui/material';
import { deleteInvoiceItem, getInvoiceItems } from 'company/api/invoice-items';
import { forwardRef, useContext, useEffect, useRef, useState } from 'react';

import { ApiQuery } from 'core/model/interface';
import { Box } from '@mui/system';
import { FacilityContext } from 'core/context/facility.context';
import { SIZES } from 'theme/constants';
import TemplateTable from '../../../../Template/TemplateTable';
import { WaitForFacility } from 'company/components';
import { formatCurrency } from 'core/utils';
import { tokens } from 'theme/theme';

type Props = {
  visibleColumns?: string[];
  hideActions?: boolean;
  tableRef?: any;
  useColoredTable?: boolean;
  invoiceId: number;
  onGetData?: (data: any) => void;
  setInvoiceItemTableLoading?: any;
};

const InvoiceItemTable = forwardRef((props: Props, ref) => {
  const { visibleColumns, hideActions, tableRef, useColoredTable, invoiceId, onGetData, setInvoiceItemTableLoading } =
    props;
  const backupRef: any = useRef();
  const mainRef: any = tableRef ?? backupRef;
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { facility } = useContext(FacilityContext);
  const [selectedRow, setSelectedRow] = useState<InvoiceItemModel>();
  const isMobile = useMediaQuery('(max-width:768px)');
  const getData = async (query: ApiQuery) => {
    const response = await getInvoiceItems(facility.id, 'all', invoiceId, { ...query, length: 1000 });
    response.data.data = response.data.data.filter((item: any) => !(item.variant_id !== null && item.auto_added));
    console.log('filteredData1', response);

    return response;
  };

  const [invoiceItemsDetails, setInvoiceItemsDetails] = useState<any>(null);

  useEffect(() => {
    setInvoiceItemTableLoading(true);
    async function getData(query: ApiQuery) {
      try {
        if (!invoiceId) return;
        const res = await getInvoiceItems(facility.id, 'all', invoiceId, { ...query, length: 1000 });
        const filteredData = res.data.data.filter((item: any) => !(item.variant_id !== null && item.auto_added));
        setInvoiceItemsDetails(filteredData);
        console.log('filteredData2', filteredData);
      } catch (err) {
        console.error(err);
      }
    }
    getData({});
  }, [invoiceId]);

  const handleDelete = (data: any) => {
    return deleteInvoiceItem(facility!.id, data).then((res) => {
      mainRef.current?.refreshTable();
    });
  };

  // useEffect(() => {
  //   mainRef.current?.refreshTable();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [invoiceId]);

  const totalAmount =
    invoiceItemsDetails &&
    invoiceItemsDetails.reduce((total: any, item: any) => {
      return total + parseFloat(item.total_price);
    }, 0);

  if (isMobile && invoiceItemsDetails)
    return (
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
          mt: '10px',
        }}
      >
        {invoiceItemsDetails.map((invoiceItem: any) => {
          const totalAmountWithoutDiscount = invoiceItem.quantity * parseFloat(invoiceItem.original_price);

          const totalDiscount =
            invoiceItem.discount_type === 'percentage'
              ? totalAmountWithoutDiscount * (invoiceItem.discount_percentage / 100)
              : invoiceItem.discount_type === 'new_unit_price'
              ? totalAmountWithoutDiscount - invoiceItem.unit_price * invoiceItem.quantity
              : 0;

          // Calculate total discounted amount
          const totalDiscountedAmount = totalAmountWithoutDiscount - totalDiscount;

          return (
            <Box
              key={invoiceItem.id}
              sx={{
                backgroundColor: colors.light_blue_background,
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                padding: '15px 20px',
                borderRadius: '10px',
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px', minWidth: '100px', maxWidth: '100px' }}>
                <Typography sx={{ fontSize: '14px', fontWeight: '500' }}>{invoiceItem.item_name}</Typography>
                {invoiceItem.included_services && (
                  <Typography sx={{ fontSize: '12px', color: colors.grey_text }}>
                    Includes: {invoiceItem.included_services}
                  </Typography>
                )}
              </Box>

              <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px', textAlign: 'end' }}>
                <Typography>₱ {invoiceItem.original_price}</Typography>
                <Box sx={{ display: 'flex', gap: '8px', justifyContent: 'flex-end' }}>
                  {invoiceItem.discount_type && invoiceItem.discount_type === 'percentage' && (
                    <Typography
                      sx={{
                        padding: '0 10px',
                        backgroundColor: colors.grey_text,
                        borderRadius: '15px',
                        color: 'white',
                        fontWeight: '500',
                        fontSize: '13px',
                      }}
                    >
                      {`${invoiceItem.discount_percentage}% OFF`}
                    </Typography>
                  )}
                  {/* {invoiceItem.discount_type && (
                    <Typography>{`- ${formatCurrency(totalDiscount)} Shop Discount`}</Typography>
                  )} */}
                </Box>
                {!!invoiceItem.sc_pwd_discounted && (
                  <Box display="flex" alignItems="center">
                    <Typography
                      fontWeight="500"
                      fontSize="13px"
                      sx={{
                        backgroundColor: 'grey',
                        color: 'white',
                        paddingInline: SIZES.paddingS,
                        borderRadius: '10px',
                      }}
                    >
                      20% OFF - SC/PWD
                    </Typography>
                  </Box>
                )}
              </Box>
              <Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                  <Typography>x {invoiceItem.quantity}</Typography>
                </Box>
              </Box>

              <Typography sx={{ fontSize: '14px', fontWeight: '600', color: colors.primary }}>
                {formatCurrency(invoiceItem.total_price)}
              </Typography>
            </Box>
          );
        })}

        {/* <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', mt: '10px' }}>
          <Typography sx={{ fontSize: '1rem', fontWeight: '600' }}>Total Amount</Typography>
          <Typography sx={{ fontSize: '1rem', fontWeight: '600', color: colors.primary }}>
            {formatCurrency(totalAmount)}
          </Typography>
        </Box> */}
      </Box>
    );
  return (
    <WaitForFacility facility={facility}>
      <TemplateTable
        hideAddButton={true}
        hideDeleteAction={hideActions}
        hideUpdateAction={true}
        useColoredTable={useColoredTable}
        ref={mainRef}
        entityName={INVOICE_ITEM_ENTITY_NAME}
        fields={invoiceItemFields}
        visibleColumns={visibleColumns ?? invoiceItemTableColumns}
        rowId="id"
        getData={getData}
        onGetData={onGetData}
        handleDelete={handleDelete}
        hideSearch
        hidePagination
        tableAction={{
          hideRowActionCallback: (data) => data.status === 'paid',
        }}
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
      />
    </WaitForFacility>
  );
});

export default InvoiceItemTable;
