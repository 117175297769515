import { useContext, useEffect, useState } from 'react';

import { UserContext } from 'core/context/user.context';
import { changeNullToBlank } from 'core/utils';
import {
  create_exported_report_schema,
  EXPORTED_REPORT_ENTITY_NAME,
  exportedReportFields,
  exportedReportFormFields,
  exportedReportInitialValues,
  updateExportedReportFormFields,
} from './ExportedReportModel';
import TemplateForm from 'company/entities/Template/TemplateForm';
import { createSetupExportedReports, updateSetupExportedReports } from 'company/api/setup-exported-reports';

type Props = {
  exportedReport?: any;
  readOnly?: boolean;
  showSubmitAtTop?: boolean;
  callbackAfterSubmit?: (data?: any) => void;
};

const ExportedReportForm = (props: Props) => {
  const { exportedReport, readOnly, showSubmitAtTop, callbackAfterSubmit } = props;
  const { user } = useContext(UserContext);
  const [dropdownOptions, setDropdownOptions] = useState<any>([]);

  useEffect(() => {
    if (user) {
      setDropdownOptions({
        facilities: user
          ? user?.facilities?.map((facility) => ({
              key: facility.facility_name,
              value: facility.id,
            }))
          : [],
      });
    }
  }, [user]);

  const handleSubmit = async (data: any) => {
    if (data.facilities) {
      var assigned_facilities = data.facilities.map((facility: { value: { toString: () => any } }) =>
        facility.value.toString()
      );
      data.facility_ids = JSON.stringify(assigned_facilities);
    }
    return exportedReport ? updateSetupExportedReports(exportedReport.id, data) : createSetupExportedReports(data);
  };

  useEffect(() => {
    if (exportedReport) {
      const facilitiesArray = exportedReport.assigned_facilities?.split(', ') ?? [];
      const idsArray = exportedReport.assigned_facilities_ids?.split(',').map(Number) ?? [];

      const selectedClinics = facilitiesArray.map((key: any, index: string | number) => ({
        key,
        value: idsArray[index],
      }));
      exportedReport.facilities = exportedReport.assigned_facilities?.length > 0 ? selectedClinics : [];
    }
  }, [exportedReport]);

  return (
    <TemplateForm
      submitButtonId={'create_exported_report_submit_button'}
      entityName={EXPORTED_REPORT_ENTITY_NAME}
      fields={exportedReportFields}
      visibleFields={exportedReport ? updateExportedReportFormFields : exportedReportFormFields}
      dropdownOptions={dropdownOptions}
      entity={exportedReport}
      initialValues={exportedReport ? changeNullToBlank(exportedReport) : exportedReportInitialValues}
      schema={create_exported_report_schema}
      showSubmitAtTop={showSubmitAtTop}
      readOnly={readOnly}
      formSubmitApiFunction={handleSubmit}
      callbackAfterSubmit={callbackAfterSubmit}
    />
  );
};

export default ExportedReportForm;
