import { ApiQuery } from 'core/model/interface';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'exported-report-signatories';

export const getExportedReportSignatory = (id: number) => {
  return axios.get(`${baseURL}/${path}/${id}`);
};

export const getExportedReportSignatories = (facility_id: number, exported_report_id: number) => {
  return axios.get(`${baseURL}/${path}/${facility_id}/${exported_report_id}`);
};

export const getAllExportedReportSignatories = (query?: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/`, {
    params: query,
  });
};

export const createExportedReportSignatory = (data: any) => {
  return axios.post(`${baseURL}/${path}`, data);
};

export const updateExportedReportSignatory = (id: number, data: any) => {
  return axios.put(`${baseURL}/${path}/${id}`, data);
};

export const deleteExportedReportSignatory = (id: number) => {
  return axios.delete(`${baseURL}/${path}/${id}`);
};

export const deleteExportedReportSignatories = (exported_report_id: number) => {
  return axios.delete(`${baseURL}/${path}/delete-all/${exported_report_id}`);
};

// export const applyTemplateSignatoriesToAllNotes = (
//   facility_id: number,
//   template_id: number,
//   signatories: PatientNoteSignatory[]
// ) => {
//   return axios.post(`${baseURL}/${path}/apply-template-signatories`, {
//     facility_id,
//     template_id,
//     signatories,
//   });
// };
