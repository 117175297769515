import { Box, Skeleton, useMediaQuery } from '@mui/material';
import CustomDateRangeFilter, { DateRangeValue } from 'core/components/CustomDateRangeFilter';
import DashboardPieChart, { PieChartData } from './components/DashboardPieChart';
import { formatCurrency, formatNumber, formatPaymentMethodLabel } from 'core/utils';
import { getBestSeller, getDashboardStat } from 'company/api/inventory-dashboard';
import { useCallback, useContext, useEffect, useState } from 'react';

import { BreadcrumbContext } from 'core/context/breadcrumb.context';
import { CareGoPage } from 'core/PageBuilder';
import ChatIcon from '@mui/icons-material/Chat';
import CustomClinicFilter from 'core/components/CustomClinicFilter';
import CustomDashboardWidget from 'core/components/CustomDashboardWidget';
import DashboardBarChart from './components/DashboardBarChart';
import DashboardLineChart from './components/DashboardLineChart';
import { FacilityContext } from 'core/context/facility.context';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import { LineSeriesType } from '@mui/x-charts/models';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import { UserContext } from 'core/context/user.context';

type Props = {
  fromClinicManagement?: boolean;
};

const InventoryDashboard: React.FC<Props> = ({ fromClinicManagement }) => {
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const { facility } = useContext(FacilityContext);
  const { user } = useContext(UserContext);

  const [stats, setStats] = useState({
    total_sales_this_month: 0,
    total_sales_this_week: 0,
    total_sales_this_year: 0,
    month_on_month: [],
    month_on_month_session_count: [],
    sms_credits: 0,
  });

  const [credit, setCredits] = useState({
    sms_credits: 0,
  });

  const [dateDependentData, setDateDependentData] = useState<any>(null);
  const [filterDateRange, setFilterDateRange] = useState<DateRangeValue>([undefined, undefined]);
  const [clinicFilters, setClinicFilters] = useState<string[]>([]);

  const isMobilePhone = useMediaQuery('(max-width:768px)');

  useEffect(() => {
    if (facility) {
      getDashboardStat(facility.id).then((res) => {
        setStats(res.data);
      });
    }

    setBreadcrumb([{ label: 'Dashboard' }]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facility]);

  useEffect(() => {
    setDateDependentData(undefined);
    const timeoutId = setTimeout(() => {
      async function getBestSellingProduct() {
        try {
          const data = await getBestSeller(facility.id, {
            start_date: filterDateRange[0],
            end_date: filterDateRange[1],
          });
          setDateDependentData(data.data);
        } catch (err) {
          console.error(err);
        }
      }
      if (facility) getBestSellingProduct();
    }, 200);

    return () => clearTimeout(timeoutId);
  }, [filterDateRange, facility]);

  const processBestSellingDataByQuantity = useCallback(
    (setChartDataKeys: (key: string[]) => void, setChartDataValues: (values: number[]) => void) => {
      const values = dateDependentData?.best_seller.map((item: { total_sales: any }) => parseInt(item.total_sales));
      const names = dateDependentData?.best_seller.map(
        (item: { product_name: string; item_name: string }) => `${item.product_name} \n${item.item_name}`
      );
      setChartDataKeys(names);
      setChartDataValues(values);
    },
    [dateDependentData]
  );

  const processBestSellingDataBySales = useCallback(
    (setChartDataKeys: (key: string[]) => void, setChartDataValues: (values: number[]) => void) => {
      const values = dateDependentData?.most_sales.map((item: { total_sales: any }) => parseFloat(item.total_sales));
      const names = dateDependentData?.most_sales.map(
        (item: { product_name: string; item_name: string }) => `${item.product_name} \n${item.item_name}`
      );
      setChartDataKeys(names);
      setChartDataValues(values);
    },
    [dateDependentData]
  );

  const processMonthOnMonthSales = useCallback(
    (
      setChartDataKey: (key: string) => void,
      setDataSets: (dataSet: any[]) => void,
      setChartDataSeries: (values: LineSeriesType[]) => void
    ) => {
      const record: any = stats?.month_on_month_session_count[0];
      if (stats && record) {
        const facilities = record.facility_names;
        setChartDataKey('month_name');
        setDataSets(stats?.month_on_month);
        setChartDataSeries(
          Object.keys(record.facility_names).map((facility: any) => ({
            id: facility,
            label: facilities[facility],
            dataKey: facility,
            valueFormatter: (value: number) => formatCurrency(value),
          })) as any[]
        );
      }
    },
    [stats]
  );

  const processMonthOnMonthSessionCount = useCallback(
    (
      setChartDataKey: (key: string) => void,
      setDataSets: (dataSet: any[]) => void,
      setChartDataSeries: (values: LineSeriesType[]) => void
    ) => {
      const record: any = stats?.month_on_month_session_count[0];
      if (stats && record) {
        const facilities = record.facility_names;
        setChartDataKey('month_name');
        setDataSets(stats?.month_on_month_session_count);
        setChartDataSeries(
          Object.keys(record.facility_names).map((facility: any) => ({
            id: facility,
            label: facilities[facility],
            dataKey: facility,
          })) as any[]
        );
      }
    },
    [stats]
  );

  const processSalesByPaymentMethod = useCallback(
    (setPieChartData: (data: PieChartData[]) => void) => {
      const data: PieChartData[] = dateDependentData?.payment_method.map((item: any) => ({
        value: parseFloat(item.total_paid),
        label: formatPaymentMethodLabel(item.payment_method),
      }));
      setPieChartData(data);
    },
    [dateDependentData]
  );

  const processHMOBreakdown = useCallback(
    (setPieChartData: (data: PieChartData[]) => void) => {
      const data: PieChartData[] = dateDependentData?.hmo_breakdown.map((item: any) => ({
        value: item.total_count,
        label: item.hmo_name ?? 'Cash',
      }));
      setPieChartData(data);
    },
    [dateDependentData]
  );

  const processHMOBreakdownBySales = useCallback(
    (setChartDataKeys: (key: string[]) => void, setChartDataValues: (values: number[]) => void) => {
      const values = dateDependentData?.hmo_breakdown.map((item: { total_amount: any }) => parseInt(item.total_amount));
      const names = dateDependentData?.hmo_breakdown.map((item: { hmo_name: string }) => item.hmo_name ?? 'Cash');
      setChartDataKeys(names);
      setChartDataValues(values);
    },
    [dateDependentData]
  );

  const processServiceProviderPatientServed = useCallback(
    (setPieChartData: (data: PieChartData[]) => void) => {
      const data: PieChartData[] = dateDependentData?.service_provider_total.map((item: any) => ({
        value: item.total_count,
        label: item.service_provider,
      }));
      setPieChartData(data);
    },
    [dateDependentData]
  );

  const processServiceProviderPatientTotalPayment = useCallback(
    (setChartDataKeys: (key: string[]) => void, setChartDataValues: (values: number[]) => void) => {
      const values = dateDependentData?.service_provider_total.map((item: { total_amount: any }) =>
        parseInt(item.total_amount)
      );
      const names = dateDependentData?.service_provider_total.map(
        (item: { service_provider: string }) => item.service_provider
      );
      setChartDataKeys(names);
      setChartDataValues(values);
    },
    [dateDependentData]
  );

  const processServiceProviderPatientTotalPaymentPie = useCallback(
    (setPieChartData: (data: PieChartData[]) => void) => {
      const data: PieChartData[] = dateDependentData?.service_provider_total.map((item: any) => ({
        value: parseInt(item.total_amount),
        label: item.service_provider,
      }));
      setPieChartData(data || []);
    },
    [dateDependentData]
  );

  const facilityOptions = Array.isArray(user?.facilities)
    ? user.facilities.map((facility) => ({
        label: facility.facility_name,
        id: facility.id.toString(),
      }))
    : [];

  return (
    <CareGoPage header={fromClinicManagement ? undefined : 'Dashboard'}>
      {stats ? (
        <>
          <Box
            display="grid"
            gridTemplateColumns={'repeat(12, 1fr)'}
            // gridAutoRows="140px"
            gap={isMobilePhone ? '5px' : '20px'}
            mb="20px"
          >
            <CustomDashboardWidget
              count={`${formatCurrency(stats.total_sales_this_week)}`}
              label="Total Sales this Week"
              icon={PaidOutlinedIcon}
              sx={{ gridColumn: isMobilePhone ? 'span 6' : 'span 3' }}
            />
            <CustomDashboardWidget
              count={`${formatCurrency(stats.total_sales_this_month)}`}
              label="Total Sales this Month"
              icon={PaidOutlinedIcon}
              sx={{ gridColumn: isMobilePhone ? 'span 6' : 'span 3' }}
            />
            <CustomDashboardWidget
              count={`${formatCurrency(stats.total_sales_this_year)}`}
              label="Total Sales this Year"
              icon={PaidOutlinedIcon}
              sx={{ gridColumn: isMobilePhone ? 'span 6' : 'span 3' }}
            />
            {/* <CustomDashboardWidget
              count={`${formatNumber(dateDependentData?.patients_served, 0)}`}
              label="Total Patients Served"
              icon={GroupOutlinedIcon}
              sx={{ gridColumn: isMobilePhone ? 'span 6' : 'span 3' }}
            /> */}
            <CustomDashboardWidget
              count={stats.sms_credits.toLocaleString()}
              label="Available Credits"
              icon={ChatIcon}
              sx={{ gridColumn: isMobilePhone ? 'span 6' : 'span 3' }}
            />
          </Box>

          <Box display="grid" gridTemplateColumns={isMobilePhone ? 'repeat(6, 2fr)' : 'repeat(12, 1fr)'} gap="20px">
            {facility.is_admin ? (
              <Box sx={{ gridColumn: 'span 6' }}>
                <CustomDateRangeFilter setFilterDateRange={setFilterDateRange} />
              </Box>
            ) : (
              <Box sx={{ gridColumn: 'span 12' }}>
                <CustomDateRangeFilter setFilterDateRange={setFilterDateRange} />
              </Box>
            )}
            {facility.is_admin && user.facilities && (
              <Box sx={{ gridColumn: 'span 6' }}>
                <CustomClinicFilter
                  facilities={user.facilities}
                  clinicFilters={clinicFilters}
                  setClinicFilters={setClinicFilters}
                />
              </Box>
            )}

            <DashboardPieChart title="HMO Transactions" processData={processHMOBreakdown} decimalPlaces={0} />
            <DashboardBarChart
              title="Payments Received"
              xAxisLabel="HMO"
              yAxisLabel="Total Amount (Php)"
              processData={processHMOBreakdownBySales}
            />
            <DashboardPieChart
              title="Patients Served by each Service Provider"
              processData={processServiceProviderPatientServed}
              decimalPlaces={0}
            />
            <DashboardPieChart
              title="Payment Received Per Method"
              processData={processSalesByPaymentMethod}
              showCurrency={true}
            />

            <DashboardBarChart
              title="Payment Received by each Service Provider"
              xAxisLabel="Service Providers"
              yAxisLabel="Total amount (Php)"
              processData={processServiceProviderPatientTotalPayment}
              colSpan={12}
            />

            <DashboardPieChart
              title="Payment Received by Service Provider"
              processData={processServiceProviderPatientTotalPaymentPie}
              showCurrency={true}
            />

            <DashboardLineChart
              title="Sales per Month"
              xAxisLabel="Month"
              yAxisLabel="Total Sales (Php)"
              processData={processMonthOnMonthSales}
            />
            <DashboardLineChart
              title="Sessions per Month"
              xAxisLabel="Month"
              yAxisLabel="Count"
              processData={processMonthOnMonthSessionCount}
            />
            {/* <DashboardBarChart
              title="Top 5 Best Selling Products By Quantity"
              xAxisLabel="Products"
              yAxisLabel="Number of items sold"
              processData={processBestSellingDataByQuantity}
            />
            <DashboardBarChart
              title="Top 5 Best Selling Products By Sales"
              xAxisLabel="Products"
              yAxisLabel="Total amount of sales (Php)"
              processData={processBestSellingDataBySales}
            /> */}
          </Box>
        </>
      ) : (
        <>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: '20px' }}>
            <Skeleton variant="rounded" width={'32.5%'} height={80} sx={{ borderRadius: '12px' }} animation="wave" />
            <Skeleton variant="rounded" width={'32.5%'} height={80} sx={{ borderRadius: '12px' }} animation="wave" />
            <Skeleton variant="rounded" width={'32.5%'} height={80} sx={{ borderRadius: '12px' }} animation="wave" />
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: '20px' }}>
            <Skeleton
              variant="rounded"
              width={'50%'}
              height={350}
              sx={{ borderRadius: '8px', marginTop: '50px' }}
              animation="wave"
            />
            <Skeleton
              variant="rounded"
              width={'50%'}
              height={350}
              sx={{ borderRadius: '8px', marginTop: '50px' }}
              animation="wave"
            />
          </Box>
        </>
      )}
    </CareGoPage>
  );
};

export default InventoryDashboard;
