import { Box, Input } from '@mui/material';
import React, { useState, ChangeEvent, KeyboardEvent } from 'react';

type TokenInputProps = {
  value: string;
  onChange: (value: string) => void;
  specialTokens: any;
  placeholder?: string;
};

const TokenInput: React.FC<TokenInputProps> = ({ value, onChange, specialTokens, placeholder }) => {
  const [suggestion, setSuggestion] = useState<string>('');

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (suggestion && (e.key === 'Tab' || e.key === 'Enter')) {
      e.preventDefault();
      const lastBracketIndex = value.lastIndexOf('[');
      const beforeToken = value.slice(0, lastBracketIndex);
      const newValue = beforeToken + suggestion;
      onChange(newValue);
      setSuggestion('');
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    const oldValue = value;

    // If we're deleting characters
    if (newValue.length < oldValue.length) {
      const cursorPosition = e.target.selectionStart || 0;

      // Find tokens in the text
      const tokenRegex = /\[[^\]]+\]/g;
      let match;

      // Check each token
      while ((match = tokenRegex.exec(oldValue)) !== null) {
        const tokenStart = match.index;
        const tokenEnd = tokenStart + match[0].length;

        // If deletion happened within a token, remove the entire token
        if (cursorPosition > tokenStart && cursorPosition <= tokenEnd) {
          const beforeToken = oldValue.slice(0, tokenStart);
          const afterToken = oldValue.slice(tokenEnd);
          onChange(beforeToken + afterToken);
          return;
        }
      }
    }

    onChange(newValue);

    const lastBracketIndex = newValue.lastIndexOf('[');
    if (lastBracketIndex !== -1 && !newValue.slice(lastBracketIndex).includes(']')) {
      const searchText = newValue.slice(lastBracketIndex).toLowerCase();
      const match = specialTokens.find((token: any) => token.token.toLowerCase().startsWith(searchText));
      setSuggestion(match ? match.token : '');
    } else {
      setSuggestion('');
    }
  };

  return (
    <>
      <Box position="relative" width="100%">
        <Input
          value={value}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          multiline
          fullWidth
          disableUnderline
          placeholder={placeholder}
          sx={{
            width: '100%',
            textDecoration: 'none',
            padding: 0,
            border: 'none',
            ':focus': {
              outline: 'none',
            },
            '&.Mui-focused': {
              border: 'none',
              boxShadow: 'none',
            },
          }}
        />
      </Box>{' '}
      {suggestion && (
        <Box
          sx={{
            left: 0,
            padding: '4px 8px',
            backgroundColor: 'rgba(0, 0, 0, 0.05)',
            borderRadius: '4px',
            fontSize: '0.875rem',
            color: 'text.secondary',
            zIndex: 1,
          }}
        >
          Press Tab to complete: {suggestion}
        </Box>
      )}
    </>
  );
};

export default TokenInput;
