import {
  APL_ENTITY_NAME,
  AppointmentLegendModel,
  appointmentLegendFieldsTable,
  appointmentLegendTableColumns,
} from './AppointmentLegendModel';
import { deleteLegend, getAllAppointmentLegends, getAllBatchTags } from 'company/api/appointment-legends';
import { forwardRef, useContext, useRef, useState } from 'react';

import { ApiQuery } from 'core/model/interface';
import AppointmentLegendForm from './AppointmentLegendForm';
import { Box } from '@mui/material';
import { FacilityContext } from 'core/context/facility.context';
import { TableActionProps } from 'core/PageBuilder/CareGoTable';
import TemplateTable from 'company/entities/Template/TemplateTable';

type Props = {
  visibleColumns?: string[];
  tableRef?: any;
  tableAction?: TableActionProps;
  callBackAfterSubmitFromAppointment?: () => void;
  fromBatch?: boolean;
};

const AppointmentLegendTable = forwardRef((props: Props, ref) => {
  const { visibleColumns, tableRef, tableAction, callBackAfterSubmitFromAppointment, fromBatch = false } = props;
  const [selectedRow, setSelectedRow] = useState<AppointmentLegendModel>();

  const backupRef: any = useRef();
  const mainRef: any = tableRef ?? backupRef;
  const { facility } = useContext(FacilityContext);

  const getData = (query: ApiQuery) => {
    return getAllAppointmentLegends(facility.id, query);
  };

  const getBatchTags = (query: ApiQuery) => {
    return getAllBatchTags(facility.id, query);
  };

  const refreshTable = () => {
    if (mainRef.current) {
      mainRef.current.refreshTable();
    } else {
      console.error('tableRef is not assigned correctly');
    }
  };

  function callBackAfterSubmit() {
    refreshTable();
    callBackAfterSubmitFromAppointment && callBackAfterSubmitFromAppointment();
    mainRef.current?.closeFormModal();
  }

  const handleDeleteAppointmentLegend = (data: any) => {
    return deleteLegend(facility.id, data);
  };

  return (
    <Box sx={{ mt: 2 }}>
      <TemplateTable
        handleDelete={(data) => handleDeleteAppointmentLegend(data)}
        useColoredTable
        ref={mainRef}
        entityName={!fromBatch ? APL_ENTITY_NAME : 'Batch Tag'}
        fields={appointmentLegendFieldsTable}
        getData={!fromBatch ? getData : getBatchTags}
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
        visibleColumns={visibleColumns ?? appointmentLegendTableColumns}
        modalWidth={600}
        templateForm={
          <AppointmentLegendForm
            callbackAfterSubmit={callBackAfterSubmit}
            legend={selectedRow}
            fromBatch={fromBatch}
            visibleFields={fromBatch ? ['tag_name'] : undefined}
          />
        }
      />
    </Box>
  );
});

export default AppointmentLegendTable;
