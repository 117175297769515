import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { ConfirmationDialog, CustomModal, RegularButton } from 'core/components';
import InfiniteScrollContainer from 'core/components/dataView/InfiniteScrollContainer';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import {
  deleteSetupExportedReports,
  getAllSetupExportedReports,
  getSetupExportedReport,
} from 'company/api/setup-exported-reports';
import { ApiQuery } from 'core/model/interface';
import { useContext, useEffect, useState } from 'react';
import { tokens } from 'theme/theme';
import { ContainerRow } from 'core/components/containers';
import { FacilityContext } from 'core/context/facility.context';
import { useSnackbar } from 'notistack';
import ExportedReportForm from './ExportedReportForm';
import PatientField from '../PatientManagement/components/PatientField';
import ExportedReportSignatories from './ExportedReportSignatories';
import {
  getExportedReportSignatories,
  createExportedReportSignatory,
  deleteExportedReportSignatory,
  deleteExportedReportSignatories,
} from 'company/api/exported-report-signatories';

const ExportedReports = () => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const isMobilePhone = useMediaQuery('(max-width:768px)');
  const colors = tokens(theme.palette.mode);
  const { facility } = useContext(FacilityContext);

  const [selectedExportedReport, setSelectedExportedReport] = useState<number>();
  const [openAddExportedReport, setOpenAddExportedReport] = useState(false);
  const [toUpdateExportedReport, setToUpdateExportedReport] = useState<any>();

  const [openDeleteExportedReport, setOpenDeleteExportedReport] = useState(false);
  const [addedExportedReport, setAddedExportedReport] = useState<number>();
  const [deletedExportedReport, setDeletedExportedReport] = useState<number>();
  const [updatedExportedReport, setUpdatedExportedReport] = useState<number>();
  const [rawExportedReport, setRawExportedReport] = useState<any>();
  const [reportSignatories, setReportSignatories] = useState<any[]>([]);

  const getData = (query: ApiQuery) => {
    return getAllSetupExportedReports(query);
  };

  const displayItem = (item: any) => {
    const isSelected = selectedExportedReport === item.id;
    return (
      <ContainerRow>
        <Typography variant="h5" color={isSelected && !isMobilePhone ? 'white' : colors.text} fontWeight="600">
          {item.report_name}
        </Typography>
      </ContainerRow>
    );
  };

  const refreshExportedReportDetails = () => {
    if (selectedExportedReport) {
      getSetupExportedReport(selectedExportedReport).then((response: any) => {
        setRawExportedReport(response.data);
      });
    }
  };

  const handleExportedReportSubmit = (data: any) => {
    setOpenAddExportedReport(false);
    setToUpdateExportedReport(undefined);
    // setRefreshTrigger((prev) => prev + 1);

    if (toUpdateExportedReport) {
      setUpdatedExportedReport(data.setup_exported_report);
      setRawExportedReport(data.setup_exported_report);
    } else {
      setAddedExportedReport(data.setup_exported_report.id);
      setSelectedExportedReport(data.setup_exported_report.id);
      setRawExportedReport(data.setup_exported_report);
    }
  };

  const handleDelete = () => {
    if (selectedExportedReport) {
      deleteSetupExportedReports(selectedExportedReport).then((response: any) => {
        setDeletedExportedReport(selectedExportedReport);
        setOpenDeleteExportedReport(false);
        enqueueSnackbar('Exported Report successfully deleted!', { variant: 'success' });
      });
    }
  };

  const handleSignatoriesChange = async (newSignatories: any[]) => {
    if (!selectedExportedReport || !facility?.id) return;

    try {
      if (newSignatories.length < reportSignatories.length) {
        const deletedSignatory = reportSignatories.find(
          (sig) => !newSignatories.find((newSig) => newSig.signatory_id === sig.signatory_id)
        );
        if (deletedSignatory) {
          await deleteExportedReportSignatory(deletedSignatory.id);
          enqueueSnackbar('Successfully removed signatory', { variant: 'success' });
        }
      } else if (newSignatories.length > reportSignatories.length) {
        const newSignatory = newSignatories[newSignatories.length - 1];
        const response = await createExportedReportSignatory({
          ...newSignatory,
          facility_id: facility.id,
        });

        const updatedSignatories = newSignatories.map((sig, index) =>
          index === newSignatories.length - 1 ? { ...sig, id: response.data.setup_exported_report_signatory.id } : sig
        );

        setReportSignatories(updatedSignatories);
        enqueueSnackbar('Successfully added signatory', { variant: 'success' });
        return;
      }
      setReportSignatories(newSignatories);
    } catch (error) {
      enqueueSnackbar('Error updating signatories', { variant: 'error' });
    }
  };

  useEffect(() => {
    refreshExportedReportDetails();
  }, [selectedExportedReport, updatedExportedReport, addedExportedReport, facility]);

  useEffect(() => {
    async function getExportedReportDetails() {
      if (!selectedExportedReport) return;
      const res = await getSetupExportedReport(selectedExportedReport);
      setRawExportedReport(res.data);
    }
    getExportedReportDetails();
  }, [selectedExportedReport]);

  useEffect(() => {
    if (selectedExportedReport && facility?.id) {
      getExportedReportSignatories(facility.id, selectedExportedReport)
        .then((response) => {
          setReportSignatories(response.data.data);
        })
        .catch(() => {
          enqueueSnackbar('Error fetching signatories', { variant: 'error' });
        });
    }
  }, [selectedExportedReport, facility?.id]);

  return (
    <Box>
      <InfiniteScrollContainer
        // key={refreshTrigger}
        urlKeyId="ex-rep-id"
        title="List of Exported Reports"
        getData={getData}
        getSingleData={getSetupExportedReport}
        renderItem={displayItem}
        selected={selectedExportedReport}
        setSelected={setSelectedExportedReport}
        addedNewItem={addedExportedReport}
        updatedItem={updatedExportedReport}
        deleteId={deletedExportedReport}
        containerHeight="calc(100vh - 170px)"
        titleComponents={
          <Box>
            {!isMobilePhone && (
              <RegularButton
                startIcon={<AddIcon />}
                label="Add"
                onClick={() => setOpenAddExportedReport(true)}
                size="small"
              />
            )}
          </Box>
        }
        displaySelectedTitle={() => (
          <Typography variant="h3" fontWeight={700} color={colors.primary}>
            {rawExportedReport?.report_name}
          </Typography>
        )}
        actions={
          !isMobilePhone
            ? [
                {
                  icon: <EditOutlinedIcon />,
                  label: 'Update',
                  action: (data) => {
                    setOpenAddExportedReport(true);
                    setToUpdateExportedReport(rawExportedReport);
                  },
                },
                {
                  icon: <DeleteOutlineIcon />,
                  label: 'Delete',
                  color: 'error',
                  action: () => {
                    setOpenDeleteExportedReport(true);
                  },
                },
              ]
            : undefined
        }
      >
        {!!selectedExportedReport ? (
          <Box>
            {!isMobilePhone ? (
              <>
                <Box display="grid" gridTemplateColumns="1fr 1fr" rowGap="15px" columnGap="50px" paddingTop={2}>
                  <PatientField label="Report Name" value={rawExportedReport?.report_name} />
                  <PatientField label="Description" value={rawExportedReport?.report_description || '-'} />
                </Box>
                <Box mt={4}>
                  <ExportedReportSignatories
                    reportId={selectedExportedReport}
                    signatories={reportSignatories}
                    onSignatoriesChange={handleSignatoriesChange}
                  />
                </Box>
              </>
            ) : (
              <Box>
                <Typography sx={{ fontSize: '0.825rem', fontWeight: '600' }}>Report Details</Typography>
                <Box display="grid" gridTemplateColumns="1fr 1fr" rowGap="15px" columnGap="50px" paddingTop={2}>
                  <PatientField label="Report Name" value={rawExportedReport?.report_name} />
                  <PatientField label="Description" value={rawExportedReport?.report_description || '-'} />
                </Box>
              </Box>
            )}
          </Box>
        ) : (
          <Typography sx={{ color: 'text.secondary', textAlign: 'center', py: 2 }}>
            No exported report added yet
          </Typography>
        )}
      </InfiniteScrollContainer>

      <CustomModal
        header={toUpdateExportedReport ? 'Update Exported Report' : 'Add Exported Report'}
        open={openAddExportedReport}
        setOpen={setOpenAddExportedReport}
        onClose={() => setToUpdateExportedReport(undefined)}
      >
        <ExportedReportForm exportedReport={toUpdateExportedReport} callbackAfterSubmit={handleExportedReportSubmit} />
      </CustomModal>

      {rawExportedReport && (
        <ConfirmationDialog
          content={`Are you sure you want to delete ${rawExportedReport.report_name}?`}
          open={openDeleteExportedReport}
          setOpen={setOpenDeleteExportedReport}
          onConfirm={handleDelete}
          //   bottomDrawerDialog={isMobilePhone}
        />
      )}
    </Box>
  );
};

export default ExportedReports;
