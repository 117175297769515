import { NewProductVariantsPage, POS } from 'company/screens/InventorySystem';

import ClinicInventory from 'company/screens/InventorySystem/Inventory/ClinicInventory';
import InventoryDashboard from 'company/screens/InventorySystem/Dashboard';
import InventoryPage from 'company/screens/InventorySystem/Inventory/InventoryPage';
import PatientBilling from 'company/screens/RegistrationBoard/components/PatientBilling';
import { ProtectedRoute } from 'core/components';
import { Route } from 'react-router-dom';
import ViewCategories from 'company/screens/InventorySystem/Inventory/ViewCategories';
import { Typography } from '@mui/material';
import InventorySettings from 'company/entities/modules/InventorySystem/InventorySettings';

const InventoryRoutes = (
  <Route path="/company/inventory" element={<ProtectedRoute parent inventory requiredAuth={['MANAGE_INVENTORY']} />}>
    <Route index element={<ClinicInventory />} />
    <Route path="dashboard" element={<InventoryDashboard />} />
    <Route path="pos" element={<ProtectedRoute requiredAuth={['USE_POS']} element={<POS />} />} />
    <Route
      path="patient-transactions"
      element={
        <ProtectedRoute
          // requiredRole={['COMPANY_ADMIN',  'COMPANY_STAFF', 'CLINIC_STAFF']}
          requiredAuth={['VIEW_PATIENT_TRANSACTIONS']}
          element={<PatientBilling viewInInventory />}
        />
      }
    />
    <Route path="products">
      <Route
        index
        element={
          <ProtectedRoute
            requiredAuth={['VIEW_PRODUCTS_AND_VARIANTS', 'MANAGE_PRODUCTS_AND_VARIANTS']}
            element={<InventoryPage />}
          />
        }
      />
      <Route path="variant" element={<NewProductVariantsPage />} />
    </Route>

    <Route path="settings">
      <Route
        index
        element={
          <ProtectedRoute
            requiredAuth={['MANAGE_INVENTORY', 'MANAGE_PRODUCTS_AND_VARIANTS']}
            element={<InventorySettings />}
          />
        }
      />
    </Route>

    <Route path="products/categories" element={<ViewCategories />} />
  </Route>
);

export default InventoryRoutes;
