import { ApiQuery } from 'core/model/interface';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'company-sms-templates';

export const getTemplates = (query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}`, {
    params: query,
  });
};

export const getLocalizedTemplates = (template_id: number, facility_id: number, query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/localized/${template_id}/${facility_id}`, {
    params: query,
  });
};

export const updateLocalizedTemplates = (template_id: number, facility_id:number, template_body: string) => {
  return axios.put(`${baseURL}/${path}/localized/${template_id}/${facility_id}`, {
    template_body,
  });
};

export const previewSms = (facility_id: number, sms_id: number | null, sms_template: string | null) => {
  return axios.get(`${baseURL}/${path}/preview/${facility_id}`, {
    params: {
      sms_id,
      sms_template,
    },
  });
};
