import {
  PRESCRIPTION_ENTITY_NAME,
  PrescriptionModel,
  prescriptionFields,
  prescriptionFormFields,
  prescriptionInitialValues,
  prescriptionWithMedicineFormFields,
  prescriptionWithMedicineInitialValues,
  prescriptionWithMedicineVisibleFormFields,
} from './PrescriptionModel';
import { createPrescription, updatePrescription } from 'company/api/prescriptions';
import { useContext, useEffect, useState } from 'react';

import { FacilityContext } from 'core/context/facility.context';
import TemplateForm from 'company/entities/Template/TemplateForm';
import { doctor_prescription_schema } from 'company/model/schema';
import { formatDateTime } from 'core/utils';
import { getAllPatientAppointments } from 'company/api/appointments';
import { getDoctorsAssigned } from 'company/api/doctors';
import { useMediaQuery } from '@mui/material';

type Props = {
  prescription?: PrescriptionModel;
  appointmentId?: number;
  patientID: number;
  noteID?: number;
  callbackAfterSubmit?: (data?: any) => void;
};
const PrescriptionForm: React.FC<Props> = (props) => {
  const { prescription: initialPrescription, appointmentId, patientID, noteID, callbackAfterSubmit } = props;
  const [prescription, setPrescription] = useState<PrescriptionModel | undefined>(initialPrescription);
  const { facility } = useContext(FacilityContext);
  const [options, setOptions] = useState({ doctor_id: [], appointment_id: [] });
  const isMobilePhone = useMediaQuery('(max-width:768px)');

  interface Medicine {
    medicine_id: number;
    quantity: number;
    instruction?: string | null;
    dosage?: string | null;
    frequency?: string | null;
    duration?: string | null;
    start_date?: string | null;
    time_to_take?: string | null;
  }

  interface PrescriptionData {
    patient_id: number;
    note_id: number | undefined;
    doctor_id: number;
    appointment_id?: number;
    medicines: Medicine[];
  }

  const handleSubmit = (formData: any) => {
    // Add patient_id and note_id to the form data
    const data: PrescriptionData = {
      patient_id: patientID,
      note_id: noteID,
      doctor_id: formData.doctor_id,
      appointment_id: formData.appointment_id,
      medicines: [],
    };

    const apiCall = prescription
      ? updatePrescription(facility.id, prescription.id, data)
      : createPrescription(facility.id, data);

    return apiCall.then((result) => {
      setPrescription(result.data);
      return result;
    });
  };

  useEffect(() => {
    getDoctorsAssigned(facility.id, {}).then((response) => {
      const doctorsList = response.data.data.reduce((uniqueDoctors: any[], doctor: any) => {
        const isDuplicate = uniqueDoctors.some((d: any) => d.value === doctor.doctor_id);

        if (!isDuplicate) {
          uniqueDoctors.push({ key: doctor.full_name, value: doctor.doctor_id });
        }

        return uniqueDoctors;
      }, []);

      getAllPatientAppointments(facility.id, patientID).then((docs) => {
        const filteredAppointments: any = docs.data.filter(
          (doc: any) => doc.status !== 'cancelled' && doc.status !== 'no-show'
        );
        filteredAppointments.sort((a: any, b: any) => new Date(b.schedule).getTime() - new Date(a.schedule).getTime());

        setOptions({
          doctor_id: doctorsList,
          appointment_id: filteredAppointments.map((u: any) => {
            return { key: `${formatDateTime(u.schedule)} - ${u.service_names}`, value: u.id };
          }),
        });
      });
    });
  }, [facility.id, patientID]);

  return (
    <>
      <TemplateForm
        submitButtonId={'create_prescriptions_submit_button'}
        entity={prescription}
        entityName={PRESCRIPTION_ENTITY_NAME}
        fields={isMobilePhone ? prescriptionWithMedicineFormFields : prescriptionFields}
        visibleFields={
          isMobilePhone
            ? appointmentId
              ? ['doctor_id']
              : prescriptionWithMedicineVisibleFormFields
            : appointmentId
            ? ['doctor_id']
            : prescriptionFormFields
        }
        initialValues={
          isMobilePhone
            ? appointmentId
              ? { ...prescriptionWithMedicineInitialValues, appointment_id: appointmentId }
              : prescriptionWithMedicineInitialValues
            : appointmentId
            ? { ...prescriptionInitialValues, appointment_id: appointmentId }
            : prescriptionInitialValues
        }
        dropdownOptions={options}
        formSubmitApiFunction={handleSubmit}
        callbackAfterSubmit={callbackAfterSubmit}
        schema={doctor_prescription_schema}
      />
    </>
  );
};

export default PrescriptionForm;
