import { ServiceCategorySearchProp } from 'company/screens/ClinicManagement/components/Services/components/ServicesPerCategoryTable';
import { ApiQuery } from 'core/model/interface';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'service-packages';

export const getAllServicePackages = (facility_id: number, query?: ServiceCategorySearchProp) => {
  return axios.get(`${baseURL}/${path}/${facility_id}`, { params: query });
};

export const getServicePackage = (facility_id: number, id: number) => {
  return axios.get(`${baseURL}/${path}/${facility_id}/${id}`);
};

export const updateServicePackage = (facility_id: number, id: number, data: any) => {
  return axios.put(`${baseURL}/${path}/${facility_id}/${id}`, data);
};

export const deleteServicePackage = (facility_id: number, id: number) => {
  return axios.delete(`${baseURL}/${path}/${facility_id}/${id}`);
};

export const getServicePackageClinics = (packageId: number, query?: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/${packageId}/clinics`, { params: query });
};

export const getServicePackageByGroupId = (group_id: number) => {
  return axios.get(`${baseURL}/${path}/by-group/${group_id}`);
};

export const assignServicePackageToClinic = (clinic_id: number, packages: any) => {
  return axios.post(`${baseURL}/${path}/clinic/${clinic_id}`, packages);
};

export const removeServicePackagesFromClinic = (clinic_id: number, service_packages: any) => {
  return axios.delete(`${baseURL}/${path}/clinic/${clinic_id}`, {
    data: {
      service_packages: service_packages,
    },
  });
};
