import { Accordion, AccordionDetails, AccordionSummary, useTheme } from '@mui/material';

import { CompanyAppointmentModel } from 'company/entities/modules/ClinicManagement/Appointment/AppointmentModel';
import CompiledAPE from './CompiledAPE';
import EjectIcon from '@mui/icons-material/Eject';
import { UserContext } from 'core/context/user.context';
import { isDevMode } from 'core/components/HideOrShowComponent';
import { tokens } from 'theme/theme';
import { useContext } from 'react';

type Props = {
  appointment: CompanyAppointmentModel;
};

const AnnualPhysicalExam: React.FC<Props> = ({ appointment }) => {
  const { user } = useContext(UserContext);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  if (
    !isDevMode() &&
    !(user.company?.company_name === 'CPHI' || user.company?.company_name.includes('St. Elsewhere'))
  ) {
    return <></>;
  }

  return (
    <Accordion
      sx={{
        backgroundColor: 'none',
        boxShadow: 'none',
        '&:before': { display: 'none' },
        '& .MuiAccordionSummary-root': {
          borderRadius: '15px',
          padding: '0 20px',
          backgroundColor: colors.light_blue_background, // Background color for header
          '& .Mui-expanded': {
            borderRadius: '50px',
          },
        },
        '& .MuiAccordionDetails-root': {
          padding: '16px 5px',
        },
      }}
      // defaultExpanded
    >
      <AccordionSummary
        expandIcon={<EjectIcon sx={{ rotate: '180deg', color: colors.primary, fontSize: '20px' }} />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        View Summarized Form
      </AccordionSummary>
      <AccordionDetails>
        <CompiledAPE assessments={[appointment]} />
      </AccordionDetails>
    </Accordion>
  );
};

export default AnnualPhysicalExam;
