import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  TextField,
  Divider,
  Grid,
} from '@mui/material';

export default function HealthOneAssessmentForm() {
  return (
    <Box sx={{ maxWidth: '1000px', minWidth: '600px' }}>
      <div
        style={{
          fontFamily: 'Arial, sans-serif',
          maxWidth: '900px',
          margin: '0 auto',
          padding: '20px',
        }}
      >
        {/* Header */}
        <div style={{ textAlign: 'center', marginBottom: '20px' }}>
          <img
            alt="health-one-logo"
            width="380px"
            height="auto"
            id="logo"
            src={'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQPM5ATOHx1V63_iaxGsVzEaOIugsgWEEPFpg&s'}
            style={{
              height: '200px',
              width: '380px',
              objectFit: 'cover',
            }}
          />
          <p style={{ margin: '-20px', fontSize: '12px' }}>
            2nd Floor ETG Business Center A. Mabini St., San Pedro, Laguna
            <br />
            Contact No.: (0917) 106 1932; (02) 83262085
            <br />
            Email address: healthoneclinic@gmail.com
          </p>
        </div>

        {/* Title */}
        <h2 style={{ textAlign: 'center', paddingBottom: '5px', fontSize: '18px' }}>MEDICAL REPORT</h2>

        {/* Patient Information */}
        <table style={{ width: '100%', marginBottom: '20px', borderCollapse: 'collapse' }}>
          <tr>
            <td style={{ border: '1px solid black', padding: '8px', width: '50%', fontSize: '12px' }}>
              <strong>PATIENT NAME:</strong>
            </td>
            <td style={{ border: '1px solid black', padding: '8px', width: '50%', fontSize: '12px' }}>
              <strong>DATE:</strong>
            </td>
          </tr>
          <tr>
            <td style={{ border: '1px solid black', padding: '8px', fontSize: '12px' }}>
              <strong>COMPANY NAME:</strong>
            </td>
            <td style={{ border: '1px solid black', padding: '8px', fontSize: '12px' }}>
              <strong>BIRTHDATE:</strong>
            </td>
          </tr>
        </table>

        {/* Summary */}
        <h3 style={{ fontSize: '14px' }}>SUMMARY</h3>
        <h4 style={{ marginBottom: '10px', fontSize: '12px' }}>MEDICAL HISTORY AND PHYSICAL EXAMINATION</h4>
        <div style={{ padding: '8px', height: '80px', fontSize: '12px' }}>
          <p style={{ margin: '0' }}>1.</p>
          <p style={{ margin: '0' }}>2.</p>
          <p style={{ margin: '0' }}>3.</p>
        </div>

        {/* Laboratory */}
        <h3 style={{ fontSize: '14px' }}>LABORATORY</h3>
        <table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '20px', fontSize: '12px' }}>
          <thead>
            <tr>
              <th style={{ border: '1px solid black', padding: '8px' }}>DATE</th>
              <th style={{ border: '1px solid black', padding: '8px' }}>TEST</th>
              <th style={{ border: '1px solid black', padding: '8px' }}>RESULTS</th>
            </tr>
          </thead>
          <tbody>
            {[
              'Complete Blood Count (CBC)',
              'Urinalysis',
              'Pregnancy Test',
              'Fecalysis',
              'Drug Test',
              'Hepatitis B surface antigen (HbsAg)',
              'ECG',
              'Others',
            ].map((test, index) => (
              <tr key={index}>
                <td style={{ border: '1px solid black', padding: '8px' }}></td>
                <td style={{ border: '1px solid black', padding: '8px' }}>{test}</td>
                <td style={{ border: '1px solid black', padding: '8px' }}></td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* Chest X-Ray */}
        <h3 style={{ marginTop: '20px', fontSize: '16px', fontWeight: 'bold' }}>CHEST X-RAY</h3>
        <table
          style={{
            width: '100%',
            borderCollapse: 'collapse',
            marginBottom: '20px',
            fontSize: '12px',
            textAlign: 'left',
          }}
        >
          <thead>
            <tr>
              <th style={{ border: '1px solid black', padding: '8px', width: '25%' }}>DATE</th>
              <th style={{ border: '1px solid black', padding: '8px' }}>FINDINGS</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={{ border: '1px solid black', padding: '8px', height: '30px' }}></td>
              <td style={{ border: '1px solid black', padding: '8px', height: '30px' }}></td>
            </tr>
          </tbody>
        </table>

        {/* Classification */}
        <h3 style={{ fontSize: '14px' }}>CLASSIFICATION: CLASS ______</h3>
        <p style={{ marginBottom: '20px', fontSize: '12px' }}>
          <strong>Class A:</strong> Individuals who are deemed fit to work without medical intervention.
          <br />
          <strong>Class B:</strong> Individuals requiring short-term medical intervention. Physically under-developed or
          with correctible defects (e.g., error of refraction, dental caries, defective hearing) but otherwise fit to
          work.
          <br />
          <strong>Class C:</strong> Individuals with manageable chronic diseases or in need of long-term medical
          intervention. Employable but owing to certain impairments (heart disease, hypertension, anatomical defects),
          requires special placement or limited duty in a specified or selected assignment requiring follow-up
          treatment/periodic evaluation.
          <br />
          <strong>Class D:</strong> Unfit or unsafe for any type of employment (e.g., active PTB, advanced heart disease
          with threatened failure, malignant hypertension, and other similar illnesses).
        </p>

        {/* Findings and Recommendations */}
        <table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '40px', fontSize: '12px' }}>
          <thead>
            <tr>
              <th style={{ border: '1px solid black', padding: '8px' }}>DATE</th>
              <th style={{ border: '1px solid black', padding: '8px' }}>FINDING/S</th>
              <th style={{ border: '1px solid black', padding: '8px' }}>RECOMMENDATION</th>
            </tr>
          </thead>
          <tbody>
            {[...Array(4)].map((_, index) => (
              <tr key={index}>
                <td style={{ border: '1px solid black', padding: '8px', height: '30px' }}></td>
                <td style={{ border: '1px solid black', padding: '8px', height: '30px' }}></td>
                <td style={{ border: '1px solid black', padding: '8px', height: '30px' }}></td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* Medical Evaluator */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'right',
            width: '100%',
            flexDirection: 'column',
            alignItems: 'flex-end',
            textAlign: 'center',
          }}
        >
          <hr style={{ margin: '40px 0 10px 0', borderTop: '1px solid black', width: '30%' }} />
          <p style={{ fontSize: '14px', fontWeight: 'bold' }}>MEDICAL EVALUATOR</p>
        </Box>
      </div>
    </Box>
  );
}
