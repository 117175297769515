import { Box, Typography } from '@mui/material';
import { reorderSummaryTemplateFields } from 'company/api/patient-notes-summary-template-fields';
import { getLinkedNotesFromServiceGroups, reorderServiceLinkedNotes } from 'company/api/service-groups';
import DraggableList from 'core/components/DraggableList';
import { FacilityContext } from 'core/context/facility.context';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { DropResult } from 'react-beautiful-dnd';

interface ServiceGroupsLinkedNotesDragListProps {
  group_id: number;
}
export default function ServiceGroupsLinkedNotesDragList({ group_id }: ServiceGroupsLinkedNotesDragListProps) {
  const { facility } = useContext(FacilityContext);
  const [reorderedFields, setReorderedFields] = useState<any[]>([]);

  useEffect(() => {
    async function getData() {
      const res = await getLinkedNotesFromServiceGroups(facility.id, group_id);
      const sortedData = res.data.sort((a: any, b: any) => a.order - b.order);
      setReorderedFields(sortedData);
    }
    getData();
  }, [group_id]);
  const handleDragEnd = async (result: DropResult) => {
    if (!result.destination) return;

    const newFields = [...reorderedFields];
    const [movedField] = newFields.splice(result.source.index, 1);
    newFields.splice(result.destination.index, 0, movedField);

    setReorderedFields(newFields);

    await reorderServiceLinkedNotes(
      facility.id,
      newFields.map((note, index) => ({ ...note, order: index + 1 }))
    );
  };

  const AddedFields = useCallback(() => {
    return (
      <Box flex="1">
        <DraggableList
          title={'Service Linked Notes'}
          droppableId={'service-linked-notes'}
          handleDragEnd={handleDragEnd}
          list={reorderedFields}
          style={{ padding: '5px 10px' }}
          displayItem={(item, index) => {
            return <Typography>{item.template_name}</Typography>;
          }}
        />
      </Box>
    );
  }, [reorderedFields]);
  return (
    <Box>
      <AddedFields />
    </Box>
  );
}
