import { EntityFields } from 'company/entities/utils';
import * as yup from 'yup';

export const EXPORTED_REPORT_ENTITY_NAME = 'Exported Report';

export const update_exported_report_schema = yup.object().shape({
  report_name: yup.string().required('Report Name is required.').max(100, 'Max of 100 characters.'),
  report_description: yup.string().max(500, 'Max of 500 characters.'),
});

export const create_exported_report_schema = update_exported_report_schema;

export interface ExportedReportModel {
  id: number;
  report_name: string;
  report_description: string | null;
  created_at: string;
  updated_at: string;
}

export interface ExportedReportInput {
  report_name: string;
  report_description: string;
  //   facilities: any[];
  //   apply_to_all_clinics: boolean;
}

export const exportedReportInitialValues: ExportedReportInput = {
  report_name: '',
  report_description: '',
  //   facilities: [],
  //   apply_to_all_clinics: true,
};

export const exportedReportFields: EntityFields[] = [
  { fieldName: 'report_name', displayName: 'Report Name' },
  { fieldName: 'report_description', displayName: 'Description', optional: true, type: 'textarea' },
  //   { fieldName: 'is_active', displayName: 'Active', type: 'checkbox', span: 4 },
  //   {
  //     fieldName: 'apply_to_all_clinics',
  //     displayName: 'Apply to All Clinics',
  //     type: 'checkbox',
  //     span: 4,
  //   },
  //   {
  //     fieldName: 'facilities',
  //     displayName: 'Clinics',
  //     type: 'multiselect',
  //     options: [],
  //     span: 4,
  //     hiddenBasedOnOtherField: (data: any) => {
  //       return data.apply_to_all_clinics;
  //     },
  //   },
];

export const exportedReportFormFields: string[] = [
  'report_name',
  'report_description',
  //   'is_active',
  //   'apply_to_all_clinics',
  //   'facilities',
];

export const updateExportedReportFormFields: string[] = ['report_name', 'report_description'];
