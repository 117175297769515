import { CompanySignatory, NoteTemplateSignatory } from 'company/model/Entities';
import { ApiQuery } from 'core/model/interface';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'note-template-signatories';

export const getNoteTemplateSignatory = (id: number) => {
  return axios.get(`${baseURL}/${path}/${id}`);
};

export const getAllNoteTemplateSignatories = (query?: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/`, {
    params: query,
  });
};

export const getNoteTemplateSignatories = (facility_id: number, patient_note_id: number) => {
  return axios.get(`${baseURL}/${path}/template/${facility_id}/${patient_note_id}`);
};

export const createNoteTemplateSignatory = (data: NoteTemplateSignatory) => {
  return axios.post(`${baseURL}/${path}`, data);
};

export const updateNoteTemplateSignatory = (id: number, data: NoteTemplateSignatory) => {
  return axios.put(`${baseURL}/${path}/${id}`, data);
};

export const deleteNoteTemplateSignatory = (id: number) => {
  return axios.delete(`${baseURL}/${path}/${id}`);
};

export const deleteAllNoteTemplateSignatories = (patient_note_id: number) => {
  return axios.delete(`${baseURL}/${path}/delete-all-signatories-from-template/${patient_note_id}`);
};
