import TemplateForm from 'company/entities/Template/TemplateForm';

import { FacilityContext } from 'core/context/facility.context';
import { useContext } from 'react';

import { createNewNoteSummaryTemplate, updateNoteSummaryTemplate } from 'company/api/patient-note-summary-template';
import { createProductUOM, updateProductUOM } from 'company/api/product-uom';
import {
  createUOMSchema,
  productUnitOfMeasureFields,
  productUnitOfMeasureInitialValues,
  ProductUnitOfMeasureModel,
  productUnitOfMeasureTableColumns,
  PUOM_ENTITY,
} from './ProductUOMModel';
type Props = {
  readonly?: boolean;
  showSubmitAtTop?: boolean;
  callbackAfterSubmit?: (data: any) => void;
  uom?: ProductUnitOfMeasureModel;
};

const ProductUOMForm = (props: Props) => {
  const { readonly, showSubmitAtTop, callbackAfterSubmit, uom } = props;
  const { facility } = useContext(FacilityContext);
  async function handleSubmit(data: any) {
    if (!uom) return createProductUOM(facility.id, data);
    return updateProductUOM(facility.id, uom.id, data);
  }

  return (
    <TemplateForm
      schema={createUOMSchema}
      callbackAfterSubmit={callbackAfterSubmit}
      entityName={PUOM_ENTITY}
      fields={productUnitOfMeasureFields}
      initialValues={uom ?? productUnitOfMeasureInitialValues}
      visibleFields={productUnitOfMeasureTableColumns}
      formSubmitApiFunction={handleSubmit}
    />
  );
};

export default ProductUOMForm;
