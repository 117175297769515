/* eslint-disable react-hooks/exhaustive-deps */

import { Box, Typography, useTheme } from '@mui/material';
import { ColoredTable, ConfirmationDialog, CustomModal, RegularButton } from 'core/components';
import { deleteProductCategory, getProductCategories, getProductsInCategory } from 'company/api/product-categories';
import { useContext, useEffect, useRef, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import { CareGoPage } from 'core/PageBuilder';
import { ContainerRow } from 'core/components/containers';
import CustomLoadingIndicator from 'core/components/CustomLoadingIndicator';
import DeleteButton from 'core/components/buttons/DeleteButton';
import { Link } from 'react-router-dom';
import { ProductCategoryForm } from 'company/entities/forms';
import SearchBar from '../POS/components/SearchBar';
import UpdateButton from 'core/components/buttons/UpdateButton';
import { UserContext } from 'core/context/user.context';
import { tokens } from 'theme/theme';
import { useSnackbar } from 'notistack';

interface Category {
  id: number;
  category: number;
  company_id: number;
  created_by: number;
  description: string;
  is_active: number;
}

const ViewCategories = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { user } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();
  const [selectedCategory, setSelectedCategory] = useState<Category>();
  const [categorySearchQuery, setCategorySearchQuery] = useState<string>('');
  const [openCategoryModal, setOpenCategoryModal] = useState<boolean>(false);
  const [openDeleteCategory, setOpenDeleteCategory] = useState<boolean>(false);
  const [productsDataMap, setProductsDataMap] = useState<Record<number, any>>({});
  const [loadingMap, setLoadingMap] = useState<Record<number, boolean>>({});

  const tableRef: any = useRef();

  const getAllProducts = () => {
    if (selectedCategory) {
      setLoadingMap((prevMap) => ({
        ...prevMap,
        [selectedCategory.id]: true,
      }));
      return getProductsInCategory(selectedCategory.id).then((res) => {
        setProductsDataMap((prevMap) => ({
          ...prevMap,
          [selectedCategory.id]: res.data.data,
        }));
        setLoadingMap((prevMap) => ({
          ...prevMap,
          [selectedCategory.id]: false,
        }));
      });
    }
  };

  const handleSelectRow = (selected: any) => {
    if (selected) {
      setSelectedCategory(selected);
    }
  };

  const refreshTable = () => {
    if (tableRef.current) {
      tableRef.current.refreshTable();
    } else {
      console.error('tableRef is not assigned correctly');
    }
  };

  const handleDeleteCategory = () => {
    if (selectedCategory) {
      const categories = { categories: JSON.stringify([selectedCategory.id]) };
      deleteProductCategory(categories)
        .then((res) => {
          enqueueSnackbar('Category successfully deleted!', { variant: 'success' });
          refreshTable();
        })
        .catch((error) => {
          enqueueSnackbar('Error occured! Unable to delete category.', { variant: 'error' });
        });
    }
  };

  type Variant = {
    variant_id: number;
    variant_name: string;
  };

  useEffect(() => {
    getAllProducts();
  }, [selectedCategory]);

  return (
    <>
      <CareGoPage
        loading={!user}
        // breadcrumb={[
        //   { label: 'Clinic Inventory', link: '/company/inventory' },
        //   { label: 'Product Directory', link: '/company/inventory/products' },
        //   { label: 'Categories' },
        // ]}
      >
        <Box display="flex" flexDirection="column" gap="20px" sx={{ backgroundColor: 'none', margin: '20px' }}>
          <ColoredTable
            ref={tableRef}
            getData={getProductCategories}
            hideSearch
            handleSelectRow={handleSelectRow}
            headerComponent={
              <>
                <Typography
                  variant="h2"
                  style={{
                    color: colors.primary,
                    fontWeight: '700',
                    fontSize: '24px',
                    letterSpacing: 0,
                    textAlign: 'left',
                    justifyContent: 'flex-start',
                  }}
                >
                  Categories
                </Typography>
                <ContainerRow gap="5px" sx={{ justifyContent: 'flex-end' }}>
                  <SearchBar handleSearch={(searchKey: string) => setCategorySearchQuery(searchKey)} />
                  <RegularButton
                    startIcon={<AddIcon />}
                    label="Add Category"
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      setSelectedCategory(undefined);
                      setOpenCategoryModal(true);
                    }}
                  />
                </ContainerRow>
              </>
            }
            columns={[
              {
                field: 'category',
                headerName: 'Category Name',
                flex: 1,
              },
              {
                field: 'description',
                headerName: 'Description',
                flex: 2,
              },
              {
                field: 'action',
                sortable: false,
                headerName: 'Actions',
                headerAlign: 'center',
                align: 'center',
                flex: 1,
                renderCell: (params) => (
                  <Box display="flex" gap="10px">
                    <UpdateButton
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedCategory(params.row);
                        setOpenCategoryModal(true);
                      }}
                    />
                    <DeleteButton
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedCategory(params.row);
                        setOpenDeleteCategory(true);
                      }}
                    />
                  </Box>
                ),
              },
            ]}
            displayChildComponent={(row) => {
              if (!productsDataMap[row.id] && !loadingMap[row.id]) {
                setLoadingMap((prevMap) => ({
                  ...prevMap,
                  [row.id]: true,
                }));
                getProductsInCategory(row.id).then((res) => {
                  setProductsDataMap((prevMap) => ({
                    ...prevMap,
                    [row.id]: res.data.data,
                  }));
                  setLoadingMap((prevMap) => ({
                    ...prevMap,
                    [row.id]: false,
                  }));
                });
              }

              return (
                <>
                  {loadingMap[row.id] ? (
                    <Box display="flex" justifyContent="center" alignItems="center">
                      <CustomLoadingIndicator />
                    </Box>
                  ) : productsDataMap[row.id] && productsDataMap[row.id].length > 0 ? (
                    <ColoredTable
                      key={row.id}
                      getData={() => getProductsInCategory(row.id)}
                      hideSearch
                      columns={[
                        {
                          field: 'product_name',
                          headerName: 'Product Name',
                          flex: 1,
                        },
                        {
                          field: 'variants',
                          headerName: 'Variants',
                          flex: 3,
                          renderCell: (params) => {
                            if (!params.row.variants || params.row.variants.length === 0) {
                              return (
                                <Typography variant="h6" color="textSecondary" align="center">
                                  No variants found.
                                </Typography>
                              );
                            }

                            const columnWidth = 100 / Math.ceil(params.row.variants.length / 3);

                            return (
                              <Box display="flex" flexDirection="column">
                                {[...Array(3)].map((_, rowIndex) => (
                                  <Box key={rowIndex} display="flex">
                                    {params.row.variants
                                      .slice(
                                        rowIndex * Math.ceil(params.row.variants.length / 3),
                                        (rowIndex + 1) * Math.ceil(params.row.variants.length / 3)
                                      )
                                      .map((variant: Variant) => (
                                        <Box
                                          key={variant.variant_id}
                                          flexGrow={1}
                                          minWidth={0}
                                          width={`${columnWidth}%`}
                                          marginRight={1}
                                        >
                                          <Link
                                            to={`/company/inventory/products/variant?id=${variant.variant_id}`}
                                            style={{ color: colors.primary }}
                                          >
                                            {variant.variant_name}
                                          </Link>
                                        </Box>
                                      ))}
                                  </Box>
                                ))}
                              </Box>
                            );
                          },
                        },
                      ]}
                    />
                  ) : (
                    <Typography variant="h6" color="textSecondary" align="center">
                      No products found.
                    </Typography>
                  )}
                </>
              );
            }}
          />
        </Box>
      </CareGoPage>

      <CustomModal
        header={selectedCategory ? 'Update Category' : 'Add Category'}
        open={openCategoryModal}
        setOpen={setOpenCategoryModal}
        width={800}
      >
        <ProductCategoryForm
          productCategory={selectedCategory}
          callbackAfterSubmit={() => {
            refreshTable();
            setOpenCategoryModal(false);
          }}
        />
      </CustomModal>

      {selectedCategory && (
        <ConfirmationDialog
          content={'Are you sure you want to delete ' + selectedCategory.category + '?'}
          open={openDeleteCategory}
          setOpen={setOpenDeleteCategory}
          onConfirm={handleDeleteCategory}
        />
      )}
    </>
  );
};

export default ViewCategories;
