import { Box, Typography, useTheme } from '@mui/material';
import { DragDropContext, Draggable, DropResult, Droppable } from 'react-beautiful-dnd';

import { CSSProperties } from 'react';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { tokens } from 'theme/theme';
import uuid from 'react-uuid';

type DraggableListProps = {
  droppableId: string;
  handleDragEnd: (result: DropResult) => void;
  list: any[];
  style?: CSSProperties;
  displayItem: (item: any, index: number) => any;
  hideDragIcon?: boolean;
  fromModal?: boolean;
  itemBackgroundColor?: any;
  title?: string;
};

const DraggableList: React.FC<DraggableListProps> = ({
  droppableId,
  handleDragEnd,
  list,
  style,
  displayItem,
  hideDragIcon,
  fromModal,
  itemBackgroundColor,
  title,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const positionStyle = fromModal
    ? {
        left: 'auto !important',
        top: 'auto !important',
      }
    : {};

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      {title && (
        <Typography fontWeight="bold" my="10px">
          {title}
        </Typography>
      )}
      <Droppable droppableId={droppableId}>
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {list.map((item, index: number) => {
              if (!item.item_id) item.item_id = uuid();
              return (
                <Draggable key={item.item_id} draggableId={item.item_id} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps} // Continue to spread draggableProps here
                      style={{
                        ...provided.draggableProps.style,
                        borderRadius: '10px',
                        backgroundColor: snapshot.isDragging ? colors.light_blue_background_2 : itemBackgroundColor,
                        ...positionStyle,
                        ...style,
                      }}
                    >
                      <Box display="flex" alignItems={'center'} gap="10px" width="100%">
                        {!hideDragIcon && (
                          <div {...provided.dragHandleProps} style={{ cursor: 'grab' }}>
                            <DragIndicatorIcon />
                          </div>
                        )}
                        <Box width="100%">{displayItem(item, index)}</Box>
                      </Box>
                    </div>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DraggableList;
