import { ApiQuery, FieldInput } from 'core/model/interface';
import { ConfirmationDialog, CustomForm, CustomGridCell, CustomModal, RegularButton } from 'core/components';
import DetailsViewer, { DetailsViewerDataFormat } from '../components/DetailsViewer';
import PageBuilder, { BLOCK_TYPE } from 'core/PageBuilder/PageBuilder';
import { block, getAll, exportRequestLogs } from 'carego-admin/api/carego-request-logs';
import { formatDatetimeJS, simpleQueryParser } from 'core/utils';
import { useEffect, useState } from 'react';

import ArchiveIcon from '@mui/icons-material/Archive';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { Box } from '@mui/material';
import GppMaybeIcon from '@mui/icons-material/GppMaybe';
import { GridColDef } from '@mui/x-data-grid';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import OpenWithIcon from '@mui/icons-material/OpenWith';
import { PageBuilderPageType } from 'core/PageBuilder';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import { firewallArchiveAllLogs } from 'carego-admin/api/carego-request-logs-archive';
import { getCompanies } from 'carego-admin/api/company';
import { useSearchParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';

const RequestLogs: React.FC = () => {
  const [forceRefresh, setForceRefresh] = useState(0);
  const [openShowDetails, setOpenShowDetails] = useState(false);
  const [openAdvancedSearch, setOpenAdvancedSearch] = useState(false);
  const [openArchiveConfirm, setOpenArchiveConfirm] = useState(false);
  const [selectedDetails, setSelectedDetails] = useState<DetailsViewerDataFormat[]>([]);
  const [companies, setCompanies] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [enableRealtime, setEnableRealtime] = useState(false);

  // advanced search values
  const [ipSearch, setIpSearch] = useState(searchParams.get('ip') ?? '');
  const [methodSearch, setMethodSearch] = useState(searchParams.get('methods') ?? '');
  const [startDatetimeSearch, setStartDatetimeSearch] = useState(searchParams.get('start_datetime') ?? '');
  const [endDatetimeSearch, setEndDatetimeSearch] = useState(searchParams.get('end_datetime') ?? '');
  const [sessionSearch, setSessionSearch] = useState(searchParams.get('session') ?? '');
  const [companySearch, setCompanySearch] = useState(searchParams.get('companies') ?? '');
  const [fromIdSearch, setFromIdSearch] = useState(searchParams.get('from_id') ?? '');
  const [toIdSearch, setToIdSearch] = useState(searchParams.get('to_id') ?? '');

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    getCompanies({ length: 10000 }).then((response) => {
      const responseData = response.data.data;
      setCompanies(
        responseData.map((companyData: any) => ({
          key: companyData.company_name,
          value: companyData.id,
        }))
      );
    });
  }, []);

  useEffect(() => {
    setSearchParams({
      ip: ipSearch,
      methods: methodSearch,
      start_datetime: startDatetimeSearch,
      end_datetime: endDatetimeSearch,
      session: sessionSearch,
      companies: companySearch,
      from_id: fromIdSearch,
      to_id: toIdSearch,
    });
  }, [
    ipSearch,
    methodSearch,
    startDatetimeSearch,
    endDatetimeSearch,
    sessionSearch,
    companySearch,
    fromIdSearch,
    toIdSearch,
  ]);

  useEffect(() => {
    setForceRefresh(forceRefresh + 1);
  }, [searchParams]);

  useEffect(() => {
    if (enableRealtime) {
      const interval = setInterval(() => {
        setForceRefresh((refresh) => refresh + 1);
      }, 5 * 1000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [enableRealtime]);

  const getRequestLogs = (apiQuery: ApiQuery) => {
    return getAll({
      ...apiQuery,
      session: searchParams.get('session') ?? '',
      methods: searchParams.get('methods') ?? '',
      start_datetime: searchParams.get('start_datetime') ?? '',
      end_datetime: searchParams.get('end_datetime') ?? '',
      ip: searchParams.get('ip') ?? '',
      companies: searchParams.get('companies') ?? '',
      from_id: searchParams.get('from_id') ?? '',
      to_id: searchParams.get('to_id') ?? '',
    });
  };

  const tableExportCallback = (apiQuery: ApiQuery) => {
    return exportRequestLogs({
      session: searchParams.get('session') ?? '',
      methods: searchParams.get('methods') ?? '',
      start_datetime: searchParams.get('start_datetime') ?? '',
      end_datetime: searchParams.get('end_datetime') ?? '',
      ip: searchParams.get('ip') ?? '',
      companies: searchParams.get('companies') ?? '',
      from_id: searchParams.get('from_id') ?? '',
      to_id: searchParams.get('to_id') ?? '',
      ...apiQuery,
    });
  };

  const extractKeyFromValue = (refArray: { key: any; value: any }[], value: any) => {
    const matched = refArray.find((reference) => reference.value == value);
    return matched ? matched.key : null;
  };

  /////////////////////////////////
  //  Advanced search variables  //
  /////////////////////////////////
  const searchInitialValues = {
    ip: ipSearch,
    start_datetime: searchParams.get('start_datetime'),
    end_datetime: searchParams.get('end_datetime'),
    session: searchParams.get('session'),
    from_id: searchParams.get('from_id'),
    to_id: searchParams.get('to_id'),
    company:
      searchParams.get('companies') && searchParams.get('companies') !== ''
        ? searchParams
            .get('companies')
            ?.split(',')
            .map((companyId) => {
              return {
                key: extractKeyFromValue(companies, companyId) ?? 'Unknown Company',
                value: companyId,
              };
            })
        : [],
    methods:
      searchParams.get('methods') && searchParams.get('methods') !== ''
        ? searchParams
            .get('methods')
            ?.split(',')
            .map((method) => {
              return {
                key: method.toUpperCase(),
                value: method,
              };
            })
        : [],
  };

  // dropdown for company filter (/api/client-management)
  const searchFormFields: FieldInput[] = [
    { field_name: 'ip', type: 'text', display_name: 'IP Address (x.x.x.x)' },
    {
      field_name: 'methods',
      type: 'multiselect',
      display_name: 'Request method',
      options: [
        { key: 'GET', value: 'get' },
        { key: 'POST', value: 'post' },
        { key: 'PUT', value: 'put' },
        { key: 'PATCH', value: 'patch' },
        { key: 'DELETE', value: 'delete' },
      ],
    },
    // { field_name: 'start_datetime', type: 'datetime', display_name: 'Start Date' },
    // { field_name: 'end_datetime', type: 'datetime', display_name: 'End Date' },
    { field_name: 'start_datetime', type: 'date', display_name: 'Start Date' },
    { field_name: 'end_datetime', type: 'date', display_name: 'End Date' },

    { field_name: 'breaker', type: 'divider' },
    { field_name: 'subheader', type: 'subsection_header', body_text: 'Other Searching Methods' },
    { field_name: 'company', type: 'multiselect', display_name: 'Company', options: companies },
    { field_name: 'session', type: 'text', display_name: 'Sesssion ID' },

    { field_name: 'breaker', type: 'divider' },
    { field_name: 'subheader', type: 'subsection_header', body_text: 'ID-Range Searching' },
    { field_name: 'from_id', type: 'text', display_name: 'From ID' },
    { field_name: 'to_id', type: 'text', display_name: 'To ID' },
  ];

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', flex: 1 },
    { field: 'ip', headerName: 'IP Address', flex: 1 },
    { field: 'method', headerName: 'Method', flex: 1, headerAlign: 'center', align: 'center' },
    { field: 'path', headerName: 'Path', flex: 3 },
    {
      field: 'created_at',
      headerName: 'Datetime',
      flex: 2,
      renderCell: (param) => <CustomGridCell>{formatDatetimeJS(param.row.created_at)}</CustomGridCell>,
    },
  ];

  //////////////////////////////
  //  Page builder variables  //
  //////////////////////////////
  const content: PageBuilderPageType = {
    type: BLOCK_TYPE.PAGE,
    header: 'Request Logs',
    block: {
      type: BLOCK_TYPE.CONTAINER,
      block: {
        type: BLOCK_TYPE.TABLE,
        entityName: 'Request Logs',
        doNotWaitForFacility: true,
        tableAction: {
          rowActions: [
            {
              label: 'Show Details',
              icon: <OpenWithIcon />,
              action: (row: any) => {
                const [rawpath, queries] = simpleQueryParser(row.path);
                setSelectedDetails([
                  { summary: 'IP Address', values: row.ip },
                  { summary: 'Full Request Path', values: rawpath, json: queries, hasJSON: true },
                  { summary: 'Request Headers', values: JSON.parse(row.header), isMono: true, isJSON: true },
                  { summary: 'Request Input', values: JSON.parse(row.body ?? '{}'), isMono: true, isJSON: true },
                ]);
                setOpenShowDetails(true);
              },
            },
            {
              label: 'Block IP',
              color: 'error',
              icon: <GppMaybeIcon />,
              action: (row: any) => {
                block(row.id)
                  .then((response) => {
                    enqueueSnackbar('Request IP successfully blocked', { variant: 'success' });
                  })
                  .catch((error) => {
                    enqueueSnackbar('Failed blocking IP (try again later)', { variant: 'error' });
                  });
              },
            },
            {
              label: 'Block CF-IP',
              icon: <GppMaybeIcon />,
              color: 'error',
              action: (row: any) => {
                block(row.id, true)
                  .then((response) => {
                    enqueueSnackbar('Request IP successfully blocked', { variant: 'success' });
                  })
                  .catch((error) => {
                    if (error.response && error.response.data && error.response.data.message)
                      return enqueueSnackbar(error.response.data.message, { variant: 'error' });
                    enqueueSnackbar('Failed blocking IP (try again later)', { variant: 'error' });
                  });
              },
            },
          ],
        },
        tableComponent: {
          exportRecords: tableExportCallback,
          enableRealtimeLoading: true,
          hideViews: true,
          forceRefresh: forceRefresh,
          columns: columns,
          getData: getRequestLogs,
          headerComponent: (
            <Box display="flex" justifyContent="flex-end" gap={1}>
              <RegularButton
                startIcon={<ManageSearchIcon />}
                label="Advanced Search"
                onClick={() => setOpenAdvancedSearch(true)}
              />
              <RegularButton
                startIcon={<ArchiveIcon />}
                label="Archive Current Logs"
                onClick={() => setOpenArchiveConfirm(true)}
              />
              <RegularButton
                startIcon={<SettingsApplicationsIcon />}
                color={enableRealtime ? 'warning' : 'success'}
                label={`${enableRealtime ? 'Disable' : 'Enable'} Real-time`}
                styles={{ color: 'white' }}
                onClick={() => setEnableRealtime(!enableRealtime)}
              />
            </Box>
          ),
        },
      },
    },
  };

  return (
    <>
      <CustomModal
        header={'Request Details'}
        subHeader="Click to expand request values"
        open={openShowDetails}
        setOpen={setOpenShowDetails}
      >
        <DetailsViewer data={selectedDetails} />
      </CustomModal>
      <ConfirmationDialog
        title="Archive all current logs"
        subContent="Are you sure you want to archive all current logs?"
        open={openArchiveConfirm}
        setOpen={setOpenArchiveConfirm}
        onConfirm={() => {
          firewallArchiveAllLogs()
            .then(() => {
              setForceRefresh(forceRefresh + 1);
              enqueueSnackbar('Successfully archived all logs', { variant: 'success' });
            })
            .catch(() => {
              enqueueSnackbar('An error occured when archiving all logs', { variant: 'error' });
            });
        }}
      />
      <CustomModal header={'Advanced Search'} open={openAdvancedSearch} setOpen={setOpenAdvancedSearch}>
        <CustomForm
          showCancelButton
          cancelBtnText="Clear Search"
          submitButtonText="Apply Search"
          loading={false}
          initialValues={searchInitialValues}
          fields={searchFormFields}
          onSubmit={(data: any) => {
            setIpSearch(data.ip);
            setMethodSearch(data.methods.map((method: any) => method.value).join(','));
            setSessionSearch(data.session);
            setEndDatetimeSearch(data.end_datetime);
            setStartDatetimeSearch(data.start_datetime);
            setCompanySearch(data.company.map((method: any) => method.value).join(','));
            setForceRefresh(forceRefresh + 1);
            setFromIdSearch(data.from_id);
            setToIdSearch(data.to_id);
            setOpenAdvancedSearch(false);
          }}
          onCancel={() => {
            setIpSearch('');
            setMethodSearch('');
            setSessionSearch('');
            setEndDatetimeSearch('');
            setStartDatetimeSearch('');
            setCompanySearch('');

            setForceRefresh(forceRefresh + 1);
            setOpenAdvancedSearch(false);
          }}
        />
      </CustomModal>
      <PageBuilder content={content} />
    </>
  );
};

export default RequestLogs;
