import { getAllAppointmentLegends, getAllBatchTags, getLegend } from 'company/api/appointment-legends';
import TemplateDropdown, { TemplateDropdownProps } from './TemplateDropdown';
import { FacilityContext } from 'core/context/facility.context';

import { ApiQuery } from 'core/model/interface';
import { useContext } from 'react';

interface AppointmentLegendSearchDropdownProps extends TemplateDropdownProps {
  fromBatch?: boolean;
}

const AppointmentLegendSearchDropdown: React.FC<AppointmentLegendSearchDropdownProps> = (props) => {
  const { facility } = useContext(FacilityContext);
  const { fromBatch } = props;
  const getData = (query: ApiQuery) => {
    return !fromBatch ? getAllAppointmentLegends(facility.id, query) : getAllBatchTags(facility.id, query);
  };

  const getSingleData = (id: number) => {
    return getLegend(facility.id, id);
  };
  return (
    <TemplateDropdown
      {...props}
      entity={!fromBatch ? 'Appointment Legend' : 'Batch Tag'}
      getData={(query: ApiQuery) => getData({ ...query, order_by: undefined })}
      getById={(id: number) => getSingleData(id)}
      processOption={(record) => ({ key: record.tag_name, value: record.id, data: record })}
      // processNewRecord={(record) => record.appointment_legend}
    />
  );
};

export default AppointmentLegendSearchDropdown;
